import React, { Component } from 'react';
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
// @ts-ignore
import fadeInUp from 'react-animations/lib/fade-in-up';
import Radium from 'radium';
import PropTypes from 'prop-types';
import './ModeratorSearchForm.scss';
import { Category, ModeratorSearchCriteria } from 'utils/Types/types';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Avatar, ListItemAvatar, ListItemText, MenuItem, TextField, ThemeProvider } from '@material-ui/core/';
import { createMuiTheme, jssPreset, StylesProvider, withStyles } from '@material-ui/core/styles';
import { langFunction } from 'lang';

import rtl from 'jss-rtl';
import { create } from 'jss';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      '& fieldset': {
        borderColor: 'var(--ion-color-medium)',
        '& legend': {
          padding: '0px 8px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
    },
  },
})(TextField);

const styleMenuItem = (sousCatgorie) => {
    if (sousCatgorie !== 0) {
        return ({ paddingRight: '100px', paddingLeft: '100px'})
    }
    else {
        return ({ paddingRight: '20px', paddingLeft: '20px', backgroundColor: 'rgb(245 246 249 / 68%)', fontWeight: 400 })
    }
};


const styleAvatar = (sousCatgorie) => {
    if (sousCatgorie !== 0) {
        return ({maxWidth: '25px', height: '25px' , backgroundColor: '#ffffff'})
    } else {
        return ({backgroundColor: '#ffffff'})
    }
};


const ltrTheme = createMuiTheme({ direction: 'ltr' });
const rtlTheme = createMuiTheme({ direction: 'rtl' });

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

class ModeratorSearchForm extends Component<Props, State> {

    static defaultProps: {
        onChange: (moderatorSearchCriteria: ModeratorSearchCriteria) => void;
        style: { padding: string };
        value: {
            query: string;
            category: string;
            annonceId: string;
        }
    };

    static propTypes: {
        onChange: (...args: any[]) => void;
        style: object;
        value: object;
    };
    
    constructor(props: Props, context: any) {
        super(props, context);

        const {value} = props;

        this.state = {
            lang: "ar",
            ...value,
        };

        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.getCategories();
    }
    
    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
        const {query, category, annonceId} = this.state;

        const {onChange} = this.props;
        if(query !== undefined || category !== 'a_vendre' || annonceId !== undefined){
            onChange({query, category, annonceId});
        }
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    handleSearchSubmit(event: Event) {
        event.preventDefault();

        const {query, category, annonceId} = this.state;

        const {onChange} = this.props;

        onChange({query, category, annonceId});
    }
    

    getCategories() {
        const url = process.env.REACT_APP_API_URL + constants.categoriesSendPost;

        fetch(url).then((result) => {
            return result.json();
        }).then((jsonResult) => {
            this.setState({
                categories: jsonResult,
            }, ()=>{
                const {category} = this.state;
                this.setState({
                    category: category,
                });
            });
        }).catch((error) => {
            console.error(error.message, error);
        });
    }

    getCategoriesOptions() {
        const { categories, lang } = this.state;
        
        const filtredCategories = categories ? categories.filter((item: Category) => { return item.searchDesactivate === false }) : null;
        const principalCategories = filtredCategories ? filtredCategories.filter((item: Category) => { return item.sousCategorie === 0 }) : null;
        const subCategories = filtredCategories ? filtredCategories.filter((item: Category) => { return item.sousCategorie !== 0 }) : null;

        let sortedCategories = [];
        if (principalCategories) {
            principalCategories.forEach((item: Category) => {
                sortedCategories.push(item);
                const subCategoriesPerCategory = subCategories ? subCategories.filter((subItem: Category) => { return subItem.sousCategorie.toString() === item.code }) : null;
                if (subCategoriesPerCategory) {
                    subCategoriesPerCategory.forEach((subItem: Category) => {
                        sortedCategories.push(subItem);
                    });
                }
            });
        }
        const finalSorted = sortedCategories ? sortedCategories.map((item) => {
            return (
                {
                    id: item.id,
                    label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
                    value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
                    nomImage: item.nomImage,
                    sousCategorie: item.sousCategorie,
                }
            )
        }) : null;
        return finalSorted;
    }

    render() {

        const {query, category, lang, annonceId} = this.state;

        const {style} = this.props;

        const fade = {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
            ...style
        };

        const categoryOptions = this.getCategoriesOptions();
        // @ts-ignore
        const {intl} = this.props;
        const categoryLabel = intl.formatMessage(langFunction.categoryLabel);
        const categoryPlaceholder = intl.formatMessage(langFunction.categoryPlaceholder);
        const constantAllCategories = lang === 'fr' ? "a_vendre" : "للبيع" ;

        // @ts-ignore
        return (<StylesProvider jss={jss}><form name={'form_filter'} style={{...fade}} onSubmit={this.handleSearchSubmit}>
                <IonGrid >
                    <IonRow>
                      {!window.location.pathname.match ("/user/Desactiver")? (   <IonCol sizeXl={'3'} sizeLg={'3'} sizeMd={'3'} sizeSm={'6'} sizeXs={'6'} >
                          <IonItem className="ion-item-search-verification">
                                <IonLabel position="floating"><FormattedMessage id={'form.idAnnonce'}/></IonLabel>
                                <IonInput value={annonceId} inputMode={'text'} name={'annonceId'}
                                          autocomplete={'on'}
                                          onIonChange={e => {
                                              this.setState({annonceId: e.detail.value!});
                                          }}
                                          clearInput/>
                            </IonItem> 
                        </IonCol>): null}
                        <IonCol sizeXl={'3'} sizeLg={'3'} sizeMd={'3'} sizeSm={'6'} sizeXs={'6'}>
                            <IonItem className="ion-item-search-verification">
                                <IonLabel position="floating"><FormattedMessage id={'form.phone'}/></IonLabel>
                                <IonInput value={query} inputMode={'text'} name={'query'}
                                          autocomplete={'on'}
                                          onIonChange={e => {
                                              const q = e.detail.value?.charAt(0) === '0' ? e.detail.value.slice(1) : e.detail.value;
                                              this.setState({query: q});
                                          }}
                                          clearInput/>
                            </IonItem>
                        </IonCol>             
                        {!window.location.pathname.match ("/user/Desactiver")? (  
                        <IonCol>
        
                             <div className="category-select-container">
                            <ThemeProvider theme={lang === "ar" ? rtlTheme : ltrTheme}>
                                <CssTextField
                                    value={category}
                                    id='outlined-textarea'
                                    onChange={(e) => {
                                        this.setState({ category: e.target.value })
                                    }}
                                    select
                                    label={categoryLabel}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {lang === 'fr' ?
                                        <MenuItem key={0} value="a_vendre" >
                                            <ListItemText primary={categoryPlaceholder} />
                                        </MenuItem> : 
                                        <MenuItem key={1} value="للبيع" >
                                            <ListItemText primary={categoryPlaceholder} />
                                        </MenuItem>
                                    }
                                    {categoryOptions ? categoryOptions.map((item) => {
                                        return (
                                            <MenuItem style={styleMenuItem(item.sousCategorie)} dense value={item.value || constantAllCategories} key={item.id}>
                                                <ListItemAvatar>
                                                    <Avatar style={styleAvatar(item.sousCategorie)}>
                                                        <picture>
                                                            <source type='image/webp' srcSet={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage.replace(".jpeg", ".webp")}?small=true`} />
                                                            <img loading="lazy" src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`} alt={item.value} />
                                                        </picture>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.label} />
                                            </MenuItem>
                                        )
                                    }) : null}
                                </CssTextField>
                            </ThemeProvider>
                        </div>
                    </IonCol> ) : null}                     
                    </IonRow> 
                    <IonRow>
                        <IonCol sizeLg={'2'} sizeMd={'4'} sizeSm={'6'}>
                                <button type={'submit'} className={'btn btn-block btn-light'}
                                        style={{backgroundColor: 'black', color: 'var(--ion-color-light)',}}>
                                        <FormattedMessage id={'menu.search'}/>
                                </button>
                            </IonCol>
                        </IonRow>                   
                </IonGrid>
            </form>
            </StylesProvider>)
    }
}

ModeratorSearchForm.defaultProps = {
    style: {
        padding: '5rem 3rem'
    },
    onChange: (moderatorSearchCriteria: ModeratorSearchCriteria) => {
    },
    value: {
        query: '',
        category: constants.allCategories,
        annonceId: '',
    },
};

ModeratorSearchForm.propTypes = {
    style: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.object,
};

interface Props extends FlahaProps {
    /**
     * Form style
     * @default {
     *         padding: '5rem 3rem',
     *      }
     * */
    style: object;
    /**
     * On Form is submitted
     * @param moderatorSearchCriteria ModeratorSearchCriteria
     * */
    onChange: (moderatorSearchCriteria: ModeratorSearchCriteria) => void;
    /**
     * The value to passe to the form
     * @var moderatorSearchCriteria
     * */
    value: ModeratorSearchCriteria;
}

interface State extends FlahaState {
    query?: string | null | undefined;
    category?: string | null | undefined;
    categories?: Array<Category> | null | undefined;
    annonceId?: string | null | undefined;
}

// @ts-ignore
export default injectIntl(ModeratorSearchForm);
