import { createAction } from "@reduxjs/toolkit";

interface scrollPosition {
  position: number;
}

export const savePosition = createAction("SAVE_POSITION", function prepare(
  scrollPosition: scrollPosition
) {
  return {
    payload: scrollPosition
  };
});