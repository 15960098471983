import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  CreateAnimation,
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, SimpleHeader } from 'components';
import { RouteComponentProps } from 'react-router';
import './ResetPassword.scss';
import { alertCircle } from 'ionicons/icons';
import { validateEmail } from 'utils/Functions/Functions';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';
import { langFunction } from 'lang';
import UserService from '../../services/UserService';

class ResetPassword extends Component<Props, State> {
  link = window.location.href;
  token = null;

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      // userToken:this.state.userToken,
      login: '',
      enableSubmit: false,
      loginError: false,
      resetMessage: false,
      password: '',
      tokenValid: false,
      id: '',
    };
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.isTokenValid = this.isTokenValid.bind(this);
  }

  isTokenValid(token: any) {
    var isValid = false;
    var decodedToken = jwtDecode(token);
    var dateNow = new Date();
    // @ts-ignore
    if (decodedToken.exp * 1000 > dateNow.getTime()) {
      isValid = true;
      // @ts-ignore
      this.setState({ id: decodedToken.sub });
    }
    this.setState({ tokenValid: isValid });
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    if (this.link.indexOf('token') !== -1) {
      this.token = this.link.substring(window.location.href.indexOf('=') + 1);
      this.isTokenValid(this.token);
    }
    window.addEventListener('ionAlertDidPresent', (e) => {
      setTimeout(() => {
        this.setState({ resetMessage: false });
      }, 5000);
    });
  }

  activateSubmit() {
    const { login, password } = this.state;
    if ((login && login !== '' && validateEmail(login)) || (password && password !== '' && password.length > 7)) {
      this.setState({ enableSubmit: true });
      return;
    }
    this.setState({ enableSubmit: false });
  }

  onSubmitResetPassword(event: FormEvent) {
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}${constants.resetPassword}`;
    const { login, lang } = this.state;

    const headers = new Headers({
      'Content-Type': 'application/json',
      accept: '*/*',
    });

    let lien = window.location.href;

    fetch(`${url}?email=${login}&localeId=${lang}&lien=${lien}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        //console.log(request);
        // return history.push('/login');
        if (request.status !== 200) {
          this.setState({ loginError: true });
        } else {
          this.setState({ resetMessage: true });
        }
      })
      .catch((error) => {
        console.error(`Can not reset Password (${error.message})`, error);
      });
  }

  onSubmitChangePassword(event: FormEvent) {
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}${constants.changePassword}`;
    const { password, id } = this.state;

    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const user = {
      id: id,
      password: password,
    };
    UserService.changePassword(user, options)
      .then((value) => {
        this.setState({ resetMessage: true });
      })
      .catch((reason) => {
        this.setState({ loginError: true });
        console.error(reason.message);
      });
  }

  showSuccessMessage(langFctName: string) {
    const { resetMessage } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    let successeMsg;
    if (langFctName === 'signInForgotPasswordChanged') {
      successeMsg = intl.formatMessage(langFunction.signInForgotPasswordChanged);
    } else {
      successeMsg = intl.formatMessage(langFunction.signInForgotSuccess);
    }
    const closeMsg = intl.formatMessage(langFunction.close);

    return (
      <IonAlert
        isOpen={resetMessage}
        message={'<img src = "./assets/check.gif" > ' + successeMsg}
        cssClass="success-alert"
        buttons={[
          {
            text: closeMsg,
            role: 'cancel',
            handler: () => {
              this.setState({ resetMessage: false });
              window.location.href = '/login';
            },
          },
        ]}
        onDidDismiss={() => {
          window.location.href = '/login';
        }}
      />
    );
  }

  showError() {
    const { loginError, resetMessage } = this.state;
    let classN = '';
    let play = false;
    let msg = <FormattedMessage id={'sign.in.forgot.error'} />;
    if (resetMessage === true) {
      return this.showSuccessMessage('signInForgotSuccess');
    }
    if (loginError === true) {
      classN = 'alert-danger';
      play = true;
      msg = <FormattedMessage id={'sign.in.forgot.error'} />;
      return (
        <CreateAnimation
          duration={1000}
          easing={'ease-in'}
          play={play}
          fromTo={[
            {
              property: 'opacity',
              fromValue: '0',
              toValue: '1',
            },
          ]}
        >
          <div className={'alert ' + classN} style={!play ? { display: 'none' } : {}}>
            <IonIcon icon={alertCircle} size={'large'} /> {msg}
          </div>
        </CreateAnimation>
      );
    }
  }

  render() {
    const { login, password, enableSubmit, tokenValid } = this.state;
    return (
      <IonPage>
        {this.metaTags()}
        <SimpleHeader />
        {!tokenValid ? (
          <IonContent className={'loginPage'}>
            <div className={'container'}>
              <form className={'ResetPaswwwordForm'} method={'POST'} onSubmit={this.onSubmitResetPassword}>
                <IonGrid>
                  <IonRow>
                    <IonCol size={'12'} className={'text-center'}>
                      <h2>
                        <FormattedMessage id={'sign.in.forgot.password'} />
                      </h2>
                      {/* <span><FormattedMessage id={'sign.in.forgot.password'}/></span> */}
                      {this.showError()}
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.email'} />
                        </IonLabel>
                        <IonInput
                          value={login}
                          type={'email'}
                          inputMode={'email'}
                          autocomplete={'on'}
                          autofocus
                          name={'login'}
                          onIonChange={(event) => {
                            this.setState({ login: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <button
                        className={'btn btn-block'}
                        type={'submit'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        disabled={!enableSubmit}
                      >
                        <FormattedMessage id={'form.send'} />
                      </button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </div>
          </IonContent>
        ) : (
          <IonContent className={'loginPage'}>
            <div className={'container'}>
              <form className={'ResetPaswwwordForm'} method={'POST'} onSubmit={this.onSubmitChangePassword}>
                <IonGrid>
                  <IonRow>
                    <IonCol size={'12'} className={'text-center'}>
                      <h2>
                        <FormattedMessage id={'form.password.new'} />
                      </h2>
                      {/* <span><FormattedMessage id={'sign.in.forgot.password'}/></span> */}
                      {this.showSuccessMessage('signInForgotPasswordChanged')}
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.password'} />
                        </IonLabel>
                        <IonInput
                          value={password}
                          type={'password'}
                          inputMode={'text'}
                          autocomplete={'off'}
                          autofocus
                          name={'password'}
                          onIonChange={(event) => {
                            this.setState({ password: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <button
                        className={'btn btn-block'}
                        type={'submit'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        disabled={!enableSubmit}
                      >
                        <FormattedMessage id={'form.send'} />
                      </button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </div>
          </IonContent>
        )}

        <Footer copyrightOnly />
      </IonPage>
    );
  }

  callback() {
    let tmpCallback = '/';

    const { callback } = this.props;

    if (callback && callback !== '') {
      tmpCallback = callback;
    }

    //const c = useQuery(this.props).get('callback');
    const c = '';
    if (c && c !== '') {
      tmpCallback = c;
    }

    return tmpCallback;
  }

  metaTags() {
    return (
      <Helmet>
        <title>Mot de Passe oublié</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {
  callback?: string;
}

interface State extends FlahaState {
  login: string;
  enableSubmit: boolean;
  loginError: boolean;
  resetMessage: boolean;
  password: string;
  tokenValid: boolean;
  id: string;
}

// @ts-ignore
export default injectIntl(ResetPassword);
