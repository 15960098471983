import React, { useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import {setUserToken , setUserProfile} from "utils/Functions/Functions";
import {constants} from "utils";
const { App: CapApp } = Plugins;

interface AppUrlListenerProps {
  lang: string;
}

const AppUrlListener: React.FC<AppUrlListenerProps> = ({ lang }: AppUrlListenerProps) => {
    useEffect(() => {
      CapApp.addListener('appUrlOpen', (data: any) => {
        const name='token';
        const token = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + token + '=([^&#]*)');

        var results = regex.exec(data.url);
        var accessToken = decodeURIComponent(results[1].replace(/\+/g, ' '));



        if(accessToken) {
            setUserToken(accessToken);
            //console.log("RESULTS /  /////// :"+accessToken);
            let url=process.env.REACT_APP_API_URL + constants.userProfileGet;
                        let bearer = 'Bearer ' + accessToken;
                        const headers = new Headers({
                            'Authorization': bearer
                        });

                        fetch(url, {
                            method: 'GET',
                            credentials: 'same-origin',
                            headers: headers,
                        })
                            .then((request) => {

                                return request.json();
                            })
                            .then((jsonRequest) => {
                                //console.log(jsonRequest);
                                if (jsonRequest) {
                                    setUserProfile(jsonRequest.email,
                                                jsonRequest.nom,
                                                jsonRequest.telephone,
                                                jsonRequest.type,
                                                jsonRequest.imageUrl,
                                                jsonRequest.admin ? 'true':'false',
                                                jsonRequest.moderator ? 'true':'false',
                                                jsonRequest.roles ? jsonRequest.roles : []);

                                lang === 'ar' ? window.location.href = `/ar/المغرب/للبيع/` : window.location.href = `/fr/maroc/a_vendre/`;            
                                }
                                else {
                                     console.error(`Can not login`);
                                }
                            });
          }
          else{
            window.location.href='/login';
          }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, );

    return null;
  };

  export default AppUrlListener;
