import { createReducer } from "@reduxjs/toolkit";
import { setShowModal } from "../actions/setShowModalAction";


const showModalRedux = {
    showModal: false,
  };


export const setShowModalReducer = createReducer(
  { showModalRedux },
  {
    [setShowModal.type]: (state, action) => ({
      ...state,
      showModalRedux: { ...action.payload }
    }),
  }
);