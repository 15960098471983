import { createAction } from "@reduxjs/toolkit";
import { Region } from "utils/Types/types";



export const saveRegions = createAction("SAVE_REGIONS", function prepare(
  regions: Array<Region>
) {
  return {
    payload: regions,
  };
});