import React, { Component } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
} from '@ionic/react';
import {
  calendarOutline,
  carOutline,
  cashOutline,
  checkmarkCircleOutline,
  searchOutline,
  trashOutline,
} from 'ionicons/icons';
// @ts-ignore
import fadeInUp from 'react-animations/lib/fade-in-up';
import Radium from 'radium';
import PropTypes from 'prop-types';
import './SearchForm.scss';
import { Category, City, Marque, Modele, Region, SearchCriteria } from 'utils/Types/types';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import { connect } from 'react-redux';

import { Avatar, ListItemAvatar, ListItemText, MenuItem, TextField, ThemeProvider } from '@material-ui/core/';
import { createMuiTheme, jssPreset, StylesProvider, withStyles } from '@material-ui/core/styles';

import rtl from 'jss-rtl';
import { create } from 'jss';
import { getCategoriesReduxUtils, getCitiesReduxUtils, getRegionsReduxUtils } from 'utils/Functions/ReferentialGetters';
import { increment, setValue } from '../../slices/searchRefreshCounterSlice';
import { Menu } from '@material-ui/core';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      '& fieldset': {
        borderColor: 'var(--ion-color-medium)',
        '& legend': {
          padding: '0px 8px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
    },
  },
})(TextField);

const styleMenuItem = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { paddingRight: '100px', paddingLeft: '100px' };
  } else {
    return { paddingRight: '20px', paddingLeft: '20px', backgroundColor: 'rgb(245 246 249 / 68%)', fontWeight: 400 };
  }
};

const styleAvatar = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { maxWidth: '25px', height: '25px', backgroundColor: '#ffffff' };
  } else {
    return { backgroundColor: '#ffffff' };
  }
};

const ltrTheme = createMuiTheme({ direction: 'ltr' });
const rtlTheme = createMuiTheme({ direction: 'rtl' });

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

class SearchForm extends Component<Props, State> {
  static defaultProps: {
    onChange: (searchCriteria: SearchCriteria) => void;
    style: { padding: string };
    value: {
      titleOnly: boolean;
      price: any;
      query: string;
      urgentOnly: boolean;
      category: string;
      region: string;
      marque: string;
      modele: string;
      typeCarburant: string;
      boiteVitesse: string;
      categoryObject: Category;
      marqueObject: Marque;
      modeleObject: Modele;
      typeCarburantObject: any;
      boiteVitesseObject: any;
      puissanceFiscale: string;
      anneeModele: string;
    };
  };

  static propTypes: {
    onChange: (...args: any[]) => void;
    style: object;
    value: object;
  };

  constructor(props: Props, context: any) {
    super(props, context);

    const { value } = props;

    this.state = {
      minPrice: 0,
      maxPrice: 100000,
      lang: 'ar',
      ...value,
      isSubmitting: false,
      marqueAnchorEl: null,
      modeleAnchorEl: null,
      typeCarburantAnchorEl: null,
      boiteVitesseAnchorEl: null,
      puissanceFiscaleAnchorEl: null,
      anneeModeleAnchorEl: null,
      priceAnchorEl: null,
    };
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);

    this.handleMarqueClick = this.handleMarqueClick.bind(this);
    this.handleMarqueClose = this.handleMarqueClose.bind(this);

    this.handleModeleClick = this.handleModeleClick.bind(this);
    this.handleModeleClose = this.handleModeleClose.bind(this);

    this.handleTypeCarburantClick = this.handleTypeCarburantClick.bind(this);
    this.handleTypeCarburantClose = this.handleTypeCarburantClose.bind(this);

    this.handleBoiteVitesseClick = this.handleBoiteVitesseClick.bind(this);
    this.handleBoiteVitesseClose = this.handleBoiteVitesseClose.bind(this);

    this.handlePuissanceFiscaleClick = this.handlePuissanceFiscaleClick.bind(this);
    this.handlePuissanceFiscaleClose = this.handlePuissanceFiscaleClose.bind(this);

    this.handleAnneeModeleClick = this.handleAnneeModeleClick.bind(this);
    this.handleAnneeModeleClose = this.handleAnneeModeleClose.bind(this);

    this.handlePriceClick = this.handlePriceClick.bind(this);
    this.handlePriceClose = this.handlePriceClose.bind(this);

    this.getPriceMinMax();
  }

  verifyCityFilter() {
    let { region } = this.state;
    const lang = window.location.href.includes('/fr/') ? 'fr' : 'ar';
    const { citiesRedux, provincesRedux } = this.props;
    let cityRedux, provinceRedux;
    if (citiesRedux && provincesRedux) {
      cityRedux =
        lang === 'ar'
          ? citiesRedux.find((item) => item.urlArab === region)
          : citiesRedux.find((item) => item.urlFr === region);
      provinceRedux =
        lang === 'ar'
          ? provincesRedux.find((item) => item.urlArab === region)
          : provincesRedux.find((item) => item.urlFr === region);
    }
    let city;
    if (cityRedux) {
      city = lang === 'ar' ? cityRedux.urlArab : cityRedux.urlFr;
      region = lang === 'ar' ? cityRedux.province.region.urlArab : cityRedux.province.region.urlFr;
      this.setState({ city: city, region: region });
    } else if (provinceRedux) {
      region = lang === 'ar' ? provinceRedux.region.urlArab : provinceRedux.region.urlFr;
      this.setState({ city: city, region: region });
    }
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.getCategories();
    this.getCities();
    this.getRegions();
    this.verifyCityFilter();
    this.getMarques();
    this.getTypesCarburant();
    this.getBoiteVitesses();
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  handleSearchSubmit(event: Event) {
    this.setState({ isSubmitting: true });
    event.preventDefault();

    const {
      query,
      category,
      city,
      price,
      titleOnly,
      urgentOnly,
      showAdvanceSearch,
      marque,
      modele,
      boiteVitesse,
      typeCarburant,
      puissanceFiscale,
      anneeModele,
      categoryObject,
      marqueObject,
      modeleObject,
      boiteVitesseObject,
      typeCarburantObject,
    } = this.state;
    let { region } = this.state;

    if (city !== undefined && city !== region) {
      region = city;
    }

    const { onChange } = this.props;

    onChange({
      query,
      category,
      region,
      price,
      titleOnly,
      urgentOnly,
      showAdvanceSearch,
      marque,
      modele,
      boiteVitesse,
      typeCarburant,
      puissanceFiscale,
      anneeModele,
      categoryObject,
      marqueObject,
      modeleObject,
      boiteVitesseObject,
      typeCarburantObject,
    });
    setTimeout(() => {
      this.setState({ isSubmitting: false });
    }, 3000);

    document.querySelector('#main-result')?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  getCategories() {
    const categories = getCategoriesReduxUtils();
    const sortedCategories =
      categories &&
      [...categories].sort(function (a, b) {
        return a.orderAffichage - b.orderAffichage;
      });
    this.setState(
      {
        categories: sortedCategories,
      },
      () => {
        const { category } = this.state;
        this.setState({
          category: category,
        });
      }
    );
  }

  getRegions() {
    const regions = getRegionsReduxUtils();
    this.setState(
      {
        regions: regions,
      },
      () => {
        const { region } = this.state;
        this.setState({
          region: region,
        });
      }
    );
  }

  getCities() {
    const cities = getCitiesReduxUtils();
    this.setState(
      {
        cities: cities,
      },
      () => {
        const { city } = this.state;
        this.setState({
          city: city,
        });
      }
    );
  }

  getPriceMinMax() {
    const url = process.env.REACT_APP_API_URL + constants.priceMinMax;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        this.setState({
          minPrice: jsonResult.min ? jsonResult.min : 0,
          maxPrice: jsonResult.max ? jsonResult.max : 10000000,
        });
      })
      .catch((error) => {
        console.error(error.message, error);
      });

    //console.log(url);
  }

  getCategoriesOptions() {
    const { categories, lang } = this.state;

    const filtredCategories = categories
      ? categories.filter((item: Category) => {
          return item.searchDesactivate === false;
        })
      : null;
    const principalCategories = filtredCategories
      ? filtredCategories.filter((item: Category) => {
          return item.sousCategorie === 0;
        })
      : null;
    const subCategories = filtredCategories
      ? filtredCategories.filter((item: Category) => {
          return item.sousCategorie !== 0;
        })
      : null;

    let sortedCategories = [];
    if (principalCategories) {
      principalCategories.forEach((item: Category) => {
        sortedCategories.push(item);
        const subCategoriesPerCategory = subCategories
          ? subCategories.filter((subItem: Category) => {
              return subItem.sousCategorie.toString() === item.code;
            })
          : null;
        if (subCategoriesPerCategory) {
          subCategoriesPerCategory.forEach((subItem: Category) => {
            sortedCategories.push(subItem);
          });
        }
      });
    }
    const finalSorted = sortedCategories
      ? sortedCategories.map((item) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
          };
        })
      : null;
    return finalSorted;
  }

  getCitiesOptionsByRegion() {
    const { regions, cities, lang, region } = this.state;
    const selectedRegion = regions
      ? lang === 'ar'
        ? regions.find((item) => item.urlArab === region)
        : regions.find((item) => item.urlFr === region)
      : null;
    const citiesByRegion =
      cities && selectedRegion
        ? cities.filter((item: City) => {
            return item.province.region.id === selectedRegion.id;
          })
        : null;
    const finalSorted = citiesByRegion
      ? citiesByRegion.map((item) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.nomFr : item.nomArab,
            value: lang === 'fr' ? item.urlFr : item.urlArab,
          };
        })
      : null;
    return finalSorted;
  }

  handleNone() {
    this.setState({
      categoryObject: null,
      marques: [],
      modeles: [],
      marqueObject: null,
      modeleObject: null,
      typeCarburantObject: null,
      boiteVitesseObject: null,
      marque: null,
      modele: null,
      typeCarburant: null,
      boiteVitesse: null,
      puissanceFiscale: '',
      anneeModele: '',
    });
  }

  handleClickCategory(value) {
    const { categories } = this.state;
    const category = categories.find((item) => item.id === value);
    this.setState(
      {
        categoryObject: category,
        modeles: [],
        marques: [],
        marqueObject: null,
        modeleObject: null,
        typeCarburantObject: null,
        boiteVitesseObject: null,
        marque: null,
        modele: null,
        typeCarburant: null,
        boiteVitesse: null,
        puissanceFiscale: '',
        anneeModele: '',
      },
      () => {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteVitesses();
      }
    );
  }

  handleMarqueClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ marqueAnchorEl: event.currentTarget });
  }

  handleMarqueClose() {
    this.setState({ marqueAnchorEl: null });
  }

  handleModeleClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ modeleAnchorEl: event.currentTarget });
  }

  handleModeleClose() {
    this.setState({ modeleAnchorEl: null });
  }

  handleTypeCarburantClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ typeCarburantAnchorEl: event.currentTarget });
  }

  handleTypeCarburantClose() {
    this.setState({ typeCarburantAnchorEl: null });
  }

  handleBoiteVitesseClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ boiteVitesseAnchorEl: event.currentTarget });
  }

  handleBoiteVitesseClose() {
    this.setState({ boiteVitesseAnchorEl: null });
  }

  handlePuissanceFiscaleClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ puissanceFiscaleAnchorEl: event.currentTarget });
  }

  handlePuissanceFiscaleClose() {
    this.setState({ puissanceFiscaleAnchorEl: null });
  }

  handleAnneeModeleClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ anneeModeleAnchorEl: event.currentTarget });
  }

  handleAnneeModeleClose() {
    this.setState({ anneeModeleAnchorEl: null });
  }

  handlePriceClick(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    this.setState({ priceAnchorEl: event.currentTarget });
  }

  handlePriceClose() {
    this.setState({ priceAnchorEl: null });
  }

  async getMarques() {
    const { categoryObject, marque } = this.state;
    if (categoryObject && categoryObject.marques !== null && categoryObject.marques !== '') {
      const idsMarques = categoryObject.marques ? categoryObject.marques : null;
      let url = process.env.REACT_APP_API_URL + constants.getMarques;
      const headers = new Headers({
        'Content-Type': 'application/json',
        accept: '*/*',
      });
      await fetch(`${url}` + `?marques=${idsMarques}`, {
        method: 'POST',
        headers: headers,
      }).then((response) => {
        let marquesData = response.json();
        marquesData.then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ marques: jsonRequest }, () => {
              const marqueObj = jsonRequest.find((item: Marque) => item.id === +marque);
              this.setState({ marqueObject: marqueObj }, () => this.getModeleByMarque(marqueObj?.id));
            });
          }
        });
      });
    }
  }

  async getTypesCarburant() {
    /*////////console.log('getTypesCarburant called !');*/
    const { categoryObject, typeCarburant } = this.state;
    /*////////console.log('calleTCdWs : ', calleTCdWs);*/
    if (categoryObject && categoryObject.marques !== null && categoryObject.marques !== '') {
      let url = process.env.REACT_APP_API_URL + constants.getTypesCarburant;

      await fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ typesCarburant: jsonRequest }, () => {
              const typeCarburantObj = jsonRequest.find((item) => item.id === +typeCarburant);
              this.setState({ typeCarburantObject: typeCarburantObj });
            });
            //////console.log(jsonRequest);
          }
        });
    }
  }

  async getBoiteVitesses() {
    const { categoryObject, boiteVitesse } = this.state;
    if (categoryObject && categoryObject.marques !== null && categoryObject.marques !== '') {
      let url = process.env.REACT_APP_API_URL + constants.getBoiteAVitesses;

      await fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ boiteVitesses: jsonRequest }, () => {
              const boiteVitesseObj = jsonRequest.find((item) => item.id === +boiteVitesse);
              this.setState({ boiteVitesseObject: boiteVitesseObj });
            });
            //////console.log(jsonRequest);
          }
        });
    }
  }

  getModeleByMarque(marqueId) {
    /* ////////console.log('getModelesByMarque called !');*/
    /* ////////console.log('marqueId : ', marqueId)*/
    const { modele } = this.state;

    if (marqueId !== null && marqueId > 0) {
      let url = process.env.REACT_APP_API_URL + constants.getModelesByMarque + '?idMarque=' + marqueId;

      return fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ modeles: jsonRequest }, () => {
              const modeleObj = jsonRequest.find((item) => item.id === +modele);
              this.setState({ modeleObject: modeleObj });
            });
          }
        });
    }
  }

  render() {
    const {
      query,
      category,
      categoryObject,
      region,
      price,
      minPrice,
      maxPrice,
      titleOnly,
      urgentOnly,
      showAdvanceSearch,
      regions,
      lang,
      city,
      isSubmitting,
      marqueAnchorEl,
      modeleAnchorEl,
      boiteVitesseAnchorEl,
      typeCarburantAnchorEl,
      puissanceFiscaleAnchorEl,
      anneeModeleAnchorEl,
      priceAnchorEl,
      marques,
      modeles,
      boiteVitesses,
      typesCarburant,
      puissanceFiscale,
      anneeModele,
      marqueObject,
      modeleObject,
      boiteVitesseObject,
      typeCarburantObject,
    } = this.state;
    const { style } = this.props;
    const fade = {
      animation: 'x 1s',
      animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      ...style,
    };
    const categoryOptions = this.getCategoriesOptions();
    const citiesByRegion = this.getCitiesOptionsByRegion();

    // @ts-ignore
    const { intl } = this.props;

    const categoryLabel = intl.formatMessage(langFunction.categoryLabel);
    const categoryPlaceholder = intl.formatMessage(langFunction.categoryPlaceholder);
    const regionLabel = intl.formatMessage(langFunction.regionLabel);
    const cityLabel = intl.formatMessage(langFunction.citiesLabel);
    const regionPlaceholder = intl.formatMessage(langFunction.regionPlaceholder);
    const cityPlaceholder = intl.formatMessage(langFunction.cityPlaceholder);
    const constantAllRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const constantAllCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    const showCityOption = region === constantAllRegion ? false : true;
    return (
      <Radium.StyleRoot>
        <StylesProvider jss={jss}>
          <form
            name={'form_filter'}
            //@ts-ignore
            style={{ ...fade }}
            //@ts-ignore
            onSubmit={this.handleSearchSubmit}
          >
            <IonGrid>
              <IonCol>
                <IonItem className="item-query">
                  <IonLabel position="floating">
                    <FormattedMessage id={'form.search'} />
                  </IonLabel>
                  <IonInput
                    value={query}
                    inputMode={'text'}
                    name={'query'}
                    autocomplete={'on'}
                    onIonChange={(e) => {
                      this.setState({ query: e.detail.value! });
                    }}
                    clearInput
                  />
                </IonItem>
              </IonCol>
              <IonCol size-xs="12" size-sm="12">
                <div className="category-select-container">
                  <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                    <CssTextField
                      value={category ? category : constantAllCategories}
                      id="outlined-textarea"
                      onChange={(e) => {
                        this.setState({ category: e.target.value });
                      }}
                      select
                      label={categoryLabel}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {lang === 'fr' ? (
                        <MenuItem key={0} value="a_vendre" onClick={() => this.handleNone()}>
                          <ListItemText primary={categoryPlaceholder} />
                        </MenuItem>
                      ) : (
                        <MenuItem key={1} value="للبيع" onClick={() => this.handleNone()}>
                          <ListItemText primary={categoryPlaceholder} />
                        </MenuItem>
                      )}
                      {categoryOptions
                        ? categoryOptions.map((item) => {
                            return (
                              <MenuItem
                                onClick={() => this.handleClickCategory(item.id)}
                                style={styleMenuItem(item.sousCategorie)}
                                dense
                                value={item.value || constantAllCategories}
                                key={item.id}
                              >
                                <ListItemAvatar>
                                  <Avatar style={styleAvatar(item.sousCategorie)}>
                                    <picture>
                                      <source
                                        type="image/webp"
                                        srcSet={`${process.env.REACT_APP_API_URL}${
                                          constants.pictures
                                        }/categories/${item.nomImage.replace('.jpeg', '.webp')}?small=true`}
                                      />
                                      <img
                                        loading="lazy"
                                        src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`}
                                        alt={item.value}
                                      />
                                    </picture>
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.label} />
                              </MenuItem>
                            );
                          })
                        : null}
                    </CssTextField>
                  </ThemeProvider>
                </div>
              </IonCol>
              <IonRow className={'category-region-container'}>
                <IonCol size-xs="6" size-sm={'6'}>
                  <div className="region-select-container">
                    <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                      <CssTextField
                        value={region ? region : constantAllRegion}
                        id="outlined-textarea"
                        onChange={(e) => {
                          this.setState({ region: e.target.value });
                          this.setState({ city: e.target.value });
                        }}
                        select
                        label={regionLabel}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value={lang === 'fr' ? 'maroc' : 'المغرب'}>
                          <ListItemText primary={regionPlaceholder} />
                        </MenuItem>
                        {regions
                          ? regions.map((item: Region, index: number) => {
                              return (
                                <MenuItem
                                  dense
                                  value={(lang === 'fr' ? item.urlFr : item.urlArab) || constantAllCategories}
                                  key={item.id}
                                >
                                  <ListItemText primary={lang === 'fr' ? item.nomFr : item.nomArab} />
                                </MenuItem>
                              );
                            })
                          : null}
                      </CssTextField>
                    </ThemeProvider>
                  </div>
                </IonCol>
                <IonCol size-xs="6" size-sm="6">
                  <div className="region-select-container">
                    <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                      <CssTextField
                        value={city ? city : region}
                        id="outlined-textarea"
                        onChange={(e) => {
                          this.setState({ city: e.target.value });
                        }}
                        select
                        label={cityLabel}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!showCityOption}
                      >
                        <MenuItem value={region ? region : constantAllRegion}>
                          <ListItemText primary={cityPlaceholder} />
                        </MenuItem>
                        {citiesByRegion
                          ? citiesByRegion.map((item: any, index: number) => {
                              return (
                                <MenuItem dense value={item.value || constantAllCategories} key={item.id}>
                                  <ListItemText primary={item.label} />
                                </MenuItem>
                              );
                            })
                          : null}
                      </CssTextField>
                    </ThemeProvider>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={'12'}>
                  <div className={'d-flex align-items-baseline'} style={{ whiteSpace: 'nowrap' }}>
                    <span>
                      <FormattedMessage id={'form.search.advance'} />
                    </span>
                    <div className={'w-100 ' + (lang === 'fr' ? 'ml-2' : 'mr-2')}>
                      <hr color="#D0D0D0" className={'m-0'} />
                    </div>
                  </div>
                </IonCol>
                <IonCol size={'12'}>
                  <div className={'search-advanced d-flex align-items-center flex-wrap'}>
                    {categoryObject && categoryObject.marques !== null && (
                      <>
                        <div>
                          <Menu
                            id="simple-marque-menu"
                            anchorEl={marqueAnchorEl}
                            keepMounted
                            open={Boolean(marqueAnchorEl)}
                            onClose={this.handleMarqueClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <IonList lines={'full'}>
                              <IonListHeader lines="full">
                                <IonLabel>
                                  <FormattedMessage id={'post.title.marque'} />
                                </IonLabel>
                                <IonButton
                                  color={'secondary'}
                                  size={'small'}
                                  fill={'clear'}
                                  onClick={() => {
                                    this.setState({
                                      marque: null,
                                      modele: null,
                                      modeleObject: null,
                                      modeles: [],
                                      marqueObject: null,
                                    });
                                    this.handleMarqueClose();
                                  }}
                                >
                                  <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={trashOutline} />
                                  <FormattedMessage id={'search.form.clear'} />
                                </IonButton>
                              </IonListHeader>
                              {marques?.map((value, index) => (
                                <IonItem
                                  key={index}
                                  color={marqueObject?.id === value.id ? 'primary' : undefined}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        marqueObject: value,
                                        marque: String(value.id),
                                        modele: null,
                                      },
                                      () => this.getModeleByMarque(value ? value.id : 0)
                                    );
                                    this.handleMarqueClose();
                                  }}
                                  button
                                >
                                  {lang === 'fr' ? value.titreFr : value.titreAr}
                                </IonItem>
                              ))}
                            </IonList>
                          </Menu>
                          <IonButton
                            color={'primary'}
                            size={'small'}
                            fill={marqueObject ? 'solid' : 'outline'}
                            onClick={this.handleMarqueClick}
                          >
                            <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={carOutline} />
                            {marqueObject ? (
                              lang === 'fr' ? (
                                marqueObject.titreFr
                              ) : (
                                marqueObject.titreAr
                              )
                            ) : (
                              <FormattedMessage id={'post.title.marque'} />
                            )}
                          </IonButton>
                        </div>
                        {!categoryObject.forAnimal && (
                          <>
                            <div>
                              <Menu
                                id="simple-modele-menu"
                                anchorEl={modeleAnchorEl}
                                keepMounted
                                open={Boolean(modeleAnchorEl)}
                                onClose={this.handleModeleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <IonList lines={'full'}>
                                  <IonListHeader lines="full">
                                    <IonLabel>
                                      <FormattedMessage id={'post.title.modele'} />
                                    </IonLabel>
                                    <IonButton
                                      color={'secondary'}
                                      size={'small'}
                                      fill={'clear'}
                                      onClick={() => {
                                        this.setState({
                                          modele: null,
                                          modeleObject: null,
                                        });
                                        this.handleModeleClose();
                                      }}
                                    >
                                      <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={trashOutline} />
                                      <FormattedMessage id={'search.form.clear'} />
                                    </IonButton>
                                  </IonListHeader>
                                  {modeles?.map((value, index) => (
                                    <IonItem
                                      color={modeleObject?.id === value.id ? 'primary' : undefined}
                                      key={index}
                                      onClick={() => {
                                        this.setState({
                                          modeleObject: value,
                                          modele: String(value.id),
                                        });
                                        this.handleModeleClose();
                                      }}
                                      button
                                    >
                                      {lang === 'fr' ? value.titreFr : value.titreAr}
                                    </IonItem>
                                  ))}
                                </IonList>
                              </Menu>
                              <IonButton
                                color={'primary'}
                                size={'small'}
                                fill={modeleObject ? 'solid' : 'outline'}
                                disabled={modeles?.length <= 0}
                                onClick={this.handleModeleClick}
                              >
                                <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={carOutline} />
                                {modeleObject ? (
                                  lang === 'fr' ? (
                                    modeleObject.titreFr
                                  ) : (
                                    modeleObject.titreAr
                                  )
                                ) : (
                                  <FormattedMessage id={'post.title.modele'} />
                                )}
                              </IonButton>
                            </div>
                            <div>
                              <Menu
                                id="simple-boiteVitesse-menu"
                                anchorEl={boiteVitesseAnchorEl}
                                keepMounted
                                open={Boolean(boiteVitesseAnchorEl)}
                                onClose={this.handleBoiteVitesseClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <IonList lines={'full'}>
                                  <IonListHeader lines="full">
                                    <IonLabel>
                                      <FormattedMessage id={'post.title.boiteVitesse'} />
                                    </IonLabel>
                                    <IonButton
                                      color={'secondary'}
                                      size={'small'}
                                      fill={'clear'}
                                      onClick={() => {
                                        this.setState({
                                          boiteVitesse: null,
                                          boiteVitesseObject: null,
                                        });
                                        this.handleBoiteVitesseClose();
                                      }}
                                    >
                                      <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={trashOutline} />
                                      <FormattedMessage id={'search.form.clear'} />
                                    </IonButton>
                                  </IonListHeader>
                                  {boiteVitesses?.map((value, index) => (
                                    <IonItem
                                      key={index}
                                      color={boiteVitesseObject?.id === value.id ? 'primary' : undefined}
                                      onClick={() => {
                                        this.setState({
                                          boiteVitesseObject: value,
                                          boiteVitesse: value.id,
                                        });
                                        this.handleBoiteVitesseClose();
                                      }}
                                      button
                                    >
                                      {lang === 'fr' ? value.titreFr : value.titreAr}
                                    </IonItem>
                                  ))}
                                </IonList>
                              </Menu>
                              <IonButton
                                color={'primary'}
                                size={'small'}
                                fill={boiteVitesseObject ? 'solid' : 'outline'}
                                onClick={this.handleBoiteVitesseClick}
                              >
                                <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={carOutline} />
                                {boiteVitesseObject ? (
                                  lang === 'fr' ? (
                                    boiteVitesseObject.titreFr
                                  ) : (
                                    boiteVitesseObject.titreAr
                                  )
                                ) : (
                                  <FormattedMessage id={'post.title.boiteVitesse'} />
                                )}
                              </IonButton>
                            </div>
                            <div>
                              <Menu
                                id="simple-typeCarburant-menu"
                                anchorEl={typeCarburantAnchorEl}
                                keepMounted
                                open={Boolean(typeCarburantAnchorEl)}
                                onClose={this.handleTypeCarburantClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <IonList lines={'full'}>
                                  <IonListHeader lines="full">
                                    <IonLabel>
                                      <FormattedMessage id={'post.title.typeCarburant'} />
                                    </IonLabel>
                                    <IonButton
                                      color={'secondary'}
                                      size={'small'}
                                      fill={'clear'}
                                      onClick={() => {
                                        this.setState({
                                          typeCarburant: null,
                                          typeCarburantObject: null,
                                        });
                                        this.handleTypeCarburantClose();
                                      }}
                                    >
                                      <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={trashOutline} />
                                      <FormattedMessage id={'search.form.clear'} />
                                    </IonButton>
                                  </IonListHeader>
                                  {typesCarburant?.map((value, index) => (
                                    <IonItem
                                      key={index}
                                      color={typeCarburantObject?.id === value.id ? 'primary' : undefined}
                                      onClick={() => {
                                        this.setState({
                                          typeCarburantObject: value,
                                          typeCarburant: value.id,
                                        });
                                        this.handleTypeCarburantClose();
                                      }}
                                      button
                                    >
                                      {lang === 'fr' ? value.titreFr : value.titreAr}
                                    </IonItem>
                                  ))}
                                </IonList>
                              </Menu>
                              <IonButton
                                color={'primary'}
                                size={'small'}
                                fill={typeCarburantObject ? 'solid' : 'outline'}
                                onClick={this.handleTypeCarburantClick}
                              >
                                <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={carOutline} />
                                {typeCarburantObject ? (
                                  lang === 'fr' ? (
                                    typeCarburantObject.titreFr
                                  ) : (
                                    typeCarburantObject.titreAr
                                  )
                                ) : (
                                  <FormattedMessage id={'post.title.typeCarburant'} />
                                )}
                              </IonButton>
                            </div>
                            <div>
                              <Menu
                                id="simple-puissanceFiscale-menu"
                                anchorEl={puissanceFiscaleAnchorEl}
                                keepMounted
                                open={Boolean(puissanceFiscaleAnchorEl)}
                                onClose={this.handlePuissanceFiscaleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <IonList lines={'full'}>
                                  <IonListHeader lines="full">
                                    <IonLabel>
                                      <FormattedMessage id={'post.title.puissanceFiscale'} />
                                    </IonLabel>
                                    <IonButton
                                      color={'primary'}
                                      size={'small'}
                                      fill={'clear'}
                                      onClick={() => {
                                        this.handlePuissanceFiscaleClose();
                                      }}
                                    >
                                      <IonIcon
                                        className={lang === 'fr' ? 'mr-2' : 'ml-2'}
                                        icon={checkmarkCircleOutline}
                                      />
                                      <FormattedMessage id={'search.form.validate'} />
                                    </IonButton>
                                  </IonListHeader>
                                  <IonItem>
                                    <IonInput
                                      value={puissanceFiscale}
                                      type={'text'}
                                      inputMode={'text'}
                                      maxlength={60}
                                      clearInput
                                      placeholder={intl.formatMessage(langFunction.puissanceFiscale)}
                                      onIonChange={(event) => {
                                        this.setState({ puissanceFiscale: event.detail.value! });
                                      }}
                                    />
                                  </IonItem>
                                </IonList>
                              </Menu>
                              <IonButton
                                color={'primary'}
                                size={'small'}
                                fill={puissanceFiscale && puissanceFiscale !== '' ? 'solid' : 'outline'}
                                onClick={this.handlePuissanceFiscaleClick}
                              >
                                <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={carOutline} />
                                {puissanceFiscale && puissanceFiscale !== '' ? (
                                  puissanceFiscale
                                ) : (
                                  <FormattedMessage id={'post.title.puissanceFiscale'} />
                                )}
                              </IonButton>
                            </div>
                            <div>
                              <Menu
                                id="simple-anneeModele-menu"
                                anchorEl={anneeModeleAnchorEl}
                                keepMounted
                                open={Boolean(anneeModeleAnchorEl)}
                                onClose={this.handleAnneeModeleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <IonList lines={'full'}>
                                  <IonListHeader lines="full">
                                    <IonLabel>
                                      <FormattedMessage id={'post.title.anneeModele'} />
                                    </IonLabel>
                                    <IonButton
                                      color={'primary'}
                                      size={'small'}
                                      fill={'clear'}
                                      onClick={() => {
                                        this.handleAnneeModeleClose();
                                      }}
                                    >
                                      <IonIcon
                                        className={lang === 'fr' ? 'mr-2' : 'ml-2'}
                                        icon={checkmarkCircleOutline}
                                      />
                                      <FormattedMessage id={'search.form.validate'} />
                                    </IonButton>
                                  </IonListHeader>
                                  <IonItem>
                                    <IonInput
                                      value={anneeModele}
                                      type={'text'}
                                      inputMode={'text'}
                                      maxlength={60}
                                      clearInput
                                      placeholder={intl.formatMessage(langFunction.anneeModele)}
                                      onIonChange={(event) => {
                                        this.setState({ anneeModele: event.detail.value! });
                                      }}
                                    />
                                  </IonItem>
                                </IonList>
                              </Menu>
                              <IonButton
                                color={'primary'}
                                size={'small'}
                                fill={anneeModele && anneeModele !== '' ? 'solid' : 'outline'}
                                onClick={this.handleAnneeModeleClick}
                              >
                                <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={calendarOutline} />
                                {anneeModele && anneeModele !== '' ? (
                                  anneeModele
                                ) : (
                                  <FormattedMessage id={'post.title.anneeModele'} />
                                )}
                              </IonButton>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div>
                      <Menu
                        id="simple-price-menu"
                        anchorEl={priceAnchorEl}
                        keepMounted
                        open={Boolean(priceAnchorEl)}
                        onClose={this.handlePriceClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <IonList lines={'full'}>
                          <IonListHeader lines="full">
                            <IonLabel>
                              <FormattedMessage id={'form.price'} />
                            </IonLabel>
                            <IonButton
                              color={'primary'}
                              size={'small'}
                              fill={'clear'}
                              onClick={() => {
                                this.handlePriceClose();
                              }}
                            >
                              <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={checkmarkCircleOutline} />
                              <FormattedMessage id={'search.form.validate'} />
                            </IonButton>
                          </IonListHeader>
                          <IonItem>
                            <IonInput
                              value={price?.lower}
                              type={'number'}
                              inputMode={'numeric'}
                              clearInput
                              placeholder={intl.formatMessage(langFunction.priceMin)}
                              onIonChange={(event) => {
                                this.setState({
                                  price: {
                                    lower: +event.detail.value!,
                                    upper: price?.upper,
                                  },
                                });
                              }}
                            />
                          </IonItem>
                          <IonItem>
                            <IonInput
                              value={price?.upper}
                              type={'number'}
                              inputMode={'numeric'}
                              clearInput
                              placeholder={intl.formatMessage(langFunction.priceMax)}
                              onIonChange={(event) => {
                                this.setState({
                                  price: {
                                    upper: +event.detail.value!,
                                    lower: price?.lower,
                                  },
                                });
                              }}
                            />
                          </IonItem>
                        </IonList>
                      </Menu>
                      <IonButton
                        color={'primary'}
                        size={'small'}
                        fill={price?.upper || price?.lower ? 'solid' : 'outline'}
                        onClick={this.handlePriceClick}
                      >
                        <IonIcon className={lang === 'fr' ? 'mr-2' : 'ml-2'} icon={cashOutline} />
                        {price?.upper || price?.lower ? (
                          `${price?.lower} - ${price?.upper}`
                        ) : (
                          <FormattedMessage id={'form.price'} />
                        )}
                      </IonButton>
                    </div>
                  </div>
                </IonCol>

                {/*<IonCol sizeLg={'4'} sizeMd={'4'} sizeSm={'6'}>
                            <IonLabel onClick={() => {
                                this.setState({showAdvanceSearch: !showAdvanceSearch});
                            }} color={'primary'}>
                                <FormattedMessage id={'form.search.advance'}/> <IonIcon
                                icon={!showAdvanceSearch ? addOutline : removeOutline}/>
                            </IonLabel>
                        </IonCol>*/}
                {/* {!showAdvanceSearch ?
                        <IonCol sizeLg={'4'} sizeMd={'4'} sizeSm={'6'} offsetLg={'4'} offsetMd={'4'} offsetSm={'0'}>
                            <button type={'submit'} className={'btn btn-block'}
                                style={{ backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-light)', display: 'flex', alignItems: 'center'}}>
                                <IonIcon color="white" size="small" icon={searchOutline} />
                                <div style={{ width: '100%' }}>{
                                    isSubmitting ? <IonSpinner name="crescent" /> : <FormattedMessage id={'menu.search'} />
                                }</div>
                            </button>
                        </IonCol>
                        : null}*/}
              </IonRow>
              <IonRow>
                <IonCol sizeLg={'4'} sizeMd={'4'} sizeSm={'6'} offsetLg={'4'} offsetMd={'4'} offsetSm={'0'}>
                  <button
                    type={'submit'}
                    className={'btn btn-block'}
                    style={{
                      backgroundColor: 'var(--ion-color-primary)',
                      color: 'var(--ion-color-light)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IonIcon color="white" size="small" icon={searchOutline} />
                    <div style={{ width: '100%' }}>
                      <FormattedMessage id={'menu.search'} />
                    </div>
                  </button>
                </IonCol>
              </IonRow>
              {/*{showAdvanceSearch ?
                    <IonRow>
                        <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'}>
                            <IonItem>
                                <IonLabel>
                                    <FormattedMessage id={'form.price'} />
                                </IonLabel>
                                <IonRange value={price} min={minPrice} max={maxPrice} dualKnobs={true} pin={true}
                                    onIonChange={e => {
                                        // @ts-ignore
                                        this.setState({ price: e.detail.value })
                                    }}>
                                    <IonIcon slot="start" size="small" icon={removeOutline} />
                                    <IonIcon slot="end" size="small" icon={addOutline} />
                                </IonRange>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    : null}*/}
              {/* {showAdvanceSearch ?
                    <IonRow>
                        <IonCol sizeLg={'4'} sizeMd={'12'} sizeSm={'12'}>
                            <IonItem>
                                <IonToggle checked={titleOnly}
                                    onIonChange={e => this.setState({ titleOnly: e.detail.checked })} />
                                <IonLabel><FormattedMessage id={'form.search.in.title'} /></IonLabel>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeLg={'4'} sizeMd={'12'} sizeSm={'12'}>
                            <IonItem>
                                <IonToggle checked={urgentOnly}
                                    onIonChange={e => this.setState({ urgentOnly: e.detail.checked })} />
                                <IonLabel><FormattedMessage id={'form.search.in.urgent'} /></IonLabel>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeLg={'4'} sizeMd={'12'} sizeSm={'12'}>
                            <button type={'submit'} className={'btn btn-block'}
                                style={{ backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-light)', display: 'flex', alignItems: 'center'}}>
                                <IonIcon color="white" size="small" icon={searchOutline} />
                                <div style={{ width: '100%' }}> {isSubmitting ? <IonSpinner name="crescent" /> : <FormattedMessage id={'menu.search'} />}</div>
                            </button>
                        </IonCol>
                    </IonRow>
                    : null}*/}
            </IonGrid>
          </form>
        </StylesProvider>
      </Radium.StyleRoot>
    );
  }
}

SearchForm.defaultProps = {
  style: {
    padding: '5rem 3rem',
  },
  onChange: (searchCriteria: SearchCriteria) => {},
  value: {
    query: '',
    category: constants.allCategories,
    region: constants.allRegion,
    price: { lower: 0, upper: 100 },
    titleOnly: false,
    urgentOnly: false,
    marque: null,
    modele: null,
    typeCarburant: null,
    boiteVitesse: null,
    puissanceFiscale: '',
    anneeModele: '',
    categoryObject: null,
    marqueObject: null,
    modeleObject: null,
    boiteVitesseObject: null,
    typeCarburantObject: null,
  },
};

SearchForm.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

interface Props extends FlahaProps {
  /**
   * Form style
   * @default {
   *         padding: '5rem 3rem',
   *      }
   * */
  style: object;
  /**
   * On Form is submitted
   * @param searchCriteria SearchCriteria
   * */
  onChange: (searchCriteria: SearchCriteria) => void;
  /**
   * The value to passe to the form
   * @var SearchCriteria
   * */
  value: SearchCriteria;
  citiesRedux: any;
  provincesRedux: any;
  pageRedux: any;
  increment: () => void;
  setCounterValue: (value) => void;
}

interface State extends FlahaState {
  titleOnly?: boolean;
  price?: { lower: number; upper: number };
  query?: string | null | undefined;
  urgentOnly?: boolean;
  category?: string | null | undefined;
  region?: string | null | undefined;
  minPrice: number;
  maxPrice: number;
  showAdvanceSearch?: boolean;
  categories?: Array<Category> | null | undefined;
  regions?: Array<Region> | null | undefined;
  cities?: Array<City> | null | undefined;
  city?: string | null | undefined;
  isSubmitting: boolean;
  categoryObject?: Category | null | undefined;
  marques?: Array<Marque> | null | undefined;
  modeles?: Array<Modele> | null | undefined;
  typesCarburant?: Array<any> | null | undefined;
  boiteVitesses?: Array<any> | null | undefined;
  marqueObject?: Marque | null | undefined;
  modeleObject?: Modele | null | undefined;
  typeCarburantObject?: any;
  boiteVitesseObject?: any;
  marque?: string | null | undefined;
  modele?: string | null | undefined;
  typeCarburant?: string | null | undefined;
  boiteVitesse?: string | null | undefined;
  puissanceFiscale?: string | null | undefined;
  anneeModele?: string | null | undefined;
  marqueAnchorEl: null | HTMLElement;
  modeleAnchorEl: null | HTMLElement;
  typeCarburantAnchorEl: null | HTMLElement;
  boiteVitesseAnchorEl: null | HTMLElement;
  puissanceFiscaleAnchorEl: null | HTMLElement;
  anneeModeleAnchorEl: null | HTMLElement;
  priceAnchorEl: null | HTMLElement;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    citiesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.cities,
    provincesRedux: state.saveProvincesReducer.provinces.provinces,
    pageRedux: state.searchRefreshCounterReducer.counter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions

    increment: () => dispatch(increment()),
    setCounterValue: (value) => dispatch(setValue(value)),
  };
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchForm));
