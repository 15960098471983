import { createReducer } from "@reduxjs/toolkit";
import { saveCategories } from "../actions/saveCategories";

const categories = [];


export const saveCategoriesReducer = createReducer(
  { categories },
  {
    [saveCategories.type]: (state, action) => ({
      ...state,
      categories: { ...action.payload }
    }),
  }
);