import http from '../http-common';
import { constants } from 'utils';
import { Campaign } from 'utils/Types/types';

class CampaignService {
  getById(id: string, options) {
    return http.get<Campaign>(constants.campaignsByIdGET.replace('{id}', id), options);
  }

  getBySlug(slug: string, options) {
    return http.get(constants.campaignsBySlugGET.replace('{slug}', slug), options);
  }
}

export default new CampaignService();