import http from '../http-common';
import { constants } from 'utils';
import { EventTracking } from 'utils/Types/types';

class EventTrackingService {
  createEvent({ data, options }: { data: EventTracking; options: any }) {
    return http.post(constants.createEventTrackingPOST, data, options);
  }

  getShopEventTrackingByPeriod({
    eventID,
    shopID,
    period,
    options,
  }: {
    eventID: string;
    shopID: string;
    period: number;
    options: any;
  }) {
    return http.get(
      constants.shopEventTrackingByPeriodGET
        .replace('{eventID}', eventID)
        .replace('{shopID}', shopID)
        .replace('{period}', String(period)),
      options
    );
  }

  getAnnonceEventTrackingByPeriod({
    eventID,
    annonceID,
    period,
    options,
  }: {
    eventID: string;
    annonceID: string;
    period: number;
    options: any;
  }) {
    return http.get(
      constants.annonceEventTrackingByPeriodGET
        .replace('{eventID}', eventID)
        .replace('{annonceID}', annonceID)
        .replace('{period}', String(period)),
      options
    );
  }

  getAnnonceEventTrackingCountAndPeriod({
    eventID,
    annonceID,
    period,
    options,
  }: {
    eventID: string;
    annonceID: string;
    period: number;
    options: any;
  }) {
    return http.get(
      constants.annonceEventTrackingCountByPeriodGET
        .replace('{eventID}', eventID)
        .replace('{annonceID}', annonceID)
        .replace('{period}', String(period)),
      options
    );
  }

  getShopLeadsByPeriod({ shopID, period, options }: { shopID: string; period: number; options: any }) {
    return http.get(
      constants.shopLeadsByPeriodGET.replace('{shopID}', shopID).replace('{period}', String(period)),
      options
    );
  }
}

export default new EventTrackingService();
