import React, { Component } from 'react';
import { IonLabel } from '@ionic/react';
import './SubSearchForm.scss';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { FormattedMessage } from 'react-intl';
import { lang, SearchCriteria } from 'utils/Types/types';
import { constants, Events } from 'utils';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

class SubSearchForm extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      offers: true,
      needs: true,
      count: 0,
      lang: 'ar',
      subSearchFormText: '',
    };
    this.getLang();
  }

  getLang() {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  onChange() {}

  getSubSearchFormText() {
    const { value, regionsRedux, citiesRedux, categoriesRedux, provincesRedux, lang } = this.props;
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    const allCategoriesConstantFr = 'a_vendre';
    const allRegionsConstantFr = 'maroc';
    const allCategoriesConstantAr = 'للبيع';
    const allRegionsConstantAr = 'المغرب';
    let category;
    let region;
    let query;
    let subSearchFormText;
    let regionObject;
    let cityObject;
    let provinceObject;
    let categoryObject;
    let isCity: boolean;
    let isProvince: boolean;
    let forSaleFr = ' ';
    let forSaleAr = ' ';
    let forUsedFr = ' ';
    let forUsedAr = ' ';
    if (value) {
      category = value.category;
      region = value.region;
      query = value.query;
    }

    if (category && category !== allCategories) {
      lang === 'ar'
        ? (categoryObject = categoriesRedux ? categoriesRedux.find((item) => item.urlArabe === category) : null)
        : (categoryObject = categoriesRedux ? categoriesRedux.find((item) => item.urlFrancais === category) : null);
      if (categoryObject) {
        forSaleFr =
          categoryObject.id === 117 ||
          categoryObject.id === 116 ||
          categoryObject.id === 130 ||
          categoryObject.id === 131
            ? ' '
            : ' à vendre ';
        forSaleAr =
          categoryObject.id === 117 ||
          categoryObject.id === 116 ||
          categoryObject.id === 130 ||
          categoryObject.id === 131
            ? ' '
            : ' للبيع ';
        forSaleFr = categoryObject.marques && !categoryObject.forAnimal ? " d'occasion " + forSaleFr : forSaleFr;
        forSaleAr = categoryObject.marques && !categoryObject.forAnimal ? ' مستعملة ' + forSaleAr : forSaleAr;
      }
    }

    if (region && region !== allRegion) {
      if (regionsRedux && citiesRedux && provincesRedux) {
        const regionRedux =
          lang === 'ar'
            ? regionsRedux.find((item) => item.urlArab === region)
            : regionsRedux.find((item) => item.urlFr === region);
        const cityRedux =
          lang === 'ar'
            ? citiesRedux.find((item) => item.urlArab === region)
            : citiesRedux.find((item) => item.urlFr === region);
        const provinceRedux =
          lang === 'ar'
            ? provincesRedux.find((item) => item.urlArab === region)
            : provincesRedux.find((item) => item.urlFr === region);

        if (regionRedux) {
          regionObject = regionRedux;
          isCity = false;
          isProvince = false;
        } else if (cityRedux) {
          cityObject = cityRedux;
          regionObject = cityRedux.province.region;
          isCity = true;
          isProvince = false;
        } else if (provinceRedux) {
          provinceObject = provinceRedux;
          regionObject = provinceRedux.region;
          isCity = false;
          isProvince = true;
        }
      }
    }
    if (lang === 'fr') {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== allCategoriesConstantFr && region === allRegionsConstantFr) {
          subSearchFormText = query + forSaleFr + ' dans ' + categoryObject.titreFrancais + ' au Maroc ';
        } else if (region !== allRegionsConstantFr && category === allCategoriesConstantFr && regionObject) {
          const cityOrRegionText = isCity
            ? cityObject.nomFr
            : isProvince
            ? provinceObject.nomFr
            : 'la région de ' + regionObject.nomFr;
          subSearchFormText = query + ' à vendre à ' + cityOrRegionText;
        } else if (category !== allCategoriesConstantFr && region !== allRegionsConstantFr && regionObject) {
          const cityOrRegionText = isCity
            ? cityObject.nomFr
            : isProvince
            ? provinceObject.nomFr
            : 'la région de ' + regionObject.nomFr;
          subSearchFormText = query + forSaleFr + ' dans ' + categoryObject.titreFrancais + ' à ' + cityOrRegionText;
        } else if (category === allCategoriesConstantFr && region === allRegionsConstantFr) {
          subSearchFormText = query + ' à vendre au Maroc';
        }
      } else if (category !== allCategoriesConstantFr && region === allRegionsConstantFr) {
        subSearchFormText = categoryObject.titreFrancais + forSaleFr + 'au Maroc';
      } else if (region !== allRegionsConstantFr && category === allCategoriesConstantFr && regionObject) {
        const cityOrRegionText = isCity
          ? cityObject.nomFr
          : isProvince
          ? provinceObject.nomFr
          : 'la région de ' + regionObject.nomFr;
        subSearchFormText = 'Tous les produits agricoles à vendre à ' + cityOrRegionText;
      } else if (category !== allCategoriesConstantFr && region !== allRegionsConstantFr && regionObject) {
        const cityOrRegionText = isCity
          ? cityObject.nomFr
          : isProvince
          ? provinceObject.nomFr
          : 'la région de ' + regionObject.nomFr;
        subSearchFormText = categoryObject.titreFrancais + forSaleFr + ' à ' + cityOrRegionText;
      } else if (
        category === allCategoriesConstantFr &&
        region === allRegionsConstantFr &&
        (query === undefined || query === null || query === '')
      ) {
        subSearchFormText = 'Tous les produits agricoles à vendre au Maroc ';
      }
    } else {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== allCategoriesConstantAr && region === allRegionsConstantAr) {
          subSearchFormText = query + forSaleAr + ' ضمن ' + categoryObject.titreArabe + '  في المغرب  ';
        } else if (region !== allRegionsConstantAr && category === allCategoriesConstantAr && regionObject) {
          const cityOrRegionText = isCity
            ? cityObject.nomArab
            : isProvince
            ? provinceObject.nomArab
            : 'جهة ' + regionObject.nomArab;
          subSearchFormText = query + ' للبيع في ' + cityOrRegionText;
        } else if (category !== allCategoriesConstantAr && region !== allRegionsConstantAr && regionObject) {
          const cityOrRegionText = isCity
            ? cityObject.nomArab
            : isProvince
            ? provinceObject.nomArab
            : 'جهة ' + regionObject.nomArab;
          subSearchFormText = query + forSaleAr + ' ضمن ' + categoryObject.titreArabe + ' في ' + cityOrRegionText;
        } else if (category === allCategoriesConstantAr && region === allRegionsConstantAr) {
          subSearchFormText = query + '  للبيع في المغرب ';
        }
      } else if (category !== allCategoriesConstantAr && region === allRegionsConstantAr) {
        subSearchFormText = categoryObject.titreArabe + forSaleAr + ' في المغرب ';
      } else if (region !== allRegionsConstantAr && category === allCategoriesConstantAr && regionObject) {
        const cityOrRegionText = isCity
          ? cityObject.nomArab
          : isProvince
          ? provinceObject.nomArab
          : 'جهة ' + regionObject.nomArab;
        subSearchFormText = ' جميع المنتجات الفلاحية للبيع في ' + cityOrRegionText;
      } else if (category && category !== allCategoriesConstantAr && region !== allRegionsConstantAr) {
        const cityOrRegionText = isCity
          ? cityObject.nomArab
          : isProvince
          ? provinceObject.nomArab
          : 'جهة ' + regionObject.nomArab;
        subSearchFormText = categoryObject.titreArabe + forSaleAr + ' في ' + cityOrRegionText;
      } else if (
        category === allCategoriesConstantAr &&
        region === allRegionsConstantAr &&
        (query === undefined || query === null || query === '')
      ) {
        subSearchFormText = ' جميع المنتجات الفلاحية للبيع في المغرب ';
      }
    }
    this.setState({ subSearchFormText: subSearchFormText });
  }

  componentDidMount() {
    this.getSubSearchFormText();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasFilterChanged = !isEqual(prevProps.value, this.props.value);
    if (hasFilterChanged) {
      this.getSubSearchFormText();
    }
  }

  render() {
    const { subSearchFormText } = this.state;
    const { results } = this.props;
    return (
      <div className={'subsearch-container'}>
        <ul className={'subsearch clearfix'}>
          <li>
            <IonLabel>
              <h1>
                {subSearchFormText} &nbsp;{' '}
                <div>
                  (
                  <FormattedMessage id={'search.result'} values={{ result: results }} />){' '}
                </div>
              </h1>
            </IonLabel>
          </li>
          {/* <li>
                        <IonItem lines={'none'}>
                            <IonCheckbox checked={offers} onIonChange={e => this.setState({offers: e.detail.checked},
                                () => {
                                    if (this.props.onChange) {
                                        const {offers, needs} = this.state;
                                        this.props.onChange(offers && needs ? null : (offers ? 'offres' : 'demandes'));
                                    }
                                })
                            }/>
                            <IonLabel><FormattedMessage id={'search.offer'}/></IonLabel>
                        </IonItem>
                    </li>
                    <li>
                        <IonItem lines={'none'}>
                            <IonCheckbox checked={needs} onIonChange={(e) => {
                                this.setState({needs: e.detail.checked},
                                    () => {
                                        if (this.props.onChange) {
                                            const {offers, needs} = this.state;
                                            this.props.onChange(offers && needs ? null : (offers ? 'offres' : 'demandes'));
                                        }
                                    });
                            }}/>
                            <IonLabel><FormattedMessage id={'search.demand'}/></IonLabel>
                        </IonItem>
                    </li>*/}
        </ul>
      </div>
    );
  }
}

interface Props extends FlahaProps {
  onChange?: (type: string | null) => void;
  results?: number;
  value?: SearchCriteria;
  regionsRedux: any;
  citiesRedux: any;
  provincesRedux: any;
  categoriesRedux: any;
  lang: lang;
}

interface State extends FlahaState {
  offers: boolean;
  needs: boolean;
  count: number;
  subSearchFormText: string;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    regionsRedux: state.saveRegionsReducer.regions.regions,
    citiesRedux: state.saveCitiesReducer.cities.cities,
    provincesRedux: state.saveProvincesReducer.provinces.provinces,
    categoriesRedux: state.saveCategoriesReducer.categories.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubSearchForm);
