import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { RouteComponentProps } from 'react-router';
import { setUserProfile, setUserToken, validateEmail } from 'utils/Functions/Functions';
import { Helmet } from 'react-helmet';
import {
  CreateAnimation,
  IonAlert,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { Footer, Header } from 'components';
import { alertCircle, eye, eyeOff } from 'ionicons/icons';
import './Register.scss';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fr from 'react-phone-number-input/locale/fr.json';
import ar from 'lang/phone-input/ar.json';

class Register extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      name: '',
      phone: undefined,
      login: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      enableSubmit: false,
      me: 'Particulier',
      registerError: false,
      terms: false,
      showRequiredMsg: false,
      isSubmitting: false,
      showRegisterSuccess: false,
    };
    this.onSubmitRegister = this.onSubmitRegister.bind(this);
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    window.addEventListener('ionAlertDidPresent', (e) => {
      setTimeout(() => {
        this.setState({ showRegisterSuccess: false });
      }, 5000);
    });
  }

  activateSubmit() {
    const { name, phone, login, password, confirmPassword, terms } = this.state;
    this.setState({
      showRequiredMsg: true,
    });
    if (
      name &&
      name !== '' &&
      login &&
      login !== '' &&
      validateEmail(login) &&
      password &&
      password !== '' &&
      confirmPassword === password &&
      terms &&
      phone &&
      isPossiblePhoneNumber(phone)
    ) {
      this.setState({ enableSubmit: true });
      return;
    }
    this.setState({ enableSubmit: false });
  }

  onSubmitRegister(event: FormEvent) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ registerError: false });
    const url = `${process.env.REACT_APP_API_URL}${constants.register}`;

    const { name, phone, login, password, me, lang } = this.state;

    let userData = {
      provider: 'email',
      nom: name,
      prenom: name.split(' ')[0],
      password: password,
      email: login,
      login: login,
      type: me,
      telephone: phone,
    };

    const headers = new Headers({
      'Content-Type': 'application/json',
      accept: '*/*',
    });

    let userDataURI = encodeURIComponent(JSON.stringify(userData));

    fetch(`${url}?userData=${userDataURI}&localeId=${lang}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        if (request.status === 406) {
          this.setState({ registerError: true });
          return;
        }
        return request.json();
      })
      .then((jsonRequest) => {
        if (jsonRequest.token) {
          setUserToken(jsonRequest.token);
          let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
          let bearer = 'Bearer ' + jsonRequest.token;
          const headers = new Headers({
            Authorization: bearer,
          });

          fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              return request.json();
            })
            .then((jsonRequest) => {
              if (jsonRequest) {
                setUserProfile(
                  jsonRequest.email,
                  jsonRequest.nom,
                  jsonRequest.telephone,
                  jsonRequest.type,
                  jsonRequest.imageUrl,
                  jsonRequest.isAdmin,
                  jsonRequest.moderator ? 'true' : 'false',
                  jsonRequest.roles ? jsonRequest.roles : []
                );
                this.setState({ showRegisterSuccess: true });
                this.setState({ isSubmitting: false });
                //lang === 'ar' ? window.location.href = `/ar/المغرب/للبيع/` : window.location.href = `/fr/maroc/a_vendre/`;
              }
            });
        } else {
          this.setState({ isSubmitting: false });
          console.error(`Can not register`);
        }
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        console.error(`Can not register (${error.message})`, error);
      });
  }

  // componentDidMount() {
  //     let dataTest = {
  //         "provider": "email",
  //         "nom": "khalil",
  //         "prenom": "ahmed",
  //         "password": "1234AZERTY",
  //         "email": "brahimmokhtassim@gmail.com",
  //         "login": "brahimmokhtassim@gmail.com",
  //         "type": "Particulier",
  //          "telephone": "+212645678974"
  //     }
  //     console.log('userData: ' +encodeURIComponent(JSON.stringify(dataTest)));
  // }
  showError() {
    const { registerError } = this.state;

    return (
      <CreateAnimation
        duration={1000}
        easing={'ease-in'}
        play={registerError}
        fromTo={[
          {
            property: 'opacity',
            fromValue: '0',
            toValue: '1',
          },
        ]}
      >
        <div className={'alert alert-danger text-left'} style={!registerError ? { display: 'none' } : {}}>
          <IonIcon icon={alertCircle} size={'large'} /> <FormattedMessage id={'sign.up.account.error'} />
        </div>
      </CreateAnimation>
    );
  }

  showRequiredError(fieldName) {
    const { name, phone, login, password, confirmPassword, terms, showRequiredMsg } = this.state;

    switch (fieldName) {
      case 'name': {
        if (name === '' && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'password': {
        if (password === '' && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} color="red" />
              <FormattedMessage id={'form.required'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'confirmPassword': {
        if (confirmPassword === '' && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'login': {
        if (login === '' && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </div>
          );
        } else if (!validateEmail(login) && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.email.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'phone': {
        if (!phone && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </div>
          );
        } else if (!isPossiblePhoneNumber(String(phone)) && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.phone.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'terms': {
        if (terms === false && showRequiredMsg) {
          return (
            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'register.terms.required'} />
            </div>
          );
        } else {
          return null;
        }
      }
      default: {
        break;
      }
    }
  }

  render() {
    const {
      lang,
      name,
      phone,
      login,
      password,
      confirmPassword,
      showPassword,
      enableSubmit,
      me,
      terms,
      isSubmitting,
      showRegisterSuccess,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;

    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent className={'registerPage'}>
          <div className={'container mbm-100'}>
            <form className={'registerForm'} method={'POST'} onSubmit={this.onSubmitRegister}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'12'} className={'text-center'}>
                    <h2>
                      <FormattedMessage id={'sign.up.title'} />
                    </h2>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonRadioGroup value={me} onIonChange={(e) => this.setState({ me: e.detail.value })}>
                      <IonListHeader>
                        <IonLabel>
                          <FormattedMessage id={'form.account.create'} />
                        </IonLabel>
                      </IonListHeader>
                      <IonGrid>
                        <IonRow>
                          <IonCol sizeLg={'6'} sizeXs={'12'}>
                            <IonItem>
                              <IonLabel>
                                <FormattedMessage id={'form.account.create.me'} />
                              </IonLabel>
                              <IonRadio slot="start" value="Particulier" />
                            </IonItem>
                          </IonCol>
                          <IonCol sizeLg={'6'} sizeXs={'12'}>
                            <IonItem>
                              <IonLabel>
                                <FormattedMessage id={'form.account.create.company'} />
                              </IonLabel>
                              <IonRadio slot="start" value="Professionnel" />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRadioGroup>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position="floating">
                        <FormattedMessage id={'form.name'} />
                      </IonLabel>
                      <IonInput
                        value={name}
                        type={'text'}
                        inputMode={'text'}
                        name={'name'}
                        placeholder={intl.formatMessage(langFunction.formNamePlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ name: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText>{this.showRequiredError('name')}</IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <div className={'label-container'}>
                        <IonLabel position={'stacked'}>
                          <FormattedMessage id={'post.info.user.telephone'} />
                        </IonLabel>
                        <IonText>{this.showRequiredError('phone')}</IonText>
                      </div>
                      <PhoneInput
                        labels={lang === 'fr' ? fr : ar}
                        international={false}
                        countryOptionsOrder={['MA', 'FR', '|']}
                        defaultCountry="MA"
                        placeholder={intl.formatMessage(langFunction.formPhonePlaceholder)}
                        value={phone}
                        onChange={(value) => {
                          this.setState({ phone: value }, () => this.activateSubmit());
                        }}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position="floating">
                        <FormattedMessage id={'form.email'} />
                      </IonLabel>
                      <IonInput
                        value={login}
                        type={'email'}
                        inputMode={'email'}
                        name={'login'}
                        placeholder={intl.formatMessage(langFunction.formEmailPlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ login: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText> {this.showRequiredError('login')}</IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position="floating">
                        <FormattedMessage id={'form.password'} />
                      </IonLabel>
                      <IonInput
                        value={password}
                        type={!showPassword ? 'password' : 'text'}
                        inputMode={'text'}
                        name={'password'}
                        placeholder={intl.formatMessage(langFunction.formPasswordPlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ password: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <span className={'right'} slot={'end'}>
                        <IonIcon
                          icon={!showPassword ? eye : eyeOff}
                          onClick={(event) => this.setState({ showPassword: !showPassword })}
                        />
                      </span>
                      <IonText> {this.showRequiredError('password')}</IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position="floating" className="ion-label">
                        <FormattedMessage id={'form.password.confirm'} />
                      </IonLabel>
                      <IonInput
                        value={confirmPassword}
                        type={!showPassword ? 'password' : 'text'}
                        inputMode={'text'}
                        name={'repeatPassword'}
                        placeholder={intl.formatMessage(langFunction.formPasswordConfirmPlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ confirmPassword: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText> {this.showRequiredError('confirmPassword')}</IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'} className={'text-center mt-4 mb-3'}>
                    <IonCheckbox
                      checked={terms}
                      onIonChange={(e) =>
                        this.setState({ terms: e.detail.checked }, () => {
                          this.activateSubmit();
                        })
                      }
                    />
                    <IonLabel>
                      <FormattedMessage id={'register.terms'} />{' '}
                      <a href={'/privacy'}>
                        <FormattedMessage id={'footer.term.condition'} />
                      </a>
                    </IonLabel>
                    {this.showRequiredError('terms')}
                  </IonCol>
                  <IonCol size={'12'} className={'text-center mt-2'}>
                    {this.showError()}
                  </IonCol>
                  <IonCol size={'12'}>
                    <button
                      className={'btn btn-block mt-2'}
                      type={'submit'}
                      style={{
                        backgroundColor: 'var(--ion-color-primary)',
                        color: 'var(--ion-color-light)',
                      }}
                      disabled={!enableSubmit || isSubmitting}
                    >
                      {!isSubmitting ? <FormattedMessage id={'sign.up.button'} /> : <IonSpinner name="crescent" />}
                    </button>
                  </IonCol>
                  <IonCol size={'12'} className={'text-center mt-4 mb-3'}>
                    <p>
                      <FormattedMessage id={'sign.up.account.first'} />{' '}
                      <a href={'/login'}>
                        <FormattedMessage id={'sign.up.account.second'} />
                      </a>
                    </p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
          <IonAlert
            isOpen={showRegisterSuccess}
            message={'<img src = "./assets/check.gif" > ' + intl.formatMessage(langFunction.signUpAccountSuccess)}
            cssClass="success-alert"
            buttons={[
              {
                text: intl.formatMessage(langFunction.close),
                role: 'cancel',
                handler: () => {
                  this.setState({ showRegisterSuccess: false });
                  lang === 'ar'
                    ? (window.location.href = `/ar/المغرب/للبيع/`)
                    : (window.location.href = `/fr/maroc/a_vendre/`);
                },
              },
            ]}
            onDidDismiss={() => {
              lang === 'ar'
                ? (window.location.href = `/ar/المغرب/للبيع/`)
                : (window.location.href = `/fr/maroc/a_vendre/`);
            }}
          />
        </IonContent>
        <Footer copyrightOnly />
      </IonPage>
    );
  }

  callback() {
    let tmpCallback = '/';

    const { callback } = this.props;

    if (callback && callback !== '') {
      tmpCallback = callback;
    }

    //const c = useQuery(this.props).get('callback');
    const c = '';
    if (c && c !== '') {
      tmpCallback = c;
    }

    return tmpCallback;
  }

  metaTags() {
    return (
      <Helmet>
        <title>S'inscrire</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {
  callback?: string;
}

interface State extends FlahaState {
  name: string;
  phone: any;
  login: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  enableSubmit: boolean;
  me: "Particulier" | "Professionnel";
  registerError: boolean;
  terms: boolean;
  showRequiredMsg: boolean;
  isSubmitting: boolean;
  showRegisterSuccess: boolean;
}

// @ts-ignore
export default injectIntl(Register);
