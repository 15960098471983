import { createReducer } from "@reduxjs/toolkit";
import { setSearch } from "../actions/setSearchAction";


const search = {
  searchClickedRedux: false,
  };


export const setSearchReducer = createReducer(
  { search },
  {
    [setSearch.type]: (state, action) => ({
      ...state,
      search: { ...action.payload }
    }),
  }
);