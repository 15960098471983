import { createReducer } from "@reduxjs/toolkit";
import { saveRegions} from "../actions/saveRegions";

const regions = [];


export const saveRegionsReducer = createReducer(
  { regions },
  {
    [saveRegions.type]: (state, action) => ({
      ...state,
      regions: { ...action.payload }
    }),
  }
);