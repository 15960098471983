import React, { Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, Header } from 'components';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import { Category, City, Marque, Modele } from 'utils/Types/types';
import { alertCircle, createOutline } from 'ionicons/icons';
import './FormChat.scss';
import { getUserToken } from 'utils/Functions/Functions';
import { StylesProvider, TextField } from '@material-ui/core';
import Radium from 'radium';
import { createMuiTheme, jssPreset, withStyles } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import isEqual from 'lodash/isEqual';
import { getCategoriesReduxUtils } from 'utils/Functions/ReferentialGetters';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const cachedStateKey = '@flaha.cachedState';
const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff',
            borderRadius: '1rem',
            '& fieldset': {
                borderColor: 'var(--ion-color-medium)',
                '& legend': {
                    padding: '0px 8px',
                },
            },
            '&:hover fieldset': {
                borderColor: 'var(--ion-color-primary)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--ion-color-primary)',
            },
        },
    },
})(TextField);

const styleMenuItem = (sousCatgorie) => {
    if (sousCatgorie !== 0) {
        return ({paddingRight: '100px', paddingLeft: '100px'})
    } else {
        return ({paddingRight: '20px', paddingLeft: '20px', backgroundColor: 'rgb(245 246 249 / 68%)', fontWeight: 400})
    }
};


const styleAvatar = (sousCatgorie) => {
    if (sousCatgorie !== 0) {
        return ({maxWidth: '25px', height: '25px', backgroundColor: '#ffffff'})
    } else {
        return ({backgroundColor: '#ffffff'})
    }
};


const ltrTheme = createMuiTheme({direction: 'ltr'});
const rtlTheme = createMuiTheme({direction: 'rtl'});

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const requiredStyle = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '10px',
    marginBottom: '9px'
};

class FormChat extends Component<Props, State> {
    link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    constructor(props: Props, context: any) {
        super(props, context);
        this.state = {
            lang: "ar",
            images: [],
            categories: [],
            category: null,
            subCategory: null,
            categoryC: null,
            subCategoryC: null,
            cities: [],
            city: null,
            type: 'ventes',
            urgent: false,
            titleFr: '',
            titleAr: '',
            description: '',
            price: 0.00,
            slug: this.link,
            enableSubmit: false,
            token: null,
            id: null,
            telephoneAnnonce: undefined,
            nomUtilisateurAnnonce: '',
            emailAnnonce: '',
            showAlert: false,
            isSubmitting: false,
            marques: [],
            modeles: [],
            typesCarburant: [],
            boiteAVitesses: [],
            surface: '', poids: '', marque: null, modele: null, anneeModele: '', kilometrage: '', typeCarburant: null,
            puissanceFiscale: '', boiteVitesse: null,
            receivedCategory: 0,
            marqueId: null,
            userOwnerEmail: ''
        };
        
        this.getCategories();
        this.getCities();
        this.getData();
        
    this.handleClick = this.handleClick.bind(this);

        
        this.checkRole = this.checkRole.bind(this);
        this.getCategoriesOptions = this.getCategoriesOptions.bind(this);
        this.getSubCategoriesOptions = this.getSubCategoriesOptions.bind(this);
        this.showAlertMessage = this.showAlertMessage.bind(this);
        this.verifyCategoryC = this.verifyCategoryC.bind(this);
        this.getPrincipalCategory = this.getPrincipalCategory.bind(this);
    }

    checkRole(role: string) {

        let roles = JSON.parse(localStorage.getItem("@flaha.userRoles"));
        let existRole = false;
        roles ? roles.map(row => {
            if (row.role.toLowerCase() === role) {
                existRole = true;
            }
        }) : existRole = false;
        return existRole;
    }

    getPrincipalCategory(category: Category) {
        const {lang, receivedCategory} = this.state;
        const categories = getCategoriesReduxUtils();
        if (categories.length > 0) {
            if (category) {
                if ((category.code === null || category.code === "NULL" || category.code === "0") && category.sousCategorie !== 0) {
                    const principalCategory = categories.find((item => item.code === category.sousCategorie.toString()));
                    this.setState({
                        category: principalCategory,
                        categoryC: (lang === "fr" ? principalCategory.urlFrancais : principalCategory.urlArabe),
                        subCategory: category,
                        subCategoryC: (lang === "fr" ? category.urlFrancais : category.urlArabe),
                        receivedCategory: receivedCategory + 1,
                    });
                } else {
                    this.setState({
                        category: category,
                        categoryC: (lang === "fr" ? category.urlFrancais : category.urlArabe),
                        receivedCategory: receivedCategory + 1,
                    });
                }
            }
        }
    }
   

    async getData() {
        
        const {slug} = this.state;
        const url = `${process.env.REACT_APP_API_URL + constants.posts + '/' + slug}`;
        
        const headers = new Headers({
            'Content-Type': 'application/json',
            'SameSite': 'secure'
        });

        await fetch(url, {
            method: 'GET',
            credentials: "same-origin",
            //mode: 'no-cors',
            headers: headers,
        }).then((request) => {
            if (request.status === 404) {
                return window.location.href = '/user/mes-annonces';
            }
            if (request.status === 200) {
                return request.json();
            }
        }).then((json) => {
            if (json) {
                this.setState({
                    images: json.photos,
                    city: json.ville.id,
                    type: json.typeAnnonce,
                    urgent: json.urgent,
                    titleFr: json.titreFrancais,
                    titleAr: json.titreArabe,
                    description: json.description,
                    price: json.prix,
                    id: json.id,
                    telephoneAnnonce: json.telephoneAnnonce,
                    nomUtilisateurAnnonce: json.nomUtilisateurAnnonce,
                    emailAnnonce: json.emailAnnonce,
                    surface: json.surface,
                    poids: json.poids, marqueId: json.marque, modele: json.modele, anneeModele: json.anneeModele,
                    kilometrage: json.kilometrage, typeCarburant: json.typeDeCarburant,
                    puissanceFiscale: json.puissanceFiscale, boiteVitesse: json.boiteAVitesse,
                    userOwnerEmail: json.utilisateur.email
                }, () => {
                    this.getModeleByMarque(json.marque ? json.marque : null);
                    //this.checkUserAuth();
                });
                this.getPrincipalCategory(json.categorie);
            }
        });
    }

    getUserToken() {
        getUserToken().then((token) => {
            this.setState({token: token})
            ////////////////console.log(token);
        })
    }

    verifyCategoryC() {
        let {category, subCategory, lang, type} = this.state;
        let categ;
        if (category) {
            categ = lang === 'ar' ? category.urlArabe : category.urlFrancais;
            this.setState({categoryC: categ, type: type});
        }
        if (subCategory) {
            categ = lang === 'ar' ? category.urlArabe : category.urlFrancais;
            this.setState({
                subCategoryC: lang === 'ar' ? subCategory.urlArabe : subCategory.urlFrancais,
                type: type
            });
        }

    }

    checkUserAuth() {
        const {userOwnerEmail} = this.state;
        if (!this.checkRole('admin') && !this.checkRole('moderateur') && !this.checkRole('editeur')) {
            let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
            let token = localStorage.getItem('@flaha.userToken');
            let bearer = 'Bearer ' + token;
            const headers = new Headers({
                'Authorization': bearer
            });

            fetch(url, {
                method: 'GET',
                credentials: 'same-origin',
                headers: headers,
            })
                .then((request) => {
                    if (request.status === 200) {
                        return request.json();
                    }
                })
                .then((jsonRequest) => {
                    if (jsonRequest) {
                        if (jsonRequest.email !== userOwnerEmail) {
                            window.location.href = "/";
                        }
                    }
                });

        }
    }

    componentDidMount(): void {
        this.getUserToken();

        //this.getMarques();
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });

        window.addEventListener('ionAlertDidPresent', e => {
            setTimeout(() => {
                this.setState({showAlert: false})
            }, 5000);
        });

        this.verifyCategoryC();
        setTimeout(() => {
            this.getMarques();
            this.getTypesCarburant();
            this.getBoiteAVitesse();
        }, 2000);
    }

    componentDidUpdate(prevProps, prevState) {
        const {lang, receivedCategory} = this.state;
        const langChanged = !isEqual(prevState.lang, lang);
        if (langChanged) {
            this.verifyCategoryC();
        }
        const stateChanged = !isEqual(prevState, this.state);
        if (stateChanged) {
            this.activateSubmit();
            if (receivedCategory === 1) {
                this.getMarques();
                this.getTypesCarburant();
                this.getBoiteAVitesse();
                this.activateSubmit();
                this.setState({receivedCategory: receivedCategory + 1});
            }
        }
    }

    showAlertMessage() {
        const {showAlert} = this.state;
        // @ts-ignore
        const {intl} = this.props;
        const annonceModereeMsg = intl.formatMessage(langFunction.annonceModereeMsg);
        const annonceModereeMsgAdmin = intl.formatMessage(langFunction.annonceModificationMsg);
        const closeMsg = intl.formatMessage(langFunction.close);

        return <IonAlert isOpen={showAlert}
                         message={(this.checkRole('admin') || this.checkRole('moderateur') || this.checkUserRole('editeur')) ? '<img src = "./assets/check.gif" > ' + annonceModereeMsgAdmin : '<img src = "./assets/check.gif" > ' + annonceModereeMsg}
                         cssClass="success-alert"
                         buttons={[{
                             text: closeMsg,
                             role: 'cancel',
                             handler: () => {
                                 this.setState({showAlert: false})
                                 window.location.href = '/user/mes-annonces';
                             }
                         }]}
                         onDidDismiss={() => {
                             window.location.href = '/user/mes-annonces';
                         }}
        />
    }
    addChat = async (slug: string) => {
       
            const formData: FormData = new FormData();
            const url = `${process.env.REACT_APP_API_URL}${constants.AddChatChannel}`;
            let token = localStorage.getItem('@flaha.userToken');
            let bearer = 'Bearer ' + token;
            const headers = new Headers({
                'Authorization': bearer
            });

            const urlAdd = url + slug ;
            return fetch(urlAdd, {
                method: 'POST',
                body: formData,
                headers: headers,
            }).then(response => {
                if (response.status !== 200 && response.status !== 409) {
                    throw response;
                } else {
                    window.location.href =`/user/chat`                    
                }
            })
        }
    


    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    // Categories
    async getCategories() {
        const url = process.env.REACT_APP_API_URL + constants.categoriesSendPost;

        await fetch(url).then((result) => {
            return result.json();
        }).then((jsonResult) => {
            this.setState({
                categories: jsonResult,
            });
        }).catch((error) => {
            console.error(error.message, error);
        });
    }

    // Villes
    getCities() {
        const url = process.env.REACT_APP_API_URL + constants.cities;

        fetch(url).then((result) => {
            return result.json();
        }).then((jsonResult) => {
            this.setState({
                cities: jsonResult,
            });
        }).catch((error) => {
            console.error(error.message, error);
        });
    }

    activateSubmit() {
        const {
            telephoneAnnonce, categoryC,
            titleFr, titleAr, price, token, description, city, category, lang,
            nomUtilisateurAnnonce,
            marque, boiteVitesse, subCategory
        } = this.state;
        ////////console.log(this.state);
        if (
            ((lang === 'fr' && titleFr && titleFr !== '')
                || (lang === 'ar' && titleAr && titleAr !== ''))
            && description && description !== ''
            && price >= 0
            && city && ((lang === 'fr' && categoryC && categoryC !== 'a_vendre') || (lang === 'ar' && categoryC && categoryC !== 'للبيع'))
            && category && ((token !== null && !this.checkRole('user') && isPossiblePhoneNumber(telephoneAnnonce)
                && telephoneAnnonce && telephoneAnnonce !== '' && nomUtilisateurAnnonce && nomUtilisateurAnnonce !== '') || (token === null || this.checkRole('user')))
            && ((category && (category.marques === null || (marque && boiteVitesse)))
                || (subCategory && (subCategory.marques === null || (marque && boiteVitesse))))

            //&& ( category && category !== null && ((category.haveSurface && surface !== '' ) || !category.haveSurface))
            //&& ( category && category !== null && ((category.haveWeight && poids !== '' ) || !category.haveWeight))

        ) {
            this.setState({enableSubmit: true});
            return
        }
        this.setState({enableSubmit: false});
    }

    handleNone() {
        this.setState({
            category: null,
            subCategory: null,
            modeles: [],
            marques: [],
            marque: null,
            modele: null,
            typeCarburant: null,
            puissanceFiscale: '',
            kilometrage: '',
            anneeModele: '',
            boiteVitesse: null
        }, () => {
            this.activateSubmit();
        });
    }

    handleClickSubCategory(value) {
        const {categories} = this.state;
        const subCategory = categories.find((item) => item.id === value);
        this.setState({
            subCategory: subCategory,
            modeles: [],
            marques: [],
            marque: null,
            modele: null,
            typeCarburant: null,
            puissanceFiscale: '',
            kilometrage: '',
            anneeModele: '',
            boiteVitesse: null
        }, () => {
            this.getMarques();
            this.getTypesCarburant();
            this.getBoiteAVitesse();
            this.activateSubmit();
        });
    }
    handleClick() {
        const {slug} = this.state;

       this.addChat(slug);
      }

    handleClickCategory(value) {
        const {categories} = this.state;
        const category = categories.find((item) => item.id === value);
        this.setState({
            category: category,
            subCategory: null,
            modeles: [],
            marques: [],
            marque: null,
            modele: null,
            typeCarburant: null,
            puissanceFiscale: '',
            kilometrage: '',
            anneeModele: '',
            boiteVitesse: null
        }, () => {
            this.getMarques();
            this.getTypesCarburant();
            this.getBoiteAVitesse();
            this.activateSubmit();
        });
    }

    getSubCategoriesOptions() {
        const {categories, lang, category} = this.state;
        const filtredCategories = categories ? categories.filter((item: Category) => {
            return item.searchDesactivate === false
        }) : null;
        const allSubCategories = filtredCategories ? filtredCategories.filter((item: Category) => {
            return item.sousCategorie !== 0
        }) : null;
        const subCategories = allSubCategories && category ? allSubCategories.filter((item: Category) => {
            return item.sousCategorie.toString() === category.code
        }) : null;

        const finalSortedSubCategories = subCategories ? subCategories.map((item) => {
            return (
                {
                    id: item.id,
                    label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
                    value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
                    nomImage: item.nomImage,
                    sousCategorie: item.sousCategorie,
                }
            )
        }) : null;
        ////////////console.log(category);
        return finalSortedSubCategories;
    }

    getCategoriesOptions() {
        const {categories, lang} = this.state;
        const principalCategories = categories ? categories.filter((item: Category) => {
            return item.sousCategorie === 0
        }) : null;


        const principalCategoriesFormatted = principalCategories ? principalCategories.map((item: Category) => {

            return (
                {
                    id: item.id,
                    label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
                    value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
                    nomImage: item.nomImage,
                    sousCategorie: item.sousCategorie,
                }

            )
        }) : null;
        return principalCategoriesFormatted;
    }

    getMarques() {
        const {category, subCategory, marqueId} = this.state;
        if ((category && category.marques !== null && category.marques !== '') || (subCategory && subCategory.marques !== null && subCategory.marques !== '')) {
            const idsMarques = subCategory ? (subCategory.marques ? subCategory.marques : category.marques) : (category.marques ? category.marques : null);
            // //////////////console.log('getMarque called !');
            let url = process.env.REACT_APP_API_URL + constants.getMarques;
            const headers = new Headers({
                'Content-Type': 'application/json',
                accept: '*/*'
            });
            fetch(`${url}` + `?marques=${idsMarques}`, {
                method: 'POST',
                headers: headers,
            }).then((response) => {
                let marquesData = response.json();
                marquesData.then((jsonRequest) => {
                    if (jsonRequest) {
                        this.setState({marques: jsonRequest}, () => {
                            const marqueObject = jsonRequest.find((item: Marque) => item.id === marqueId);
                            this.setState({marque: marqueObject});
                        });
                    }
                });
            });
        }

    }

    async getTypesCarburant() {
        const {category, subCategory} = this.state;
        //console.log(category, subCategory);
        if ((category && category.marques !== null && category.marques !== '' && !category.forAnimal) || (subCategory && subCategory.marques !== null && subCategory.marques !== '' && !category.forAnimal)) {
            let url = process.env.REACT_APP_API_URL + constants.getTypesCarburant;
            const request = await fetch(url, {
                method: 'GET'
            });
            const jsonRequest = await request.json();
            if (jsonRequest) {
                this.setState({typesCarburant: jsonRequest});
            }
        }
    }

    getBoiteAVitesse() {
        const {category, subCategory} = this.state;
        if ((category && category.marques !== null && category.marques !== '') || (subCategory && subCategory.marques !== null && subCategory.marques !== '')) {
            let url = process.env.REACT_APP_API_URL + constants.getBoiteAVitesses;

            return fetch(url, {
                method: 'GET'
            })
                .then((request) => {
                    return request.json();
                })
                .then((jsonRequest) => {
                    if (jsonRequest) {
                        this.setState({boiteAVitesses: jsonRequest});
                        //////////console.log(jsonRequest);
                    }
                });
        }
    }
   

    getModeleByMarque(idMarque: any) {
        const {marque} = this.state;
        const idMarqueState = marque ? marque.id : null;
        let url;

        if (idMarque) {
            url =
                process.env.REACT_APP_API_URL +
                constants.getModelesByMarque +
                '?idMarque=' +
                idMarque;
        } else {
            url =
                process.env.REACT_APP_API_URL +
                constants.getModelesByMarque +
                '?idMarque=' +
                idMarqueState;
        }
        //////////console.log(marqueId)
        return fetch(url, {
            method: 'GET',
        })
            .then((request) => {
                return request.json();
            })
            .then((jsonRequest) => {
                if (jsonRequest) {
                    this.setState({modeles: jsonRequest});
                }
            });
    }

    
    

    render() {
        const {
            images,
            type,
            urgent,
            category,
            categoryC,
            subCategoryC,
            isSubmitting,
            city,
            cities,
            titleFr,
            titleAr,
            description,
            price,
            enableSubmit,
            lang,
            telephoneAnnonce,
            nomUtilisateurAnnonce,
            emailAnnonce,
            slug,
            subCategory
        } = this.state;
        // @ts-ignore
        const {intl} = this.props;
        const categoryPlaceholder = intl.formatMessage(langFunction.categoryPlaceholder);
        const subCategoryPlaceholder = intl.formatMessage(langFunction.subCategoryPlaceholder);

        const categoryOptions = this.getCategoriesOptions();
        const subCategoriesOptions = this.getSubCategoriesOptions();

        const constantAllCategories = lang === 'fr' ? "a_vendre" : "للبيع";

        const cityObject = cities.find((item: City) => {
            return item.id === Number(city);
        });
        const cityName = cityObject ? (lang === "fr" ? cityObject.nomFr : cityObject.nomArab) : "";
        return (
            <IonPage>
                {this.metaTags()}
                <Header/>
                <IonContent className={'SendPostPage'}>
                    {this.showAlertMessage()}
                    <div className={'container mbm-100'}>
                        <Radium.StyleRoot>
                            <StylesProvider jss={jss}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                                                <IonGrid>
                                                    <IonRow className={`card mt-5`}>
                                                    <IonCol size={'12'} className={'card-header'}>
                                                                <h4 className={'card-title'}><FormattedMessage
                                                                    id={'post.info'}/></h4>
                                                            </IonCol>
                                                        
                                                            <IonCol size={'12'}>
                                                                <IonItem>
                                                                    <IonLabel
                                                                        position="floating">{category !== null && category.id === 117 ?
                                                                        <FormattedMessage
                                                                            id={'post.title.veterinaire.1'}/> :
                                                                        <FormattedMessage
                                                                            id={'post.title.1'}/>}</IonLabel>
                                                                    <IonInput value={titleFr}
                                                                    readonly/>
                                                                    {titleFr === '' &&
                                                                    <IonText style={requiredStyle} slot={'end'}>
                                                                        <IonIcon style={{marginBottom: "-4px"}}
                                                                                 icon={alertCircle} size={'small'}/>
                                                                        <FormattedMessage id={'form.required'}/>
                                                                    </IonText>
                                                                    }
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol size={'12'}>
                                                                <IonItem>
                                                                    {titleAr === '' &&
                                                                    <IonText style={requiredStyle} slot={'end'}>
                                                                        <IonIcon style={{marginBottom: "-4px"}}
                                                                                 icon={alertCircle} size={'small'}/>
                                                                        <FormattedMessage id={'form.required'}/>
                                                                    </IonText>
                                                                    }
                                                                    <IonLabel
                                                                        position="floating">{category !== null && category.id === 117 ?
                                                                        <FormattedMessage
                                                                            id={'post.title.veterinaire.2'}/> :
                                                                        <FormattedMessage
                                                                            id={'post.title.2'}/>}</IonLabel>
                                                                    <IonInput value={titleAr} readonly/>
                                                                </IonItem>
                                                            </IonCol>
                                                        <IonCol size={'12'}>
                                                            <IonItem>
                                                                {description === '' &&
                                                                <IonText style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                    fontSize: "10px",
                                                                }} slot={'end'}>
                                                                    <IonIcon style={{marginBottom: "-4px"}}
                                                                             icon={alertCircle}
                                                                             size={'small'}/>
                                                                    <FormattedMessage id={'form.required'}/>
                                                                </IonText>
                                                                }
                                                                <IonLabel
                                                                    position="floating">{category !== null && category.id === 117 ?
                                                                    <FormattedMessage
                                                                        id={'post.description.veterinaire'}/> :
                                                                    <FormattedMessage
                                                                        id={'post.description'}/>}</IonLabel>
                                                                <IonTextarea value={description} readonly/>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size={'12'}>
                                                            <IonItem>
                                                                <IonLabel position="floating">
                                                                    <FormattedMessage id={'post.price'}/> <br/>
                                                                </IonLabel>
                                                                <IonInput value={price} readonly/>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size={'12'}>
                                                            <IonItem className={'item-has-value'}>
                                                            <IonLabel position="floating">
                                                                    <FormattedMessage id={'post.city'}/> <br/>
                                                                </IonLabel>
                                                            <IonInput value={cityName} readonly/>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                                
                                                
                                        </IonCol>
                                       
                                        <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                                                
                                                    <IonGrid>
                                                        <IonRow className={`card mt-5`}>
                                                            <IonCol size={'12'} className={'card-header'}>
                                                                <h4 className={'card-title'}><FormattedMessage
                                                                    id={'message.user.seller'}/></h4>
                                                            </IonCol>
                                                            <IonCol size={'12'}>
                                                                <IonItem>
                                                                    <IonLabel position="floating"><FormattedMessage
                                                                        id={'post.info.user.nom'}/></IonLabel>
                                                                    <IonInput value={nomUtilisateurAnnonce}
                                                                              readonly
                                                                      />
                                                                    {!nomUtilisateurAnnonce && nomUtilisateurAnnonce === '' &&
                                                                    <IonText style={requiredStyle} slot={'end'}>
                                                                        <IonIcon style={{marginBottom: "-4px"}}
                                                                                 icon={alertCircle} size={'small'}/>
                                                                        <FormattedMessage id={'form.required'}/>
                                                                    </IonText>}
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol size={'12'}>
                                                                <IonItem >
                                                                    <div className={'label-container'}>
                                                                        <IonLabel position={'stacked'}><FormattedMessage
                                                                            id={'post.info.user.telephone'}/></IonLabel>
                                                                        {!telephoneAnnonce && <IonText style={requiredStyle}>
                                                                            <IonIcon style={{marginBottom: "-4px"}}
                                                                                     icon={alertCircle} size={'small'}/>
                                                                            <FormattedMessage id={'form.required'}/>
                                                                        </IonText>}
                                                                        {!isPossiblePhoneNumber(String(telephoneAnnonce)) && <IonText style={requiredStyle}>
                                                                            <IonIcon style={{marginBottom: "-4px"}}
                                                                                     icon={alertCircle} size={'small'}/>
                                                                            <FormattedMessage id={'form.phone.invalid'}/>
                                                                        </IonText>}
                                                                    </div>
                                                                    <IonInput
                                                                        value={telephoneAnnonce}
                                                                        readonly/>
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol size={'12'}>
                                                                <IonItem>
                                                                    <IonLabel position="floating"><FormattedMessage
                                                                        id={'message.user'}/></IonLabel>
                                                                    
            
                                                                    <IonTextarea rows={6} cols={20} placeholder="Ecrivez votre message..." ></IonTextarea>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'}
                                                                className={'send-button'}>
                                                            <button type={'submit'} className={'btn btn-block'}
                                                                    style={{
                                                                        backgroundColor: 'var(--ion-color-primary)',
                                                                        color: 'var(--ion-color-light)',
                                                                    }}
                                                                    onClick={this.handleClick}
                                                                    disabled={!enableSubmit || isSubmitting}
                                                            >
                                                                {!isSubmitting ? <FormattedMessage id={'form.send'}/> :
                                                                    <IonSpinner name="crescent"/>}
                                                                <IonIcon size={'small'} icon={createOutline}
                                                                         style={{float: 'right'}}/>
                                                            </button>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                                
                                                </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </StylesProvider>
                        </Radium.StyleRoot>
                    </div>
                    <Footer copyrightOnly/>
                </IonContent>
            </IonPage>
        );
    }

    metaTags() {
        return (
            <Helmet>
                <title>Envoyer un message </title>
            </Helmet>
        );
    }

    checkUserRole(role: string) {

        let roles = JSON.parse(localStorage.getItem("@flaha.userRoles"));
        let existRole = false;
        roles ? roles.map(row => {
            if (row.role.toLowerCase() === role) {
                existRole = true;
            }
        }) : existRole = false;
        return existRole;
    }

}

// @ts-ignore
export default injectIntl(FormChat);


interface Props extends FlahaProps {

}

interface State extends FlahaState {
    images: Array<string>;
    categories: Array<Category>;
    category: Category | null;
    subCategory: Category | null;
    categoryC?: string | null | undefined;
    subCategoryC?: string | null | undefined;
    cities: Array<City>;
    city: City | null;
    type: 'ventes' | 'achats' | 'locations' | 'offres' | 'achats';
    urgent: boolean;
    titleFr: string;
    titleAr: string;
    description: string;
    price: number;
    enableSubmit: boolean;
    token: string | null;
    slug: string | null;
    id: number | null;
    telephoneAnnonce: any;
    nomUtilisateurAnnonce: string;
    emailAnnonce: string;
    showAlert: boolean;
    isSubmitting: boolean;
    marques: Array<Marque>;
    modeles: Array<Modele>;
    typesCarburant: Array<any>;
    boiteAVitesses: Array<any>;
    surface: string;
    poids: string;
    marque: Marque;
    modele: Modele;
    anneeModele: string;
    kilometrage: string;
    typeCarburant: any;
    puissanceFiscale: string;
    boiteVitesse: any;
    receivedCategory: number;
    marqueId: number;
    userOwnerEmail: string;
}
