import React, { Component } from 'react';
import './FeaturedResults.scss';
import { constants, Events, madFormatter } from 'utils';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { Post, SearchCriteria } from 'utils/Types/types';
// @ts-ignore
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { trackEvent } from '../../tracking';
import { postClicked, postHovered } from '../../tracking/events';
import EventTrackingService from '../../services/EventTrackingService';

export default class FeaturedResults extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      posts: [],
      lang: 'ar',
      lastPage: false,
      value: props.value,
      size: 2,
      page: 0,
    };
  }

  getData() {
    const { categoryId } = this.props;

    let filterData = {
      prixMin: null,
      prixMax: null,
      typeAnnonce: null,
      typeUtilisateur: null,
      searchText: null,
      categorieId: categoryId || null,
      villeId: null,
      regionId: null,
      urgent: true,
      titleOnly: false,
    };
    const urgentUri = encodeURIComponent(JSON.stringify(filterData));
    const url = `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri;
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        if (result.status !== 200) {
          return [];
        }
        return result.json();
      })
      .then((jsonResult) => {
        //console.log(jsonResult);
        if (jsonResult?.content?.length <= 0) {
          this.getDefaultData();
        } else {
          this.setState(
            {
              // @ts-ignore
              posts: jsonResult.content,
              page: jsonResult.number + 1,
              lastPage: true,
            },
            () => {
              if (this.props.results) this.props.results(jsonResult.content);
            }
          );
        }
      })
      .catch((error) => {
        console.error(error.message, error);
        this.setState({
          lastPage: true,
        });
      });
  }

  getDefaultData() {
    let filterData = {
      prixMin: null,
      prixMax: null,
      typeAnnonce: null,
      typeUtilisateur: null,
      searchText: null,
      categorieId: null,
      villeId: null,
      regionId: null,
      urgent: true,
      titleOnly: false,
    };

    const urgentUri = encodeURIComponent(JSON.stringify(filterData));

    const url = `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri;
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        if (result.status !== 200) {
          return [];
        }
        return result.json();
      })
      .then((jsonResult) => {
        //console.log(jsonResult);
        this.setState(
          {
            // @ts-ignore
            posts: jsonResult.content,
            page: jsonResult.number + 1,
            lastPage: true,
          },
          () => {
            if (this.props.results) this.props.results(jsonResult.content);
          }
        );
      })
      .catch((error) => {
        console.error(error.message, error);
        this.setState({
          lastPage: true,
        });
      });
  }

  componentDidMount() {
    this.getData();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.getData();
    }
  }

  componentWillUnmount() {
    Events.destroy(constants.lang_change);
  }

  renderItem(item: Post, index: number) {
    const { id, titreFrancais, titreArabe, prix, premierePhoto, categorie, ville, slug } = item;
    const { lang } = this.state;
    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
    const villeArab = ville ? ville.urlArab : '';
    const villeFrancais = ville ? ville.urlFr : '';
    const regionArab = ville ? ville.province.region.urlArab : '';
    const regionFr = ville ? ville.province.region.urlFr : '';
    const categorieFrancais = categorie ? categorie.urlFrancais : '';
    const categorieArab = categorie ? categorie.urlArabe : '';

    return (
      <div
        key={id}
        className={'post-item featured'}
       /* onMouseEnter={() => {
          trackEvent(postHovered({ postSlug: slug, postId: id, context: 'featured' }));
          const options = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          EventTrackingService.createEvent({
            data: {
              event: {
                id: constants.POST_HOVERED,
              },
              annonce: {
                id: id,
              },
              context: {
                id: constants.FEATURED,
              },
            },
            options: options,
          }).catch((reason) => console.error(reason.message));
        }}*/
      >
        <span className={'budget'}>
          <FormattedMessage id={'main.focus'} />
        </span>
        <IonCard
          button
          href={
            lang === 'ar'
              ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
              : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
          }
          onClick={() => {
            trackEvent(postClicked({ postSlug: slug, postId: id, context: 'featured' }));
            const options = {
              headers: {
                'Content-Type': 'application/json',
              },
            };

            EventTrackingService.createEvent({
              data: {
                event: {
                  id: constants.POST_CLICKED,
                },
                annonce: {
                  id: id,
                },
                context: {
                  id: constants.FEATURED,
                },
              },
              options: options,
            }).catch((reason) => console.error(reason.message));
          }}
          style={{ margin: '-1.47rem 0rem 0rem 0rem', boxShadow: 'none' }}
        >
          <a
            href={
              lang === 'ar'
                ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
            }
          />
          <picture>
            <source
              type="image/webp"
              srcSet={`${
                premierePhoto && premierePhoto.cheminWebp
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
            />
            <img
              loading="lazy"
              decoding={'async'}
              src={`${
                premierePhoto && premierePhoto.chemin
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.chemin + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
              className={'rounded-top d-block bg-dark'}
              style={{ maxHeight: '13rem', minWidth: '100%' }}
              alt={lang === 'fr' ? titreFrancais + '-' + id : titreArabe + '-' + id}
            />
          </picture>
          <IonCardHeader>
            <IonCardTitle className={'title'}>{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
            <meta
              itemProp="priceCurrency"
              content="MAD"
              data-reactid={prix !== 0.0 ? madFormatter().format(prix) : 'Prix négociable'}
            />
            <IonCardSubtitle className={'price'}>
              {prix !== 0.0 ? (
                <FormattedNumber
                  value={prix}
                  style={'currency'}
                  currency={'Mad'}
                  currencyDisplay={'symbol'}
                  maximumFractionDigits={2}
                />
              ) : (
                <FormattedMessage id={'price.negotiable'} />
              )}
            </IonCardSubtitle>
            {/* <IonCardContent className={'detail'}>
                            {categorie !== undefined ?
                                <p>
                                    <strong>{lang === 'fr' && (categorie !== undefined) ? categorie.titreFrancais : categorie.titreArabe}</strong>
                                </p> : null
                            }
                            {ville !== undefined ?
                                <p>{lang === 'fr' && (ville !== undefined) ? ville.nomFr : ville.nomArab}</p> : null
                            }
                            <p>{dateFormatter(dateModification, lang)}</p>
                        </IonCardContent>*/}
          </IonCardHeader>
        </IonCard>
      </div>
    );
  }

  render() {
    const { posts } = this.state;

    /* `${3500 - initScroll * 200}rem` */
    return <div>{posts?.slice(0, 2).map((item: Post, index: number) => this.renderItem(item, index))}</div>;
  }
}

interface Props extends FlahaProps {
  value?: object;
  results?: (value: number) => void;
  categoryId?: string;
}

interface State extends FlahaState {
  posts: any;
  lastPage: boolean;
  value?: SearchCriteria;
  size: number;
  page: number;
}
