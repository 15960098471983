/**
 * This is a GA event object that is tracked when the user clicks on view phone number in the desktop version of the application 
 */
export const phoneNumberViewed = ({ postSlug, postId }: { postSlug: string, postId: number }) => ({
  event: "PHONE_NUMBER_VIEWED",
  postId,
  postSlug
});

/**
 * This is a GA event object that is tracked when the user clicks on view phone number in the desktop version of the application 
 */
 export const whatsappShareClicked = ({ postSlug, postId }: { postSlug: string, postId: number }) => ({
  event: "WHATSAPP_SHARE_CLICKED",
  postId,
  postSlug
});

/**
 * This is a GA event object that is tracked when the user clicks on view phone number in the desktop version of the application 
 */
 export const whatsappCallClicked = ({ postSlug, postId }: { postSlug: string, postId: number }) => ({
  event: "WHATSAPP_CALL_CLICKED",
  postId,
  postSlug
});

/**
 * This is a GA event object that is tracked when the user clicks on call phone number in the mobile version of the application
 */
export const callPhoneNumberClicked = ({ postSlug, postId }: { postSlug: string, postId: number }) => ({
  event: "CALL_PHONE_NUMBER_CLICKED",
  postId,
  postSlug
});

/**
 * This is a GA event object that is tracked when the user clicks on send mail in the post details page
 */
export const sendMailClicked = ({ postSlug, postId }: { postSlug: string, postId: number }) => ({
  event: "SEND_MAIL_CLICKED",
  postId,
  postSlug
});

/**
 * This is a GA event object that is tracked when the user hover on a post in multiple contexts
 * featured context: when the post is being displayed in `à la une`
 * listing context: when the post is being displayed in the infini scroll page below the search box
 * recommanded context: when the post is being displayed in the recommanded posts on the post details page
 */
export const postHovered = ({ postSlug, postId, context }: { postSlug: string, postId: number, context?: "featured"|"listing"|"recommanded"|"other_shop_posts" }) => ({
  event: "POST_HOVERED",
  postId,
  postSlug,
  context
});

/**
 * This is a GA event object that is tracked when the user clicks on a post in multiple contexts
 * featured context: when the post is being displayed in `à la une`
 * listing context: when the post is being displayed in the infini scroll page below the search box
 * recommanded context: when the post is being displayed in the recommanded posts on the post details page
 */
export const postClicked = ({ postSlug, postId, context }: { postSlug: string, postId: number, context?: "featured"|"listing"|"recommanded"|"other_shop_posts" }) => ({
  event: "POST_CLICKED",
  postId,
  postSlug,
  context
});

/**
 * This is a GA event object that is tracked when the user click on the search button in the filter box
 * It gets triggered in two contexts:
 * home context: home page (first search/filter)
 * listing context: when the user is already looking at posts but wants to filter again or change filters
 */
export const searchButtonClicked = ({ region, category, query, price, titleOnly, urgentOnly, marque, modele, typeCarburant, boiteVitesse, puissanceFiscale, anneeModele, context }: { region: string|number, category: string|number, query: string, price: { lower: number, upper: number }, titleOnly: boolean, urgentOnly: boolean, marque: string|number, modele: string|number, typeCarburant: string|number, boiteVitesse: string|number, puissanceFiscale: string, anneeModele: string, context: "home"|"listing" }) => ({
  event: "SEARCH_BUTTON_CLICKED",
  region,
  category, 
  query, 
  price, 
  titleOnly, 
  urgentOnly,
  marque,
  modele,
  typeCarburant,
  boiteVitesse,
  puissanceFiscale,
  anneeModele,
  context
});

/**
 * This is a GA event object that is tracked when the user clicks on category button on the home page as a shortcut
 * to see the list of posts within that specific category
 */
export const categoryButtonClicked = ({ categoryId }: { categoryId: number }) => ({
  event: "CATEGORY_BUTTON_CLICKED",
  categoryId
});

/**
 * This is a GA event object that is tracked when the user clicks on region button on the home page as a shortcut
 * to see the list of posts within that specific region
 */
export const regionButtonClicked = ({ regionId }: { regionId: number }) => ({
  event: "REGION_BUTTON_CLICKED",
  regionId
});

/**
 * This is a GA event object that is tracked when the user clicks on top category button on the home page as a shortcut
 * to see the list of posts within that specific category
 */
export const topCategoryButtonClicked= ({ categoryId }: { categoryId: number }) => ({
  event: "TOP_CATEGORY_BUTTON_CLICKED",
  categoryId
});

/**
 * This is a GA event object that is tracked when the user clicks on recent region button on the home page as a shortcut
 * to see the list of posts within that specific region
 */
export const recentRegionButtonClicked = ({ regionId }: { regionId: number }) => ({
  event: "RECENT_REGION_BUTTON_CLICKED",
  regionId
});
