import * as React from 'react';
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { madFormatter, constants, dateFormatter} from "utils";
import {FormattedMessage, FormattedNumber, injectIntl} from "react-intl";
import {Post} from "utils/Types/types";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSlides, 
  IonSlide,
  isPlatform,
  IonModal,
  IonLoading
} from "@ionic/react";
import {    
  chevronForwardOutline,
  chevronBackOutline
} from 'ionicons/icons';
import {langFunction} from "lang";
import './RecommendedPostsHome.scss'
import { SearchDetail } from 'pages';

const slideOpts = {
  initialSlide: 1,
  speed: 400,
  slidesPerView: 2,
  centeredSlides: false,
  breakpoints: {
      1024: {
        slidesPerView: 5,
        centeredSlides: false
      },
      325: {
          slidesPerView: 2.5,
          centeredSlides: false,
          spaceBetween: 0
      },
    }
};

const noImage = "/assets/img/no_images.webp"

interface RecommendedPostsHomeProps {
  lang: string;
  intl: any;
}

const RecommendedPostsHome: React.FC<RecommendedPostsHomeProps> = ({ lang, intl }: RecommendedPostsHomeProps) => {
  const slider: React.Ref<HTMLIonSlidesElement> = React.useRef()
  const [posts, setPosts] = React.useState([])
  const [showModal, setShowModal] = React.useState(false)
  const [slicedSlug, setSlicedSlug] = React.useState('')
  const [slug, setSlug] = React.useState('')
  const [showLoading, setShowLoading] = React.useState(false);
  React.useEffect(() => {
    document.addEventListener('ionBackButton', (ev: CustomEvent) => {
      ev.detail.register(10, () => {
        setShowModal(false);
        });
    });
    let filterData = {
        prixMin:null,
        prixMax:null,
        typeAnnonce:null,
        typeUtilisateur:null,
        searchText:null,
        categorieId:null,
        villeId:null,
        regionId:null,
        urgent:false,
        titleOnly:false
    }
    const urgentUri = encodeURIComponent(JSON.stringify(filterData));
    const url = `${process.env.REACT_APP_API_URL}${constants.posts}`+'?filter='+urgentUri+'&page=0&size=15';
    const headers = new Headers({
        'Content-Type': 'application/json',
        'SameSite': 'secure'
    });

    fetch(url, {
        method: 'GET',
        credentials: "same-origin",
        //mode: 'no-cors',
        headers: headers,
    }).then((result) => {

        if (result.status!==200){
            return {content: []};
        }
        return result.json();
    }).then((jsonResult) => {
      setPosts(jsonResult.content);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const slugProps = {slug: slicedSlug};
  return !isEmpty(posts) ?
  <div className="sliderContainer2">
          {isPlatform('capacitor') && <IonModal
                    isOpen={showModal}
                    cssClass='my-custom-class'
                    swipeToClose={true} 
                    onDidDismiss={() => setShowModal(false)}
                    >
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() =>setShowLoading(false)}
                        duration={1000}
                        mode="ios" 
                        spinner={"bubbles"}
                    />  
                    <SearchDetail {...slugProps} ></SearchDetail>
                </IonModal> }
          {isPlatform('desktop') && 
              <>
                  <IonIcon className="nextBtn2" onClick={() => {
                    lang === 'fr' ? slider.current.slideNext() : slider.current.slidePrev();
                    }} icon={chevronForwardOutline} />
                  <IonIcon className="prevBtn2" onClick={() => {
                    lang === 'fr' ? slider.current.slidePrev() : slider.current.slideNext();
                    }} icon={chevronBackOutline} />
              </>}
            <IonSlides ref={slider} options={slideOpts}>
              {posts.map((post: Post,index) => {
                const id = get(post, 'id');
                const pic = get(post, 'premierePhoto.chemin');
                const picWebp = get(post, 'premierePhoto.cheminWebp');
                const prix = get(post, 'prix');
                const slug = get(post, 'slug');
                const titreFrancais = get(post, 'titreFrancais');
                const dateModification = get(post, 'dateModification');
                const titreArabe = get(post, 'titreArabe');
                const slicedSlug = slug ? slug.replace(id + '', "_" + id) : '';
                const villeArab = get(post, 'ville.urlArab');
                const villeFrancais = get(post, 'ville.urlFr');
                const regionArab = get(post, 'ville.province.region.urlArab');
                const regionFr =   get(post, 'ville.province.region.nomFr.urlFr');
                const categorieFrancais =   get(post, 'categorie.urlFrancais');
                const categorieArab =  get(post, 'categorie.urlArabe');
                const villeArabTitre = get(post, 'ville.nomArab');
                const villeFrancaisTitre = get(post, 'ville.nomFr');

                return (
                  <IonSlide key={index+""}>
                    <IonCard className="slide_card" //href={`/detail/${slug}`} 
                        onClick={() => {
                          if (isPlatform('capacitor')) {
                            setSlug(slug);
                            setSlicedSlug(slicedSlug);
                            setShowModal(true);
                            setShowLoading(true);
                          }else {
                            lang === 'ar' ? 
                            window.location.href = `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm` 
                            : window.location.href = `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`;     
                          }
                        }}>
                          <a href={lang === 'ar' ?
                             `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                             : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`}></a>
                        {pic ? (
                         
                          <picture>
                            <source type='image/webp' srcSet={process.env.REACT_APP_API_URL + constants.pictures + picWebp + "?small=false"} />
                            <img loading="lazy"
                              alt={slicedSlug}
                              src={process.env.REACT_APP_API_URL + constants.pictures + pic +"?small=false"}
                            />
                        </picture>
                        ) : (
                          <img loading="lazy"
                            alt="empty"
                            src={noImage}
                          />
                        )}
                        <IonCardHeader className="post_info">
                            <IonCardTitle className="post_title">{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
                            <meta itemProp="priceCurrency" content="MAD"
                                data-reactid={prix !== 0.0 ?
                                    madFormatter().format(prix) :
                                    intl.formatMessage(langFunction.priceNegotiable)
                                }/>
                            <div className="date-info">
                              {dateFormatter(dateModification, lang)}
                            </div>   
                            <IonCardSubtitle className={'price'} color={'primary'}>
                                {prix !== 0.0 ?
                                    <FormattedNumber value={prix} style={'currency'} currency={'Mad'}
                                                    currencyDisplay={'symbol'}
                                                    maximumFractionDigits={2}/> :
                                    <FormattedMessage id={'price.negotiable'}/>
                                }
                            </IonCardSubtitle>
                            <div className="details">
                              {(villeFrancais && villeArab) ?
                                <p>{lang === 'fr' ? villeFrancaisTitre  : villeArabTitre}</p> : null
                              }
                            </div>
                        </IonCardHeader>
                      </IonCard>
                  </IonSlide>
                )
              }
              )}
                  
            </IonSlides>
          </div>
  :
  null
}

export default injectIntl(RecommendedPostsHome);
