import { createReducer } from "@reduxjs/toolkit";
import { saveRegionsCategories } from "../actions/saveRegionsCategories";

const Regions_Categories = {
  regions: [],
  categories: [],
  cities: []
};


export const saveRegionsCategoriesReducer = createReducer(
  { Regions_Categories },
  {
    [saveRegionsCategories.type]: (state, action) => ({
      ...state,
      Regions_Categories: { ...action.payload }
    }),
  }
);