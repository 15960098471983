import React, { useEffect, useState } from 'react';
import './PremiumAnnonces.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { constants, madFormatter, urlDataFormatter } from 'utils';
import { setValue } from '../../slices/searchRefreshCounterSlice';
import { Post } from 'utils/Types/types';
import { trackEvent } from '../../tracking';
import { postClicked, postHovered } from '../../tracking/events';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import EventTrackingService from '../../services/EventTrackingService';

interface PremiumAnnonceRenderProps {
  item: Post;
  lang: string;
}

const PremiumAnnonceRender: React.FC<PremiumAnnonceRenderProps> = ({ item, lang }) => {
  const { id, titreFrancais, titreArabe, prix, premierePhoto, categorie, ville, slug } = item;

  const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
  const villeArab = ville ? ville.urlArab : '';
  const villeFrancais = ville ? ville.urlFr : '';
  const regionArab = ville ? ville.province.region.urlArab : '';
  const regionFr = ville ? ville.province.region.urlFr : '';
  const categorieFrancais = categorie ? categorie.urlFrancais : '';
  const categorieArab = categorie ? categorie.urlArabe : '';

  return (
    <div
      className={'post-item featured'}
      /*onMouseEnter={() => {
        trackEvent(postHovered({ postSlug: slug, postId: id, context: 'featured' }));
        const options = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        EventTrackingService.createEvent({
          data: {
            event: {
              id: constants.POST_HOVERED,
            },
            annonce: {
              id: id,
            },
            context: {
              id: constants.FEATURED,
            },
          },
          options: options,
        }).catch((reason) => console.error(reason.message));
      }}*/
    >
      <span className={'budget'}>
        <FormattedMessage id={'main.focus'} />
      </span>
      <IonCard
        button
        href={
          lang === 'ar'
            ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
            : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        }
        onClick={() => {
          trackEvent(postClicked({ postSlug: slug, postId: id, context: 'featured' }));
          const options = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          EventTrackingService.createEvent({
            data: {
              event: {
                id: constants.POST_CLICKED,
              },
              annonce: {
                id: id,
              },
              context: {
                id: constants.FEATURED,
              },
            },
            options: options,
          }).catch((reason) => console.error(reason.message));
        }}
        style={{ margin: '-1.47rem 0rem 0rem 0rem' }}
      >
        <a
          href={
            lang === 'ar'
              ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
              : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
          }
        />
        <div className={'d-flex overflow-hidden'} style={{aspectRatio: '315 / 250'}}>
          <picture>
            <source
                type="image/webp"
                srcSet={`${
                    premierePhoto && premierePhoto.cheminWebp
                        ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
                        : '/assets/img/no_images.webp'
                }`}
            />
            <img
                loading="lazy"
                decoding={'async'}
                src={`${
                    premierePhoto && premierePhoto.chemin
                        ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.chemin + '?small=false'
                        : '/assets/img/no_images.webp'
                }`}
                className={'rounded-top d-block bg-dark'}
                style={{ width: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                alt={lang === 'fr' ? titreFrancais + '-' + id : titreArabe + '-' + id}
            />
          </picture>
        </div>
        <IonCardHeader>
          <IonCardTitle className={'title'}>{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
          <meta
            itemProp="priceCurrency"
            content="MAD"
            data-reactid={prix !== 0.0 ? madFormatter().format(prix) : 'Prix négociable'}
          />
          <IonCardSubtitle className={'price'}>
            {prix !== 0.0 ? (
              <FormattedNumber
                value={prix}
                style={'currency'}
                currency={'Mad'}
                currencyDisplay={'symbol'}
                maximumFractionDigits={2}
              />
            ) : (
              <FormattedMessage id={'price.negotiable'} />
            )}
          </IonCardSubtitle>
          {/* <IonCardContent className={'detail'}>
                            {categorie !== undefined ?
                                <p>
                                    <strong>{lang === 'fr' && (categorie !== undefined) ? categorie.titreFrancais : categorie.titreArabe}</strong>
                                </p> : null
                            }
                            {ville !== undefined ?
                                <p>{lang === 'fr' && (ville !== undefined) ? ville.nomFr : ville.nomArab}</p> : null
                            }
                            <p>{dateFormatter(dateModification, lang)}</p>
                        </IonCardContent>*/}
        </IonCardHeader>
      </IonCard>
    </div>
  );
};

interface PremiumAnnoncesProps {
  categoryId?: string;
  lang: string;
}

const PremiumAnnonces: React.FC<PremiumAnnoncesProps> = ({ categoryId, lang }) => {
  const [posts, setPosts] = useState<Array<Post>>([]);
  const [defaultPosts, setDefaultPosts] = useState<Array<Post>>([]);

  const page = useSelector((state: RootState) => state.searchRefreshCounterReducer.counter);
  const dispatch = useDispatch();

  useEffect(() => {
    let controller = new AbortController();

    const getDefaultData = (page: number, isDefault: boolean) => {
      let filterData = {
        prixMin: null,
        prixMax: null,
        typeAnnonce: null,
        typeUtilisateur: null,
        searchText: null,
        categorieId: null,
        villeId: null,
        regionId: null,
        urgent: true,
        titleOnly: false,
      };

      const data = {
        page: page,
        size: 2,
      };

      const urgentUri = encodeURIComponent(JSON.stringify(filterData));
      const formData = urlDataFormatter(data);
      const url =
        `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri + `&${formData.join('&')}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        SameSite: 'secure',
      });

      return fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: headers,
        signal: controller.signal,
      })
        .then((result) => {
          if (result.status !== 200) {
            dispatch(setValue(0));
            return [];
          }
          return result.json();
        })
        .then((jsonResult) => {
          // console.log('defaultData', jsonResult);
          if (!isDefault) {
            setPosts(jsonResult?.content);
            if (jsonResult?.content?.length <= 0) {
              dispatch(setValue(0));
            }
          }
          return jsonResult;
        })
        .catch((error) => {
          if (error.name !== 'AbortError') console.error(error.message, error);
        });
    };

    const getData = () => {
      let filterData = {
        prixMin: null,
        prixMax: null,
        typeAnnonce: null,
        typeUtilisateur: null,
        searchText: null,
        categorieId: categoryId || null,
        villeId: null,
        regionId: null,
        urgent: true,
        titleOnly: false,
      };

      let data = {
        page: page,
        size: 2,
      };

      // console.log('pageCounter', page);

      const urgentUri = encodeURIComponent(JSON.stringify(filterData));
      const formData = urlDataFormatter(data);
      const url =
        `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri + `&${formData.join('&')}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        SameSite: 'secure',
      });

      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: headers,
        signal: controller.signal,
      })
        .then((result) => {
          if (result.status !== 200) {
            dispatch(setValue(0));
            return [];
          }
          return result.json();
        })
        .then((jsonResult) => {
          // console.log('data', jsonResult);
          if (jsonResult?.content?.length <= 0) {
            getDefaultData(page, false);
          } else {
            setPosts(jsonResult?.content);
            if (jsonResult?.content?.length === 1) {
              getDefaultData(0, true).then((defData) => {
                setDefaultPosts(defData?.content);
              });
            }
            if (jsonResult?.content?.length <= 0) {
              dispatch(setValue(0));
            }
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') console.error(error.message, error);
        });
    };

    getData();

    return () => {
      controller.abort();
    };
  }, [page, lang, categoryId]);

  return (
    <>
      {posts?.map((post: Post, index) => (
        <PremiumAnnonceRender key={`prem-ann-item-${index}`} item={post} lang={lang} />
      ))}
      {posts?.length === 1 &&
        defaultPosts.slice(0, 1).map((post, index) => {
          if (posts[0].id === post?.id) {
            defaultPosts
              .slice(1, 2)
              .map((value, index1) => (
                <PremiumAnnonceRender key={`def-prem-ann-item-${index1}`} item={value} lang={lang} />
              ));
          } else return <PremiumAnnonceRender key={`def-prem-ann-item-${index}`} item={post} lang={lang} />;
        })}
    </>
  );
};

export default PremiumAnnonces;
