import { createReducer } from "@reduxjs/toolkit";
import { saveCities} from "../actions/saveCities";

const cities = [];


export const saveCitiesReducer = createReducer(
  { cities },
  {
    [saveCities.type]: (state, action) => ({
      ...state,
      cities: { ...action.payload }
    }),
  }
);