import { createAction } from "@reduxjs/toolkit";
import { Province } from "utils/Types/types";



export const saveProvinces = createAction("SAVE_PROVINCES", function prepare(
    cities: Array<Province>
) {
  return {
    payload: cities,
  };
});