import React, { useEffect, useRef, useState } from 'react';
import './TopHeader.scss';
import { FormattedMessage } from 'react-intl';
import { IonButton, IonGrid, IonIcon } from '@ionic/react';
import { logoFacebook, logoInstagram, logoLinkedin, logoYoutube } from 'ionicons/icons';

interface TopHeaderProps {
  lang: string;
}

const TopHeader: React.FC<TopHeaderProps> = ({ lang }) => {
  return (
    <header className={`top-header`}>
      <div className="container d-flex justify-content-center justify-content-md-between align-items-center">
        <nav>
          <ul className="nav-links d-flex my-0 px-0">
            <li>
              <a href={lang === 'fr' ? '/fr/maroc/Terrain_Ferme/' : '/ar/المغرب/ارض_مزرعة/'}>
                <FormattedMessage id={'menu.immo.agricole'} />
              </a>
            </li>
            <li>
              <a href={`/${lang}/boutiques/`}>
                <FormattedMessage id={'menu.shops'} />
              </a>
            </li>
            <li>
              <a href={`/${lang}/neuf/`}>
                <FormattedMessage id={'flaha.neuf.menu.title'} />
              </a>
            </li>
            <li>
              <a href={`https://blog.flaha.ma/`}>
                <FormattedMessage id={'menu.blog.info'} />
              </a>
            </li>
          </ul>
        </nav>
        <nav>
          <ul className="nav-links my-0 px-0 align-items-center d-none d-md-flex">
            <li>
              <a href={'/centre-aide'}>
                <FormattedMessage id={'menu.help.center'} />
              </a>
            </li>

            <li>
              <IonButton href={'/contact'} size={'small'} fill="solid">
                <FormattedMessage id={'menu.contact.full'} />
              </IonButton>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default TopHeader;
