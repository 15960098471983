import * as React from 'react';
import { constants } from "utils";
import { FormattedMessage } from "react-intl";
import {
    IonGrid,
    IonChip,
} from "@ionic/react";

import './PopularTags.scss'
import { lang } from 'utils/Types/types';

interface PopularTagsProps {
    lang: lang;
};

const PopularTags: React.FC<PopularTagsProps> = ({ lang }: PopularTagsProps) => {
    const [tags, setTags] = React.useState([]);


    React.useEffect(() => {

        const url = `${process.env.REACT_APP_API_URL}${constants.getPopularTags}`;
        const headers = new Headers({
            'Content-Type': 'application/json',
            'SameSite': 'secure'
        });

        fetch(url, {
            method: 'GET',
            credentials: "same-origin",
            //mode: 'no-cors',
            headers: headers,
        }).then((result) => {

            if (result.status !== 200) {
                return { content: [] };
            }
            return result.json();
        }).then((jsonResult) => {
            setTags(jsonResult);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <IonGrid className="grid-popular-tags-container">
            <h2 className="popular-tags-label"><FormattedMessage id={'popular.tags.label'} /></h2>
            <div className="popular-tags-list">
                {tags && tags.map(function (tag: any, index: number) {
                    return (
                        <IonChip key={index}
                            onClick={() => window.location.href = lang === "ar" ? `/ar/المغرب/للبيع/${tag.valueAr.replace(/ /g, "_")}`
                                : `/fr/maroc/a_vendre/${tag.valueFr.replace(/ /g, "_")}`}>
                                <a href={lang === "ar" ? `/ar/المغرب/للبيع/${tag.valueAr.replace(/ /g, "_")}`
                                : `/fr/maroc/a_vendre/${tag.valueFr.replace(/ /g, "_")}` }>
                                    {lang === "fr" ? tag.valueFr : tag.valueAr}
                                </a>
                        </IonChip>
                    )
                })}
            </div>
        </IonGrid>
    )

}

export default PopularTags;