import { createAction } from "@reduxjs/toolkit";
import {Region, Category, Province, City} from "utils/Types/types";



interface Regions_Categories {
    regions: Array<Region>,
    categories: Array<Category>,
    cities: Array<City>
  };

export const saveRegionsCategories = createAction("SAVE_REGIONS_CATEGORIES", function prepare(
  Regions_Categories: Regions_Categories
) {
  return {
    payload: Regions_Categories,
  };
});