import { constants } from 'utils';

class FileService {
  serveMarqueImagePath(path: string) {
    return path ? process.env.REACT_APP_API_URL + constants.serveMarqueImage + path : '/assets/img/no_images.png';
  }

  serveMarqueImageWebpPath(webpPath: string) {
    return webpPath
      ? process.env.REACT_APP_API_URL + constants.serveMarqueImage + webpPath
      : '/assets/img/no_images.webp';
  }

  serveModeleImagePath(path: string) {
    return path ? process.env.REACT_APP_API_URL + constants.serveModeleImage + path : '/assets/img/no_images.png';
  }

  serveModeleImageWebpPath(webpPath: string) {
    return webpPath
      ? process.env.REACT_APP_API_URL + constants.serveModeleImage + webpPath
      : '/assets/img/no_images.webp';
  }

  serveMaterialImagePath(path: string) {
    return path ? process.env.REACT_APP_API_URL + constants.serveMaterialImage + path : '/assets/img/no_images.png';
  }

  serveMaterialImageWebpPath(webpPath: string) {
    return webpPath
      ? process.env.REACT_APP_API_URL + constants.serveMaterialImage + webpPath
      : '/assets/img/no_images.webp';
  }

  serveCampaignBannerPath(path: string) {
    return path ? process.env.REACT_APP_API_URL + constants.serveCampaignBanner + path : '/assets/img/no_images.png';
  }

  serveCampaignBannerWebpPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL +
          constants.serveCampaignBanner +
          path.replace('.jpeg', '.webp').replace('.jpg', '.webp').replace('.png', '.webp')
      : '/assets/img/no_images.webp';
  }

  serveCampaignSmallBannerPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL + constants.serveCampaignSmallBanner + path
      : '/assets/img/no_images.png';
  }

  serveCampaignSmallBannerWebpPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL +
          constants.serveCampaignSmallBanner +
          path.replace('.jpeg', '.webp').replace('.jpg', '.webp').replace('.png', '.webp')
      : '/assets/img/no_images.webp';
  }

  serveCampaignTallBannerPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL + constants.serveCampaignTallBanner + path
      : '/assets/img/no_images.png';
  }

  serveCampaignTallBannerWebpPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL +
          constants.serveCampaignTallBanner +
          path.replace('.jpeg', '.webp').replace('.jpg', '.webp').replace('.png', '.webp')
      : '/assets/img/no_images.webp';
  }

  serveCampaignLogoPath(path: string) {
    return path ? process.env.REACT_APP_API_URL + constants.serveCampaignLogo + path : '/assets/img/no_images.png';
  }

  serveCampaignLogoWebpPath(path: string) {
    return path
      ? process.env.REACT_APP_API_URL +
          constants.serveCampaignLogo +
          path.replace('.jpeg', '.webp').replace('.jpg', '.webp').replace('.png', '.webp')
      : '/assets/img/no_images.webp';
  }
}

export default new FileService();
