import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonTitle,
} from '@ionic/react';

import './PopularCities.scss';
import { City, lang } from 'utils/Types/types';
import { caretDownOutline, closeOutline, searchOutline } from 'ionicons/icons';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { langFunction } from 'lang/langFunctions';
import {getCitiesReduxUtils, getProvincesReduxUtils} from 'utils/Functions/ReferentialGetters';
import { useEffect, useState } from 'react';

interface PopularCitiesProps {
  lang: lang;
  intl: any;
}

const PopularCities: React.FC<PopularCitiesProps> = ({ lang, intl }: PopularCitiesProps) => {
  const [cities, setCities] = React.useState([]);

  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [showCityModal, setShowCityModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const citiesRedux = getCitiesReduxUtils();
    const provincesRedux = getProvincesReduxUtils();
    setCities(citiesRedux);
    setFilteredProvinces(provincesRedux?.filter((city) => city.popular === true));
  }, []);

  return (
    <IonGrid className="grid-popular-cities-container">
      <h2 className="popular-cities-label">
        <FormattedMessage id={'form.city.choose'} />
      </h2>
      <div className="popular-cities-list">
        {filteredProvinces?.map(function (city: any, index: number) {
          return (
            <IonChip
              key={index}
              onClick={() =>
                (window.location.href = lang === 'ar' ? `/ar/${city.urlArab}/للبيع/` : `/fr/${city.urlFr}/a_vendre/`)
              }
            >
              <a href={lang === 'ar' ? `/ar/${city.urlArab}/للبيع/` : `/fr/${city.urlFr}/a_vendre/`}>
                {lang === 'fr' ? city.nomFr : city.nomArab}
              </a>
            </IonChip>
          );
        })}
        <IonChip key={0} onClick={() => setShowCityModal(true)}>
          <IonLabel>
            <FormattedMessage id={'form.subject.select.4'} />
          </IonLabel>
        </IonChip>
      </div>
      <IonModal
        isOpen={showCityModal}
        cssClass="city-modal-class"
        swipeToClose={true}
        onDidDismiss={() => setShowCityModal(false)}
        showBackdrop={true}
        backdropDismiss={true}
      >
        <IonLoading
          cssClass="loading-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={1000}
          mode="ios"
          spinner={'bubbles'}
        />
        <div className={'city-modal-container'}>
          <IonRow className="city-title-row">
            <h3>
              <IonTitle>
                <FormattedMessage id={'form.city.choose'} />
              </IonTitle>
            </h3>
          </IonRow>
          <div className="city-container">
            <IonRow>
              <IonCol size="8">
                <IonItem lines={'none'}>
                  <div className={'label-container-city'}>
                    <Autocomplete
                      className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                      options={
                        cities &&
                        cities.filter((item: City, index: number) => {
                          return item.id >= 1;
                        })
                      }
                      onChange={(event, values: any) => {
                        setButtonDisabled(false);
                        if (values) {
                          lang === 'ar' ? setSelectedCity(values.urlArab) : setSelectedCity(values.urlFr);
                        }
                      }}
                      noOptionsText={'...'}
                      size={'medium'}
                      popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                      openOnFocus={true}
                      forcePopupIcon={true}
                      closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                      getOptionLabel={(option) =>
                        option
                          ? (lang === 'fr' ? option.nomFr : option.nomArab).replace(' -', '').replace(' –', '')
                          : ''
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            selectedCity === null ? (
                              <FormattedMessage id={'post.city'} />
                            ) : (
                              intl.formatMessage(langFunction.cityLabel)
                            )
                          }
                          margin="normal"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                </IonItem>
              </IonCol>
              <IonCol size="3" className="city-button-column">
                <IonButton
                  disabled={buttonDisabled}
                  onClick={() =>
                    (window.location.href =
                      lang === 'ar' ? `/ar/${selectedCity}/للبيع/` : `/fr/${selectedCity}/a_vendre/`)
                  }
                >
                  <IonIcon color="white" size="small" icon={searchOutline} />
                  <div style={{ width: '100%' }}>
                    <FormattedMessage id={'menu.search'} />
                  </div>
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </IonModal>
    </IonGrid>
  );
};

// ts
export default injectIntl(PopularCities);
