import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {chevronBackOutline, chevronForwardOutline, pricetag, star} from 'ionicons/icons';
import {constants, madFormatter, urlDataFormatter} from 'utils';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Post } from 'utils/Types/types';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLoading,
  IonModal,
  IonSlide,
  IonSlides, IonText,
  isPlatform,
} from '@ionic/react';
import { langFunction } from 'lang';
import './PremiumPostsSlide.scss';
import { trackEvent } from '../../tracking';
import { postClicked, postHovered } from '../../tracking/events';
import { SearchDetail } from 'pages';
import EventTrackingService from '../../services/EventTrackingService';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {useEffect, useState} from "react";
import {setValue} from "../../slices/searchRefreshCounterSlice";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 1.5,
  centeredSlides: false,
  spaceBetween: 0,
};

const noImage = '/assets/img/no_images.webp';

interface PremiumPostsSlideProps {
  categoryId?: string;
  lang: string;
  intl: any;
}

const PremiumPostsSlide: React.FC<PremiumPostsSlideProps> = ({ categoryId, lang, intl }: PremiumPostsSlideProps) => {
  const slider: React.Ref<HTMLIonSlidesElement> = React.useRef();

  const [posts, setPosts] = React.useState([]);
  const [defaultPosts, setDefaultPosts] = useState<Array<Post>>([]);

  const [showModal, setShowModal] = React.useState(false);
  const [slug, setSlug] = React.useState('');
  const [slicedSlug, setSlicedSlug] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);

  const page = useSelector((state: RootState) => state.searchRefreshCounterReducer.counter);
  const dispatch = useDispatch();



  useEffect(() => {
    let controller = new AbortController();

    const getDefaultData = (page: number, isDefault: boolean) => {
      let filterData = {
        prixMin: null,
        prixMax: null,
        typeAnnonce: null,
        typeUtilisateur: null,
        searchText: null,
        categorieId: null,
        villeId: null,
        regionId: null,
        urgent: true,
        titleOnly: false,
      };

      const data = {
        page: page,
        size: 2,
      };

      const urgentUri = encodeURIComponent(JSON.stringify(filterData));
      const formData = urlDataFormatter(data);
      const url =
          `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri + `&${formData.join('&')}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        SameSite: 'secure',
      });

      return fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: headers,
        signal: controller.signal,
      })
          .then((result) => {
            if (result.status !== 200) {
              dispatch(setValue(0));
              return [];
            }
            return result.json();
          })
          .then((jsonResult) => {
            // console.log('defaultData', jsonResult);
            if (!isDefault) {
              setPosts(jsonResult?.content);
              if (jsonResult?.content?.length <= 0) {
                dispatch(setValue(0));
              }
            }
            return jsonResult;
          })
          .catch((error) => {
            if (error.name !== 'AbortError') console.error(error.message, error);
          });
    };

    const getData = () => {
      let filterData = {
        prixMin: null,
        prixMax: null,
        typeAnnonce: null,
        typeUtilisateur: null,
        searchText: null,
        categorieId: categoryId || null,
        villeId: null,
        regionId: null,
        urgent: true,
        titleOnly: false,
      };

      let data = {
        page: page,
        size: 2,
      };

      // console.log('pageCounter', page);

      const urgentUri = encodeURIComponent(JSON.stringify(filterData));
      const formData = urlDataFormatter(data);
      const url =
          `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri + `&${formData.join('&')}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        SameSite: 'secure',
      });

      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: headers,
        signal: controller.signal,
      })
          .then((result) => {
            if (result.status !== 200) {
              dispatch(setValue(0));
              return [];
            }
            return result.json();
          })
          .then((jsonResult) => {
            // console.log('data', jsonResult);
            if (jsonResult?.content?.length <= 0) {
              getDefaultData(page, false);
            } else {
              setPosts(jsonResult?.content);
              if (jsonResult?.content?.length === 1) {
                getDefaultData(0, true).then((defData) => {
                  setDefaultPosts(defData?.content);
                });
              }
              if (jsonResult?.content?.length <= 0) {
                dispatch(setValue(0));
              }
            }
          })
          .catch((error) => {
            if (error.name !== 'AbortError') console.error(error.message, error);
          });
    };

    getData();

    return () => {
      controller.abort();
    };
  }, [page, lang, categoryId]);

  const slugProps = { slug: slicedSlug };

  return !isEmpty(posts) ? (
    <div className="sliderContainer">
      <div className={'d-flex mb-0'}>
        <IonText style={{fontSize: '14px'}} color={'primary'}>
          <IonIcon icon={star} className={`${lang === 'fr' ? 'mr-1' : 'ml-1'}`}/>
          <FormattedMessage id={'main.focus'} />
        </IonText>
      </div>
      {isPlatform('capacitor') && (
        <IonModal
          isOpen={showModal}
          cssClass="my-custom-class"
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            duration={1000}
            mode="ios"
            spinner={'bubbles'}
          />
          <SearchDetail {...slugProps}></SearchDetail>
        </IonModal>
      )}
      {isPlatform('desktop') && (
        <>
          <IonIcon
            className="nextBtn"
            onClick={() => {
              lang === 'fr' ? slider.current.slideNext() : slider.current.slidePrev();
            }}
            icon={chevronForwardOutline}
          />
          <IonIcon
            className="prevBtn"
            onClick={() => {
              lang === 'fr' ? slider.current.slidePrev() : slider.current.slideNext();
            }}
            icon={chevronBackOutline}
          />
        </>
      )}
      <IonSlides ref={slider} options={slideOpts}>
        {posts?.slice(0, 2).map((post: Post, index) => {
          const id = get(post, 'id');
          const pic = get(post, 'premierePhoto.chemin');
          const picWebp = get(post, 'premierePhoto.cheminWebp');
          const prix = get(post, 'prix');
          const slug = get(post, 'slug');
          const titreFrancais = get(post, 'titreFrancais');
          const titreArabe = get(post, 'titreArabe');
          const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
          const villeArab = get(post, ' ville.urlArab');
          const villeFrancais = get(post, 'ville.urlFr');
          const regionArab = get(post, 'ville.province.region.urlArab');
          const regionFr = get(post, 'ville.province.region.urlFr');
          const categorieFrancais = get(post, 'categorie.urlFrancais');
          const categorieArab = get(post, 'categorie.urlArabe');

          return (
            <IonSlide
              key={index + ''}
              /*onMouseEnter={() => {
                trackEvent(
                  postHovered({
                    postId: post.id,
                    postSlug: post.slug,
                    context: 'featured',
                  })
                );
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                };

                EventTrackingService.createEvent({
                  data: {
                    event: {
                      id: constants.POST_HOVERED,
                    },
                    annonce: {
                      id: id,
                    },
                    context: {
                      id: constants.FEATURED,
                    },
                  },
                  options: options,
                }).catch((reason) => console.error(reason.message));
              }}*/
            >
              <IonCard
                className="slide-card"
                onClick={() => {
                  trackEvent(
                    postClicked({
                      postId: post.id,
                      postSlug: post.slug,
                      context: 'featured',
                    })
                  );
                  const options = {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  };

                  EventTrackingService.createEvent({
                    data: {
                      event: {
                        id: constants.POST_CLICKED,
                      },
                      annonce: {
                        id: id,
                      },
                      context: {
                        id: constants.FEATURED,
                      },
                    },
                    options: options,
                  }).catch((reason) => console.error(reason.message));
                  if (isPlatform('capacitor')) {
                    setSlug(slug);
                    setSlicedSlug(slicedSlug);
                    setShowModal(true);
                    setShowLoading(true);
                  } else {
                    lang === 'ar'
                      ? (window.location.href = `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`)
                      : (window.location.href = `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`);
                  }
                }}
              >
                <a
                  href={
                    lang === 'ar'
                      ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                      : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
                  }
                >
                  {' '}
                </a>
                {pic ? (
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={
                        process.env.REACT_APP_API_URL +
                        constants.pictures +
                        pic.replace(/\b(?:jpeg|png|jpg)\b/gi, 'webp') +
                        '?small=false'
                      }
                    />
                    <img
                      loading="lazy"
                      alt={slicedSlug}
                      src={process.env.REACT_APP_API_URL + constants.pictures + pic + '?small=false'}
                    />
                  </picture>
                ) : (
                  <img loading="lazy" alt="empty" src={noImage} />
                )}
                <IonCardHeader className="post_info p-1">
                  <IonCardTitle className="post_title">{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
                  <meta
                    itemProp="priceCurrency"
                    content="MAD"
                    data-reactid={
                      prix !== 0.0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                    }
                  />
                  <IonCardSubtitle className={'price d-flex justify-content-between mt-1'} color={'primary'}>
                    {prix !== 0.0 ? (
                      <FormattedNumber
                        value={prix}
                        style={'currency'}
                        currency={'Mad'}
                        currencyDisplay={'symbol'}
                        maximumFractionDigits={2}
                      />
                    ) : (
                      <FormattedMessage id={'price.negotiable'} />
                    )}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonSlide>
          );
        })}
        {posts?.length === 1 && defaultPosts.slice(0, 1).map((post: Post, index) => {
          if (posts[0].id === post.id) {
            defaultPosts.slice(1, 2).map((value, index1) => {
              const id = get(value, 'id');
              const pic = get(value, 'premierePhoto.chemin');
              const picWebp = get(value, 'premierePhoto.cheminWebp');
              const prix = get(value, 'prix');
              const slug = get(value, 'slug');
              const titreFrancais = get(value, 'titreFrancais');
              const titreArabe = get(value, 'titreArabe');
              const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
              const villeArab = get(value, ' ville.urlArab');
              const villeFrancais = get(value, 'ville.urlFr');
              const regionArab = get(value, 'ville.province.region.urlArab');
              const regionFr = get(value, 'ville.province.region.urlFr');
              const categorieFrancais = get(value, 'categorie.urlFrancais');
              const categorieArab = get(value, 'categorie.urlArabe');

              return (
                  <IonSlide
                      key={index1 + ''}
                      /*onMouseEnter={() => {
                        trackEvent(
                          postHovered({
                            postId: post.id,
                            postSlug: post.slug,
                            context: 'featured',
                          })
                        );
                        const options = {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        };

                        EventTrackingService.createEvent({
                          data: {
                            event: {
                              id: constants.POST_HOVERED,
                            },
                            annonce: {
                              id: id,
                            },
                            context: {
                              id: constants.FEATURED,
                            },
                          },
                          options: options,
                        }).catch((reason) => console.error(reason.message));
                      }}*/
                  >
                    <IonCard
                        className="slide-card"
                        onClick={() => {
                          trackEvent(
                              postClicked({
                                postId: post.id,
                                postSlug: post.slug,
                                context: 'featured',
                              })
                          );
                          const options = {
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          };

                          EventTrackingService.createEvent({
                            data: {
                              event: {
                                id: constants.POST_CLICKED,
                              },
                              annonce: {
                                id: id,
                              },
                              context: {
                                id: constants.FEATURED,
                              },
                            },
                            options: options,
                          }).catch((reason) => console.error(reason.message));
                          if (isPlatform('capacitor')) {
                            setSlug(slug);
                            setSlicedSlug(slicedSlug);
                            setShowModal(true);
                            setShowLoading(true);
                          } else {
                            lang === 'ar'
                                ? (window.location.href = `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`)
                                : (window.location.href = `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`);
                          }
                        }}
                    >
                      <a
                          href={
                            lang === 'ar'
                                ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                                : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
                          }
                      >
                        {' '}
                      </a>
                      {pic ? (
                          <picture>
                            <source
                                type="image/webp"
                                srcSet={
                                    process.env.REACT_APP_API_URL +
                                    constants.pictures +
                                    pic.replace(/\b(?:jpeg|png|jpg)\b/gi, 'webp') +
                                    '?small=false'
                                }
                            />
                            <img
                                loading="lazy"
                                alt={slicedSlug}
                                src={process.env.REACT_APP_API_URL + constants.pictures + pic + '?small=false'}
                            />
                          </picture>
                      ) : (
                          <img loading="lazy" alt="empty" src={noImage} />
                      )}
                      <IonCardHeader className="post_info p-1">
                        <IonCardTitle className="post_title">{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
                        <meta
                            itemProp="priceCurrency"
                            content="MAD"
                            data-reactid={
                              prix !== 0.0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                            }
                        />
                        <IonCardSubtitle className={'price d-flex justify-content-between mt-1'} color={'primary'}>
                          {prix !== 0.0 ? (
                              <FormattedNumber
                                  value={prix}
                                  style={'currency'}
                                  currency={'Mad'}
                                  currencyDisplay={'symbol'}
                                  maximumFractionDigits={2}
                              />
                          ) : (
                              <FormattedMessage id={'price.negotiable'} />
                          )}
                        </IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                  </IonSlide>
              );
            })
          }
          else {
            const id = get(post, 'id');
            const pic = get(post, 'premierePhoto.chemin');
            const picWebp = get(post, 'premierePhoto.cheminWebp');
            const prix = get(post, 'prix');
            const slug = get(post, 'slug');
            const titreFrancais = get(post, 'titreFrancais');
            const titreArabe = get(post, 'titreArabe');
            const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
            const villeArab = get(post, ' ville.urlArab');
            const villeFrancais = get(post, 'ville.urlFr');
            const regionArab = get(post, 'ville.province.region.urlArab');
            const regionFr = get(post, 'ville.province.region.urlFr');
            const categorieFrancais = get(post, 'categorie.urlFrancais');
            const categorieArab = get(post, 'categorie.urlArabe');

            return (
                <IonSlide
                    key={index + ''}
                    /*onMouseEnter={() => {
                      trackEvent(
                        postHovered({
                          postId: post.id,
                          postSlug: post.slug,
                          context: 'featured',
                        })
                      );
                      const options = {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      };

                      EventTrackingService.createEvent({
                        data: {
                          event: {
                            id: constants.POST_HOVERED,
                          },
                          annonce: {
                            id: id,
                          },
                          context: {
                            id: constants.FEATURED,
                          },
                        },
                        options: options,
                      }).catch((reason) => console.error(reason.message));
                    }}*/
                >
                  <IonCard
                      className="slide-card"
                      onClick={() => {
                        trackEvent(
                            postClicked({
                              postId: post.id,
                              postSlug: post.slug,
                              context: 'featured',
                            })
                        );
                        const options = {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        };

                        EventTrackingService.createEvent({
                          data: {
                            event: {
                              id: constants.POST_CLICKED,
                            },
                            annonce: {
                              id: id,
                            },
                            context: {
                              id: constants.FEATURED,
                            },
                          },
                          options: options,
                        }).catch((reason) => console.error(reason.message));
                        if (isPlatform('capacitor')) {
                          setSlug(slug);
                          setSlicedSlug(slicedSlug);
                          setShowModal(true);
                          setShowLoading(true);
                        } else {
                          lang === 'ar'
                              ? (window.location.href = `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`)
                              : (window.location.href = `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`);
                        }
                      }}
                  >
                    <a
                        href={
                          lang === 'ar'
                              ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                              : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
                        }
                    >
                      {' '}
                    </a>
                    {pic ? (
                        <picture>
                          <source
                              type="image/webp"
                              srcSet={
                                  process.env.REACT_APP_API_URL +
                                  constants.pictures +
                                  pic.replace(/\b(?:jpeg|png|jpg)\b/gi, 'webp') +
                                  '?small=false'
                              }
                          />
                          <img
                              loading="lazy"
                              alt={slicedSlug}
                              src={process.env.REACT_APP_API_URL + constants.pictures + pic + '?small=false'}
                          />
                        </picture>
                    ) : (
                        <img loading="lazy" alt="empty" src={noImage} />
                    )}
                    <IonCardHeader className="post_info p-1">
                      <IonCardTitle className="post_title">{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
                      <meta
                          itemProp="priceCurrency"
                          content="MAD"
                          data-reactid={
                            prix !== 0.0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                          }
                      />
                      <IonCardSubtitle className={'price d-flex justify-content-between mt-1'} color={'primary'}>
                        {prix !== 0.0 ? (
                            <FormattedNumber
                                value={prix}
                                style={'currency'}
                                currency={'Mad'}
                                currencyDisplay={'symbol'}
                                maximumFractionDigits={2}
                            />
                        ) : (
                            <FormattedMessage id={'price.negotiable'} />
                        )}
                      </IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                </IonSlide>
            );
          }
        })
        }
      </IonSlides>
    </div>
  ) : null;
};

export default injectIntl(PremiumPostsSlide);
