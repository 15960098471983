import { defineMessages } from 'react-intl';

export const langFunction = defineMessages({
  appName: {
    id: 'app.name',
  },
  appNameFull: {
    id: 'app.name.full',
  },
  formNamePlaceholder: {
    id: 'form.name.placeholder',
  },
  formEmailPlaceholder: {
    id: 'form.email.placeholder',
  },
  formPasswordPlaceholder: {
    id: 'form.password.placeholder',
  },
  formPasswordConfirmPlaceholder: {
    id: 'form.password.confirm.placeholder',
  },
  formPhonePlaceholder: {
    id: 'form.phone.placeholder',
  },
  formMessagePlaceholder: {
    id: 'form.message.placeholder',
  },
  priceNegotiable: {
    id: 'price.negotiable',
  },
  today: {
    id: 'today',
  },
  shareWhatsappLink: {
    id: 'share.whatsapp.link',
  },
  shareWhatsappMsg: {
    id: 'share.whatsapp.msg',
  },
  shareWhatsappMsgStore: {
    id: 'share.whatsapp.msg.store',
  },
  callWhatsappMsg: {
    id: 'call.whatsapp.msg',
  },
  post: {
    id: 'post',
  },
  detail: {
    id: 'detail',
  },
  menuReturn: {
    id: 'menu.return',
  },
  accessMediaError: {
    id: 'Access has been denied. Change your setting > this app > Photo enable',
  },
  galleryChoice: {
    id: 'gallery.choice',
  },
  postTitle1: {
    id: 'post.title.1',
  },
  postTitle2: {
    id: 'post.title.2',
  },
  postDescription: {
    id: 'post.description',
  },
  postTitle1Placeholder: {
    id: 'post.title.1.placeholder',
  },
  postTitle1VeterinairePlaceholder: {
    id: 'post.title.veterinaire.1.placeholder',
  },
  postTitle2Placeholder: {
    id: 'post.title.2.placeholder',
  },
  postTitle2VeterinairePlaceholder: {
    id: 'post.title.veterinaire.2.placeholder',
  },
  postTitleMarque: {
    id: 'post.title.marque',
  },
  postTitleModele: {
    id: 'post.title.modele',
  },
  postDescriptionPlaceholder: {
    id: 'post.description.placeholder',
  },
  postDescriptionVeterinairPlaceholder: {
    id: 'post.description.veterinaire.placeholder',
  },
  postPricePlaceholder: {
    id: 'post.price.placeholder',
  },
  postNomAnnoncePlaceholder: {
    id: 'post.info.user.nom',
  },
  postTelAnnoncePlaceholder: {
    id: 'post.info.user.telephone',
  },
  postEmailPlaceholder: {
    id: 'post.info.user.email',
  },
  signInError: {
    id: 'sign.in.error',
  },
  formRequiredholder: {
    id: 'form.required',
  },
  categoryLabel: {
    id: 'form.category',
  },
  categoryPlaceholder: {
    id: 'form.category.all',
  },
  subCategoryPlaceholder: {
    id: 'form.subCategory.all',
  },
  regionLabel: {
    id: 'form.region',
  },
  cityLabel: {
    id: 'post.city',
  },
  cityPlaceholder: {
    id: 'form.city.all',
  },
  citiesLabel: {
    id: 'city.label',
  },
  regionPlaceholder: {
    id: 'form.region.all',
  },
  annonceModereeMsg: {
    id: 'annonce.moderee.message',
  },
  annonceModereeTitle: {
    id: 'annonce.moderee.title',
  },
  close: {
    id: 'close',
  },
  annonceModereeMsgAdmin: {
    id: 'annonce.moderee.message.admin',
  },
  annonceModificationMsg: {
    id: 'annonce.modification.message',
  },
  signUpAccountSuccess: {
    id: 'sign.up.account.success',
  },
  signInForgotPasswordChanged: {
    id: 'sign.in.forgot.password.changed',
  },
  signInForgotSuccess: {
    id: 'sign.in.forgot.success',
  },
  contactInfosSuccess: {
    id: 'contact.infos.success',
  },
  userLabel: {
    id: 'user.label',
  },
  anneeModele: {
    id: 'post.title.anneeModele',
  },
  kilometrage: {
    id: 'post.title.kilometrage',
  },
  puissanceFiscale: {
    id: 'post.title.puissanceFiscale',
  },
  updateMessage: {
    id: 'update.message',
  },
  updateMessageButton: {
    id: 'update.message.button',
  },
  contactNom: {
    id: 'contact.nom',
  },
  contactEmail: {
    id: 'contact.email',
  },
  contactSubject: {
    id: 'contact.subject',
  },
  contactSentAt: {
    id: 'contact.sentAt',
  },
  contactTel: {
    id: 'contact.tel',
  },
  contactMessage: {
    id: 'contact.message',
  },
  contactActionPlaceholder: {
    id: 'contact.action.placeholder',
  },
  contactUntreated: {
    id: 'contact.untreated',
  },
  tableLabelRowsPerPage: {
    id: 'table.labelRowsPerPage',
  },
  priceMin: {
    id: 'form.price.min',
  },
  priceMax: {
    id: 'form.price.max',
  },
  formSearch: {
    id: 'form.search',
  },
  userAccountDeleteMessage: {
    id: 'user.account.delete.message',
  },
  userAccountDeleteTitle: {
    id: 'user.account.delete.title',
  },
  confirmYes: {
    id: 'confirm.yes',
  },
  shopFormTitle: {
    id: 'shop.form.title',
  },
  shopFormName: {
    id: 'shop.form.name',
  },
  shopFormEmail: {
    id: 'shop.form.email',
  },
  shopFormPhone: {
    id: 'shop.form.phone',
  },
  shopFormAction: {
    id: 'shop.form.action',
  },
  shopFormStatus: {
    id: 'shop.form.status',
  },
  shopDeleteTitle: {
    id: 'shop.delete.title',
  },
  shopDeleteMessage: {
    id: 'shop.delete.message',
  },
  shopDeleteConfirm: {
    id: 'shop.delete.confirm',
  },
  shopCreateSuccessMessage: {
    id: 'shop.create.success.message',
  },
  shopCreateErrorMessage: {
    id: 'shop.create.error.message',
  },
  shopCreateExistErrorMessage: {
    id: 'shop.create.exist.error.message',
  },
  shopUpdateSuccessMessage: {
    id: 'shop.update.success.message',
  },
  shopDetailMetaTitle: {
    id: 'shop.detail.meta.title',
  },
  shopFormChooseLabel: {
    id: 'shop.form.choose.label',
  },
  shopHomeMetaTitle: {
    id: 'shop.home.meta.title',
  },
  shopsUserMetaTitle: {
    id: 'shop.user.meta.title',
  },
  annonceDeleteTitle: {
    id: 'annonce.delete.title',
  },
  annonceModerationTitle: {
    id: 'moderation.post.disable.label',
  },  
  annonceDeleteMessage: {
    id: 'annonce.delete.message',
  },
  annonceUnmoderationMessage: {
    id: 'annonce.unmoderation.message',
  },
  annonceBoostTitle: {
    id: 'annonce.boost.title',
  },
  annonceBoostMessage: {
    id: 'annonce.boost.message',
  },
  annonceDeleteMotif: {
    id: 'annonce.delete.motif',
  },
  annonceDeleteMotifTwo: {
    id: 'annonce.delete.motif.two',
  },
  annonceDeleteMotifThree: {
    id: 'annonce.delete.motif.three',
  },
  annonceDeleteMotifFour: {
    id: 'annonce.delete.motif.four',
  },
  annonceDeleteMotifFive: {
    id: 'annonce.delete.motif.five',
  },
  subscriptionCreateSuccessMessage: {
    id: 'subscription.create.success.message',
  },
  annonceBoostChoiceOne: {
    id: 'annonce.boost.choice.one',
  },
  annonceBoostChoiceTwo: {
    id: 'annonce.boost.choice.two',
  },
  annonceBoostChoiceThree: {
    id: 'annonce.boost.choice.three',
  },
  annonceBoostChoiceValidate: {
    id: 'annonce.boost.choice.validate',
  },
  annonceBoostNotEnoughCreditMessage: {
    id: 'annonce.boost.not.enough.credit.message',
  },
  annoncePostExistMessage: {
    id: 'annonce.post.exist.message',
  },
  subscriptionFormStatus: {
    id: 'subscription.moderate.title',
  },
  subscriptionDeleteTitle: {
    id: 'subscription.delete.title',
  },
  subscriptionDeleteMessage: {
    id: 'subscription.delete.message',
  },
  subscriptionDeleteConfirm: {
    id: 'subscription.delete.confirm',
  },
  boostGetFunds: {
    id: 'boost.get.funds',
  },
  subscriptionFundsAmount: {
    id: 'subscription.funds.amount',
  },
  fundRequestDeleteTitle: {
    id: 'fund.request.delete.title',
  },
  fundRequestDeleteMessage: {
    id: 'fund.request.delete.message',
  },
  fundRequestDeleteConfirm: {
    id: 'fund.request.delete.confirm',
  },
  fundRequestFormStatus: {
    id: 'fund.request.form.status',
  },
  marquePlaceholder: {
    id: 'form.marque.all',
  },
  modelePlaceholder: {
    id: 'form.modele.all',
  },
  materialContactFormCommentPlaceholder: {
    id: 'material.contact.form.comment.placeholder',
  },
  userExcludedMessage: {
    id: 'user.excluded.message',
  },
  adManagerFormTitle: {
    id: 'ad.manager.form.title',
  },
  adManagerContactFormCommentPlaceholder: {
    id: 'ad.manager.contact.form.comment.placeholder',
  },
  dashboardShopTabAnnoncesLabel: {
    id: 'dashboard.shop.tab.annonces.label',
  },
  dashboardShopTabStatsLabel: {
    id: 'dashboard.shop.tab.stats.label',
  },
  shopStatsViewCountLabel: {
    id: 'shop.stats.view.count.label',
  },
  boostTimeModalInputTextValidate: {
    id: 'boost.time.modal.input.text.validate',
  },
  boostTimeModalInputTextCancel: {
    id: 'boost.time.modal.input.text.cancel',
  },
  categoryStatsViewCountLabel: {
    id: 'category.stats.view.count.label',
  },
  categoryStatsViewCountName: {
    id: 'category.stats.view.count.name',
  },
  shopStatsPhoneCallsCountLabel: {
    id: 'shop.stats.phone.calls.count.label',
  },
  shopStatsPhoneViewsCountLabel: {
    id: 'shop.stats.phone.views.count.label',
  },
  shopStatsWhatsAppCallsCountLabel: {
    id: 'shop.stats.whatsapp.calls.count.label',
  },
  shopStatsClicksCountLabel: {
    id: 'shop.stats.clicks.count.label',
  },
  shopStatsWhatsAppSharesCountLabel: {
    id: 'shop.stats.whatsapp.shares.count.label',
  },
  moderationDisableAnnoncesMetaTitle: {
    id: 'moderation.disable.annonces.meta.title',
  },
  materialContactFormStatus: {
    id: 'material.contact.form.status',
  },
  modeleDemandeFormCommentPlaceholder: {
    id: 'modele.demande.form.comment.placeholder',
  },
});
