import { createAction } from "@reduxjs/toolkit";


interface showModalRedux {
    showModal: boolean,
  };

export const setShowModal = createAction("SET_SHOWMODAL", function prepare(
    showModalRedux: showModalRedux
) {
  return {
    payload: showModalRedux
  };
});