import { createAction } from "@reduxjs/toolkit";


interface showSearchModalRedux {
    showSearchModal: boolean,
  };

export const setShowSearchModal = createAction("SET_SHOW_SEARCH_MODAL", function prepare(
    showSearchModalRedux: showSearchModalRedux
) {
  return {
    payload: showSearchModalRedux
  };
});