import React, { useEffect, useState } from 'react';
import shopService from '../../services/ShopService';
import { IonBadge } from '@ionic/react';

const NotificationShop: React.FC = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getCount = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      shopService
        .getCountNotModerated(options)
        .then((value) => {
          const { data } = value;
          setCount(data.count);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };

    getCount();
  }, []);

  return count > 0 && <IonBadge color="danger">{count}</IonBadge>;
};

export default NotificationShop;
