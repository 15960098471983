import http from '../http-common';
import { Plan } from 'utils/Types/types';
import { constants } from 'utils';

class PlanService {
  getAll(options) {
    return http.get(constants.plansGetAll, options);
  }

  getAllBoostPlans(options) {
    return http.get(constants.boostPlansGetAll, options);
  }

  get(id: string, options) {
    return http.get<Plan>(constants.plansGet.replace('{id}', id), options);
  }
}

export default new PlanService();
