import React, { Component } from 'react';
// @ts-ignore
import { FlahaProps, FlahaState } from "utils/Interfaces/Interfaces";
import { setUserToken, setUserProfile } from "utils/Functions/Functions";
import { constants, Events } from "utils";
export const ACCESS_TOKEN = 'accessToken';
const prevLocationKey = "@flaha.prevLocation"

class OAuth2RedirectHandler extends Component<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
            loginError: false,
        };
        this.OnLoginSuccess = this.OnLoginSuccess.bind(this);
    }

    componentDidMount(): void {
        this.OnLoginSuccess();
        Events.subscribe(constants.lang_change, ({ lang }) => {
            this.setState({
                lang: lang,
            })
        });
    }
    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    OnLoginSuccess() {
        const previousLocation = localStorage.getItem(prevLocationKey);
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        const {lang} = this.state;
        if (token) {
            setUserToken(token);
            let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
            let bearer = 'Bearer ' + token;
            const headers = new Headers({
                'Authorization': bearer
            });

            fetch(url, {
                method: 'GET',
                credentials: 'same-origin',
                headers: headers,
            })
                .then((request) => {

                    return request.json();
                })
                .then((jsonRequest) => {
                    //console.log(jsonRequest);
                    if (jsonRequest) {
                        setUserProfile(jsonRequest.email,
                            jsonRequest.nom,
                            jsonRequest.telephone,
                            jsonRequest.type,
                            jsonRequest.imageUrl,
                            jsonRequest.admin ? 'true' : 'false',
                            jsonRequest.moderator ? 'true':'false',
                            jsonRequest.roles ? jsonRequest.roles : []);
                        if (previousLocation === 'login') {
                            if (jsonRequest.telephone) {
                                lang === 'ar' ? window.location.href = `/ar/المغرب/للبيع/` : window.location.href = `/fr/maroc/a_vendre/`;
                            }
                            else {
                                window.location.href = '/user/profile';
                            }
                            window.localStorage.removeItem(prevLocationKey);
                        } else {
                            window.location.href = '/sendPost';
                        }
                    }
                    else {
                        this.setState({
                            loginError: true,
                        }, () => {
                            console.error(`Can not login`);
                        });
                    }
                });
        }
        else {
            console.error(error);
            window.location.href = '/login';
        }
    }

    render() {
        return <a></a>
    }
}
interface Props extends FlahaProps {
    location: { search: any };
}
interface State extends FlahaState {
    loginError: boolean,
}
export default OAuth2RedirectHandler;
