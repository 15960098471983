import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { langFunction } from 'lang';
import './BoostTimeFormModalContent.scss';

interface BoostTimeFormModalContentProps {
  intl: any;
  lang: string;
  selectedAnnonceID: number;
  onBoost: any;
  onClose: any;
  boostID: number;
}

const BoostTimeFormModalContent: React.FC<BoostTimeFormModalContentProps> = ({
  intl,
  lang,
  selectedAnnonceID,
  onBoost,
  onClose,
  boostID,
}) => {
  const [boostTime, setBoostTime] = useState(undefined);

  const [buttonsEnabled, setButtonsEnabled] = useState(false);

  const enableButtons = () => {
    if (boostTime) setButtonsEnabled(true);
    else setButtonsEnabled(false);
  };

  useEffect(() => {
    enableButtons();
  }, [boostTime]);

  return (
    <IonContent>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={onClose}>
            <FormattedMessage id={'close'} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonGrid>
        <IonRow className={'justify-content-center'}>
          <IonCol>
            <div className={'d-flex justify-content-center flex-column'}>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <FormattedMessage id={'boost.time.modal.title'} />
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonItem>
                    <IonLabel position={'floating'}>
                      <FormattedMessage id={'boost.form.time'} />
                    </IonLabel>
                    <IonDatetime
                      cancelText={intl.formatMessage(langFunction.boostTimeModalInputTextCancel)}
                      doneText={intl.formatMessage(langFunction.boostTimeModalInputTextValidate)}
                      displayFormat="HH:mm"
                      pickerFormat="HH:mm"
                      value={boostTime}
                      onIonChange={(e) => {
                        setBoostTime(e.detail.value);
                      }}
                    />
                  </IonItem>
                  {!boostTime && (
                    <IonText color="danger" className="ion-padding-start">
                      <small>
                        <FormattedMessage id={'form.required'} />
                      </small>
                    </IonText>
                  )}
                  <div className={'d-flex justify-content-around py-3'}>
                    <IonButton
                      disabled={!buttonsEnabled}
                      color={'primary'}
                      onClick={() => onBoost(selectedAnnonceID, boostID, boostTime)}
                    >
                      <FormattedMessage id={'boost.time.modal.action.validate'} />
                    </IonButton>
                    <IonButton color={'danger'} onClick={() => onClose()}>
                      <FormattedMessage id={'boost.time.modal.action.cancel'} />
                    </IonButton>
                  </div>
                </IonCardContent>
              </IonCard>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default BoostTimeFormModalContent;
