import { createSlice } from '@reduxjs/toolkit';

export const searchRefreshCounter = createSlice({
  name: 'searchRefreshCounter',
  initialState: {
    counter: -1,
  },
  reducers: {
    setValue: (state, action) => {
      state.counter = action.payload;
    },
    increment: (state) => {
      state.counter += 1;
    },
  },
});

export const { setValue, increment } = searchRefreshCounter.actions;

export default searchRefreshCounter.reducer;