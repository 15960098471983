import { createAction } from "@reduxjs/toolkit";


interface search {
  searchClickedRedux: boolean,
  };

export const setSearch = createAction("SET_SEARCH_CLICKED", function prepare(
    search: search
) {
  return {
    payload: search
  };
});