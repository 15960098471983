import {
  IonAlert,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  isPlatform,
} from '@ionic/react';
import OAuth2RedirectHandler from 'components/OAuth2RedirectHandler/OAuth2RedirectHandler';
import { addCircleOutline, homeOutline, personCircleOutline } from 'ionicons/icons';
/*import {
    About, AnnoncesModerator, ModerateAnnonces, AnnoncesUser, Blog,
    ChangePassword, Contact, Home,
    Login, NotFound,
    Privacy, Profile, Register,
    ResetPassword, Search,
    SearchDetail, SendPost, UpdatePost, ModerateAnnonceForm
} from "pages";*/
import { Login, Register } from 'pages';
import React, { Component, lazy } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { constants, Events } from 'utils';
import { getLanguage, getSEOParametre, removeUserProfile, removeUserToken } from 'utils/Functions/Functions';
import { FlahaState } from 'utils/Interfaces/Interfaces';
import './Navigation.scss';
import isEqual from 'lodash/isEqual';
import { langFunction } from 'lang';
import { lang } from 'utils/Types/types';
import FormChat from 'pages/FormChat/FormChat';
import Chat from 'pages/Chat/Chat';

const SearchDetail = lazy(() => import('pages/SearchDetail/SearchDetail'));

const About = lazy(() => import('pages/About/About'));
const AnnoncesUser = lazy(() => import('pages/AnnoncesUser/AnnoncesUser'));
const Blog = lazy(() => import('pages/Blog/Blog'));
const ChangePassword = lazy(() => import('pages/ChangePassword/ChangePassword'));
const Contact = lazy(() => import('pages/Contact/Contact'));
const Home = lazy(() => import('pages/Home/Home'));
//const Login = lazy(() => import('pages/Login/Login'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const Privacy = lazy(() => import('pages/Privacy/Privacy'));
const Profile = lazy(() => import('pages/Profile/Profile'));
//const Register = lazy(() => import('pages/Register/Register'));
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPassword'));
const Search = lazy(() => import('pages/Search/Search'));
const SendPost = lazy(() => import('pages/SendPost/SendPost'));
const UpdatePost = lazy(() => import('pages/UpdatePost/UpdatePost'));
const HelpCenter = lazy(() => import('pages/HelpCenter/HelpCenter'));
const CreateShop = lazy(() => import('pages/CreateShop/CreateShop'));
const ShopsHome = lazy(() => import('pages/ShopsHome/ShopsHome'));
const ShopDetail = lazy(() => import('pages/ShopDetail/ShopDetail'));
const UpdateShop = lazy(() => import('pages/UpdateShop/UpdateShop'));
const ShopsUser = lazy(() => import('pages/ShopsUser/ShopsUser'));
const CreateSubscription = lazy(() => import('pages/CreateSubscription/CreateSubscription'));
const Funds = lazy(() => import('pages/Funds/Funds'));
const FlahaNeufHome = lazy(() => import('pages/FlahaNeufHome/FlahaNeufHome'));
const MaterialDetail = lazy(() => import('pages/MaterialDetail/MaterialDetail'));
const CreateAdManagerContact = lazy(() => import('pages/CreateAdManagerContact/CreateAdManagerContact'));
const ShopDashboard = lazy(() => import('pages/ShopDashboard/ShopDashboard'));
const SheepLandingPage = lazy(() => import('pages/SheepLandingPage/SheepLandingPage'));
const PaymentInfo = lazy(() => import('pages/PaymentInfo/PaymentInfo'));
// const ModerateSubscriptions = lazy(() => import('pages/ModerateSubscriptions/ModerateSubscriptions'));

const androidAppVersionKey = '@flaha.androidAppVersion';

class Navigation extends Component<Props, State> {
  link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      token: null,
    };
    this.checkRole = this.checkRole.bind(this);
    this.getInitialLanguage();
  }

  async getInitialLanguage() {
    await getLanguage().then((result: lang) => {
      this.setState({ lang: result });
    });
  }

  componentDidMount(): void {
    this.getToken();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  getToken() {
    this.setState({ token: localStorage.getItem('@flaha.userToken') });
  }

  removeToken() {
    removeUserToken();
    removeUserProfile();
    const url = `${process.env.REACT_APP_API_URL}${constants.logout}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    }).then((result) => {
      if (result.status === 200) {
      }
    });
    this.setState({ token: null });
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.forEach((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  render() {
    const { token, lang } = this.state;

    let isModerator = localStorage.getItem('@flaha.userModerator');
    let isAdmin = localStorage.getItem('@flaha.userAdmin');
    // @ts-ignore
    const { intl } = this.props;

    const baseURL = process.env.REACT_APP_ADMIN_SUB_DOMAIN_URL;
    return (
      <>
        <IonTabs>
          <IonRouterOutlet id={'app'} dir={lang === 'fr' ? 'ltr' : 'rtl'}>
            <Route exact path={['/:lang/:region/:category/:query', '/:lang/:region/:category/']} component={Search} />
            {/* login */}
            <Route
              path="/login"
              render={(props: RouteComponentProps) => {
                const { match, location, history, staticContext } = props;
                // @ts-ignore
                const { callback } = match.params;

                return token ? (
                  (window.location.href = '/')
                ) : (
                  <Login
                    match={match}
                    location={location}
                    history={history}
                    staticContext={staticContext}
                    callback={callback}
                  />
                );
              }}
            />
            {/*FacebookLogin*/}
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
            {/* mes annonces */}
            <Route
              path="/user/mes-annonces"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <AnnoncesUser /> : <Redirect to="/" />;
              }}
            />
            {/* Chat*/}

            <Route
              exact
              path="/user/chat"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <Chat /> : <Redirect to="/" />;
              }}
            />
            {/* ChatMessage*/}

            {/* annonces moderator*/}
            <Route
              exact
              path="/user/verify-annonces"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* desactiver les annonces*/}
            <Route
              exact
              path="/user/desactiver-annonces"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* Modérer les annonces en masse */}
            <Route
              path="/moderator/modifier-annonces-en-masse"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* Modérer les annonces */}
            <Route
              path="/moderator/moderate-annonces"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route
              path="/moderator/liste-contacts"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route
              path="/moderator/liste-kitmedia"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route
              path="/moderator/shops"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route
              path="/moderator/fund-requests"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route
              path="/ouvrir-boutique"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <CreateShop lang={lang} intl={intl} token={token} /> : <Redirect to="/login" />;
              }}
            />
            {/*<Route
              path={'/demande-abonnement'}
              render={() => {
                return token ? <CreateSubscription lang={lang} intl={intl} token={token} /> : <Redirect to="/login" />;
              }}
            />*/}
            <Route
              path={'/demande-solde'}
              render={() => {
                return token ? <Funds lang={lang} intl={intl} token={token} /> : <Redirect to="/login" />;
              }}
            />
            {/* Modifier Annonce */}
            <Route
              exact
              path="/:lang/user/annonce/edit/:slug"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <UpdatePost /> : <Redirect to="/" />;
              }}
            />
            {/* Chat Annonce */}
            <Route
              exact
              path="/:lang/user/messagerie/form/:slug"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <FormChat /> : <Redirect to="/" />;
              }}
            />
            {/* Flaha neuf Home */}
            <Route
              exact
              path={'/:lang/neuf/'}
              render={(props) => {
                return <FlahaNeufHome intl={intl} {...props} />;
              }}
            />
            {/* Material Detail */}
            <Route
              exact
              path={'/:lang/neuf/:vilReg/:cat/:mar/:mod/:slug'}
              render={(props) => {
                return <MaterialDetail intl={intl} token={token} {...props} />;
              }}
            />
            {/* shops Home */}
            <Route
              exact
              path={'/:lang/boutiques/'}
              render={(props) => {
                return <ShopsHome intl={intl} token={token} {...props} />;
              }}
            />
            {/* shop Detail */}
            <Route
              exact
              path={'/:lang/:slug.htm'}
              render={(props) => {
                return <ShopDetail intl={intl} token={token} {...props} />;
              }}
            />
            {/* shop update */}
            <Route
              exact
              path={'/:lang/user/shop/edit/:id'}
              render={(props) => {
                return token ? <UpdateShop lang={lang} intl={intl} token={token} {...props} /> : <Redirect to="/" />;
              }}
            />
            {/* shops user */}
            <Route
              path="/user/mes-boutiques"
              render={(props) => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <ShopsUser intl={intl} token={token} {...props} /> : <Redirect to="/" />;
              }}
            />
            {/* shop Dashboard */}
            <Route
              path="/user/boutique-dashboard"
              render={(props) => {
                return token ? <ShopDashboard lang={lang} intl={intl} token={token} {...props} /> : <Redirect to="/" />;
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/analytics-dashboard"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/leads-moderation"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* contact commercial*/}
            <Route
              exact
              path="/:lang/contact-commercial/:slug.htm"
              render={(props) => {
                return <CreateAdManagerContact intl={intl} {...props} />;
              }}
            />
            {/* sheep landing page */}
            <Route
              path="/souk-el-haouli"
              render={() => {
                return <SheepLandingPage lang={lang} intl={intl} />;
              }}
            />
            {/* payment info */}
            <Route
              path="/paiement-infos"
              render={() => {
                return <PaymentInfo lang={lang} intl={intl} />;
              }}
            />
            {/* Modifier Annonce et modérer */}
            <Route
              exact
              path="/:lang/moderator/annonce/edit/:slug"
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            {/* profile */}
            <Route
              path="/user/profile"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <Profile /> : <Redirect to="/" />;
              }}
            />
            {/* changePassword */}
            <Route
              path="/user/changepassword"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <ChangePassword /> : <Redirect to="/" />;
              }}
            />
            {/* logout */}
            <Route
              path="/logout"
              render={() => {
                this.removeToken();
                return (window.location.href = '/');
              }}
            />
            <Route
              path="/register"
              render={(props: RouteComponentProps) => {
                // @ts-ignore
                const callback = '';
                const callbackProps = { callback: callback };

                return token ? (window.location.href = '/') : <Register {...callbackProps} />;
              }}
            />
            <Route
              path="/centre-aide"
              render={(props: RouteComponentProps) => {
                return <HelpCenter lang={lang} />;
              }}
            />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/resetpassword/:token" component={ResetPassword} />
            <Route exact path="/:lang/:region/:ville/:category/:slug.htm" component={SearchDetail} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route
              exact
              path={['/contact', '/contact-signaler/:id', '/contact-publicite/:subject']}
              render={(props: RouteComponentProps) => {
                // console.log(props);
                // @ts-ignore
                //  const {match, location, history, staticContext} = props;
                if (props) {
                  return (
                    <Contact
                      match={props.match}
                      location={props.location}
                      history={props.history}
                      staticContext={props.staticContext}
                    />
                  );
                }
                return <Contact />;
              }}
            />
            <Route path="/sendPost" render={() => {
                return token ? <SendPost /> : <Redirect to="/login" />;
            }} />
            <Route path="/" component={Home} exact>
              {isPlatform('capacitor') ? (
                <Redirect to={this.props.lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`} />
              ) : null}
            </Route>
            <Route path="/ar" component={Home} exact />
            <Route path="/fr" component={Home} exact />
            <Route path="/notfound" component={NotFound}>
              <Redirect to={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`} />
            </Route>
            <Route
              path={[
                '/user/verify/annonces/:category/:query/:annonceId',
                '/user/verify/annonces/:category/:query/',
                '/user/verify/annonces/:category//:annonceId',
                '/user/verify/annonces/:category/',
              ]}
              render={(props) => {
                  window.location.href = `${baseURL}${props.location.pathname}`;
                  return null;
              }}
            />
            <Route render={() => <Redirect to="/notfound" />} exact />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton tab="search" href={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`}>
              <IonIcon icon={homeOutline} />
              <IonText>
                <FormattedMessage id={'annonces'} />
              </IonText>
            </IonTabButton>
            <IonTabButton tab="sendPost" href="/sendPost">
              <IonIcon icon={addCircleOutline} />
              <IonText>
                <FormattedMessage id={'depose'} />
              </IonText>
            </IonTabButton>
            {token === null && (
              <IonTabButton tab="login" href="/login">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'menu.user.sign.in'} />
                </IonText>
              </IonTabButton>
            )}
            {token !== null &&
              <IonTabButton tab="profile" href="/user/profile">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'account'} />
                </IonText>
              </IonTabButton>
            }
          </IonTabBar>
        </IonTabs>
      </>
    );
  }
}

interface Props {
    lang: lang;
}

interface State extends FlahaState {
    token: string | null | undefined;
}

// @ts-ignore
export default injectIntl(Navigation);
