import React, {Component, Requireable, Validator} from 'react';
import {IonCol, IonItem, IonList, IonListHeader} from "@ionic/react";
import PropTypes from 'prop-types';
// @ts-ignore
import slideInUp from 'react-animations/lib/slide-in-up';
import Radium from 'radium';
// @ts-ignore
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {Region, Zone as ZoneType} from 'utils/Types/types'
import './Zone.scss';
import {constants, Events} from "utils";

export default class Zone extends Component<Props, State> {
    static propTypes: {
        zone: Validator<NonNullable<object>>;
        style: Requireable<object>;
        onRegionClick: Function;
    };

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
        }
    }

    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    render() {
        const { zone, onRegionClick } = this.props;
        const {lang} = this.state;

        const slide = {
            animation: 'x 1s',
            animationName: Radium.keyframes(slideInUp, 'fadeInUp'),
            animationDelay: '2s',
        };
        // @ts-ignore
        return (<IonCol size={'3'} sizeLg={'3'} sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}><Radium.StyleRoot><div style={slide}>
                    <IonList>
                        <IonListHeader lines="inset">
                            <h2>{(lang==='fr' ? zone.name : zone.nameAr).toLowerCase().trim()}</h2>
                        </IonListHeader>
                        {
                            zone.regions.map((region: Region, index: number) => {
                                return (
                                    <IonItem key={region.id} href={lang === 'ar' ?  
                                    `/ar/${region.urlArab}/للبيع` 
                                    : `/fr/${region.urlFr}/${constants.allCategories}`} 
                                    onClick={() => onRegionClick && onRegionClick(region)} lines="none">
                                        {(lang==='fr' ? region.nomFr : region.nomArab).toLowerCase()}
                                    </IonItem>
                                );
                            })
                        }
                    </IonList>
                </div>
            </Radium.StyleRoot>
            </IonCol>
        );
    }
}

Zone.propTypes = {
    zone: PropTypes.object.isRequired,
    style: PropTypes.object,
    onRegionClick: PropTypes.func
}

interface Props extends FlahaProps {
    zone: ZoneType;
    style?: object;
    onRegionClick?: Function;
}

interface State extends FlahaState {
}
