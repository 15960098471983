import http from '../http-common';
import { FundRequest } from 'utils/Types/types';
import { constants } from 'utils';

class FundRequestService {
  getAll(options) {
    return http.get(constants.fundRequestsAllGET, options);
  }

  get(id: string, options) {
    return http.get<FundRequest>(constants.fundRequestsGET.replace('{id}', id), options);
  }

  getCreditCost(options) {
    return http.get(constants.fundRequestsCreditCostGET, options);
  }

  getMinPtsFunds(options) {
    return http.get(constants.fundRequestsMinPtsGET, options);
  }


  create(data: FundRequest, options) {
    return http.post(constants.fundRequestsPOST, data, options);
  }

  moderateFundRequest(id: any, data, options) {
    return http.put(constants.fundRequestsPUT.replace('{id}', id), data, options);
  }

  getCountNotModerated(options) {
    return http.get(constants.fundRequestGetCountNotModerated, options);
  }

  delete(id: any, options) {
    return http.delete<any>(constants.fundRequestsDEL.replace('{id}', id), options);
  }
}

export default new FundRequestService();
