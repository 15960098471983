import { createSlice } from '@reduxjs/toolkit';

export const flahaNeufSearch = createSlice({
  name: 'flahaNeufSearch',
  initialState: {
    searchChanged: false,
  },
  reducers: {
    setValue: (state, action) => {
      state.searchChanged = action.payload;
    },
  },
});

export const { setValue } = flahaNeufSearch.actions;

export default flahaNeufSearch.reducer;