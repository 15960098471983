import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { getUserToken, setUserToken } from 'utils/Functions/Functions';
import { langFunction } from 'lang';
import { constants, Events } from 'utils';
import { Footer, Header } from 'components';
import {
  CreateAnimation,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './ChangePassword.scss';
import { alertCircle, createOutline, eye, eyeOff, lockClosedOutline } from 'ionicons/icons';

class ChangePassword extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      passwordCurrent: '',
      password: '',
      showPasswordCurrent: false,
      showPassword: false,
      confirmPassword: '',
      showMessage: false,
      update: false,
      enableSubmit: false,
      token: '',
      isSubmitting: false,
    };
    this.onSubmitUpdate = this.onSubmitUpdate.bind(this);
  }

  componentDidMount() {
    this.getTokenUser();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  getTokenUser() {
    getUserToken().then((data) => {
      if (data == undefined) {
        this.setState({ token: null });
      } else {
        this.setState({ token: data });
      }
    });
  }

  onSubmitUpdate(event: FormEvent) {
    this.setState({ isSubmitting: true });
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}${constants.userChangePassword}`;

    let { token, password } = this.state;

    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
      'Content-Type': 'application/json',
      accept: '*/*',
    });
    fetch(`${url}?password=${password}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: headers,
    }).then((request) => {
      if (request.status === 200) {
        this.setState({ update: true, showMessage: true, isSubmitting: false });
        return;
      }
      if (request.status === 401) {
        ////////////////////// Partie Ajoutée ///////////////
        let url = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ authToken: token }),
          credentials: 'same-origin',
          headers: headers,
        })
          .then((request) => {
            //console.log(request)
            debugger;
            return request.json();
          })
          .then((jsonRequest) => {
            //console.log(jsonRequest);
            debugger;
            setUserToken(jsonRequest.token);
            token = localStorage.getItem('@flaha.userToken');
            let bearer = 'Bearer ' + token;
            const headers = new Headers({
              Authorization: bearer,
            });
            fetch(`${url}?password=${password}`, {
              method: 'POST',
              credentials: 'same-origin',
              headers: headers,
            }).then((result) => {
              this.setState({ update: true, showMessage: true });
              return;
              ////////////////////// Partie Ajoutée ///////////////
            });
          })
          .catch((error) => {
            console.error(`Can not update (${error.message})`, error);
          });
      }
    });
  }

  activateSubmit() {
    const { passwordCurrent, confirmPassword, password } = this.state;
    if (
      passwordCurrent &&
      passwordCurrent !== '' &&
      password &&
      password !== '' &&
      confirmPassword &&
      confirmPassword !== ''
    ) {
      this.setState({ enableSubmit: true });
      return;
    }
    this.setState({ enableSubmit: false });
  }

  // show Message
  showMessage() {
    const { showMessage } = this.state;
    let classN = '';
    let msg: JSX.Element;
    if (showMessage === true) {
      classN = 'alert-success';
      msg = <FormattedMessage id={'user.profile.password.success'} />;
    } else {
      classN = 'alert-danger';
      msg = <FormattedMessage id={'sign.up.account.infos.error'} />;
    }
    return (
      <CreateAnimation
        duration={1000}
        easing={'ease-in'}
        play={true}
        fromTo={[
          {
            property: 'opacity',
            fromValue: '0',
            toValue: '1',
          },
        ]}
      >
        <div className={'alert text-left ' + classN}>
          <IonIcon icon={alertCircle} size={'large'} /> {msg}
        </div>
      </CreateAnimation>
    );
  }

  render() {
    const {
      password,
      showPassword,
      confirmPassword,
      isSubmitting,
      passwordCurrent,
      showPasswordCurrent,
      enableSubmit,
      update,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    return (
      <IonPage>
        <Header />
        <IonContent>
          <div className={'container mbm-100'}>
            <IonRow>
              <div className={'links'}>
                <IonRow>
                  <IonCol sizeLg={'2'}></IonCol>
                  <IonCol sizeLg={'8'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/mes-annonces'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={createOutline} />
                        <span>
                          <FormattedMessage id={'user.annonce'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                  <IonCol sizeLg={'6'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/profile'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={createOutline} />
                        <span>
                          <FormattedMessage id={'user.profile.text'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                  <IonCol sizeLg={'6'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/changepassword'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={lockClosedOutline} />
                        <span>
                          <FormattedMessage id={'user.profile.password.text'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </div>
              {update ? (
                <div className={'Profile'}>
                  <IonGrid>{this.showMessage()}</IonGrid>
                </div>
              ) : null}
              <form className={'Profile'} method={'POST'} onSubmit={this.onSubmitUpdate}>
                <IonListHeader>
                  <IonLabel>
                    <FormattedMessage id={'user.profile.password.text'} />
                  </IonLabel>
                </IonListHeader>
                <IonGrid>
                  <IonRow>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.password.actuel'} />
                        </IonLabel>
                        <IonInput
                          value={passwordCurrent}
                          type={!showPasswordCurrent ? 'password' : 'text'}
                          inputMode={'text'}
                          name={'password'}
                          placeholder={intl.formatMessage(langFunction.formPasswordPlaceholder)}
                          required={true}
                          onIonChange={(event) => {
                            this.setState({ passwordCurrent: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                        <span className={'right'} slot={'end'}>
                          <IonText color={'medium'}>
                            <FormattedMessage id={'form.required'} />
                          </IonText>
                          <IonIcon
                            icon={!showPasswordCurrent ? eye : eyeOff}
                            onClick={(event) =>
                              this.setState({ showPasswordCurrent: !showPasswordCurrent }, () => {
                                this.activateSubmit();
                              })
                            }
                          />
                        </span>
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.password.new'} />
                        </IonLabel>
                        <IonInput
                          value={password}
                          type={!showPassword ? 'password' : 'text'}
                          inputMode={'text'}
                          name={'password'}
                          placeholder={intl.formatMessage(langFunction.formPasswordPlaceholder)}
                          required={true}
                          onIonChange={(event) => {
                            this.setState({ password: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                        <span className={'right'} slot={'end'}>
                          <IonText color={'medium'}>
                            <FormattedMessage id={'form.required'} />
                          </IonText>
                          <IonIcon
                            icon={!showPassword ? eye : eyeOff}
                            onClick={(event) =>
                              this.setState({ showPassword: !showPassword }, () => {
                                this.activateSubmit();
                              })
                            }
                          />
                        </span>
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.password.confirm'} />
                        </IonLabel>
                        <IonInput
                          value={confirmPassword}
                          type={!showPassword ? 'password' : 'text'}
                          inputMode={'text'}
                          name={'repeatPassword'}
                          placeholder={intl.formatMessage(langFunction.formPasswordConfirmPlaceholder)}
                          required={true}
                          onIonChange={(event) => {
                            this.setState({ confirmPassword: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                        <IonText slot={'end'} color={'medium'}>
                          <FormattedMessage id={'form.required'} />
                        </IonText>
                      </IonItem>
                    </IonCol>

                    <IonCol size={'12'}>
                      <button
                        className={'btn btn-block mt-2'}
                        type={'submit'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        disabled={!enableSubmit}
                      >
                        {isSubmitting ? (
                          <IonSpinner name="crescent" />
                        ) : (
                          <FormattedMessage id={'user.profile.password.text'} />
                        )}
                      </button>
                    </IonCol>
                    <IonCol size={'12'} className={'text-center mt-2'}>
                      {/* {this.showError()} */}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </IonRow>
          </div>
          <Footer copyrightOnly />
        </IonContent>
      </IonPage>
    );
  }
}

interface Props extends FlahaProps {}

interface State extends FlahaState {
  password: string | null;
  showPassword: boolean;
  confirmPassword: string | null;
  passwordCurrent: string | null;
  showPasswordCurrent: boolean;
  token: string | null;
  enableSubmit: boolean;
  showMessage: boolean;
  update: boolean;
  isSubmitting: boolean;
}

// @ts-ignore
export default injectIntl(ChangePassword);
