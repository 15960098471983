import React, {Component} from 'react';
import {IonGrid, IonRow} from "@ionic/react";
import {Zone} from 'components';
import './Zones.scss';
// @ts-ignore
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {Zone as ZoneType} from "utils/Types/types";
import {RegionData} from "utils";

export default class Zones extends Component<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
            zones: RegionData,
        }
    }

    render() {

        const { onRegionClick } = this.props;
        const {zones} = this.state;

        return (
            <div className={'container'} style={{marginTop: window.innerWidth<=767 ? '1rem' : '5rem'}}>
                <IonGrid className={'zones'}>
                    <IonRow className={'mbm-100'}>
                        {zones.map((e: ZoneType, i: number) => {
                            return (<Zone key={e.id} zone={e} onRegionClick={onRegionClick}/>);
                        })}
                    </IonRow>
                </IonGrid>
            </div>
        );
    }
}

interface Props extends FlahaProps {
    onRegionClick?: Function
}

interface State extends FlahaState {
    zones: Array<ZoneType>,
}
