import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import './Contact.scss';
import { Helmet } from 'react-helmet';
import {
  CreateAnimation,
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react';
import { alertCircle } from 'ionicons/icons';
import { Footer, Header } from 'components';
import { constants, Events, validateEmail, validatePhone } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import { RouteComponentProps } from 'react-router';
import { getUserToken } from 'utils/Functions/Functions';

class Contact extends Component<Props, State> {
  idAnnonce: string;
  link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  idTopic = 0;

  constructor(props: Props, context: any) {
    super(props, context);
    // @ts-ignore
    const { id, subject } = this.props.match.params;
    // default topics
    if (id) {
      // Case signal post, get topics of signal post
      this.idAnnonce = id;
      this.idTopic = 2;
    }
    this.state = {
      lang: 'ar',
      idAnnonceSignalie: this.idAnnonce,
      subject: null,

      name: '',
      email: '',
      phone: '',
      message: '',
      enableSubmit: false,
      update: false,
      showMessage: false,
      sujets: [],
      token: null,
      showSuccessMsg: false,
      isSubmitting: false,
    };

    this.onSubmitContact = this.onSubmitContact.bind(this);
    this.getUserToken();
    this.getTypesContact();
  }

  onSubmitContact(event: FormEvent) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    const url = `${process.env.REACT_APP_API_URL}${constants.contact}`;
    const { subject, name, idAnnonceSignalie, email, phone, message, sujets } = this.state;
    const headers = new Headers({
      'Content-Type': 'application/json',
      accept: '*/*',
    });

    // serch for service contact
    let serviceEmail = '';
    sujets.map((row: any) => {
      if (row.titreFrancais === subject || row.titreArabe === subject) {
        serviceEmail = row.serviceEmail;
      }
    });

    fetch(url + '?service=' + serviceEmail, {
      method: 'POST',
      body: JSON.stringify({
        nom: name,
        idAnnonceSignalie: idAnnonceSignalie,
        topic: subject,
        email: email,
        telephone: phone,
        message: message,
      }),
      headers: headers,
    })
      .then((request) => {
        if (request.status === 200) {
          this.setState({ update: true, showMessage: true, showSuccessMsg: true });
          this.setState({ isSubmitting: false });
          return;
        }
        if (request.status === 401) {
          this.setState({ isSubmitting: false });
          return (window.location.href = '/login');
        }
        this.setState({ update: true });
        this.setState({ isSubmitting: false });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        console.error(`Can not update (${error.message})`, error);
      });
  }

  getUserToken() {
    getUserToken().then((token) => {
      this.setState({ token: token });
      if (token !== null) {
        this.setState({ email: localStorage.getItem('@flaha.userLoginEmail') });
        this.setState({ name: localStorage.getItem('@flaha.userLoginNom') });
        this.setState({ phone: localStorage.getItem('@flaha.userLoginTelephone') });
      }
    });
  }

  //signal or publicite
  verifySubjectOrId() {
    // @ts-ignore
    const { id, subject } = this.props.match.params;
    const { sujets } = this.state;
    if (this.props.match.params) {
      if (id) {
        this.setState({ idAnnonceSignalie: id });
      } else if (subject) {
        const subjectObject = sujets ? sujets.find((sujet) => sujet.titreFrancais === subject) : null;
        this.setState({ subject: subjectObject });
        //console.log(this.state.subject);
      }
      //console.log(this.state.subject);
    }
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    window.addEventListener('ionAlertDidPresent', (e) => {
      setTimeout(() => {
        this.setState({ showSuccessMsg: false });
      }, 10000);
    });
  }

  showSuccessMessage() {
    const { showSuccessMsg } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    const successeMsg = intl.formatMessage(langFunction.contactInfosSuccess);
    const closeMsg = intl.formatMessage(langFunction.close);

    return (
      <IonAlert
        isOpen={showSuccessMsg}
        message={successeMsg}
        cssClass="success-alert"
        buttons={[
          {
            text: closeMsg,
            role: 'cancel',
            handler: () => {
              this.setState({
                showSuccessMsg: false,
                idAnnonceSignalie: '',
                subject: null,
                message: '',
                enableSubmit: false,
                update: false,
                showMessage: false,
                isSubmitting: false,
              });
            },
          },
        ]}
      />
    );
  }

  // show Message
  showMessage() {
    const { showMessage } = this.state;
    let classN = '';
    let msg;
    if (showMessage === true) {
      return this.showSuccessMessage();
    } else {
      classN = 'alert-danger';
      msg = <FormattedMessage id={'contact.infos.fail'} />;
      return (
        <CreateAnimation
          duration={1000}
          easing={'ease-in'}
          play={true}
          fromTo={[
            {
              property: 'opacity',
              fromValue: '0',
              toValue: '1',
            },
          ]}
        >
          <div className={'alert ' + classN}>
            <IonIcon icon={alertCircle} size={'large'} /> {msg}
          </div>
        </CreateAnimation>
      );
    }
  }

  // Sujet de contact
  getTypesContact() {
    const url = process.env.REACT_APP_API_URL + constants.typeContact + '?id=' + this.idTopic;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        // console.log('jsonResult : ',jsonResult);
        this.setState(
          {
            sujets: jsonResult,
          },
          () => {
            this.verifySubjectOrId();
          }
        );
      })
      .catch((error) => {
        console.error(error.message, error);
      });
  }

  activateSubmit() {
    const { name, email, phone, message, subject } = this.state;
    if (name && name !== '' && email && email !== '' && validateEmail(email) && message && message !== '') {
      if (phone === '' || (phone !== '' && validatePhone(phone))) {
        if (subject) {
          this.setState({ enableSubmit: true });
          return;
        }
      }
    }
    this.setState({ enableSubmit: false });
  }

  render() {
    const { subject, name, email, phone, message, enableSubmit, update, sujets, lang, token, isSubmitting } =
      this.state;

    // @ts-ignore
    const { intl } = this.props;
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent className={'contactPage'}>
          <div className={`container ${token ? 'mym-100' : 'mbm-100'}`}>
            <form className={'contactForm'} method={'POST'} onSubmit={this.onSubmitContact}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'12'} className={'text-center'}>
                    <h2>
                      <FormattedMessage id={'contact.title'} />
                    </h2>
                    <p>
                      <FormattedMessage id={'contact.subtitle'} />
                    </p>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position={'stacked'}>
                        <FormattedMessage id={'form.subject'} />
                      </IonLabel>
                      <IonSelect
                        interface={'alert'}
                        value={subject}
                        onIonChange={(e) => {
                          this.setState({ subject: e.detail.value }, () => {
                            this.activateSubmit();
                          });
                        }}
                      >
                        {sujets
                          ? sujets.map((item: any, index: number) => {
                              return (
                                <IonSelectOption
                                  value={item}
                                  key={item.lang === 'fr' ? item.titreFrancais : item.titreArabe}
                                >
                                  {lang === 'fr' ? item.titreFrancais : item.titreArabe}
                                </IonSelectOption>
                              );
                            })
                          : null}
                      </IonSelect>
                      <IonText slot={'end'} color={'medium'}>
                        <FormattedMessage id={'form.required'} />
                      </IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'} className={token === null ? 'userNotConnected' : 'userConnected'}>
                    <IonItem>
                      <IonLabel position="stacked">
                        <FormattedMessage id={'form.name'} />
                      </IonLabel>
                      <IonInput
                        value={name}
                        type={'text'}
                        inputMode={'text'}
                        name={'name'}
                        placeholder={intl.formatMessage(langFunction.formNamePlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ name: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText slot={'end'} color={'medium'}>
                        <FormattedMessage id={'form.required'} />
                      </IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'} className={token === null ? 'userNotConnected' : 'userConnected'}>
                    <IonItem>
                      <IonLabel position="stacked">
                        <FormattedMessage id={'form.email'} />
                      </IonLabel>
                      <IonInput
                        value={email}
                        type={'email'}
                        inputMode={'email'}
                        name={'login'}
                        placeholder={intl.formatMessage(langFunction.formEmailPlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ email: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText slot={'end'} color={'medium'}>
                        <FormattedMessage id={'form.required'} />
                      </IonText>
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'} className={token === null ? 'userNotConnected' : 'userConnected'}>
                    <IonItem>
                      <IonLabel position="stacked">
                        <FormattedMessage id={'form.phone'} />
                      </IonLabel>
                      <IonInput
                        value={phone}
                        type={'tel'}
                        inputMode={'tel'}
                        name={'phone'}
                        placeholder={intl.formatMessage(langFunction.formPhonePlaceholder)}
                        minlength={10}
                        maxlength={15}
                        onIonChange={(event) => {
                          this.setState({ phone: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size={'12'}>
                    <IonItem>
                      <IonLabel position="floating">
                        <FormattedMessage id={'form.message'} />
                      </IonLabel>
                      <IonTextarea
                        value={message}
                        rows={6}
                        name={'message'}
                        placeholder={intl.formatMessage(langFunction.formMessagePlaceholder)}
                        onIonChange={(event) => {
                          this.setState({ message: event.detail.value! }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                      <IonText slot={'end'} color={'medium'}>
                        <FormattedMessage id={'form.required'} />
                      </IonText>
                    </IonItem>
                  </IonCol>
                  {update ? (
                    <IonCol size={'12'}>
                      {/* <IonGrid> */}
                      {this.showMessage()}
                      {/* </IonGrid> */}
                    </IonCol>
                  ) : null}
                  <IonCol size={'12'}>
                    <button
                      className={'btn btn-block mt-2'}
                      type={'submit'}
                      style={{
                        backgroundColor: 'var(--ion-color-primary)',
                        color: 'var(--ion-color-light)',
                      }}
                      disabled={!enableSubmit || isSubmitting}
                    >
                      {!isSubmitting ? <FormattedMessage id={'form.send'} /> : <IonSpinner name="crescent" />}
                    </button>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Contactez-nous</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {}

interface State extends FlahaState {
  subject: any; //'Annonce refusée'|'Dépôt annonce'|'Problème technique'|'Autres'
  name: string;
  idAnnonceSignalie: string;
  email: string;
  phone: string;
  message: string;
  enableSubmit: boolean;
  update: boolean;
  showMessage: Boolean;
  sujets: Array<any>;
  token: string;
  showSuccessMsg: boolean;
  isSubmitting: boolean;
}

// @ts-ignore
export default injectIntl(Contact);
