import { createAction } from "@reduxjs/toolkit";
import { City } from "utils/Types/types";



export const saveCities = createAction("SAVE_CITIES", function prepare(
  cities: Array<City>
) {
  return {
    payload: cities,
  };
});