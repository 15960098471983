import React, {Component} from 'react';
import './PostGallery.scss';
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {constants, Events} from "utils";

const noImage = "/assets/img/no_images.png"
const noImageWebP = "/assets/img/no_images.webp"

export default class PostGallery extends Component<Props, State> {

    _gallery: any = null;

    async getData() {
        const idAnnnonce = this.props.idAnnonce;
        const headers = new Headers({
            'Content-Type': 'application/json',
            'SameSite': 'secure'
        });
        fetch(`${process.env.REACT_APP_API_URL + constants.posts + '/' + idAnnnonce}`, {
            method: 'GET',
            credentials: "same-origin",
            //mode: 'no-cors',
            headers: headers,
        })
            .then((result) => {
                if (result.status !== 200) {
                    return [];
                }

                return result.json();
            })
            .then((jsonResult) => {
                let links = new Array<string>();
                jsonResult.photos.forEach((ph: { chemin: string; }) => {
                    links.push(ph.chemin);
                });
                this.setState({
                    imageLinks: links,
                    activeItem: 0,
                    loading: false,
                    titreArabe: jsonResult.titreArabe,
                    titreFrancais: jsonResult.titreFrancais,
                })
            })
            .catch((error) => {
                this.setState({
                    imageLinks: [],
                    lang: "ar",
                    activeItem: 0,
                });
            });

    }

    constructor(props: Props, context: any) {
        super(props, context);
        this.state = {
            imageLinks: [],
            activeItem: 0,
            idAnnonce: this.props.idAnnonce,
            loading: true,
            lang: "ar",
            titreArabe: "",
            titreFrancais: "",
        };
    }

    renderItems() {
        if (!this.state || this.state.loading) return null;
        const {imageLinks, titreArabe, titreFrancais, lang} = this.state;
        if (imageLinks.length == 0) {
            return ([{
                original: noImage,
                thumbnail: noImage,
                originalAlt: lang === "ar" ? titreArabe : titreFrancais,
                srcSet: noImageWebP
            }])
        }
        let tmp = (imageLinks.map((item: string, index: number) => {
            const itemWebp = item.replace(".jpeg", ".webp").replace(".jpg", ".webp").replace(".png", ".webp");
            return (
                {
                    original: process.env.REACT_APP_API_URL + constants.pictures + item + '?small=false',
                    thumbnail: process.env.REACT_APP_API_URL + constants.pictures + item + '?small=false',
                    originalAlt: lang === "ar" ? titreArabe + ' - ' + index : titreFrancais + ' - ' + index,
                    srcSet: process.env.REACT_APP_API_URL + constants.pictures + itemWebp + '?small=false'
                }
            );
        }));
        //document.querySelector('meta[property="og:image:secure_url"]').setAttribute("content", tmp[0].original);
        //document.querySelector('meta[property="og:image"]').setAttribute("content", tmp[0].original);

        return tmp;
    }

    componentDidMount() {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
        this.getData();

    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    render() {
        if (!this.state || this.state.loading) return null;
        if (!this.state || this.state.loading) return;
        const {imageLinks} = this.state;

        return (

            <ImageGallery showFullscreenButton={false} isRTL={this.state.lang === "ar"}
                          showBullets={imageLinks.length > 1} showThumbnails={false} showPlayButton={false}
                          items={this.renderItems()}
                          lazyLoad={true}
            />
        );
    }
}

// @ts-ignore
PostGallery.defaultProps = {
    imagesLink: '',
};

// @ts-ignore
PostGallery.propTypes = {
    idAnnonce: PropTypes.string.isRequired,
};

interface Props extends FlahaProps {
    idAnnonce: string;
}

interface State extends FlahaState {
    activeItem: number;
    idAnnonce: string;
    imageLinks: Array<string>;
    loading: boolean;
    titreArabe: string,
    titreFrancais: string,
}
