import http from '../http-common';
import { Shop } from 'utils/Types/types';
import { constants } from 'utils';

class ShopService {
  getAll(options) {
    return http.get(constants.shopsGetAll, options);
  }

  getAnnoncesByShopId(id: string, options) {
    return http.get<any>(constants.annonceShopsGet.replace('{id}', id), options);
  }

  getUserShops(options) {
    return http.get<Array<Shop>>(constants.userShopsGet, options);
  }

  get(id: string, options) {
    return http.get<Shop>(constants.shopsGet.replace('{id}', id), options);
  }

  getBySlug(slug: string, options) {
    return http.get<Shop>(constants.shopsGetBySlug.replace('{slug}', slug), options);
  }

  getCountNotModerated(options) {
    return http.get(constants.shopsGetCountNotModerated, options);
  }

  create(data, options) {
    return http.post(constants.shopsPost, data, options);
  }

  update(data, options, id: any) {
    return http.put<any>(constants.shopsPut.replace('{id}', id), data, options);
  }

  moderateShop(id: any, data, options) {
    return http.put(constants.shopsPut.replace('{id}', id), data, options);
  }

  delete(id: any, options) {
    return http.delete<any>(constants.shopsDel.replace('{id}', id), options);
  }
}

export default new ShopService();
