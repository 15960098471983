import http from '../http-common';
import { Subscription } from 'utils/Types/types';
import { constants } from 'utils';

class SubscriptionService {
  getAll(options) {
    return http.get(constants.subscriptionsGetAll, options);
  }

  get(id: string, options) {
    return http.get<Subscription>(constants.subscriptionsGet.replace('{id}', id), options);
  }

  getActiveSubscription(options) {
    return http.get<Subscription>(constants.activeSubscriptionsGet, options);
  }

  getShopActiveSubscription(id: string, options) {
    return http.get<Subscription>(constants.activeShopSubscriptionsGet.replace('{shopID}', id), options);
  }

  create(data: Subscription, options) {
    return http.post(constants.subscriptionsPost, data, options);
  }

  moderateSubscription(id: any, data, options) {
    return http.put(constants.subscriptionsPut.replace('{id}', id), data, options);
  }

  delete(id: any, options) {
    return http.delete<any>(constants.subscriptionsDel.replace('{id}', id), options);
  }
}

export default new SubscriptionService();
