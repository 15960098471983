import http from '../http-common';
import { constants } from 'utils';

class BoostService {
  getAnnonceBoost(annonceID: string, options) {
    return http.get<any>(constants.annonceBoostGET.replace('{annonceID}', annonceID), options);
  }

  getShopAnnonceBoost(shopID: string, annonceID: string, options) {
    return http.get<any>(
      constants.shopAnnonceBoostGET.replace('{shopID}', shopID).replace('{annonceID}', annonceID),
      options
    );
  }

  boostAnnonce(annonceID: string, boostID: string, data, options) {
    return http.post(
      constants.annonceBoostPOST.replace('{annonceID}', annonceID).replace('{boostID}', boostID),
      data,
      options
    );
  }
  boostAnnonceStop(annonceID: string, boostID: string, options) {
    return http.post(
      constants.annonceBoostStop.replace('{annonceID}', annonceID).replace('{boostID}', boostID),
      null,
      options
    );
  }
  boostAnnonceStart(annonceID: string, boostID: string, options) {
    return http.post(
      constants.annonceBoostStart.replace('{annonceID}', annonceID).replace('{boostID}', boostID),
      null,
      options
    );
  }

  boostShopAnnonce(shopID: string, annonceID: string, boostID: string, data, options) {
    return http.post(
      constants.shopAnnonceBoostPOST
        .replace('{shopID}', shopID)
        .replace('{annonceID}', annonceID)
        .replace('{boostID}', boostID),
      data,
      options
    );
  }
}

export default new BoostService();
