import http from '../http-common';
import { Material, MaterialContact, MaterialImage, ModeleDemande } from 'utils/Types/types';
import { constants } from 'utils';

class MaterialService {
  getAllMaterials(options) {
    return http.get(constants.materialsAllGET, options);
  }

  getPopularMarques(options) {
    return http.get(constants.popularMarquesAllGET, options);
  }

  getPopularModeles(options) {
    return http.get(constants.popularModelesAllGET, options);
  }

  getAllMarques(options) {
    return http.get(constants.allMarquesGET, options);
  }

  getAllModeles(options) {
    return http.get(constants.allModelesGET, options);
  }

  get(id: string, options) {
    return http.get<Material>(constants.materialsGET.replace('{id}', id), options);
  }

  getBySlug(slug: string, options) {
    return http.get(constants.materialsBySlugGET.replace('{slug}', slug), options);
  }

  createMaterialContact(data: MaterialContact, options) {
    return http.post(constants.materialContactPOST, data, options);
  }

  getVilles(options) {
    return http.get(constants.villesAllGET, options);
  }

  getMaterialImages(id: string, options) {
    return http.get<Array<MaterialImage>>(constants.materialImagesGET.replace('{id}', id), options);
  }

  getAllMaterialContacts({ options }: { options: any }) {
    return http.get(constants.materialContactsAllGET, options);
  }

  moderateMaterialContact({ id, data, options }: { id: any; data: any; options: any }) {
    return http.put(constants.materialContactModeratePUT.replace('{id}', id), data, options);
  }

  getAllMaterialContactStatus({ options }: { options: any }) {
    return http.get(constants.materialContactStatusAllGET, options);
  }

  createModeleDemande({ data, options }: { data: ModeleDemande; options: any }) {
    return http.post(constants.modeleDemandePOST, data, options);
  }

  getCountNotModerated(options) {
    return http.get(constants.materialContactGetCountNotModerated, options);
  }
}

export default new MaterialService();
