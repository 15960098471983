import React, { useEffect, useState } from 'react';
import { Post, Subscription } from 'utils/Types/types';
import './PostItemSearch.scss';
import {
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonText,
  isPlatform,
} from '@ionic/react';
import { constants, dateFormatter, madFormatter } from 'utils';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { eye, location as loc, rocketOutline, star, starOutline, storefrontOutline, time } from 'ionicons/icons';
import { langFunction } from 'lang';
import { capitalize } from '@material-ui/core';
import EventTrackingService from '../../services/EventTrackingService';
import axios from 'axios';
import StatsService from '../../services/StatsService';
import { trackEvent } from '../../tracking';
import { postClicked } from '../../tracking/events';

interface PostItemSearchProps {
  post: Post;
  lang: string;
  intl: any;
}

const PostItemSearch: React.FC<PostItemSearchProps> = ({ post, lang, intl }) => {
  let reason = intl.formatMessage(langFunction.annonceDeleteMotif);

  const {
    id,
    titreFrancais,
    titreArabe,
    prix,
    premierePhoto,
    categorie,
    ville,
    slug,
    datePublication,
    shop,
    boostPlanID,
    viewCounts,
  } = post;

  const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
  const villeArab = ville.urlArab;
  const villeFrancais = ville.urlFr;
  const regionArab = ville.province.region.urlArab;
  const regionFr = ville.province.region.urlFr;
  const categorieFrancais = categorie.urlFrancais;
  const categorieArab = categorie.urlArabe;

  return (
    <div className={'post-item-container'}>
      {boostPlanID && boostPlanID === constants.boostPlanStar && (
        <IonBadge className={'my-ion-star'}>
          <IonIcon icon={starOutline} />
        </IonBadge>
      )}
      <IonCard
        class="post-item"
        href={
          lang === 'ar'
            ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
            : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        }
        onClick={(e) => {
          // trackEvent(postClicked({ postSlug: slug, postId: id, context: 'listing' }));
          const options = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          EventTrackingService.createEvent({
            data: {
              event: {
                id: constants.POST_CLICKED,
              },
              annonce: {
                id: id,
              },
              context: {
                id: constants.LISTING,
              },
            },
            options: options,
          }).catch((reason) => console.error(reason.message));
        }}
      >
        <div
          className={
            'd-flex justify-content-between' +
            (boostPlanID && boostPlanID === constants.boostPlanStar ? ' annonce-star-bg' : '')
          }
        >
          <picture>
            <source
              type="image/webp"
              srcSet={`${
                premierePhoto && premierePhoto.cheminWebp
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
            />
            <img
              loading="lazy"
              decoding={'async'}
              src={`${
                premierePhoto && premierePhoto.chemin
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.chemin + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
              className={'rounded-top d-block bg-dark'}
              style={{ maxHeight: '15rem', minWidth: '116px' }}
              alt={lang === 'fr' ? titreFrancais + '-' + id : titreArabe + '-' + id}
            />
          </picture>
          <div className={'d-flex flex-grow-1 flex-column justify-content-between'}>
            <IonCardHeader>
              <IonCardTitle color={'primary'}>
                <meta
                  itemProp="priceCurrency"
                  content="MAD"
                  data-reactid={
                    prix !== 0.0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                  }
                />
                {prix !== 0.0 ? (
                  <FormattedNumber
                    value={prix}
                    style={'currency'}
                    currency={'Mad'}
                    currencyDisplay={'symbol'}
                    maximumFractionDigits={2}
                  />
                ) : (
                  <FormattedMessage id={'price.negotiable'} />
                )}
              </IonCardTitle>
              <IonCardSubtitle>
                <span>{lang === 'fr' ? capitalize(titreFrancais) : titreArabe}</span>
              </IonCardSubtitle>
            </IonCardHeader>
            <div className={'body-bottom'}>
              <IonText className={'body-bottom-category'}>
                {lang === 'fr' ? categorie?.titreFrancais : categorie?.titreArabe}
              </IonText>
              <div className="w-100">
                <hr color="#D0D0D0" className="m-0" />
              </div>
              <div className={'mt-1 py-1 d-flex flex-row flex-nowrap'}>
                <IonText className={`d-flex flex-row align-items-center ${lang === 'fr' ? 'mr-1' : 'ml-1'}`}>
                  <IonIcon className={lang === 'fr' ? 'mr-1' : 'ml-1'} icon={loc} />
                  <span>{lang === 'fr' ? (ville ? ville.nomFr : '') : ville ? ville.nomArab : ''}</span>
                </IonText>
                {/*<IonText className={'d-flex flex-row align-items-center'}>
                  <IonIcon className={lang === 'fr' ? 'mr-1' : 'ml-1'} icon={time} />
                  <span>{dateFormatter(datePublication, lang)}</span>
                </IonText>*/}
                {/*<IonText className={'d-flex flex-row align-items-center'}>
                  <IonIcon className={'mx-1'} icon={eye} />
                  <span>
                    <FormattedMessage id={'annonce.view.count.label'} values={{ count: viewCounts }} />
                  </span>
                </IonText>*/}
              </div>
            </div>
          </div>
          {shop && (
            <IonBadge className={'my-ion-badge'}>
              <IonIcon icon={storefrontOutline} />
            </IonBadge>
          )}
        </div>
      </IonCard>
    </div>
  );
};

export default PostItemSearch;
