import { createAction } from "@reduxjs/toolkit";
import { Category } from "utils/Types/types";



export const saveCategories = createAction("SAVE_CATEGORIES", function prepare(
  categories: Array<Category>
) {
  return {
    payload: categories,
  };
});