import React, {Component} from "react";
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {IonLabel} from "@ionic/react";
import {constants, Events} from "utils";
import {FormattedMessage} from 'react-intl';

export default class LangViewer extends Component<Props, State> {

    events = new Events();

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
        }
    }

    componentDidMount(): void {

        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    render() {

        const {lang} = this.state;

        return (
            <IonLabel>
                {lang === 'fr' ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 2">
                            <rect width="3" height="2" fill="#ED2939"/>
                            <rect width="2" height="2" fill="#fff"/>
                            <rect width="1" height="2" fill="#002395"/>
                        </svg>
                    )
                    :
                    (
                      <svg version="1.1" id="Calque_1"  x="0px" y="0px" viewBox="0 0 36 24" >
                      <g>
                          <rect className="st0" width="36" height="24" fill="#C1272D"/>
                          <path className="st1" d="M18,9l-1.8,5.5l4.7-3.4h-5.8l4.7,3.4L18,9z" fill="none" stroke="#006233" strokeWidth="0.5852"/>
                      </g>
                  </svg>
                    )
                }

                <FormattedMessage id={'local.name'} />
            </IonLabel>
        );
    }
}

interface Props extends FlahaProps {

}

interface State extends FlahaState {
}
