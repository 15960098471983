import { createReducer } from '@reduxjs/toolkit';
import { saveMarques } from '../actions/saveMarques';

const marques = [];

export const saveMarquesReducer = createReducer(
  { marques },
  {
    [saveMarques.type]: (state, action) => ({
      ...state,
      marques: [...action.payload],
    }),
  }
);
