import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { constants, madFormatter } from 'utils';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Post } from 'utils/Types/types';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLoading,
  IonModal,
  IonSlide,
  IonSlides,
  isPlatform,
} from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { langFunction } from 'lang';
import './RecommendedPosts.scss';
import { trackEvent } from '../../tracking';
import { postClicked, postHovered } from '../../tracking/events';
import { SearchDetail } from 'pages';
import EventTrackingService from '../../services/EventTrackingService';

const slideOpts = {
  initialSlide: 1,
  speed: 400,
  slidesPerView: 2,
  centeredSlides: false,
  breakpoints: {
    1024: {
      slidesPerView: 4,
      centeredSlides: false,
    },
    325: {
      slidesPerView: 2.5,
      centeredSlides: false,
      spaceBetween: 0,
    },
  },
};

const noImage = '/assets/img/no_images.webp';

interface RecommendedPostsProps {
  lang: string;
  intl: any;
  categorie: number | string;
  typeAnnonce: string;
  ville: number | string;
}

const RecommendedPosts: React.FC<RecommendedPostsProps> = ({
  lang,
  intl,
  categorie,
  typeAnnonce,
  ville,
}: RecommendedPostsProps) => {
  const slider: React.Ref<HTMLIonSlidesElement> = React.useRef();
  const [posts, setPosts] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [slug, setSlug] = React.useState('');
  const [slicedSlug, setSlicedSlug] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);

  React.useEffect(() => {
    let filterData = {
      prixMin: null,
      prixMax: null,
      typeAnnonce: typeAnnonce,
      typeUtilisateur: null,
      searchText: null,
      categorieId: categorie,
      villeId: ville,
      regionId: null,
      urgent: false,
      titleOnly: false,
    };
    const urgentUri = encodeURIComponent(JSON.stringify(filterData));
    const url = `${process.env.REACT_APP_API_URL}${constants.posts}` + '?filter=' + urgentUri + '&page=0&size=15';
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        if (result.status !== 200) {
          return { content: [] };
        }
        return result.json();
      })
      .then((jsonResult) => {
        setPosts(jsonResult.content);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slugProps = { slug: slicedSlug };

  return !isEmpty(posts) ? (
    <div className="sliderContainer">
      {isPlatform('capacitor') && (
        <IonModal
          isOpen={showModal}
          cssClass="my-custom-class"
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            duration={1000}
            mode="ios"
            spinner={'bubbles'}
          />
          <SearchDetail {...slugProps}></SearchDetail>
        </IonModal>
      )}
      {isPlatform('desktop') && posts.length > 4 && (
        <>
          <IonIcon
            className="nextBtn"
            onClick={() => {
              lang === 'fr' ? slider.current.slideNext() : slider.current.slidePrev();
            }}
            icon={chevronForwardOutline}
          />
          <IonIcon
            className="prevBtn"
            onClick={() => {
              lang === 'fr' ? slider.current.slidePrev() : slider.current.slideNext();
            }}
            icon={chevronBackOutline}
          />
        </>
      )}
      <IonSlides ref={slider} options={slideOpts}>
        {posts.map((post: Post, index) => {
          const id = get(post, 'id');
          const pic = get(post, 'premierePhoto.chemin');
          const prix = get(post, 'prix');
          const slug = get(post, 'slug');
          const titreFrancais = get(post, 'titreFrancais');
          const titreArabe = get(post, 'titreArabe');
          const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
          const villeArab = get(post, 'ville.urlArab');
          const villeFrancais = get(post, 'ville.urlFr');
          const villeArabTitre = get(post, 'ville.nomArab');
          const villeFrancaisTitre = get(post, 'ville.nomFr');
          const regionArab = get(post, 'ville.province.region.urlArab');
          const regionFr = get(post, 'ville.province.region.urlFr');
          const categorieFrancais = get(post, 'categorie.urlFrancais');
          const categorieArab = get(post, 'categorie.urlArabe');

          return (
            <IonSlide
              key={index + ''}
              /*onMouseEnter={() => {
                trackEvent(postHovered({ postId: post.id, postSlug: post.slug, context: 'recommanded' }));
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                };

                EventTrackingService.createEvent({
                  data: {
                    event: {
                      id: constants.POST_HOVERED,
                    },
                    annonce: {
                      id: id,
                    },
                    context: {
                      id: constants.RECOMMENDED,
                    },
                  },
                  options: options,
                }).catch((reason) => console.error(reason.message));
              }}*/
            >
              <IonCard
                className="slide_card"
                onClick={() => {
                  trackEvent(postClicked({ postId: post.id, postSlug: post.slug, context: 'recommanded' }));
                  const options = {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  };

                  EventTrackingService.createEvent({
                    data: {
                      event: {
                        id: constants.POST_CLICKED,
                      },
                      annonce: {
                        id: id,
                      },
                      context: {
                        id: constants.RECOMMENDED,
                      },
                    },
                    options: options,
                  }).catch((reason) => console.error(reason.message));
                  if (isPlatform('capacitor')) {
                    setSlug(slug);
                    setSlicedSlug(slicedSlug);
                    setShowModal(true);
                    setShowLoading(true);
                  } else {
                    lang === 'ar'
                      ? (window.location.href = `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`)
                      : (window.location.href = `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`);
                  }
                }}
              >
                <a
                  href={
                    lang === 'ar'
                      ? `/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`
                      : `/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
                  }
                >
                  {' '}
                </a>
                {pic ? (
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={
                        process.env.REACT_APP_API_URL +
                        constants.pictures +
                        pic.replace(/\b(?:jpeg|png|jpg)\b/gi, 'webp') +
                        '?small=false'
                      }
                    />
                    <img
                      loading="lazy"
                      alt={slicedSlug}
                      src={process.env.REACT_APP_API_URL + constants.pictures + pic + '?small=false'}
                    />
                  </picture>
                ) : (
                  <img loading="lazy" alt="empty" src={noImage} />
                )}
                <IonCardHeader className="post_info">
                  <IonCardTitle className="post_title">{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
                  <meta
                    itemProp="priceCurrency"
                    content="MAD"
                    data-reactid={
                      prix !== 0.0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                    }
                  />
                  <IonCardSubtitle className={'price'} color={'primary'}>
                    {prix !== 0.0 ? (
                      <FormattedNumber
                        value={prix}
                        style={'currency'}
                        currency={'Mad'}
                        currencyDisplay={'symbol'}
                        maximumFractionDigits={2}
                      />
                    ) : (
                      <FormattedMessage id={'price.negotiable'} />
                    )}
                  </IonCardSubtitle>
                  <div className="details">
                    {villeFrancais && villeArab ? <p>{lang === 'fr' ? villeFrancaisTitre : villeArabTitre}</p> : null}
                  </div>
                </IonCardHeader>
              </IonCard>
            </IonSlide>
          );
        })}
      </IonSlides>
    </div>
  ) : null;
};

export default injectIntl(RecommendedPosts);
