import React, { Component, FormEvent } from 'react';

import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  CreateAnimation,
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  isPlatform,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, GallerySelector, Header } from 'components';
import { constants, Events, validateEmail } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import { Category, City, Marque, Modele, Shop } from 'utils/Types/types';
import {
  alertCircle,
  arrowBackOutline,
  arrowForwardOutline,
  caretDownOutline,
  closeOutline,
  createOutline,
  eye,
  eyeOff,
  informationCircleOutline,
  sendOutline,
} from 'ionicons/icons';
import './SendPost.scss';
import {
  authHeader,
  checkUserAdmin,
  getFileType,
  getUserProfile,
  makeblob,
  removeUserToken,
  setUserProfile,
  setUserToken,
} from 'utils/Functions/Functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Plugins, registerWebPlugin } from '@capacitor/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { MenuItem, Step, StepLabel, Stepper, StylesProvider, ThemeProvider, Typography } from '@material-ui/core';
import Radium from 'radium';
import { createMuiTheme, jssPreset, withStyles } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fr from 'react-phone-number-input/locale/fr.json';
import ar from 'lang/phone-input/ar.json';
import shopService from '../../services/ShopService';
import ReactQuill from 'react-quill';
import BoostAnnonceForm from 'components/BoostAnnonceForm/BoostAnnonceForm';
import { Debugger } from 'inspector';

const cachedStateKey = '@flaha.cachedState';
const tempDataKey = '@flaha.tempData';
const prevLocationKey = '@flaha.prevLocation';

const OAUTH2_REDIRECT_URI = 'https://flaha.ma/oauth2/redirect';
const FACEBOOK_AUTH_URL =
  process.env.REACT_APP_API_URL + '/api/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

const getUserProfileInfo = (token: string) => {
  let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
  let bearer = 'Bearer ' + token;
  const headers = new Headers({
    Authorization: bearer,
  });

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: headers,
  })
    .then((request) => {
      return request.json();
    })
    .then((jsonRequest) => {
      ////////console.log(jsonRequest);
      /*//debugger;*/
      if (jsonRequest) {
        setUserProfile(
          jsonRequest.email,
          jsonRequest.nom,
          jsonRequest.telephone,
          jsonRequest.type,
          jsonRequest.imageUrl,
          jsonRequest.admin ? 'true' : 'false',
          jsonRequest.moderator ? 'true' : 'false',
          jsonRequest.roles ? jsonRequest.roles : []
        );
      }
    });
};

const loginUser = ({ login, password }: { login: string; password: string }) => {
  const url = `${process.env.REACT_APP_API_URL}${constants.login}`;

  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email: login, password }),
    credentials: 'same-origin',
    headers: headers,
  }).then((response) => {
    if (response.status !== 200) {
      throw response;
    } else {
      return response.json();
    }
  });
};

const registerUser = ({
  me,
  name,
  phone,
  login,
  password,
  lang,
}: {
  me: string;
  name: string;
  phone: string;
  login: string;
  password: string;
  lang: string;
}) => {
  const url = `${process.env.REACT_APP_API_URL}${constants.register}`;

  let userData = {
    provider: 'email',
    nom: name,
    prenom: name.split(' ')[0],
    password: password,
    email: login,
    login: login,
    type: me,
    telephone: phone,
  };

  const headers = new Headers({
    'Content-Type': 'application/json',
    accept: '*/*',
  });

  let userDataURI = encodeURIComponent(JSON.stringify(userData));

  return fetch(`${url}?userData=${userDataURI}&localeId=${lang}`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: headers,
  }).then((response) => {
    if (response.status !== 200) {
      throw response;
    } else {
      return response.json();
    }
  });
};

const updateUserPhone = ({ announceTelephone, token }: { announceTelephone: string; token: string }) => {
  const { nom, email, imageUrl, type } = getUserProfile();
  const url = `${process.env.REACT_APP_API_URL}${constants.userProfilePut}`;
  let userData = {
    provider: 'facebook',
    email: email,
    nom: nom,
    telephone: announceTelephone,
    type: type,
    imageUrl: imageUrl,
  };
  let bearer = 'Bearer ' + token;
  const headers = new Headers({
    Authorization: bearer,
    'Content-Type': 'application/json',
    accept: '*/*',
  });
  fetch(url, {
    method: 'PUT',
    body: JSON.stringify(userData),
    credentials: 'same-origin',
    headers: headers,
  })
    .then((request) => {
      if (request.status === 200 || request.status === 409) {
        return request.json();
      }
      if (request.status === 401) {
        removeUserToken();
        return (window.location.href = '/login');
      }
    })
    .then((jsonRequest) => {
      if (jsonRequest) {
        setUserToken(jsonRequest.token);
      }
    })
    .catch((error) => {
      console.error(`Can not update (${error.message})`, error);
    });
};

const createPost = ({
  type,
  urgent,
  titleFr,
  titleAr,
  description,
  price,
  category,
  subCategory,
  city,
  images,
  phone,
  token,
  lang,
  telephoneUtilisateurAnnonce,
  nomUtilisateurAnnonce,
  emailUtilisateurAnnonce,
  surface,
  poids,
  marque,
  modele,
  anneeModele,
  kilometrage,
  typeCarburant,
  puissanceFiscale,
  boiteVitesse,
  shop,
  secondaryPhone,
  boostID,
}: {
  type: string;
  urgent: boolean;
  titleFr: string;
  titleAr: string;
  description: string;
  price: number;
  category: Category | null;
  subCategory: Category | null;
  city: City | null;
  images: Array<string>;
  phone: string;
  token: string;
  lang: string;
  telephoneUtilisateurAnnonce: string;
  nomUtilisateurAnnonce: string;
  emailUtilisateurAnnonce: string;
  surface: string;
  poids: string;
  marque: Marque | null;
  modele: Modele | null;
  anneeModele: string;
  kilometrage: string;
  typeCarburant: any;
  puissanceFiscale: string;
  boiteVitesse: any;
  shop?: Shop;
  secondaryPhone?: string;
  boostID: string;
}) => {
  const url = `${process.env.REACT_APP_API_URL}${constants.posts}`;

  const { nom, email, telephone } = getUserProfile();
  if (!telephone) {
    const announceTelephone = phone;
    updateUserPhone({ announceTelephone, token });
  }
  const announceTelephone = telephone ? telephone : phone;
  const isUserOrCommercial = checkUserRole('user') || checkUserRole('commercial');
  console.log('isUserOrCommercial', isUserOrCommercial);
  /*////////console.log('isUser : ', isUser);*/
  // debugger;
  let annonce = {
    description: description,
    emailAnnonce: !isUserOrCommercial ? emailUtilisateurAnnonce : email,
    // moderate:true,
    nomUtilisateurAnnonce: !isUserOrCommercial ? nomUtilisateurAnnonce : nom,
    prix: price,
    telephoneAnnonce: !isUserOrCommercial ? telephoneUtilisateurAnnonce : announceTelephone,
    titreArabe: titleAr,
    titreFrancais: titleFr,
    typeAnnonce: type,
    urgent: urgent,
    categorie: { id: subCategory ? subCategory.id : category.id },
    ville: { id: city },
    surface: surface,
    poids: poids,
    marque: marque ? marque.id : null,
    modele: modele ? { id: modele.id } : null,
    anneeModele: anneeModele,
    kilometrage: kilometrage,
    typeDeCarburant: typeCarburant ? { id: typeCarburant.id } : null,
    puissanceFiscale: puissanceFiscale,
    boiteAVitesse: boiteVitesse ? { id: boiteVitesse.id } : null,
    shop: shop ? { id: shop.id } : null,
    secondaryPhone: secondaryPhone,
  };
  ////console.log(annonce);
  // debugger;
  let bearer = 'Bearer ' + token;
  const headers = new Headers({
    Authorization: bearer,
  });
  const formData: FormData = new FormData();
  formData.append('annonce', JSON.stringify(annonce));
  //////////console.log(annonce);
  for (let i = 0; i < images.length; i++) {
    const ph = images[i];
    formData.append('photos', makeblob(ph), getFileType(ph, i));
  }
  formData.append('localeId', JSON.stringify(lang));
  formData.append('boostID', JSON.stringify(boostID));

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: headers,
  }).then((response) => {
    if (response.status == 403) {
      alert(
        lang === 'fr'
          ? 'Ce numéro a été exclu, tu ne peux pas ajouterdes annonces pour ce client'
          : ' لقد تم حذر هذا الرقم، لا يمكنك إظافة إعلانات لهذ الشخص'
      );
    } else if (response.status == 406) {
      return response;
    } else if (response.status == 402) {
      return response;
    } else if (response.status !== 200 && response.status !== 409) {
      throw response;
    } else {
      return response;
    }
  });
};

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      '& fieldset': {
        borderColor: 'var(--ion-color-medium)',
        '& legend': {
          padding: '0px 8px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
    },
  },
})(TextField);

const styleMenuItem = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { paddingRight: '20px', paddingLeft: '20px' };
  } else {
    return { paddingRight: '20px', paddingLeft: '20px', backgroundColor: 'rgb(245 246 249 / 68%)', fontWeight: 400 };
  }
};

const styleAvatar = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { maxWidth: '25px', height: '25px', backgroundColor: '#ffffff' };
  } else {
    return { backgroundColor: 'var(--ion-color-medium-contrast)' };
  }
};

const ltrTheme = createMuiTheme({ direction: 'ltr' });
const rtlTheme = createMuiTheme({ direction: 'rtl' });

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ],
};

const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent'];

class SendPost extends Component<Props, State> {
  _sendPostForm: any = null;
  slectedCities: any = [];
  optionTopping: any = [
    { value: 'extra-cheese', ll: 'extra', kkk: 'gg' },
    { value: 'extra-cheese2', ll: 'extra2', kkk: 'gg' },
    { value: 'extra-cheese3', ll: 'extra3', kkk: 'gg' },
    { value: 'extra-cheese4', ll: 'extra4', kkk: 'gg' },
  ];

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      images: [],
      categories: [],
      category: null,
      subCategory: null,
      cities: [],
      city: null,
      type: 'ventes',
      urgent: false,
      titleFr: '',
      titleAr: '',
      description: '',
      price: 0.0,
      haveAccount: true,
      name: '',
      phone: undefined,
      login: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      enableSubmit: false,
      me: 'Particulier',
      token: null,
      errorMessage: null,
      isSubmitting: false,
      isAdmin: false,
      isUser: false,
      isUserExist: false,
      telephoneUtilisateurAnnonce: undefined,
      nomUtilisateurAnnonce: '',
      emailUtilisateurAnnonce: '',
      showRequiredMsg: false,
      phoneNotFound: false,
      loginErrorFacebook: false,
      showAlert: false,
      showAlertExist: false,
      showAlertPostExist: false,
      marques: [],
      modeles: [],
      typesCarburant: [],
      boiteAVitesses: [],
      surface: '',
      poids: '',
      marque: null,
      modele: null,
      anneeModele: '',
      kilometrage: '',
      typeCarburant: null,
      puissanceFiscale: '',
      boiteVitesse: null,
      shop: undefined,
      userShops: [],
      secondaryPhone: undefined,
      activeStep: 0,
      enableFinalSubmit: false,
      boostID: undefined,
      showAlertOnBoostError: false,
    };

    this.getCategories();
    this.getCities();
    this.checkUserAdmin();
    this.getUserShops();

    this.onSubmit = this.onSubmit.bind(this);
    this.checkIfExistBeforeSubmit = this.checkIfExistBeforeSubmit.bind(this);
    this.fbSignInDesktop = this.fbSignInDesktop.bind(this);
    this.fbSignInMobile = this.fbSignInMobile.bind(this);
    // this.showAlertMessage = this.showAlertMessage.bind(this);
    this.checkRole = this.checkRole.bind(this);
    this.getCategoriesOptions = this.getCategoriesOptions.bind(this);
    this.renderItemCategorie = this.renderItemCategorie.bind(this);
    this.getMarques = this.getMarques.bind(this);
    this.getModeleByMarque = this.getModeleByMarque.bind(this);
    this.getTypesCarburant = this.getTypesCarburant.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.enableFinalSubmit = this.enableFinalSubmit.bind(this);
  }

  getTokenUser() {
    this.setState({
      token: window.localStorage.getItem('@flaha.userToken'),
    });
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  checkUserAdmin() {
    checkUserAdmin().then((data) => {
      if (data === undefined) {
        this.setState({ isAdmin: false });
      } else {
        this.setState({ isAdmin: data === 'true' ? true : false });
      }
    });
  }

  checkUserRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  checkUserPhone() {
    const data = getUserProfile();
    const telephone = data.telephone;
    if (!telephone) {
      this.setState({ phoneNotFound: true });
    }
  }

  loadUserInput() {
    const previousLocation = localStorage.getItem(prevLocationKey);
    const token = window.localStorage.getItem('@flaha.userToken');

    if (token !== null && previousLocation === 'sendPost') {
      const tempData = JSON.parse(localStorage.getItem(tempDataKey));
      this.setState({
        images: tempData.images || [],
        category: tempData.category || null,
        subCategory: tempData.subCategory || null,
        city: tempData.city || '',
        type: tempData.type || 'offres',
        urgent: tempData.urgent || false,
        titleFr: tempData.titleFr || '',
        titleAr: tempData.titleAr || '',
        description: tempData.description || [],
        price: tempData.price || 0,
      });
      window.localStorage.removeItem(prevLocationKey);
      window.localStorage.removeItem(tempDataKey);
    }
  }

  componentDidMount(): void {
    registerWebPlugin(FacebookLogin);
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    window.addEventListener('ionAlertDidPresent', (e) => {
      setTimeout(() => {
        this.setState({ showAlert: false, showAlertExist: false });
      }, 5000);
    });
    // this.getUserConnect();
    this.getTokenUser();
    this.checkUserPhone();
    this.loadUserInput();

  }

  componentDidUpdate() {
    const images = this.state.images;
    const formData: FormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      const ph = images[i];
      formData.append('photos', makeblob(ph), getFileType(ph, i));
      //////////console.log(formData.getAll);
    }
  }

  saveUserInput() {
    const { images, type, urgent, city, category, subCategory, titleFr, titleAr, description, price } = this.state;
    window.localStorage.setItem(prevLocationKey, 'sendPost');
    localStorage.setItem(
      tempDataKey,
      JSON.stringify({
        images,
        type,
        urgent,
        city,
        category,
        subCategory,
        titleFr,
        titleAr,
        description,
        price,
      })
    );
  }

  fbSignInDesktop() {
    this.saveUserInput();
    window.location.href = FACEBOOK_AUTH_URL;
  }

  async checkUserIfExist(telephone): Promise<boolean> {
    try {
      const url = process.env.REACT_APP_API_URL + constants.normalUsersByPhone + telephone;

      const res = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: authHeader(),
      });

      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      const data = await res.json();

      return data.length > 0;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async fbSignInMobile(): Promise<void> {
    this.setState({
      loginErrorFacebook: false,
      errorMessage: null,
    });

    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    //const resultTest= await Plugins.FacebookLogin.logout();
    const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result && result.accessToken) {
      //////////console.log('result-Token  :' +  result.accessToken.token);
      //////////console.log('result-userId :' + result.accessToken.userId );
      let url = `${process.env.REACT_APP_API_URL}${constants.loginFacebookApp}`;
      let authToken = result.accessToken.token;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ authToken: authToken }),
        credentials: 'same-origin',
        headers: headers,
      })
        .then((request) => {
          //////////console.log(request)
          return request.json();
        })
        .then((jsonRequest) => {
          //////////console.log(jsonRequest);
          if (jsonRequest.token) {
            setUserToken(jsonRequest.token);
            let bearer = 'Bearer ' + jsonRequest.token;
            let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
            const headers = new Headers({
              Authorization: bearer,
            });
            fetch(url, {
              method: 'GET',
              credentials: 'same-origin',
              headers: headers,
            })
              .then((request) => {
                return request.json();
              })
              .then((jsonRequest) => {
                if (jsonRequest) {
                  setUserProfile(
                    jsonRequest.email,
                    jsonRequest.nom,
                    jsonRequest.telephone,
                    jsonRequest.type,
                    jsonRequest.imageUrl,
                    jsonRequest.admin ? 'true' : 'false',
                    jsonRequest.moderator ? 'true' : 'false',
                    jsonRequest.roles ? jsonRequest.roles : []
                  );
                  this.saveUserInput();
                  window.location.href = '/sendPost';
                }
              });
          } else {
            this.setState(
              {
                loginErrorFacebook: true,
              },
              () => {
                console.error(`Can not login facebook`);
              }
            );
          }
        });
    }
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  /*showAlertMessage() {
      const {showAlert, showAlertExist, lang} = this.state;
      // @ts-ignore
      const {intl} = this.props;
      const msgPostExist = lang === 'fr' ? 'Annonce existe déjà' : 'هذا الإعلان موجود، تحقق منه !';
      const annonceModereeMsg = intl.formatMessage(langFunction.annonceModereeMsg);
      const annonceModereeMsgAdmin = intl.formatMessage(langFunction.annonceModereeMsgAdmin);
      const closeMsg = intl.formatMessage(langFunction.close);
      // //debugger;
      if (showAlert) {
          return <IonAlert isOpen={showAlert}
                           message={(this.checkUserRole('admin') || this.checkUserRole('moderateur') || this.checkUserRole('editeur')) ? '<img src = "./assets/check.gif" > ' + annonceModereeMsgAdmin : '<img src = "./assets/check.gif" > ' + annonceModereeMsg}
                           cssClass={"success-alert"}
                           buttons={[{
                               text: closeMsg,
                               role: 'cancel',
                               handler: () => {
                                   this.setState({showAlert: false, isSubmitting: false});
                               }
                           }]}
                           onDidDismiss={() => {
                               window.location.href = '/user/mes-annonces';
                           }}
          />
      } else if (showAlertExist) {
          return <IonAlert isOpen={showAlertExist}
                           message={msgPostExist}
                           cssClass={"error-alert"}
                           buttons={[{
                               text: closeMsg,
                               role: 'cancel',
                               handler: () => {
                                   this.setState({showAlertExist: false, isSubmitting: false});
                               }
                           }]}
                           onDidDismiss={() => {
                               this.setState({showAlertExist: false, isSubmitting: false});
                           }}
          />
      }
      return
  }*/

  checkIfExistBeforeSubmit(event: FormEvent) {
    const { telephoneUtilisateurAnnonce, lang, token } = this.state;

    event.preventDefault();
    this.setState({ isSubmitting: true });
    const isUserOrCommercial = checkUserRole('user') || checkUserRole('commercial');

    if (token && !isUserOrCommercial) {
      this.checkUserIfExist(telephoneUtilisateurAnnonce).then((value) => {
        if (value) {
          alert(
            lang === 'fr'
              ? `Un utilisateur avec le numéro de téléphone "${formatPhoneNumberIntl(
                  telephoneUtilisateurAnnonce
                )}" existe déjà.`
              : `يوجد بالفعل مستخدم برقم الهاتف "${formatPhoneNumberIntl(telephoneUtilisateurAnnonce)}".`
          );
          this.setState({ isSubmitting: false });
        } else {
          this.onSubmit();
        }
      });
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    let {
      type,
      urgent,
      titleFr,
      titleAr,
      description,
      price,
      category,
      subCategory,
      city,
      images,
      haveAccount,
      token,
      me,
      name,
      phone,
      login,
      password,
      lang,
      telephoneUtilisateurAnnonce,
      nomUtilisateurAnnonce,
      emailUtilisateurAnnonce,
      surface,
      poids,
      marque,
      modele,
      anneeModele,
      kilometrage,
      typeCarburant,
      puissanceFiscale,
      boiteVitesse,
      shop,
      secondaryPhone,
      boostID,
    } = this.state;

    if (token) {
      // user is already logged in and create post directly with token
      createPost({
        type,
        urgent,
        titleFr,
        titleAr,
        description,
        price,
        category,
        subCategory,
        city,
        images,
        phone,
        token,
        lang,
        telephoneUtilisateurAnnonce,
        nomUtilisateurAnnonce,
        emailUtilisateurAnnonce,
        surface,
        poids,
        marque,
        modele,
        anneeModele,
        kilometrage,
        typeCarburant,
        puissanceFiscale,
        boiteVitesse,
        shop,
        secondaryPhone,
        boostID,
      })
        .then((response) => {
          // Laisser ce block en commentaire, il va être enlever pour la MEP de la modération d'annonce
          response.status === 200 ? this.setState({ showAlert: true }) : this.setState({ showAlert: false });
          response.status === 409 ? this.setState({ showAlertExist: true }) : this.setState({ showAlertExist: false });
          response.status === 406
            ? this.setState({ showAlertPostExist: true })
            : this.setState({ showAlertPostExist: false });
          response.status === 402
            ? this.setState({ showAlertOnBoostError: true })
            : this.setState({ showAlertOnBoostError: false });
          window.localStorage.setItem(cachedStateKey, null);
          //window.location.href = '/user/mes-annonces';
        })
        .catch((responseError) => {
          if (responseError.status === 401) {
            removeUserToken();
            window.location.href = '/login';
          }
          this.setState({ isSubmitting: false });
        });
    } else {
      if (haveAccount) {
        // user is not yet logged in
        loginUser({ login, password })
          .then((jsonResult) => {
            if (jsonResult.token) {
              setUserToken(jsonResult.token);
              getUserProfileInfo(jsonResult.token).then(() => {
                createPost({
                  type,
                  urgent,
                  titleFr,
                  titleAr,
                  description,
                  price,
                  category,
                  subCategory,
                  city,
                  images,
                  phone,
                  token: jsonResult.token,
                  lang,
                  telephoneUtilisateurAnnonce,
                  nomUtilisateurAnnonce,
                  emailUtilisateurAnnonce,
                  surface,
                  poids,
                  marque,
                  modele,
                  anneeModele,
                  kilometrage,
                  typeCarburant,
                  puissanceFiscale,
                  boiteVitesse,
                  shop,
                  secondaryPhone,
                  boostID,
                })
                  .then((response) => {
                    // Laisser ce block en commentaire, il va être enlever pour la MEP de la modération d'annonce
                    response.status === 200 ? this.setState({ showAlert: true }) : this.setState({ showAlert: false });
                    response.status === 409
                      ? this.setState({ showAlertExist: true })
                      : this.setState({ showAlertExist: false });
                    response.status === 406
                      ? this.setState({ showAlertPostExist: true })
                      : this.setState({ showAlertPostExist: false });
                    response.status === 402
                      ? this.setState({ showAlertOnBoostError: true })
                      : this.setState({ showAlertOnBoostError: false });
                    window.localStorage.setItem(cachedStateKey, null);
                    //window.location.href = '/user/mes-annonces';
                  })
                  .catch((responseError) => {
                    if (responseError.status === 401) {
                      removeUserToken();
                      window.location.href = '/login';
                    }
                    this.setState({ isSubmitting: false });
                  });
              });
            }
          })
          .catch(() => {
            // handle failed login
            this.setState({ errorMessage: 'sign.in.error', isSubmitting: false });
          });
      } else {
        // user is not registered yet
        registerUser({ me, name, phone, login, password, lang })
          .then((jsonResult) => {
            if (jsonResult.token) {
              setUserToken(jsonResult.token);
              getUserProfileInfo(jsonResult.token).then(() => {
                createPost({
                  type,
                  urgent,
                  titleFr,
                  titleAr,
                  description,
                  price,
                  category,
                  subCategory,
                  city,
                  images,
                  phone,
                  token: jsonResult.token,
                  lang,
                  telephoneUtilisateurAnnonce,
                  nomUtilisateurAnnonce,
                  emailUtilisateurAnnonce,
                  surface,
                  poids,
                  marque,
                  modele,
                  anneeModele,
                  kilometrage,
                  typeCarburant,
                  puissanceFiscale,
                  boiteVitesse,
                  shop,
                  secondaryPhone,
                  boostID,
                })
                  .then((response) => {
                    // Laisser ce block en commentaire, il va être enlever pour la MEP de la modération d'annonce
                    response.status === 200 ? this.setState({ showAlert: true }) : this.setState({ showAlert: false });
                    response.status === 409
                      ? this.setState({ showAlertExist: true })
                      : this.setState({ showAlertExist: false });
                    response.status === 406
                      ? this.setState({ showAlertPostExist: true })
                      : this.setState({ showAlertPostExist: false });
                    response.status === 402
                      ? this.setState({ showAlertOnBoostError: true })
                      : this.setState({ showAlertOnBoostError: false });

                    window.localStorage.setItem(cachedStateKey, null);
                    //window.location.href = '/user/mes-annonces';
                  })
                  .catch((responseError) => {
                    if (responseError.status === 401) {
                      removeUserToken();
                      window.location.href = '/login';
                    }
                    this.setState({ isSubmitting: false });
                  });
              });
            }
          })
          .catch(() => {
            // handle registration failed error
            this.setState({ errorMessage: 'sign.up.account.error', isSubmitting: false });
          });
      }
    }
  }

  activateSubmit() {
    const {
      name,
      phone,
      login,
      password,
      confirmPassword,
      haveAccount,
      categoryC,
      titleFr,
      titleAr,
      price,
      token,
      description,
      city,
      category,
      phoneNotFound,
      lang,
      telephoneUtilisateurAnnonce,
      nomUtilisateurAnnonce,
      marque,
      boiteVitesse,
      subCategory,
      secondaryPhone,
    } = this.state;
    //console.log(this.state);

    //show required alert
    this.setState({
      showRequiredMsg: true,
    });

    if (
      ((lang === 'fr' && titleFr && titleFr !== '') || (lang === 'ar' && titleAr && titleAr !== '')) &&
      description &&
      description !== '' &&
      price >= 0 &&
      city &&
      ((lang === 'fr' && categoryC && categoryC !== 'a_vendre') ||
        (lang === 'ar' && categoryC && categoryC !== 'للبيع')) &&
      category &&
      ((token !== null &&
        !this.checkRole('user') &&
        !this.checkRole('commercial') &&
        telephoneUtilisateurAnnonce &&
        telephoneUtilisateurAnnonce !== '' &&
        isPossiblePhoneNumber(String(telephoneUtilisateurAnnonce)) &&
        ((secondaryPhone && isPossiblePhoneNumber(String(secondaryPhone))) ||
          !secondaryPhone ||
          secondaryPhone === '') &&
        nomUtilisateurAnnonce &&
        nomUtilisateurAnnonce !== '') ||
        token === null ||
        this.checkRole('user') ||
        this.checkRole('commercial')) &&
      ((category && (category.marques === null || (marque && boiteVitesse))) ||
        (subCategory && (subCategory.marques === null || (marque && boiteVitesse))))
      //&& ( category && category !== null && ((category.haveSurface && surface !== '' ) || !category.haveSurface))
      //&& ( category && category !== null && ((category.haveWeight && poids !== '' ) || !category.haveWeight))
    ) {
      if (token && !phoneNotFound) {
        this.setState({ enableSubmit: true });
        return;
      } else if (token && phoneNotFound && phone && isPossiblePhoneNumber(String(phone)) !== null) {
        this.setState({ enableSubmit: true });
        return;
      } else if (haveAccount) {
        if (password && password !== '' && login && login !== '' && validateEmail(login)) {
          this.setState({ enableSubmit: true });
          return;
        }
      } else {
        if (
          name &&
          name !== '' &&
          login &&
          login !== '' &&
          validateEmail(login) &&
          password &&
          password !== '' &&
          password === confirmPassword &&
          phone &&
          isPossiblePhoneNumber(String(phone))
        ) {
          this.setState({ enableSubmit: true });
          return;
        }
      }
    }
    this.setState({ enableSubmit: false });
  }

  getCategories() {
    const url = process.env.REACT_APP_API_URL + constants.categoriesSendPost;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        const sortedJSON = jsonResult.sort(function (a, b) {
          return a.orderAffichage - b.orderAffichage;
        });
        //////console.log('sortedJSON : ',sortedJSON);
        this.setState(
          {
            categories: sortedJSON,
          },
          () => {
            /*this.setState({
            category: jsonResult.length > 0 ? jsonResult[0].id : null,
        });*/
          }
        );
      })
      .catch((error) => {
        console.error('errorMessage : ', error.message, error);
      });
  }

  getCities() {
    const url = process.env.REACT_APP_API_URL + constants.cities;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        this.setState(
          {
            cities: jsonResult,
          },
          () => {
            /*this.setState({
            city: jsonResult.length > 0 ? jsonResult[0].id : null,
        });*/
          }
        );
      })
      .catch((error) => {
        console.error('erroressage2', error.message, error);
      });
  }

  showError(feildName, requiredMsg) {
    const {
      name,
      phone,
      login,
      password,
      confirmPassword,
      city,
      titleFr,
      titleAr,
      description,
      showRequiredMsg,
      category,
      nomUtilisateurAnnonce,
      telephoneUtilisateurAnnonce,
      emailUtilisateurAnnonce,
      marque,
      modele,
      anneeModele,
      typeCarburant,
      boiteVitesse,
      secondaryPhone,
    } = this.state;

    const requiredJSX = (
      <div style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>
        <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
        {requiredMsg}
      </div>
    );

    switch (feildName) {
      case 'name': {
        if (name === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'titleAr': {
        if (titleAr === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'titleFr': {
        if (titleFr === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'description': {
        if (description === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'city': {
        if (city === null && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'category': {
        if (category === null && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'password': {
        if (password === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'confirmPassword': {
        if (confirmPassword === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'login': {
        if (login === '' && showRequiredMsg) {
          return requiredJSX;
        } else if (!validateEmail(login) && showRequiredMsg) {
          return (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '10px',
                paddingLeft: '17px',
                paddingRight: '17px',
              }}
            >
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.email.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'phone': {
        if (!phone && showRequiredMsg) {
          return requiredJSX;
        } else if (!isPossiblePhoneNumber(String(phone)) && showRequiredMsg) {
          return (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '10px',
                paddingLeft: '17px',
                paddingRight: '17px',
              }}
            >
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.phone.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'nomUtilisateurAnnonce': {
        if (nomUtilisateurAnnonce === '' && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      case 'telephoneUtilisateurAnnonce': {
        if (!telephoneUtilisateurAnnonce && showRequiredMsg) {
          return requiredJSX;
        } else if (!isPossiblePhoneNumber(String(telephoneUtilisateurAnnonce)) && showRequiredMsg) {
          return (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '10px',
                textAlign: 'center',
              }}
            >
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.phone.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      case 'secondaryPhone': {
        if (secondaryPhone && !isPossiblePhoneNumber(String(secondaryPhone)) && showRequiredMsg) {
          return (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '10px',
                textAlign: 'center',
              }}
            >
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.phone.invalid'} />
            </div>
          );
        } else {
          return null;
        }
      }
      /* case "emailUtilisateurAnnonce": {
           if (emailUtilisateurAnnonce === '' && showRequiredMsg) {
               return (requiredJSX);
           } else if (!validateEmail(emailUtilisateurAnnonce) && showRequiredMsg) {
               return (
                   <div style={{
                       color: "red",
                       fontWeight: "bold",
                       fontSize: "10px",
                       textAlign: "center",
                   }}>
                       <IonIcon style={{marginBottom: "-4px"}} icon={alertCircle} size={'small'}/>
                       <FormattedMessage id={'form.email.invalid'}/>
                   </div>);
           } else {
               return null;
           }
       }*/
      case 'marque': {
        if ((marque === '' || marque === null) && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }
      /* case "modele": {
           if ((modele === '' || modele === null) && showRequiredMsg) {
               return (requiredJSX);
           } else {
               return null;
           }
       }
       case "anneeModele": {
           if ((anneeModele === '' || anneeModele === null) && showRequiredMsg) {
               return (requiredJSX);
           } else {
               return null;
           }
       }
       case "typeCarburant": {
           if ((typeCarburant === '' || typeCarburant === null) && showRequiredMsg) {
               return (requiredJSX);
           } else {
               return null;
           }
       }*/
      case 'boiteVitesse': {
        if ((boiteVitesse === '' || boiteVitesse === null) && showRequiredMsg) {
          return requiredJSX;
        } else {
          return null;
        }
      }

      default: {
        break;
      }
    }
  }

  getSubCategoryOptions() {
    const { categories, lang, category } = this.state;
    const filtredCategories = categories
      ? categories.filter((item: Category) => {
          return item.searchDesactivate === false;
        })
      : null;
    const allSubCategories = filtredCategories
      ? filtredCategories.filter((item: Category) => {
          return item.sousCategorie !== 0;
        })
      : null;
    const subCategories =
      allSubCategories && category
        ? allSubCategories.filter((item: Category) => {
            return item.sousCategorie.toString() === category.code;
          })
        : null;

    const finalSortedSubCategories = subCategories
      ? subCategories.map((item) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
          };
        })
      : null;
    return finalSortedSubCategories;
  }

  getCategoriesOptions() {
    const { categories, lang } = this.state;
    const principalCategories = categories
      ? categories.filter((item: Category) => {
          return item.sousCategorie === 0;
        })
      : null;

    const principalCategoriesFormatted = principalCategories
      ? principalCategories.map((item: Category) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
          };
        })
      : null;
    return principalCategoriesFormatted;
  }

  handleNone() {
    this.setState({
      category: null,
      subCategory: null,
      modeles: [],
      marques: [],
      marque: null,
      modele: null,
      typeCarburant: null,
      puissanceFiscale: '',
      kilometrage: '',
      anneeModele: '',
      boiteVitesse: null,
    });
    this.activateSubmit();
  }

  handleClickCategory(value) {
    const { categories } = this.state;
    const category = categories.find((item) => item.id === value);
    this.setState(
      {
        category: category,
        modeles: [],
        marques: [],
        marque: null,
        modele: null,
        typeCarburant: null,
        puissanceFiscale: '',
        kilometrage: '',
        anneeModele: '',
        boiteVitesse: null,
      },
      () => {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteVitesses();
      }
    );
    this.activateSubmit();
  }

  handleClickSubCategory(value) {
    const { categories } = this.state;
    const subCategory = categories.find((item) => item.id === value);
    this.setState(
      {
        subCategory: subCategory,
        modeles: [],
        marques: [],
        marque: null,
        modele: null,
        typeCarburant: null,
        puissanceFiscale: '',
        kilometrage: '',
        anneeModele: '',
        boiteVitesse: null,
      },
      () => {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteVitesses();
      }
    );
    this.activateSubmit();
  }

  handleNext() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  }

  handlePrev() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  }

  enableFinalSubmit(boostID) {
    if (boostID || boostID === 0) {
      this.setState({ enableFinalSubmit: true, boostID: boostID });
    } else this.setState({ enableFinalSubmit: false });
  }

  async getMarques() {
    const { category, subCategory, marque } = this.state;
    if (
      (category && category.marques !== null && category.marques !== '') ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '')
    ) {
      const idsMarques = subCategory
        ? subCategory.marques
          ? subCategory.marques
          : category.marques
        : category.marques
        ? category.marques
        : null;
      let url = process.env.REACT_APP_API_URL + constants.getMarques;
      const headers = new Headers({
        'Content-Type': 'application/json',
        accept: '*/*',
      });
      await fetch(`${url}` + `?marques=${idsMarques}`, {
        method: 'POST',
        headers: headers,
      }).then((response) => {
        let marquesData = response.json();
        marquesData.then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ marques: jsonRequest }, () => {
              const marqueObject = jsonRequest.find((item: Marque) => (item.id === marque ? marque.id : null));
              this.setState({ marque: marqueObject });
            });
          }
        });
      });
    }
  }

  getTypesCarburant() {
    /*////////console.log('getTypesCarburant called !');*/
    const { category, subCategory } = this.state;
    /*////////console.log('calleTCdWs : ', calleTCdWs);*/
    if (
      (category && category.marques !== null && category.marques !== '') ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '')
    ) {
      let url = process.env.REACT_APP_API_URL + constants.getTypesCarburant;

      return fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ typesCarburant: jsonRequest });
            //////console.log(jsonRequest);
          }
        });
    }
  }

  getRequestParams(filter, pageSize) {
    let params = {};
    if (filter) {
      params['filter'] = filter;
    }
    if (pageSize) {
      params['size'] = pageSize;
    }
    return params;
  }

  getUserShops() {
    const filter = {
      shopOwnerEmail: String(getUserProfile().email),
      disabled: false,
      moderated: true,
    };

    const params = this.getRequestParams(JSON.stringify(filter), 50);
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    };

    shopService
      .getAll(options)
      .then((value) => {
        const { shops } = value.data;
        this.setState({ userShops: [...shops] });
      })
      .catch((reason) => {
        console.error(reason.message);
      });
  }

  getBoiteVitesses() {
    const { category, subCategory } = this.state;
    if (
      (category && category.marques !== null && category.marques !== '') ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '')
    ) {
      let url = process.env.REACT_APP_API_URL + constants.getBoiteAVitesses;

      return fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ boiteAVitesses: jsonRequest });
            //////console.log(jsonRequest);
          }
        });
    }
  }

  getModeleByMarque(marqueId) {
    /* ////////console.log('getModelesByMarque called !');*/
    /* ////////console.log('marqueId : ', marqueId)*/

    if (marqueId !== null && marqueId > 0) {
      let url = process.env.REACT_APP_API_URL + constants.getModelesByMarque + '?idMarque=' + marqueId;

      return fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ modeles: jsonRequest });
          }
        });
    }
  }

  renderTypeAnnonceService(value) {
    if (value) {
      return (
        <IonGrid>
          <IonRow>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.offer.service'} />
                </IonLabel>
                <IonRadio slot="start" value="offres" />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.demand.service'} />
                </IonLabel>
                <IonRadio slot="start" value="demandes" />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.location.service'} />
                </IonLabel>
                <IonRadio slot="start" value="locations" />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
    return (
      <IonGrid>
        <IonRow>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.offer.service'} />
              </IonLabel>
              <IonRadio slot="start" value="offres" />
            </IonItem>
          </IonCol>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.demand.service'} />
              </IonLabel>
              <IonRadio slot="start" value="demandes" />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  renderTypeAnnonce(value) {
    if (value) {
      return (
        <IonGrid>
          <IonRow>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.offer'} />
                </IonLabel>
                <IonRadio slot="start" value="ventes" />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.demand'} />
                </IonLabel>
                <IonRadio slot="start" value="achats" />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.location'} />
                </IonLabel>
                <IonRadio slot="start" value="locations" />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
    return (
      <IonGrid>
        <IonRow>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.offer'} />
              </IonLabel>
              <IonRadio slot="start" value="ventes" />
            </IonItem>
          </IonCol>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.demand'} />
              </IonLabel>
              <IonRadio slot="start" value="achats" />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  renderItemCategorie(category) {
    const {
      lang,
      surface,
      poids,
      boiteAVitesses,
      marque,
      modele,
      anneeModele,
      kilometrage,
      typeCarburant,
      puissanceFiscale,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;

    //////console.log(typeCarburant);
    const requiredMsg = intl.formatMessage(langFunction.formRequiredholder);
    if (category.haveSurface) {
      return (
        <IonCol size={'12'}>
          <IonItem lines={'full'}>
            <div className={'label-container'}>
              <IonLabel position="floating">
                <FormattedMessage id={'post.title.surface'} />
              </IonLabel>
            </div>
            <IonInput
              value={surface}
              type={'text'}
              inputMode={'text'}
              name={'surface'}
              maxlength={60}
              placeholder={''}
              required={true}
              clearInput={true}
              onIonChange={(event) => {
                this.setState({ surface: event.detail.value! }, () => {});
                this.activateSubmit();
              }}
            />
          </IonItem>
        </IonCol>
      );
    }

    if (category.haveWeight) {
      return (
        <IonCol size={'12'}>
          <IonItem lines={'full'}>
            <div className={'label-container'}>
              <IonLabel position="floating">
                <FormattedMessage id={'post.title.poids'} />
              </IonLabel>
            </div>
            <IonInput
              value={poids}
              type={'text'}
              inputMode={'text'}
              placeholder={''}
              name={'poids'}
              maxlength={60}
              required={true}
              clearInput={true}
              onIonChange={(event) => {
                this.setState({ poids: event.detail.value! }, () => {});
                this.activateSubmit();
              }}
            />
          </IonItem>
        </IonCol>
      );
    }
    if (category.marques !== null && category.marques !== '') {
      // @ts-ignore
      const { intl } = this.props;
      const { marques, modeles, typesCarburant, boiteVitesse } = this.state;
      return (
        <IonRow className={`card mt-3`}>
          <IonText>{this.showError('marque', requiredMsg)}</IonText>
          {marques.length > 0 && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={marques.filter((item: any, index: number) => {
                    return item.id >= 1;
                  })}
                  onChange={(event, values: any) => {
                    this.setState({ marque: values ? values : null, modele: null }, () => {
                      this.getModeleByMarque(values ? values.id : 0);
                      this.activateSubmit();
                    });
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) => (option ? (lang === 'fr' ? option.titreFr : option.titreAr) : '')}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.marque'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={marque}
                />
              </IonItem>
            </IonCol>
          )}
          {!category.forAnimal && modeles.length > 0 && <IonText>{this.showError('modele', requiredMsg)}</IonText>}{' '}
          {!category.forAnimal && modeles.length > 0 && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={modeles.filter((item: any, index: number) => {
                    return item.id >= 1;
                  })}
                  onChange={(event, values: any) => {
                    this.setState({ modele: values ? values : null }, () => {
                      this.activateSubmit();
                    });
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) => (option ? (lang === 'fr' ? option.titreFr : option.titreAr) : '')}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.modele'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={modele}
                />
              </IonItem>
            </IonCol>
          )}{' '}
          {!category.forAnimal && <IonText>{this.showError('anneeModele', requiredMsg)}</IonText>}{' '}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={anneeModele}
                  type={'text'}
                  inputMode={'text'}
                  name={'anneeModele'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.anneeModele)}
                  onIonChange={(event) => {
                    this.setState({ anneeModele: event.detail.value! }, () => {
                      this.activateSubmit();
                    });
                  }}
                />
              </IonItem>
            </IonCol>
          )}{' '}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={kilometrage}
                  type={'text'}
                  inputMode={'text'}
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  name={'kilometrage'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.kilometrage)}
                  onIonChange={(event) => {
                    this.setState({ kilometrage: event.detail.value! }, () => {
                      this.activateSubmit();
                    });
                  }}
                />
              </IonItem>
            </IonCol>
          )}{' '}
          {!category.forAnimal && <IonText>{this.showError('typeCarburant', requiredMsg)}</IonText>}{' '}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={typesCarburant}
                  onChange={(event, values: any) => {
                    this.setState({ typeCarburant: values !== null ? values : null }, () => {
                      this.activateSubmit();
                    });
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) =>
                    option ? (
                      lang === 'fr' ? (
                        option.titreFr
                      ) : (
                        option.titreAr
                      )
                    ) : (
                      <FormattedMessage id={'post.title.typeCarburant'} />
                    )
                  }
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.typeCarburant'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={typeCarburant}
                />
              </IonItem>
            </IonCol>
          )}{' '}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={puissanceFiscale}
                  type={'text'}
                  inputMode={'text'}
                  name={'puissanceFiscale'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.puissanceFiscale)}
                  onIonChange={(event) => {
                    this.setState({ puissanceFiscale: event.detail.value! }, () => {
                      this.activateSubmit();
                    });
                  }}
                />
              </IonItem>
            </IonCol>
          )}{' '}
          {!category.forAnimal && <IonText>{this.showError('boiteVitesse', requiredMsg)}</IonText>}{' '}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={boiteAVitesses.filter((item: string, index: number) => {
                    return item !== '';
                  })}
                  onChange={(event, values: any) => {
                    this.setState({ boiteVitesse: values ? values : null }, () => {
                      this.activateSubmit();
                    });
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) =>
                    option ? (
                      lang === 'fr' ? (
                        option.titreFr
                      ) : (
                        option.titreAr
                      )
                    ) : (
                      <FormattedMessage id={'post.title.boiteVitesse'} />
                    )
                  }
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.boiteVitesse'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={boiteVitesse}
                />
              </IonItem>
            </IonCol>
          )}
        </IonRow>
      );
    }
  }

  render() {
    const {
      images,
      name,
      phone,
      login,
      password,
      confirmPassword,
      showPassword,
      haveAccount,
      type,
      category,
      subCategory,
      cities,
      titleFr,
      titleAr,
      description,
      price,
      enableSubmit,
      me,
      lang,
      token,
      errorMessage,
      isSubmitting,
      telephoneUtilisateurAnnonce,
      nomUtilisateurAnnonce,
      emailUtilisateurAnnonce,
      phoneNotFound,
      loginErrorFacebook,
      city,
      shop,
      userShops,
      secondaryPhone,
      activeStep,
      enableFinalSubmit,
      showAlertOnBoostError,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;

    const categoryPlaceholder = intl.formatMessage(langFunction.categoryPlaceholder);
    const subCategoryPlaceholder = intl.formatMessage(langFunction.subCategoryPlaceholder);

    const categoryOptions = this.getCategoriesOptions();
    const subCategoriesOptions = this.getSubCategoryOptions();
    const constantAllCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';

    const requiredMsg = intl.formatMessage(langFunction.formRequiredholder);

    const { showAlert, showAlertExist, showAlertPostExist } = this.state;
    const msgPostExist = lang === 'fr' ? 'Annonce existe déjà' : 'هذا الإعلان موجود، تحقق منه !';
    const annonceModereeMsg = intl.formatMessage(langFunction.annonceModereeMsg);
    const annonceModereeMsgAdmin = intl.formatMessage(langFunction.annonceModereeMsgAdmin);
    const closeMsg = intl.formatMessage(langFunction.close);
    const annonceCategorieExist = intl.formatMessage(langFunction.annoncePostExistMessage);

    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent className={'SendPostPage'}>
          <div className={'container mbm-100'}>
            <IonAlert
              isOpen={showAlert}
              message={
                this.checkUserRole('admin') ||
                this.checkUserRole('moderateur') ||
                this.checkUserRole('editeur') ||
                this.checkUserRole('commercial')
                  ? '<img src = "./assets/check.gif" > ' + annonceModereeMsgAdmin
                  : '<img src = "./assets/check.gif" > ' + annonceModereeMsg
              }
              cssClass={'success-alert'}
              buttons={[
                {
                  text: closeMsg,
                  role: 'cancel',
                  handler: () => {
                    this.setState({ showAlert: false, isSubmitting: false });
                  },
                },
              ]}
              onDidDismiss={() => {
                {
                  shop
                    ? (window.location.href = `/${lang}/${shop.slug}.htm`)
                    : (window.location.href = '/user/mes-annonces');
                }
              }}
            />
            <IonAlert
              isOpen={showAlertExist}
              message={msgPostExist}
              cssClass={'error-alert'}
              buttons={[
                {
                  text: closeMsg,
                  role: 'cancel',
                  handler: () => {
                    this.setState({ showAlertExist: false, isSubmitting: false });
                  },
                },
              ]}
              onDidDismiss={() => {
                this.setState({ showAlertExist: false, isSubmitting: false });
              }}
            />
            <IonAlert
              isOpen={showAlertPostExist}
              message={`<img src = "./assets/error.gif" class="img-fluid w-25" > <div class="text-wrap mb-2"> ${annonceCategorieExist}</div><div class="text-wrap mb-2">${intl.formatMessage(
                { id: 'post.limit.message' }
              )}</div><div class="text-wrap mb-2">${intl.formatMessage({
                id: 'post.limit.message.contact'
              })}</div><div class="text-wrap">${intl.formatMessage({
                id: 'post.limit.message.end'
              })}</div>`}
              cssClass={'success-alert'}
              buttons={[
                {
                  text: closeMsg,
                  role: 'cancel',
                  handler: () => {
                    this.setState({ showAlertPostExist: false, isSubmitting: false });
                  },
                },
              ]}
              onDidDismiss={() => {
                this.setState({ showAlertPostExist: false, isSubmitting: false });
              }}
            />
            <IonAlert
              cssClass={'error-alert'}
              isOpen={showAlertOnBoostError}
              onDidDismiss={() => {
                this.setState({ showAlertOnBoostError: false, isSubmitting: false });
                shop
                  ? (window.location.href = `/${lang}/${shop.slug}.htm`)
                  : (window.location.href = '/user/mes-annonces');
              }}
              message={`<div>${intl.formatMessage(
                  { id: 'boost.create.message' }
              )}</div><div><a href="/paiement-infos">${intl.formatMessage(
                  { id: 'boost.create.payment.link' }
              )}</a></div>`}
              buttons={[
                {
                  text: intl.formatMessage(langFunction.close),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                    this.setState({ showAlertOnBoostError: false, isSubmitting: false });
                  },
                },
              ]}
            />
            <Radium.StyleRoot>
              <StylesProvider jss={jss}>
                <IonGrid>
                  {/*<IonRow>*/}
                  {/*    <IonCol size={'12'} className={'text-center'}>*/}
                  {/*        <h2><FormattedMessage id={'contact.title'}/></h2>*/}
                  {/*        <p><FormattedMessage id={'contact.subtitle'}/></p>*/}
                  {/*    </IonCol>*/}
                  {/*</IonRow>*/}
                  <IonRow className={'justify-content-center'}>
                    <IonCol size={'12'} sizeMd={'6'}>
                      <Stepper activeStep={activeStep} orientation={isPlatform('desktop') ? 'horizontal' : 'vertical'}>
                        <Step>
                          <StepLabel
                            optional={
                              <Typography variant="caption">
                                <FormattedMessage id={'form.step.one.subtitle'} />
                              </Typography>
                            }
                          >
                            <FormattedMessage id={'form.step.one'} />
                          </StepLabel>
                        </Step>
                        <Step>
                          <StepLabel
                            optional={
                              <Typography variant="caption">
                                <FormattedMessage id={'form.step.two.subtitle'} />
                              </Typography>
                            }
                          >
                            <FormattedMessage id={'form.step.two'} />
                          </StepLabel>
                        </Step>
                      </Stepper>
                    </IonCol>
                  </IonRow>
                  {activeStep === 0 && (
                    <IonRow>
                      <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                        <div className={'SendPostForm'}>
                          <IonGrid>
                            <IonRow className={`card mt-3`}>
                              <IonCol size={'12'} className={'card-header'}>
                                <h4 className={'card-title'}>
                                  <FormattedMessage id={'post.info'} />
                                </h4>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem className="category-item">
                                  <div className="category-label-text">
                                    <IonLabel className={'category-label'} position="stacked">
                                      <FormattedMessage id={'form.category'} />
                                    </IonLabel>
                                    <IonText>{this.showError('category', requiredMsg)}</IonText>
                                  </div>
                                  <div className="category-select-container w-100 mb-1">
                                    <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                                      <CssTextField
                                        value={
                                          category
                                            ? lang === 'fr'
                                              ? category.urlFrancais
                                              : category.urlArabe
                                            : constantAllCategories
                                        }
                                        id="outlined-textarea"
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              categoryC: e.target.value,
                                              subCategory: null,
                                              modeles: [],
                                              marques: [],
                                            },
                                            () => {
                                              this.activateSubmit();
                                            }
                                          );
                                        }}
                                        select
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      >
                                        {lang === 'fr' ? (
                                          <MenuItem key={0} value="a_vendre" onClick={() => this.handleNone()}>
                                            <ListItemText primary={categoryPlaceholder} />
                                          </MenuItem>
                                        ) : (
                                          <MenuItem key={1} value="للبيع" onClick={() => this.handleNone()}>
                                            <ListItemText primary={categoryPlaceholder} />
                                          </MenuItem>
                                        )}
                                        {categoryOptions
                                          ? categoryOptions.map((item) => {
                                              return (
                                                <MenuItem
                                                  style={styleMenuItem(item.sousCategorie)}
                                                  dense
                                                  value={item.value}
                                                  key={item.id}
                                                  onClick={() => this.handleClickCategory(item.id)}
                                                >
                                                  {item.sousCategorie === 0 && (
                                                    <ListItemAvatar>
                                                      <Avatar style={styleAvatar(item.sousCategorie)}>
                                                        <picture>
                                                          <source
                                                            type="image/webp"
                                                            srcSet={`${process.env.REACT_APP_API_URL}${
                                                              constants.pictures
                                                            }/categories/${item.nomImage.replace(
                                                              '.jpeg',
                                                              '.webp'
                                                            )}?small=true`}
                                                          />
                                                          <img
                                                            loading="lazy"
                                                            src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`}
                                                            alt={item.value}
                                                          />
                                                        </picture>
                                                      </Avatar>
                                                    </ListItemAvatar>
                                                  )}
                                                  <ListItemText primary={item.label} />
                                                </MenuItem>
                                              );
                                            })
                                          : null}
                                      </CssTextField>
                                    </ThemeProvider>
                                  </div>
                                </IonItem>
                                {category && subCategoriesOptions.length > 0 && (
                                  <IonItem className="category-item">
                                    <div className="category-label-text">
                                      <IonLabel className={'category-label'} position="stacked">
                                        <FormattedMessage id={'form.subCategory'} />
                                      </IonLabel>
                                    </div>
                                    <div className="category-select-container w-100 mb-1">
                                      <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                                        <CssTextField
                                          value={
                                            subCategory
                                              ? lang === 'fr'
                                                ? subCategory.urlFrancais
                                                : subCategory.urlArabe
                                              : constantAllCategories
                                          }
                                          id="outlined-textarea"
                                          onChange={(e) => {
                                            this.setState({ categoryC: e.target.value }, () => {
                                              this.activateSubmit();
                                            });
                                          }}
                                          select
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        >
                                          {lang === 'fr' ? (
                                            <MenuItem
                                              key={0}
                                              value="a_vendre"
                                              onClick={() => this.setState({ subCategory: null })}
                                            >
                                              <ListItemText primary={subCategoryPlaceholder} />
                                            </MenuItem>
                                          ) : (
                                            <MenuItem
                                              key={1}
                                              value="للبيع"
                                              onClick={() => this.setState({ subCategory: null })}
                                            >
                                              <ListItemText primary={subCategoryPlaceholder} />
                                            </MenuItem>
                                          )}
                                          {subCategoriesOptions
                                            ? subCategoriesOptions.map((item) => {
                                                return (
                                                  <MenuItem
                                                    style={styleMenuItem(item.sousCategorie)}
                                                    dense
                                                    value={item.value}
                                                    key={item.id}
                                                    onClick={() => this.handleClickSubCategory(item.id)}
                                                  >
                                                    <ListItemAvatar>
                                                      <Avatar
                                                        style={{ backgroundColor: 'var(--ion-color-medium-contrast)' }}
                                                      >
                                                        <picture>
                                                          <source
                                                            type="image/webp"
                                                            srcSet={`${process.env.REACT_APP_API_URL}${
                                                              constants.pictures
                                                            }/categories/${item.nomImage.replace(
                                                              '.jpeg',
                                                              '.webp'
                                                            )}?small=true`}
                                                          />
                                                          <img
                                                            loading="lazy"
                                                            src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`}
                                                            alt={item.value}
                                                          />
                                                        </picture>
                                                      </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.label} />
                                                  </MenuItem>
                                                );
                                              })
                                            : null}
                                        </CssTextField>
                                      </ThemeProvider>
                                    </div>
                                  </IonItem>
                                )}                                
                                {userShops?.length > 0 && (
                                  <IonCol size={'12'}>
                                    <IonItem>
                                      <Autocomplete
                                        className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                                        options={userShops}
                                        onChange={(event, value: any) => {
                                          this.setState({ shop: value ?? null }, () => {
                                            this.activateSubmit();
                                          });
                                        }}
                                        noOptionsText={<FormattedMessage id={'no.option'} />}
                                        size={'small'}
                                        popupIcon={
                                          <IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />
                                        }
                                        openOnFocus={true}
                                        forcePopupIcon={true}
                                        closeIcon={
                                          <IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />
                                        }
                                        getOptionLabel={(option) =>
                                          option ? option.name : <FormattedMessage id={'shop.form.choose.label'} />
                                        }
                                        style={{ width: '100%' }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={<FormattedMessage id={'shop.form.choose.label'} />}
                                            margin="normal"
                                            variant="standard"
                                          />
                                        )}
                                        value={shop}
                                      />
                                    </IonItem>
                                  </IonCol>
                                )}
                                {category !== null && (
                                  <IonCol size={'12'}>
                                    <IonRadioGroup
                                      value={type}
                                      onIonChange={(e) => {
                                        this.setState({ type: e.detail.value }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                    >
                                      <IonListHeader>
                                        <IonLabel>
                                          <FormattedMessage id={'post.type'} />
                                        </IonLabel>
                                      </IonListHeader>
                                      {category.id === 117 ||
                                      category.id === 116 ||
                                      category.id === 130 ||
                                      category.id === 131
                                        ? this.renderTypeAnnonceService(
                                            subCategory ? subCategory.forRental : category.forRental
                                          )
                                        : this.renderTypeAnnonce(
                                            subCategory ? subCategory.forRental : category.forRental
                                          )}
                                    </IonRadioGroup>
                                  </IonCol>
                                )}
                              </IonCol>
                              {/*<IonCol size={'12'}>
                                                    <IonItem className={'ion-radio-container'} lines={'full'}>
                                                        <IonToggle
                                                            checked={urgent}
                                                            onIonChange={e => {
                                                                this.setState({ urgent: e.detail.checked }, () => {
                                                                    this.activateSubmit();
                                                                });
                                                            }} />
                                                       <IonLabel>
                                                            <FormattedMessage id={'post.urgent'} />
                                                        </IonLabel>
                                                    </IonItem>
                                                </IonCol>*/}
                              {((lang === 'fr' && (this.checkUserRole('user') || token === null)) ||
                                (!this.checkUserRole('user') && token !== null)) && (
                                <IonCol size={'12'}>
                                  <IonItem lines={'full'}>
                                    <div className={'label-container'}>
                                      <IonLabel position="floating">
                                        {category !== null && category.id === 117 ? (
                                          <FormattedMessage id={'post.title.veterinaire.1'} />
                                        ) : (
                                          <FormattedMessage id={'post.title.1'} />
                                        )}
                                      </IonLabel>
                                      <IonText>{this.showError('titleFr', requiredMsg)}</IonText>
                                    </div>
                                    <IonInput
                                      value={titleFr}
                                      type={'text'}
                                      inputMode={'text'}
                                      name={'name'}
                                      maxlength={60}
                                      placeholder={intl.formatMessage(
                                        category !== null && category.id === 117
                                          ? langFunction.postTitle1VeterinairePlaceholder
                                          : langFunction.postTitle1Placeholder
                                      )}
                                      required={true}
                                      clearInput={true}
                                      onIonChange={(event) => {
                                        this.setState({ titleFr: event.detail.value }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                              )}
                              {((lang === 'ar' && (this.checkUserRole('user') || token === null)) ||
                                (!this.checkUserRole('user') && token !== null)) && (
                                <IonCol size={'12'}>
                                  <IonItem lines={'full'}>
                                    <div className={'label-container'}>
                                      <IonLabel position="floating">
                                        {category !== null && category.id === 117 ? (
                                          <FormattedMessage id={'post.title.veterinaire.2'} />
                                        ) : (
                                          <FormattedMessage id={'post.title.2'} />
                                        )}
                                      </IonLabel>
                                      <IonText>{this.showError('titleAr', requiredMsg)}</IonText>
                                    </div>
                                    <IonInput
                                      value={titleAr}
                                      type={'text'}
                                      inputMode={'text'}
                                      name={'name'}
                                      maxlength={60}
                                      placeholder={intl.formatMessage(
                                        category !== null && category.id === 117
                                          ? langFunction.postTitle2VeterinairePlaceholder
                                          : langFunction.postTitle2Placeholder
                                      )}
                                      required={true}
                                      clearInput={true}
                                      onIonChange={(event) => {
                                        this.setState({ titleAr: event.detail.value! }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                              )}
                              <IonCol size={'12'}>
                                <IonItem lines={'full'}>
                                  <div className={'label-container'}>
                                    <IonLabel position="stacked">
                                      {category !== null && category.id === 117 ? (
                                        <FormattedMessage id={'post.description.veterinaire'} />
                                      ) : (
                                        <FormattedMessage id={'post.description'} />
                                      )}
                                    </IonLabel>
                                    <IonText>{this.showError('description', requiredMsg)}</IonText>
                                  </div>
                                  <ReactQuill
                                    className={'w-100'}
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={description}
                                    placeholder={intl.formatMessage(
                                      category !== null && category.id === 117
                                        ? langFunction.postDescriptionVeterinairPlaceholder
                                        : langFunction.postDescriptionPlaceholder
                                    )}
                                    onChange={(value) => {
                                      this.setState({ description: value }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem lines={'full'}>
                                  <div className={'label-container'}>
                                    <IonLabel position="floating" style={{ overflow: 'visible' }}>
                                      <FormattedMessage id={'post.price'} />
                                    </IonLabel>
                                    <small className={'text-info'}>
                                      <FormattedMessage id={'post.price.info'} />
                                    </small>
                                  </div>
                                  <IonInput
                                    value={price}
                                    type={'number'}
                                    min={'0'}
                                    step={'1'}
                                    inputMode={'text'}
                                    name={'name'}
                                    placeholder={intl.formatMessage(langFunction.postPricePlaceholder)}
                                    required={true}
                                    clearInput={true}
                                    onIonChange={(event) => {
                                      this.setState({ price: parseFloat(event.detail.value) }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                </IonItem>
                              </IonCol>

                              {category !== null && this.renderItemCategorie(subCategory ? subCategory : category)}
                              <IonCol size={'12'}>
                                <IonItem lines={'full'}>
                                  <div className={'label-container-city'}>
                                    <IonText>{this.showError('city', requiredMsg)}</IonText>
                                    <Autocomplete
                                      className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                                      options={cities.filter((item: City, index: number) => {
                                        return item.id >= 1;
                                      })}
                                      onChange={(event, values: any) => {
                                        this.setState(
                                          {
                                            city: values ? values.id : null,
                                          },
                                          () => {
                                            this.activateSubmit();
                                          }
                                        );
                                      }}
                                      noOptionsText={<FormattedMessage id={'no.option'} />}
                                      size={'small'}
                                      popupIcon={
                                        <IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />
                                      }
                                      openOnFocus={true}
                                      forcePopupIcon={true}
                                      closeIcon={
                                        <IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />
                                      }
                                      getOptionLabel={(option) =>
                                        option
                                          ? (lang === 'fr' ? option.nomFr : option.nomArab)
                                              .replace(' -', '')
                                              .replace(' –', '')
                                          : ''
                                      }
                                      style={{ width: '100%' }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={<FormattedMessage id={'post.city'} />}
                                          margin="normal"
                                          variant="standard"
                                        />
                                      )}
                                    />
                                  </div>
                                </IonItem>
                              </IonCol>
                              {token && phoneNotFound ? (
                                <IonCol size={'12'}>
                                  <IonItem>
                                    <div className={'label-container'}>
                                      <IonLabel position={'stacked'}>
                                        <FormattedMessage id={'form.phone'} />
                                      </IonLabel>
                                      <IonText>{this.showError('phone', requiredMsg)}</IonText>
                                    </div>
                                    <PhoneInput
                                      labels={lang === 'fr' ? fr : ar}
                                      international={false}
                                      countryOptionsOrder={['MA', 'FR', '|']}
                                      defaultCountry="MA"
                                      placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                      value={phone}
                                      onChange={(value) => {
                                        this.setState({ phone: value }, () => this.activateSubmit());
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                              ) : null}
                            </IonRow>
                            {!token ? (
                              <IonRow className={`card mt-3`}>
                                <IonCol size={'12'} className={token ? 'display' : 'card-header'}>
                                  <h4 className={'card-title'}>
                                    <FormattedMessage id={'post.info.perso'} />
                                  </h4>
                                </IonCol>

                                <IonCol size={'12'} className={token ? 'display' : ''}>
                                  {/* temp action
                                {isPlatform('ios') && isPlatform('capacitor') ? null : (
                                  <IonCol className={'facebook-column'} size={'12'}>
                                    <button
                                      className={'btn-facebook'}
                                      onClick={() => {
                                        isPlatform('android') || isPlatform('ios')
                                          ? this.fbSignInMobile()
                                          : this.fbSignInDesktop();
                                      }}
                                    >
                                      <IonIcon
                                        icon={logoFacebook}
                                        style={lang === 'fr' ? { float: 'left' } : { float: 'right' }}
                                      />
                                      <div>
                                        <FormattedMessage id={'sign.in.account.facebook'} />
                                      </div>
                                    </button>
                                  </IonCol>
                                )}
                                <div className="separator">
                                  <FormattedMessage id={'or'} />
                                </div>*/}
                                  <IonRadioGroup
                                    value={haveAccount ? 'true' : 'false'}
                                    onIonChange={(e) => {
                                      this.setState({ haveAccount: e.detail.value === 'true' }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  >
                                    <IonListHeader>
                                      <IonLabel>
                                        <FormattedMessage id={'post.have.account'} />
                                      </IonLabel>
                                    </IonListHeader>
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol size={'6'}>
                                          <IonItem className={'ion-radio-container'} lines={'full'}>
                                            <IonLabel>
                                              <FormattedMessage id={'post.have.account.yes'} />
                                            </IonLabel>
                                            <IonRadio slot="start" value="true" />
                                          </IonItem>
                                        </IonCol>
                                        <IonCol size={'6'}>
                                          <IonItem className={'ion-radio-container'} lines={'full'}>
                                            <IonLabel>
                                              <FormattedMessage id={'post.have.account.no'} />
                                            </IonLabel>
                                            <IonRadio slot="start" value="false" />
                                          </IonItem>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonRadioGroup>
                                </IonCol>
                                {!haveAccount ? (
                                  <IonRow>
                                    <IonCol size={'12'}>
                                      <IonRadioGroup
                                        value={me}
                                        onIonChange={(e) => {
                                          this.setState({ me: e.detail.value }, () => {
                                            this.activateSubmit();
                                          });
                                        }}
                                      >
                                        <IonListHeader>
                                          <IonLabel>
                                            <FormattedMessage id={'form.account.create'} />
                                          </IonLabel>
                                        </IonListHeader>
                                        <IonGrid>
                                          <IonRow>
                                            <IonCol sizeLg={'6'} sizeXs={'12'}>
                                              <IonItem className={'ion-radio-container'} lines={'full'}>
                                                <IonLabel>
                                                  <FormattedMessage id={'form.account.create.me'} />
                                                </IonLabel>
                                                <IonRadio slot="start" value="Particulier" />
                                              </IonItem>
                                            </IonCol>
                                            <IonCol sizeLg={'6'} sizeXs={'12'}>
                                              <IonItem className={'ion-radio-container'} lines={'full'}>
                                                <IonLabel>
                                                  <FormattedMessage id={'form.account.create.company'} />
                                                </IonLabel>
                                                <IonRadio slot="start" value="Professionnel" />
                                              </IonItem>
                                            </IonCol>
                                          </IonRow>
                                        </IonGrid>
                                      </IonRadioGroup>
                                    </IonCol>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating">
                                            <FormattedMessage id={'form.name'} />
                                          </IonLabel>
                                          <IonText>{this.showError('name', requiredMsg)}</IonText>
                                        </div>
                                        <IonInput
                                          value={name}
                                          type={'text'}
                                          inputMode={'text'}
                                          name={'name'}
                                          placeholder={intl.formatMessage(langFunction.formNamePlaceholder)}
                                          required={!haveAccount}
                                          clearInput={true}
                                          onIonChange={(event) => {
                                            this.setState({ name: event.detail.value }, () => {
                                              this.activateSubmit();
                                            });
                                          }}
                                        />
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size={'12'}>
                                      <IonItem>
                                        <div className={'label-container'}>
                                          <IonLabel position={'stacked'}>
                                            <FormattedMessage id={'form.phone'} />
                                          </IonLabel>
                                          <IonText>{this.showError('phone', requiredMsg)}</IonText>
                                        </div>
                                        <PhoneInput
                                          labels={lang === 'fr' ? fr : ar}
                                          international={false}
                                          countryOptionsOrder={['MA', 'FR', '|']}
                                          defaultCountry="MA"
                                          placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                          value={phone}
                                          onChange={(value) => {
                                            this.setState({ phone: value }, () => this.activateSubmit());
                                          }}
                                        />
                                      </IonItem>
                                    </IonCol>
                                  </IonRow>
                                ) : null}
                                {haveAccount ? (
                                  <IonRow>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating">
                                            <FormattedMessage id={'form.email'} />
                                          </IonLabel>
                                          <IonText>{this.showError('login', requiredMsg)}</IonText>
                                        </div>
                                        <IonInput
                                          value={login}
                                          type={'email'}
                                          inputMode={'email'}
                                          name={'login'}
                                          placeholder={intl.formatMessage(langFunction.formEmailPlaceholder)}
                                          required={true}
                                          clearInput={true}
                                          onIonInput={(e: any) => this.setState({ login: e.target.value })}
                                          onIonChange={(event) => {
                                            this.setState({ login: event.detail.value }, () => {
                                              this.activateSubmit();
                                              // clear error
                                              this.setState({ errorMessage: null });
                                            });
                                          }}
                                        />
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating">
                                            <FormattedMessage id={'form.password'} />
                                          </IonLabel>
                                          <IonText>{this.showError('password', requiredMsg)}</IonText>
                                        </div>
                                        <div className={'pass-input-container'}>
                                          <IonInput
                                            value={password}
                                            type={!showPassword ? 'password' : 'text'}
                                            inputMode={'text'}
                                            name={'password'}
                                            placeholder={intl.formatMessage(langFunction.formPasswordPlaceholder)}
                                            required={true}
                                            clearInput={true}
                                            onIonInput={(e: any) => this.setState({ password: e.target.value })}
                                            onIonChange={(event) => {
                                              this.setState({ password: event.detail.value }, () => {
                                                this.activateSubmit();
                                                // clear error
                                                this.setState({ errorMessage: null });
                                              });
                                            }}
                                          />
                                          <IonIcon
                                            icon={!showPassword ? eye : eyeOff}
                                            onClick={(event) =>
                                              this.setState({ showPassword: !showPassword }, () => {
                                                this.activateSubmit();
                                              })
                                            }
                                          />
                                        </div>
                                      </IonItem>
                                    </IonCol>
                                  </IonRow>
                                ) : (
                                  <IonRow>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating">
                                            <FormattedMessage id={'form.email'} />
                                          </IonLabel>
                                          <IonText>{this.showError('login', requiredMsg)}</IonText>
                                        </div>
                                        <IonInput
                                          value={login}
                                          type={'email'}
                                          inputMode={'email'}
                                          name={'login'}
                                          placeholder={intl.formatMessage(langFunction.formEmailPlaceholder)}
                                          required={true}
                                          clearInput={true}
                                          onIonInput={(e: any) => this.setState({ login: e.target.value })}
                                          onIonChange={(event) => {
                                            this.setState({ login: event.detail.value }, () => {
                                              this.activateSubmit();
                                              // clear error
                                              this.setState({ errorMessage: null });
                                            });
                                          }}
                                        />
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating">
                                            <FormattedMessage id={'form.password'} />
                                          </IonLabel>
                                          <IonText>{this.showError('password', requiredMsg)}</IonText>
                                        </div>
                                        <div className={'pass-input-container'}>
                                          <IonInput
                                            value={password}
                                            type={!showPassword ? 'password' : 'text'}
                                            inputMode={'text'}
                                            name={'password'}
                                            placeholder={intl.formatMessage(langFunction.formPasswordPlaceholder)}
                                            required={true}
                                            clearInput={true}
                                            onIonInput={(e: any) => this.setState({ password: e.target.value })}
                                            onIonChange={(event) => {
                                              this.setState({ password: event.detail.value }, () => {
                                                this.activateSubmit();
                                                // clear error
                                                this.setState({ errorMessage: null });
                                              });
                                            }}
                                          />
                                          <IonIcon
                                            icon={!showPassword ? eye : eyeOff}
                                            onClick={(event) =>
                                              this.setState({ showPassword: !showPassword }, () => {
                                                this.activateSubmit();
                                              })
                                            }
                                          />
                                        </div>
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size={'12'}>
                                      <IonItem lines={'full'}>
                                        <div className={'label-container'}>
                                          <IonLabel position="floating" className="ion-label">
                                            <FormattedMessage id={'form.password.confirm'} />
                                          </IonLabel>
                                          <IonText>{this.showError('confirmPassword', requiredMsg)}</IonText>
                                        </div>
                                        <IonInput
                                          value={confirmPassword}
                                          type={!showPassword ? 'password' : 'text'}
                                          inputMode={'text'}
                                          name={'repeatPassword'}
                                          placeholder={intl.formatMessage(langFunction.formPasswordConfirmPlaceholder)}
                                          required={!haveAccount}
                                          clearInput={true}
                                          onIonChange={(event) => {
                                            this.setState({ confirmPassword: event.detail.value }, () => {
                                              this.activateSubmit();
                                            });
                                          }}
                                        />
                                      </IonItem>
                                    </IonCol>
                                  </IonRow>
                                )}
                              </IonRow>
                            ) : null}
                          </IonGrid>
                          {token != null &&
                          (this.checkRole('admin') || this.checkRole('moderateur') || this.checkRole('editeur')) ? (
                            <IonGrid>
                              <IonRow className={`card mt-3`}>
                                <IonCol size={'12'} className={'card-header'}>
                                  <h4 className={'card-title'}>
                                    <FormattedMessage id={'post.info.user'} />
                                  </h4>
                                </IonCol>
                                <IonCol size={'12'}>
                                  <IonItem lines={'full'}>
                                    <div className={'label-container'}>
                                      <IonLabel position="floating">
                                        <FormattedMessage id={'post.info.user.nom'} />
                                      </IonLabel>
                                      <IonText>{this.showError('nomUtilisateurAnnonce', requiredMsg)}</IonText>
                                    </div>
                                    <IonInput
                                      value={nomUtilisateurAnnonce}
                                      type={'text'}
                                      inputMode={'text'}
                                      name={'name'}
                                      placeholder={intl.formatMessage(langFunction.postNomAnnoncePlaceholder)}
                                      required={true}
                                      clearInput={true}
                                      onIonChange={(event) => {
                                        this.setState({ nomUtilisateurAnnonce: event.detail.value! }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                                <IonCol size={'12'}>
                                  <IonItem>
                                    <div className={'label-container'}>
                                      <IonLabel position={'stacked'}>
                                        <FormattedMessage id={'post.info.user.telephone'} />
                                      </IonLabel>
                                      <IonText>{this.showError('telephoneUtilisateurAnnonce', requiredMsg)}</IonText>
                                    </div>
                                    <PhoneInput
                                      labels={lang === 'fr' ? fr : ar}
                                      international={false}
                                      countryOptionsOrder={['MA', 'FR', '|']}
                                      defaultCountry="MA"
                                      placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                      value={telephoneUtilisateurAnnonce}
                                      onChange={(value) => {
                                        this.setState({ telephoneUtilisateurAnnonce: value }, () =>
                                          this.activateSubmit()
                                        );
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                                <IonCol size={'12'}>
                                  <IonItem>
                                    <div className={'label-container'}>
                                      <IonLabel position={'stacked'}>
                                        <FormattedMessage id={'post.info.user.secondary.phone'} />
                                      </IonLabel>
                                      <IonText>{this.showError('secondaryPhone', requiredMsg)}</IonText>
                                    </div>
                                    <PhoneInput
                                      labels={lang === 'fr' ? fr : ar}
                                      international={false}
                                      countryOptionsOrder={['MA', 'FR', '|']}
                                      defaultCountry="MA"
                                      placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                      value={secondaryPhone}
                                      onChange={(value) => {
                                        this.setState({ secondaryPhone: value }, () => this.activateSubmit());
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                                <IonCol size={'12'}>
                                  <IonItem lines={'full'}>
                                    <div className={'label-container'}>
                                      <IonLabel position="floating">
                                        <FormattedMessage id={'post.info.user.email'} />
                                      </IonLabel>
                                      <IonText>{this.showError('emailUtilisateurAnnonce', requiredMsg)}</IonText>
                                    </div>
                                    <IonInput
                                      value={emailUtilisateurAnnonce}
                                      type={'text'}
                                      inputMode={'text'}
                                      name={'name'}
                                      placeholder={intl.formatMessage(langFunction.postEmailPlaceholder)}
                                      required={true}
                                      clearInput={true}
                                      onIonChange={(event) => {
                                        this.setState({ emailUtilisateurAnnonce: event.detail.value! }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                    />
                                  </IonItem>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          ) : null}
                          <IonGrid>
                            <IonRow>
                              <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'} className={'send-button'}>
                                {loginErrorFacebook && (
                                  <CreateAnimation
                                    duration={1000}
                                    easing={'ease-in'}
                                    play={true}
                                    fromTo={[
                                      {
                                        property: 'opacity',
                                        fromValue: '0',
                                        toValue: '1',
                                      },
                                    ]}
                                  >
                                    <div className={'alert alert-danger'}>
                                      <IonIcon icon={alertCircle} size={'large'} />
                                      <FormattedMessage id={'sign.in.error.facebook'} />
                                    </div>
                                  </CreateAnimation>
                                )}
                                {errorMessage && (
                                  <CreateAnimation
                                    duration={1000}
                                    easing={'ease-in'}
                                    play={true}
                                    fromTo={[
                                      {
                                        property: 'opacity',
                                        fromValue: '0',
                                        toValue: '1',
                                      },
                                    ]}
                                  >
                                    <div className={'alert alert-danger'}>
                                      <IonIcon icon={alertCircle} size={'large'} />
                                      <FormattedMessage id={errorMessage} />
                                    </div>
                                  </CreateAnimation>
                                )}
                                <div className="d-flex justify-content-center">
                                  <IonButton
                                    className={'w-100'}
                                    color={'primary'}
                                    onClick={this.handleNext}
                                    disabled={!enableSubmit}
                                  >
                                    <IonIcon icon={arrowForwardOutline} slot={'start'} />
                                    <FormattedMessage id={'form.step.next'} />
                                  </IonButton>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      </IonCol>
                      <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                        <GallerySelector
                          value={images}
                          shop={shop}
                          onChange={(images: Array<string>) => {
                            this.setState({ images: images }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                      </IonCol>
                      <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'} className={'end-send-button'}>
                        {loginErrorFacebook && (
                          <CreateAnimation
                            duration={1000}
                            easing={'ease-in'}
                            play={true}
                            fromTo={[
                              {
                                property: 'opacity',
                                fromValue: '0',
                                toValue: '1',
                              },
                            ]}
                          >
                            <div className={'alert alert-danger'}>
                              <IonIcon icon={alertCircle} size={'large'} />
                              <FormattedMessage id={'sign.in.error.facebook'} />
                            </div>
                          </CreateAnimation>
                        )}
                        {errorMessage && (
                          <CreateAnimation
                            duration={1000}
                            easing={'ease-in'}
                            play={true}
                            fromTo={[
                              {
                                property: 'opacity',
                                fromValue: '0',
                                toValue: '1',
                              },
                            ]}
                          >
                            <div className={'alert alert-danger'}>
                              <IonIcon icon={alertCircle} size={'large'} />
                              <FormattedMessage id={errorMessage} />
                            </div>
                          </CreateAnimation>
                        )}
                        <div className="d-flex justify-content-center">
                          <IonButton
                            className={'w-100'}
                            color={'primary'}
                            onClick={this.handleNext}
                            disabled={!enableSubmit}
                          >
                            <IonIcon icon={arrowForwardOutline} slot={'start'} />
                            <FormattedMessage id={'form.step.next'} />
                          </IonButton>
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
                  {activeStep === 1 && (
                    <IonRow className={'justify-content-center'}>
                      <IonCol size={'12'} sizeMd={'8'}>
                        <BoostAnnonceForm intl={intl} lang={lang} onEnableSubmit={this.enableFinalSubmit} />
                        <div className="d-flex justify-content-between">
                          <IonButton className={'w-75'} color={'secondary'} onClick={this.handlePrev}>
                            <IonIcon icon={arrowBackOutline} slot={'start'} />
                            <FormattedMessage id={'form.step.back'} />
                          </IonButton>
                          <IonButton
                            className={'w-75'}
                            color={'primary'}
                            onClick={this.checkIfExistBeforeSubmit}
                            disabled={!enableFinalSubmit || isSubmitting}
                          >
                            <IonIcon icon={sendOutline} slot={'start'} />
                            <FormattedMessage id={'form.send'} />
                          </IonButton>
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </StylesProvider>
            </Radium.StyleRoot>
          </div>
          <Footer copyrightOnly />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Déposer une annonce</title>
      </Helmet>
    );
  }
}

// @ts-ignore
export default injectIntl(SendPost);

interface Props extends FlahaProps {
}

interface State extends FlahaState {
    images: Array<string>;
    categories: Array<Category>;
    category: Category | null;
    subCategory?: Category | null;
    categoryC?: string | null | undefined;
    cities: Array<City>;
    city: City | null;
    type: 'ventes' | 'achats' | 'locations' | 'offres' | 'demandes';
    urgent: boolean;
    titleFr: string;
    titleAr: string;
    description: string;
    price: number;
    haveAccount: boolean;
    name: string;
    phone: any;
    login: string;
    password: string;
    confirmPassword: string;
    showPassword: boolean;
    enableSubmit: boolean;
    me: 'Particulier' | 'Professionnel';
    token: string | null;
    errorMessage: string;
    isSubmitting: boolean;
    isAdmin: boolean;
    isUser: boolean;
    isUserExist: boolean;
    telephoneUtilisateurAnnonce: any;
    nomUtilisateurAnnonce: string;
    emailUtilisateurAnnonce: string;
    showRequiredMsg: boolean;
    phoneNotFound: boolean;
    loginErrorFacebook: boolean;
    showAlert: boolean;
    showAlertExist: boolean;
    showAlertPostExist: boolean;
    marques: Array<any>;
    modeles: Array<any>;
    typesCarburant: Array<any>;
    boiteAVitesses: Array<any>;
    surface: string;
    poids: string;
    marque: any;
    modele: any;
    anneeModele: string;
    kilometrage: string;
    typeCarburant: any;
    puissanceFiscale: string;
    boiteVitesse: any;
    userShops: Array<Shop>;
    shop: Shop;
    secondaryPhone?: string;
    activeStep: number;
    enableFinalSubmit: boolean;
    boostID: string;
    showAlertOnBoostError: boolean;
}

function checkUserRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
        ? roles.map((row) => {
            if (row.role.toLowerCase() === role) {
                existRole = true;
            }
        })
        : (existRole = false);
    return existRole;
}
