import React, {Component} from "react";
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import './SimpleHeader.scss';
import {IonButtons, IonIcon, isPlatform, IonBackButton, IonMenuToggle} from "@ionic/react";
import {menu, shieldCheckmark, searchOutline} from "ionicons/icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {constants, Events} from "utils";
import {Helmet} from "react-helmet";
import {langFunction} from "lang";

class SimpleHeader extends Component<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);

        this.state={
            lang: "ar",
        }
    }

    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    render() {
        const {lang} = this.state;
        // @ts-ignore
        const {intl} = this.props;
        return (
            <header className={'navbar navbar-expand-lg navbar-light bd-navbar'}>
                {this.metaTags()}
                <div className={'container'}>
                    {isPlatform('ios') ? (
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={'/'} text={intl.formatMessage(langFunction.menuReturn)} color={'dark'}/>
                        </IonButtons>
                    ) : null}
                    {isPlatform('android') ? (
                        <a id={'headerSearchIcon'} href={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`}>
                           <IonIcon size={'large'} icon={searchOutline}/>
                           <span><FormattedMessage id={'menu.search'} /></span>
                        </a>
                    ) : null}
                    <div className={'brand-center mx-auto'}>
                        <a className="navbar-brand mr-0 mr-md-2" href="/" aria-label="Flaha">
                        <picture>
                            <source type='image/webp' srcSet={'assets/Logo_flaha.webp'} />
                            <img loading="lazy" src={'assets/Logo_flaha.svg'} alt={'Flaha.ma'} />
                        </picture>
                        </a>
                        <a className={'headerCheckmark'}>
                            <IonIcon icon={shieldCheckmark} size={'large'}/>
                            <span><FormattedMessage id={'menu.login.connexion'}/></span>
                        </a>
                    </div>
                    <IonMenuToggle menu={'mobileMenu'}>
                        {/*<span className="navbar-toggler-icon d-block"></span>*/}
                        <IonIcon size={'large'} icon={menu}/>
                        <span><FormattedMessage id={'menu'}/></span>
                    </IonMenuToggle>
                </div>
            </header>
        );
    }

    metaTags() {
        const {lang} = this.state;
        return (<Helmet>
            <html lang={lang} dir={lang === 'fr' ? 'LTR' : 'RTL'}/>
            <link rel="shortcut icon" type="image/svg" href="assets/Logo_flaha.svg"/>
        </Helmet>);
    }
}

interface Props extends FlahaProps {
}

interface State extends FlahaState {
}
// @ts-ignore
export default injectIntl(SimpleHeader);
