import { createReducer } from "@reduxjs/toolkit";
import { setShowSearchModal } from "../actions/setShowSearchModalAction";


const showSearchModalRedux = {
    showSearchModal: false,
  };


export const setShowSearchModalReducer = createReducer(
  { showSearchModalRedux },
  {
    [setShowSearchModal.type]: (state, action) => ({
      ...state,
      showSearchModalRedux: { ...action.payload }
    }),
  }
);