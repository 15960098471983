import http from '../http-common';
import { constants } from 'utils';
import { Parametrage } from 'utils/Types/types';

class ParametrageService {
  getSEO(options) {
    return http.get<Parametrage>(constants.getParametrebyCle, options);
  }

  get({ options }: { options: any }) {
    return http.get<Parametrage>(constants.getParametrebyCle, options);
  }
}

export default new ParametrageService();
