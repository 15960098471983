import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { constants, dateFormatter, Events, madFormatter, urlDataFormatter } from 'utils';
import {
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonText,
  isPlatform,
} from '@ionic/react';
import './MainResults.scss';
import { lang, Marque, Post, SearchCriteria } from 'utils/Types/types';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { FormattedMessage, FormattedNumber, injectIntl, IntlShape } from 'react-intl';
import { langFunction } from 'lang';
import { SubSearchForm } from 'components';
import { trackEvent } from '../../tracking';
import { postClicked, postHovered } from '../../tracking/events';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ReactPaginate from 'react-paginate';
import MetaComponent from '../MetaComponent/MetaComponent';
import { AdSlot } from 'react-dfp';
import qs from 'query-string';
import { isProduction } from 'utils/Functions/Functions';
import { eye, location as loc, storefrontOutline, time } from 'ionicons/icons';
import { capitalize, Fade } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import EventTrackingService from '../../services/EventTrackingService';
import StatsService from '../../services/StatsService';
import PostItemSearch from '../PostItemSearch/PostItemSearch';

//const previouslyVisitedPostKey = "@flaha.previouslyVisitedPost"
const cachedStateKey = '@flaha.cachedState';

const BannerMobile = (category) => {
  return (
    <div key={Math.random()} className={'d-flex justify-content-center my-2'}>
      <AdSlot sizes={[[300, 250]]} adUnit={category?.adUnits?.bannerListingPage || 'banner_search_listing_page'} />
    </div>
  );
};

const BannerMobileSecond = (category) => {
  return (
    <div key={Math.random()} className={'d-flex justify-content-center my-2'}>
      <AdSlot
        sizes={[[300, 250]]}
        adUnit={category?.adUnits?.bannerListingPageSecond || 'banner_search_listing_page_second'}
      />
    </div>
  );
};

const BannerMobileThird = (category) => {
  return (
    <div key={Math.random()} className={'d-flex justify-content-center my-2'}>
      <AdSlot
        sizes={[[300, 250]]}
        adUnit={category?.adUnits?.bannerListingPageThird || 'banner_search_listing_page_third'}
      />
    </div>
  );
};

const FadeChild = (props) => {
  return <div {...props}>{props?.children}</div>;
};

class MainResults extends Component<Props, State> {
  private _infiniteScroll: any = null;

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      posts: [],
      page: 0,
      itemCount: 30,
      lang: 'ar',
      lastPage: false,
      totalElements: 0,
      slug: '',
      showLoading: false,
      pageCount: 0,
      slicedSlug: '',
      loading: true,
    };
    this.props.setShowModal(false);
    this.onIonInfinite = this.onIonInfinite.bind(this);
    this.getData = this.getData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  onIonInfinite(event: Event) {
    const item = this.state.itemCount + 2;
    this.setState({ itemCount: item });
    this.getData();
  }

  //   getCategory(){
  //         const {value} = this.state;
  //         // @ts-ignore
  //         let {category} = value;
  //         if (category !== '' && category !== constants.allCategories) {
  //             // @ts-ignore
  //             const url = `${constants.host}${constants.categoryBySlug.replace('__slug__', category)}`;
  //             fetch(url).then((result) => {
  //                 return result.json();
  //             }).then((jsonResult) => {
  //                 //console.log(jsonResult.id);
  //                 this.setState({ categoryIS: this.state.categoryIS+jsonResult.id }, () => {
  //                     //console.log(this.state.categoryIS, 'categoryIS1');
  //                   });
  //                 //console.log(this.state.categoryIS);
  //         }).catch((error) => {
  //                 //console.error(error.message, error);
  //             });
  //         }
  //     }

  getData() {
    //console.log('DataLoading begun')
    const { page, itemCount, posts } = this.state;
    const { lang, regionsRedux, citiesRedux, categoriesRedux, provincesRedux } = this.props;
    const { value } = this.props;
    let url = process.env.REACT_APP_API_URL + constants.posts;
    let data = {
      page: page,
      size: itemCount,
    };
    let filter = {};
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    if (value) {
      let {
        query,
        category,
        region,
        price,
        titleOnly,
        urgentOnly,
        // @ts-ignore
        type,
        marque,
        modele,
        typeCarburant,
        boiteVitesse,
        puissanceFiscale,
        anneeModele,
      } = value;
      let categoryRedux;
      let isCity: boolean;
      let isProvince: boolean;
      if (region && region !== allRegion && regionsRedux && citiesRedux) {
        if (regionsRedux && citiesRedux) {
          const regionRedux =
            lang === 'ar'
              ? regionsRedux.find((item) => item.urlArab === region)
              : regionsRedux.find((item) => item.urlFr === region);
          const cityRedux =
            lang === 'ar'
              ? citiesRedux.find((item) => item.urlArab === region)
              : citiesRedux.find((item) => item.urlFr === region);
          const provinceRedux =
            lang === 'ar'
              ? provincesRedux.find((item) => item.urlArab === region)
              : provincesRedux.find((item) => item.urlFr === region);

          if (regionRedux) {
            region = regionRedux.id;
            isCity = false;
            isProvince = false;
          } else if (cityRedux) {
            region = cityRedux.id;
            isCity = true;
            isProvince = false;
          } else if (provinceRedux) {
            region = provinceRedux.id;
            isCity = false;
            isProvince = true;
          }
        }
      }
      if (category && category !== allCategories && categoriesRedux) {
        if (categoriesRedux) {
          lang === 'ar'
            ? (categoryRedux = categoriesRedux.find((item) => item.urlArabe === category))
            : (categoryRedux = categoriesRedux.find((item) => item.urlFrancais === category));
          category = categoryRedux ? categoryRedux.id : null;
        }
      }
      const minPrice = get(price, 'lower');
      const maxPrice = get(price, 'upper');
      filter = {
        prixMin: !isNil(minPrice) && !isNil(maxPrice) ? minPrice : null,
        prixMax: !isNil(maxPrice) ? maxPrice : null,
        searchText: query !== '' ? query : null,
        categorieId: category !== '' && category !== allCategories ? category : null,
        urgent: urgentOnly || false,
        titleOnly: titleOnly || false,
        regionId: !isCity && !isProvince && region && region !== allRegion ? region : null,
        villeId: isCity && !isProvince ? region : null,
        provinceId: !isCity && isProvince ? region : null,
        typeAnnonce: type && type !== '' ? type : null,
        marque: marque || null,
        modele: modele || null,
        typeCarburant: typeCarburant || null,
        boiteVitesse: boiteVitesse || null,
        puissanceFiscale: puissanceFiscale || null,
        anneeModele: anneeModele || null,
      };
    }
    let formData = urlDataFormatter(data);
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });
    const filterUri = encodeURIComponent(JSON.stringify(filter));

    url = `${url}` + '?filter=' + filterUri + `&${formData.join('&')}`;
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        if (result.status !== 200) {
          return {
            content: [],
            number: page,
            last: true,
          };
        }
        return result.json();
      })
      .then((jsonResult) => {
        if (jsonResult.content) {
          this.props.setSearch(false);
          this.setState(
            {
              // @ts-ignore
              posts: [...posts, ...jsonResult.content],
              page: jsonResult.number + 1,
              lastPage: jsonResult.totalPages,
              totalElements: jsonResult.totalElements,
            },
            () => {
              //console.log(jsonResult.totalElements);
              if (this._infiniteScroll) this._infiniteScroll.complete();
              const { posts, page, lastPage, totalElements, itemCount } = this.state;
              const { value } = this.props;
              localStorage.setItem(
                cachedStateKey,
                JSON.stringify({
                  mainResults: {
                    posts,
                    page,
                    lastPage,
                    totalElements,
                    itemCount,
                  },
                  filter: value,
                })
              );
            }
          );
          return;
        }
        if (this._infiniteScroll) this._infiniteScroll.complete();
      })
      .catch((error) => {
        if (this._infiniteScroll) this._infiniteScroll.complete();
      });
  }

  refreshList() {
    this.setState(
      {
        posts: [],
        loading: true,
        page: 0,
        itemCount: 30,
        lastPage: false,
        totalElements: 0,
      },
      () => {
        //this.props.savePosts([]);
        isPlatform('capacitor') ? this.getData() : this.getDataPage();
      }
    );
  }

  handlePageClick(currentPage) {
    const { value } = this.props;
    const { price, marque, modele, typeCarburant, boiteVitesse, puissanceFiscale, anneeModele } = value;

    const params = {
      mar: marque,
      mod: modele,
      tc: typeCarburant,
      bv: boiteVitesse,
      pf: puissanceFiscale,
      am: anneeModele,
      minP: price?.lower,
      maxP: price?.upper,
      p: currentPage.selected + 1,
    };
    const searchString = qs.stringify(params, { skipNull: true, skipEmptyString: true, sort: false });

    window.location.href = window.location.pathname + `?${searchString}`;
  }

  getDataPage() {
    const { itemCount } = this.state;
    const urlPage = new URL(window.location.href);
    const page = parseInt(urlPage.searchParams.get('p')) - 1;
    const { lang, regionsRedux, citiesRedux, categoriesRedux, provincesRedux } = this.props;
    const { value } = this.props;
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    let url = process.env.REACT_APP_API_URL + constants.posts;
    let data = {
      page: page ? page : 0,
      size: itemCount,
    };
    let filter = {};
    if (value) {
      let {
        query,
        category,
        region,
        price,
        titleOnly,
        urgentOnly,
        // @ts-ignore
        type,
        marque,
        modele,
        typeCarburant,
        boiteVitesse,
        puissanceFiscale,
        anneeModele,
      } = value;
      let categoryRedux;
      let isCity: boolean;
      let isProvince: boolean;
      if (region && region !== allRegion && regionsRedux && citiesRedux && provincesRedux) {
        const regionRedux =
          lang === 'ar'
            ? regionsRedux.find((item) => item.urlArab === region)
            : regionsRedux.find((item) => item.urlFr === region);
        const cityRedux =
          lang === 'ar'
            ? citiesRedux.find((item) => item.urlArab === region)
            : citiesRedux.find((item) => item.urlFr === region);
        const provinceRedux =
          lang === 'ar'
            ? provincesRedux.find((item) => item.urlArab === region)
            : provincesRedux.find((item) => item.urlFr === region);

        if (regionRedux) {
          region = regionRedux.id;
          isCity = false;
          isProvince = false;
        } else if (cityRedux) {
          region = cityRedux.id;
          isCity = true;
          isProvince = false;
        } else if (provinceRedux) {
          region = provinceRedux.id;
          isCity = false;
          isProvince = true;
        }
      }
      if (category && category !== allCategories && categoriesRedux) {
        lang === 'ar'
          ? (categoryRedux = categoriesRedux ? categoriesRedux.find((item) => item.urlArabe === category) : null)
          : (categoryRedux = categoriesRedux ? categoriesRedux.find((item) => item.urlFrancais === category) : null);
        category = categoryRedux ? categoryRedux.id : null;
      }
      const minPrice = get(price, 'lower');
      const maxPrice = get(price, 'upper');
      filter = {
        prixMin: !isNil(minPrice) && !isNil(maxPrice) ? minPrice : null,
        prixMax: !isNil(maxPrice) ? maxPrice : null,
        searchText: query !== '' ? query : null,
        categorieId: category !== '' && category !== allCategories ? category : null,
        urgent: urgentOnly || false,
        titleOnly: titleOnly || false,
        regionId: !isCity && !isProvince && region && region !== allRegion ? region : null,
        villeId: isCity && !isProvince ? region : null,
        provinceId: !isCity && isProvince ? region : null,
        typeAnnonce: type && type !== '' ? type : null,
        marque: marque || null,
        modele: modele || null,
        typeCarburant: typeCarburant || null,
        boiteVitesse: boiteVitesse || null,
        puissanceFiscale: puissanceFiscale || null,
        anneeModele: anneeModele || null,
      };
    }
    let formData = urlDataFormatter(data);
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });
    const filterUri = encodeURIComponent(JSON.stringify(filter));
    url = `${url}` + '?filter=' + filterUri + `&${formData.join('&')}`;
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        if (result.status !== 200) {
          return {
            content: [],
            number: page,
            last: true,
          };
        }
        return result.json();
      })
      .then((jsonResult) => {
        if (jsonResult.content) {
          this.setState(
            {
              // @ts-ignore
              posts: [...jsonResult.content],
              lastPage: jsonResult.totalPages,
              totalElements: jsonResult.totalElements,
              pageCount: jsonResult.totalPages,
              page: page ? page : 0,
            },
            () => {
              const { posts, page, lastPage, totalElements, itemCount } = this.state;
              const { value } = this.props;
              localStorage.setItem(
                cachedStateKey,
                JSON.stringify({
                  mainResults: {
                    posts,
                    page,
                    lastPage,
                    totalElements,
                    itemCount,
                  },
                  filter: value,
                })
              );
              /* setTimeout(() => {
                                                           this.setState({ loading: false });
                                                         }, 500);*/
              this.setState({ loading: false });
            }
          );
          return;
        }
      })

      .catch((error) => {
        console.error(error.message, error);
      });
  }

  componentDidMount() {
    this.props.setShowModal(false);
    const searchClickedRedux = this.props.searchClickedRedux;
    document.addEventListener('ionBackButton', (ev: CustomEvent) => {
      ev.detail.register(10, () => {
        if (!this.props.showModalRedux) {
          window.location.href = '/';
        } else {
          this.props.setShowModal(false);
        }
      });
    });
    isPlatform('capacitor') ? this.getData() : this.getDataPage();
    if (this.props.history.action === 'POP' && !searchClickedRedux) {
      this.props.setSearch(false);
      setTimeout(() => {
        const element = document.getElementById(this.props.scrollPosition + '');
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
          }, 1000);
        }
      }, 2500);
    }
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const hasSearchClicked = !isEqual(prevProps.searchClicked, this.props.searchClicked);
    if (hasSearchClicked) {
      this.refreshList();
    }
    const hasFilterChanged = !isEqual(prevProps.value, this.props.value);
    if (hasFilterChanged) {
      this.refreshList();
    }
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  renderItem(item: Post, index: number) {
    const {
      id,
      titreFrancais,
      titreArabe,
      prix,
      premierePhoto,
      categorie,
      ville,
      slug,
      datePublication,
      shop,
      boostPlanID,
    } = item;

    const { lang } = this.state;
    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
    const villeArab = ville.urlArab;
    const villeFrancais = ville.urlFr;
    const regionArab = ville.province.region.urlArab;
    const regionFr = ville.province.region.urlFr;
    const categorieFrancais = categorie.urlFrancais;
    const categorieArab = categorie.urlArabe;

    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    const { categoriesRedux } = this.props;
    const { value } = this.props;
    let categoryRedux = undefined;
    if (value) {
      // @ts-ignore
      let { category } = value;
      if (category && category !== allCategories && categoriesRedux) {
        lang === 'ar'
          ? (categoryRedux = categoriesRedux ? categoriesRedux.find((item) => item.urlArabe === category) : null)
          : (categoryRedux = categoriesRedux ? categoriesRedux.find((item) => item.urlFrancais === category) : null);
      }
    }
    // indexes where mobile ad is going to be inserted
    const indexes = [2, 13, 21];
    // @ts-ignore
    const { intl } = this.props;
    return (
      <div key={`post-item-${id}`}>
        <PostItemSearch post={item} lang={lang} intl={intl} />
        {isProduction() && !isPlatform('desktop') && index === indexes[0] ? BannerMobile(categoryRedux) : null}
        {isProduction() && !isPlatform('desktop') && index === indexes[1] ? BannerMobileSecond(categoryRedux) : null}
        {isProduction() && !isPlatform('desktop') && index === indexes[2] ? BannerMobileThird(categoryRedux) : null}
      </div>
    );
  }

  render() {
    const { posts, totalElements, loading, slicedSlug, showLoading, page } = this.state;
    const { lang } = this.props;
    const slugProps = {
      // make sure all required component's inputs/Props keys&types match
      slug: slicedSlug,
    };

    return (
      <div>
        {posts.map((item: Post) => this.metaTags(item))}
        {loading ? (
          <div className={'subsearch-container'}>
            <Skeleton className={'mb-1'} variant={'text'} width={'60%'} />
          </div>
        ) : (
          <SubSearchForm value={this.props.value} results={totalElements} lang={lang} />
        )}

        {(loading ? Array.from(new Array(6)) : posts).map((item, index) => {
          {
            return item ? (
              <Fade key={`item-fade-${index}`} timeout={1000} in={true}>
                <FadeChild>{this.renderItem(item, index)}</FadeChild>
              </Fade>
            ) : (
              <Skeleton
                style={{ margin: '10px' }}
                key={`skeleton-${index}`}
                variant={'rect'}
                width={'100%'}
                height={150}
              />
            );
          }
        })}

        {!loading && totalElements <= 0 && (
          <Fade timeout={1000} in={true}>
            <FadeChild>
              <div className="styles_Notfound">
                <img src={'assets/img/no-content.jpg'} alt="not found image" className={'img-fluid'} />
              </div>
              <div className="MessageNotFound">
                <span>
                  <FormattedMessage id={'not.found.message'} />
                </span>
              </div>
            </FadeChild>
          </Fade>
        )}

        {!loading && totalElements > 0 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={0}
            pageRangeDisplayed={9}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
            activeLinkClassName={'active-link'}
            previousClassName={'prev'}
            nextClassName={'next'}
            previousLinkClassName={'prev-link'}
            nextLinkClassName={'next-link'}
            forcePage={page}
          />
        )}
      </div>
    );
  }

  getMarqueValue(id: number) {
    const marques = JSON.parse(localStorage.getItem('@flaha.marques'));
    const { lang } = this.props;
    const marqueObject = marques ? marques.marques.find((item: Marque) => item.id === id) : '';
    if (lang === 'fr') {
      return marqueObject ? marqueObject.titreFr : '';
    } else {
      return marqueObject ? marqueObject.titreAr : '';
    }
  }

  metaTags(post: Post) {
    const { lang } = this.props;
    const {
      id,
      titreArabe,
      titreFrancais,
      description,
      premierePhoto,
      prix,
      nomUtilisateurAnnonce,
      ville,
      categorie,
      slug,
      marque,
      anneeModele,
    } = post;
    const name = lang === 'fr' ? titreFrancais : titreArabe;
    let productionDate;
    let brand;
    let ldType;
    let isCar = false;

    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
    const villeArab = ville ? ville.urlArab : '';
    const villeFrancais = ville ? ville.urlFr : '';
    const regionArab = ville ? ville.province.region.urlArab : '';
    const regionFr = ville ? ville.province.region.urlFr : '';
    const categorieFrancais = categorie ? categorie.urlFrancais : '';
    const categorieArab = categorie ? categorie.urlArabe : '';
    const url =
      lang === 'fr'
        ? `https://flaha.ma/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        : `https://flaha.ma/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;
    const imageUrl = `${
      premierePhoto && premierePhoto.cheminWebp
        ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
        : 'https://flaha.ma/assets/img/no_images.webp'
    }`;

    if (categorie.id === 142 || categorie.id === 143 || categorie.id === 144 || categorie.id === 107) {
      ldType = 'Car';
      if (marque) {
        productionDate = anneeModele ? anneeModele : '';
        brand = this.getMarqueValue(marque) ? this.getMarqueValue(marque) : '';
      }
      isCar = true;
    } else if (categorie.id === 2) {
      ldType = 'Residence';
    } else if (categorie.id === 117) {
      ldType = 'VeterinaryCare';
    } else {
      ldType = 'Product';
    }

    const jsonLdData = {
      '@context': 'http://schema.org',
      '@type': ldType,
      name: name,
      description: description,
      image: imageUrl,
      url: url,
      offers: {
        '@type': 'Offer',
        price: prix,
        priceCurrency: 'MAD',
        url: url,
        seller: { '@type': 'Person', name: nomUtilisateurAnnonce },
      },
    };
    const jsonLdDataCar = {
      '@context': 'http://schema.org',
      '@type': ldType,
      name: name,
      description: description,
      image: imageUrl,
      url: url,
      offers: {
        '@type': 'Offer',
        price: prix,
        priceCurrency: 'MAD',
        url: url,
        seller: { '@type': 'Person', name: nomUtilisateurAnnonce },
      },
      brand,
      productionDate,
    };

    return <MetaComponent key={`meta-${post.id}`} jsonLd={JSON.stringify(isCar ? jsonLdDataCar : jsonLdData)} />;
  }
}

interface Params {
  query: string | null | undefined;
  region: string | null | undefined;
  category: string | null | undefined;
}

interface Props extends RouteComponentProps<Params>, FlahaProps, IntlShape {
  value?: SearchCriteria;
  scrollPosition: { position: number };
  savePosition: (id) => void;
  //globalPosts: { posts: Array<Post> };
  //savePosts: (posts) => void;
  searchClicked: number;
  showModalRedux: boolean;
  setShowModal: (showModal) => void;
  searchClickedRedux: boolean;
  setSearch: (searchClicked) => void;
  regionsRedux: any;
  citiesRedux: any;
  provincesRedux: any;
  categoriesRedux: any;
  lang: lang;
}

interface State extends FlahaState {
  posts: Array<Post>;
  page: number;
  itemCount: number;
  loading: boolean;
  lastPage: boolean;
  totalElements: number;
  slug: string;
  slicedSlug: string;
  showLoading: boolean;
  // categoryIS: number;
  pageCount: number;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    scrollPosition: state.savePosReducer.scrollPosition.position,
    //globalPosts: state.savePostsReducer.globalPosts,
    showModalRedux: state.setShowModalReducer.showModalRedux.showModal,
    searchClickedRedux: state.setSearchReducer.search.searchClickedRedux,
    regionsRedux: state.saveRegionsCategoriesReducer.Regions_Categories.regions,
    citiesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.cities,
    provincesRedux: state.saveProvincesReducer.provinces.provinces,
    categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    savePosition: (id) => dispatch({ type: 'SAVE_POSITION', payload: { position: id } }),
    //savePosts: (posts: Post[]) => dispatch({ type: 'SAVE_POSTS', payload: { posts: posts } }),
    setShowModal: (showModal) => dispatch({ type: 'SET_SHOWMODAL', payload: { showModal: showModal } }),
    setSearch: (searchClickedRedux) =>
      dispatch({
        type: 'SET_SEARCH_CLICKED',
        payload: { searchClickedRedux: searchClickedRedux },
      }),
  };
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainResults));
