import jwtDecode from 'jwt-decode';
import get from 'lodash/get';

interface CustomGAEvent {
  event: string;
  [params: string]: any
};

interface CustomGAEventWithUserInfo extends CustomGAEvent {
    userToken?: string,
    userEmail?: string,
    firstLogin?: string,
    userName?: string,
    userId?: number
};




const getTokenData: (token: string) => {[key: string]: any}  = token => {
    try {
        return jwtDecode(token)
    } catch {
        return undefined
    }
}

declare var ganalytics: {track: Function};

export function trackEvent(event: CustomGAEvent) {
    const userToken = window.localStorage.getItem('@flaha.userToken');
    const tokenData = getTokenData(userToken)
    const eventWithUserInfo: CustomGAEventWithUserInfo = {
        ...event,
        userToken: userToken,
        userEmail: get(tokenData, 'email'),
        firstLogin: get(tokenData, 'firstLogin'),
        userName: get(tokenData, 'name'),
        userId: get(tokenData, 'userId')
    }
    ganalytics.track(eventWithUserInfo);
}