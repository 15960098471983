import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Plugins, registerWebPlugin } from '@capacitor/core';
import {
  CreateAnimation,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  isPlatform,
} from '@ionic/react';
import { Footer, Header, SimpleHeader } from 'components';
import { alertCircle, checkmarkCircleOutline, eye, eyeOff } from 'ionicons/icons';
import React, { Component, FormEvent } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { constants, Events } from 'utils';
import { setUserProfile, setUserToken, validateEmail } from 'utils/Functions/Functions';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import './Login.scss';

const OAUTH2_REDIRECT_URI = 'https://flaha.ma/oauth2/redirect';
const FACEBOOK_AUTH_URL =
  process.env.REACT_APP_API_URL + '/api/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

class Login extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      login: '',
      password: '',
      showPassword: false,
      enableSubmit: false,
      loginError: false,
      loginErrorFacebook: false,
      loginSuccessFacebook: false,
      isSubmitting: false,
    };
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.fbSignInDesktop = this.fbSignInDesktop.bind(this);
    this.fbSignInMobile = this.fbSignInMobile.bind(this);
  }

  activateSubmit() {
    const { login, password } = this.state;
    if (login && login !== '' && validateEmail(login) && password && password !== '') {
      this.setState({ enableSubmit: true });
      return;
    }
    this.setState({ enableSubmit: false });
  }

  onSubmitLogin(event: FormEvent) {
    this.setState({ isSubmitting: true });
    event.preventDefault();

    this.setState({
      loginError: false,
    });
    this.setState({
      loginErrorFacebook: false,
    });

    const url = `${process.env.REACT_APP_API_URL}${constants.login}`;

    const { login, password, lang } = this.state;

    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email: login, password: password }),
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        return request.json();
      })
      .then((jsonRequest) => {
        if (jsonRequest.token) {
          setUserToken(jsonRequest.token);
          let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
          let bearer = 'Bearer ' + jsonRequest.token;
          const headers = new Headers({
            Authorization: bearer,
          });

          fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              return request.json();
            })
            .then((jsonRequest) => {
              if (jsonRequest) {
                setUserProfile(
                  jsonRequest.email,
                  jsonRequest.nom,
                  jsonRequest.telephone,
                  jsonRequest.type,
                  jsonRequest.imageUrl,
                  jsonRequest.admin ? 'true' : 'false',
                  jsonRequest.moderator ? 'true' : 'false',
                  jsonRequest.roles ? jsonRequest.roles : []
                );
                //console.log('jsonRequest.admin : ', jsonRequest.admin);

                lang === 'ar'
                  ? (window.location.href = `/ar/المغرب/للبيع/`)
                  : (window.location.href = `/fr/maroc/a_vendre/`);
              }
            });
        } else {
          this.setState(
            {
              loginError: true,
              isSubmitting: false,
            },
            () => {
              console.error(`Can not login`);
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          {
            loginError: true,
          },
          () => {
            console.error(`Can not login (${error.message})`, error);
          }
        );
      });
  }

  componentDidMount(): void {
    registerWebPlugin(FacebookLogin);
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  fbSignInDesktop() {
    window.localStorage.setItem('@flaha.prevLocation', 'login');
    window.location.href = FACEBOOK_AUTH_URL;
  }

  async fbSignInMobile(): Promise<void> {
    const { lang } = this.state;

    this.setState({
      loginErrorFacebook: false,
    });
    this.setState({
      loginError: false,
    });

    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result && result.accessToken) {
      let url = `${process.env.REACT_APP_API_URL}${constants.loginFacebookApp}`;
      let authToken = result.accessToken.token;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ authToken: authToken }),
        credentials: 'same-origin',
        headers: headers,
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest.token) {
            setUserToken(jsonRequest.token);
            let bearer = 'Bearer ' + jsonRequest.token;
            let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
            const headers = new Headers({
              Authorization: bearer,
            });
            fetch(url, {
              method: 'GET',
              credentials: 'same-origin',
              headers: headers,
            })
              .then((request) => {
                return request.json();
              })
              .then((jsonRequest) => {
                if (jsonRequest) {
                  setUserProfile(
                    jsonRequest.email,
                    jsonRequest.nom,
                    jsonRequest.telephone,
                    jsonRequest.type,
                    jsonRequest.imageUrl,
                    jsonRequest.admin ? 'true' : 'false',
                    jsonRequest.moderator ? 'true' : 'false',
                    jsonRequest.roles ? jsonRequest.roles : []
                  );

                  this.setState({
                    loginSuccessFacebook: true,
                  });
                  if (jsonRequest.telephone) {
                    lang === 'ar'
                      ? (window.location.href = `/ar/المغرب/للبيع/`)
                      : (window.location.href = `/fr/maroc/a_vendre/`);
                  } else {
                    window.location.href = '/user/profile';
                  }
                }
              });
          } else {
            this.setState(
              {
                loginErrorFacebook: true,
              },
              () => {
                console.error(`Can not login facebook`);
              }
            );
          }
        });
    }
  }

  render() {
    const {
      login,
      password,
      showPassword,
      enableSubmit,
      loginError,
      loginErrorFacebook,
      loginSuccessFacebook,
      isSubmitting,
    } = this.state;

    return (
      <IonPage>
        {this.metaTags()}
        {isPlatform('desktop') ? <SimpleHeader /> : <Header />}
        <IonContent className={'loginPage'}>
          <div className={'container'}>
            <div className={'loginContainer'}>
              <div className={'login'}>
                <IonGrid>
                  <IonRow className={'text-center justify-content-center'}>
                    <IonCol size={'12'}>
                      <h2 className="title">
                        <FormattedMessage id={'sign.in.subtitle'} />
                      </h2>
                    </IonCol>
                    <form className={'loginForm'} method={'POST'} onSubmit={this.onSubmitLogin}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.email'} />
                        </IonLabel>
                        <IonInput
                          value={login}
                          type={'email'}
                          inputMode={'email'}
                          autocomplete={'on'}
                          autofocus
                          name={'login'}
                          onIonInput={(e: any) => this.setState({ login: e.target.value })}
                          onIonChange={(event) => {
                            this.setState({ login: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                      </IonItem>
                      <IonItem className={'mb-4'}>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.password'} />
                        </IonLabel>
                        <div className={'pass-input-container'}>
                          <IonInput
                            value={password}
                            type={!showPassword ? 'password' : 'text'}
                            inputMode={'text'}
                            name={'password'}
                            onIonInput={(e: any) => this.setState({ password: e.target.value })}
                            onIonChange={(event) => {
                              this.setState({ password: event.detail.value! }, () => {
                                this.activateSubmit();
                              });
                            }}
                          />
                          <IonIcon
                            slot={'end'}
                            icon={!showPassword ? eye : eyeOff}
                            onClick={(event) => this.setState({ showPassword: !showPassword })}
                          />
                        </div>
                      </IonItem>
                      <IonCol size={'12'}>
                        {loginError && (
                          <CreateAnimation
                            duration={1000}
                            easing={'ease-in'}
                            play={true}
                            fromTo={[
                              {
                                property: 'opacity',
                                fromValue: '0',
                                toValue: '1',
                              },
                            ]}
                          >
                            <div className={'alert alert-danger'}>
                              <IonIcon icon={alertCircle} size={'large'} />
                              <FormattedMessage id={'sign.in.error'} />
                            </div>
                          </CreateAnimation>
                        )}
                        {loginErrorFacebook && (
                          <CreateAnimation
                            duration={1000}
                            easing={'ease-in'}
                            play={true}
                            fromTo={[
                              {
                                property: 'opacity',
                                fromValue: '0',
                                toValue: '1',
                              },
                            ]}
                          >
                            <div className={'alert-text-facebook'}>
                              <IonIcon icon={alertCircle} size={'large'} />
                              <FormattedMessage id={'sign.in.error.facebook'} />
                            </div>
                          </CreateAnimation>
                        )}
                        {loginSuccessFacebook && (
                          <CreateAnimation
                            duration={1000}
                            easing={'ease-in'}
                            play={true}
                            fromTo={[
                              {
                                property: 'opacity',
                                fromValue: '0',
                                toValue: '1',
                              },
                            ]}
                          >
                            <div className={'alert-success'}>
                              <IonIcon icon={checkmarkCircleOutline} size={'large'} />
                              <FormattedMessage id={'sign.up.title'} />
                            </div>
                          </CreateAnimation>
                        )}
                      </IonCol>
                      <IonCol size={'12'}>
                        <a
                          className="font-normal"
                          href={'/resetpassword'}
                        >
                          <FormattedMessage id={'sign.in.forgot.password.question'} />
                        </a>
                      </IonCol>

                      <button
                        className={'btn btn-primary btn-block mt-4 submit-button'}
                        type={'submit'}
                        disabled={!enableSubmit}
                      >
                        {isSubmitting ? <IonSpinner name="crescent" /> : <FormattedMessage id={'sign.in.button'} />}
                      </button>
                    </form>
                    {/* temp action
                    <div className="my-4 d-flex justify-content-center align-items-center separator-2">
                      <hr className={'sep'} />
                      <span className={'mx-2 font-semibold'}>
                        <FormattedMessage id={'or'} />
                      </span>
                      <hr className={'sep'} />
                    </div>


                    {isPlatform('ios') && isPlatform('capacitor') ? null : (
                      <IonCol size={'12'} className="ion-column">
                        <button
                          className={`btn-facebook d-flex align-items-center justify-content-center`}
                          onClick={() => {
                            isPlatform('android') || (isPlatform('ios') && !isPlatform('capacitor'))
                              ? this.fbSignInMobile()
                              : this.fbSignInDesktop();
                          }}
                        >
                          <IonIcon className={'align-content-start'} icon={logoFacebook} />
                          <span className={'w-100'}>
                            <FormattedMessage id={'sign.in.account.facebook'} />
                          </span>
                        </button>
                      </IonCol>
                    )}*/}

                  </IonRow>
                </IonGrid>
              </div>
              <div className={'login'}>
                <IonGrid>
                  <IonRow className={'text-center justify-content-center'}>
                    <IonCol size={'12'}>
                      <h2 className="title">
                        <FormattedMessage id={'sign.in.account.first'} />
                      </h2>
                    </IonCol>
                    <IonCol size={'12'}>
                      <button
                        className={'btn btn-block btn-signup'}
                        onClick={(event) => (window.location.href = '/register')}
                        style={{}}
                      >
                        <FormattedMessage id={'sign.in.account.second'} />
                      </button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
        </IonContent>
        <Footer copyrightOnly/>
      </IonPage>
    );
  }

  callback() {
    let tmpCallback = '/';

    const { callback } = this.props;

    if (callback && callback !== '') {
      tmpCallback = callback;
    }

    //const c = useQuery(this.props).get('callback');
    const c = '';
    if (c && c !== '') {
      tmpCallback = c;
    }

    return tmpCallback;
  }

  metaTags() {
    return (
      <Helmet>
        <title>Login</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {
  callback?: string;
  intl: any;
}

interface State extends FlahaState {
  login: string;
  password: string;
  showPassword: boolean;
  enableSubmit: boolean;
  loginError: boolean;
  loginErrorFacebook: boolean;
  loginSuccessFacebook: boolean;
  isSubmitting: boolean;
}

// @ts-ignore
export default injectIntl(Login);
