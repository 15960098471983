import React, { ChangeEvent, Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import './GallerySelector.scss';
import { IonImg, isPlatform } from '@ionic/react';
import { constants, Events, getBase64, getDeviceStoragePermission } from 'utils';
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import {Shop} from "utils/Types/types";


const supportedMineTypes = ['image/png', "image/jpg", "image/jpeg"];

class GallerySelector extends Component<Props, State> {

    _webFileChooser: HTMLElement | null = null;

    constructor(props: Props, context: any) {
        super(props, context);

        const {value} = props;
        this.state = {
            lang: "ar",
            value: value,
            dragOver: false
        };

        this._selectFiles = this._selectFiles.bind(this);
        this._addDragOver = this._addDragOver.bind(this);
        this._webFileChooserChange = this._webFileChooserChange.bind(this);
    }


    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    // @ts-ignore
    _selectFiles(e: MouseEvent<HTMLFormElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();


        if (isPlatform('capacitor')) {
            // @ts-ignore
            const {intl, shop} = this.props;

            let options: ImagePickerOptions = {
                allow_video: false,
                maximumImagesCount: shop ? 10 : 4,
                outputType: 1,
                quality: 70,
                title: intl.formatMessage(langFunction.appNameFull),
                message: intl.formatMessage(langFunction.galleryChoice),
            };

            getDeviceStoragePermission((result) => {
                if (result) {
                    const {value} = this.props;
                    ImagePicker
                        .getPictures(options)
                        .then((result) => {
                            let images: any = value ? value : [];
                            if (Array.isArray(result)) {
                                result.forEach((base64: string, index: number) => {

                                        if (base64.charAt(0) === '/') {
                                        images.push(`data:image/png;base64,${base64}`);
                                    } else if (base64.charAt(0) === 'i') {
                                        images.push(`data:image/jpeg;base64,${base64}`);
                                    }
                                });
                            } else {
                                if (result.charAt(0) === '/') {
                                    images.push(`data:image/png;base64,${result}`);
                                } else if (result.charAt(0) === 'i') {
                                    images.push(`data:image/jpeg;base64,${result}`);
                                }
                            }
                            this.setState({
                                value: images,
                            });
                        })
                        .catch((error) => {
                            alert(error.message);
                            console.error(error.message, error);
                        });
                }
            });
        } else {
            if (this._webFileChooser) {
                this._webFileChooser.click();
            }
        }
    }

    _getDevicePermission(callback: (result: any) => void) {
        if (isPlatform('android')) {
            AndroidPermissions
                .checkPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                .then((result) => {
                    if (result.hasPermission) {
                        callback(true);
                    } else {
                        AndroidPermissions
                            .requestPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                            .then((result) => {
                                if (result.hasPermission) {
                                    callback(true);
                                } else {
                                    callback(false);
                                }
                            })
                            .catch((error) => {
                                console.error(error.message, error);
                                callback(false);
                            })
                    }
                })
                .catch((e) => {
                    AndroidPermissions
                        .requestPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                        .then((result) => {
                            if (result.hasPermission) {
                                callback(true);
                            } else {
                                callback(false);
                            }
                        })
                        .catch((error) => {
                            console.error(error.message, error);
                            callback(false);
                        })
                });
        } else {
            ImagePicker.hasReadPermission()
                .then((permission) => {
                    if (permission) {
                        callback(permission);
                    } else {
                        ImagePicker.requestReadPermission()
                            .catch((error) => {
                                console.error(error);
                                callback(false);
                            });
                    }
                })
                .catch((e) => {
                    ImagePicker.requestReadPermission()
                        .catch((error) => {
                            console.error(error);
                            callback(false);
                        });
                });
        }
    }

    _webFileChooserChange(e: ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        e.preventDefault();

        const {target} = e;
        this.getImages(target.files);
    }

    // @ts-ignore
    _addDragOver(e: MouseEvent<HTMLFormElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();

        const {type, dataTransfer} = e;

        this.setState({
            dragOver: type === 'dragover' || type === 'dragenter'
        }, () => {
            if (type === 'drop') {
                this.getImages(dataTransfer.files);
            }
        });
    }

    async getImages(files: FileList | null) {
        const maxImages = this.props.shop ? 10 : 4;
        if (files ) {

            for (var i = 0; i < files.length; i++) {
                if (supportedMineTypes.filter((mineType) => mineType === files[i].type).length > 0) {
                    await getBase64(files[i], (result: string | ArrayBuffer | null) => {
                        const {value, shop} = this.props;
                        //console.log("vaue bade : "+ files.length)
                        if (value?.length < maxImages && result) {
                            value.push(result.toString());
                            this.setState({
                                value: value,
                            }, () => {
                                const {onChange} = this.props;
                                if (onChange) {
                                    onChange(value);
                                }
                            });
                        }
                    });
                }
            }

            if (this._webFileChooser) {
                // @ts-ignore
                this._webFileChooser.value = null;
            }

        }
    }

    // @ts-ignore
    _removeImage(item: string, index: number, e: MouseEvent<HTMLFormElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();

        const {value} = this.props;

        if (value) {

            value.splice(index, 1);

            this.setState({
                value: value,
            }, () => {
                const {onChange} = this.props;
                if (onChange) {
                    onChange(value);
                }
            })

        }



    }

    renderImages(item: any, index: number) {
        if(item.chemin)
        {

            // index = item.id;
            if (item?.imageEncodedBase64string?.charAt(0) === '/') {
                item= `data:image/png;base64,${item.imageEncodedBase64string}`;
            } else if (item?.imageEncodedBase64string?.charAt(0) === 'i') {
               item = `data:image/jpeg;base64,${item.imageEncodedBase64string}`;
            }
        }

        return (

            <li key={index} className={'gallery-item'} onClick={(e) => {
                this._removeImage(item, index, e)
            }}>
                {/*console.log(index)*/}
                <img loading="lazy" src={item} alt={''}/>

            </li>
        );
    }

    render() {

        let {dragOver} = this.state;
        return (
            <div>
                <input ref={(ref) => this._webFileChooser = ref}
                       type={'file'}
                       multiple={true}
                       accept={supportedMineTypes.join(', ')}
                       style={{display: "none"}}
                       onChange={(e) => this._webFileChooserChange(e)}
                />
                <form className={`gallery-selector mx-auto ${dragOver ? 'is-dragover' : ''}`} method="post" action=""
                      encType="multipart/form-data"
                      onSubmit={(e) => e.preventDefault()}
                      onDragOver={e => this._addDragOver(e)}
                      onDragEnter={e => this._addDragOver(e)}
                      onDragLeave={e => this._addDragOver(e)}
                      onDragEnd={e => this._addDragOver(e)}
                      onDrop={e => this._addDragOver(e)}
                      onClick={e => this._selectFiles(e)}>
                    {this.props.value === null || this.props.value === undefined || this.props.value.length < 1 ?
                        <div className={'box__input'}>
                            <h2 className={'box__title'}><FormattedMessage id={'gallery'}/></h2>
                            <IonImg src={"assets/img/addImg.png"} alt="pickimg" style={{marginTop:"110px",marginBottom:"50px",height:"95px"}}></IonImg>
                            <p>
                                <strong className={'clickToChose'}><FormattedMessage id={'gallery.choice'}/></strong>
                                {!isPlatform('capacitor') ?
                                    <span className="box__dragndrop"><FormattedMessage id={'gallery.drag'}/></span> : null}
                                <span className="box__dragndrop"><FormattedMessage id={'gallery.max'} values={{max: this.props.shop ? 10 : 4}}/></span>
                            </p>
                        </div>
                        :
                        <div className={'viewer'}>
                            <ul className={'gallery'}>
                                {this.props.value.map((item: string | object, index: number) => this.renderImages(item, index))}
                                <li className={'icon-add'} style={{float:"left",height: "7rem",width:"7rem",margin:".5rem"}}>    <IonImg src={"assets/img/addImg.png"} alt="pickimg" style={{height:"100%",width:"100%",opacity: ".2"}}></IonImg>
</li>
                            </ul>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

interface Props extends FlahaProps {
    value?: Array<string>;
    onChange?: (images: Array<string>) => void;
    shop?: Shop;
}

interface State extends FlahaState {
    value?: Array<string>;
    dragOver: boolean;
}

// @ts-ignore
export default injectIntl(GallerySelector);
