import React, {Component} from "react";
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {IonIcon} from "@ionic/react";
import {informationCircle} from "ionicons/icons";
import './NoContent.scss';
import {FormattedMessage} from "react-intl";

export default class NoContent extends Component<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "fr",
        };
    }

    render() {
        const {title} = this.props;

        return (
            <div className={'noContentComponent'}>
                <div className={'container text-center'}>
                    <IonIcon icon={informationCircle} size={'large'} color={'medium'}/>
                    <p>
                        <FormattedMessage id={'no.posts'} values={{title: title}}/>
                    </p>
                    <a href={'/'}><FormattedMessage id={'goto.home'}/></a>
                </div>
            </div>
        );
    }
}

interface Props extends FlahaProps {
    title: string;
}

interface State extends FlahaState {

}
