import React, { Component } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow, isPlatform } from '@ionic/react';
import { Categories, Footer, Header, PopularCities, PopularTags, RecommendedPostsHome, Search } from 'components';
import { Helmet } from 'react-helmet';
import './Home.scss';
import { SearchCriteria } from 'utils/Types/types';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { RouteComponentProps } from 'react-router';
import { constants, Events } from 'utils';
import { trackEvent } from '../../tracking';
import { categoryButtonClicked, searchButtonClicked } from '../../tracking/events';
import { FormattedMessage } from 'react-intl';
import ReactPixel from 'react-facebook-pixel';
import { getSEOParametre, isProduction, setLanguage } from 'utils/Functions/Functions';
import { connect } from 'react-redux';
import { AdSlot, DFPSlotsProvider } from 'react-dfp';
import qs from 'query-string';
import EventTrackingService from '../../services/EventTrackingService';


class Home extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      metaTitle: ['', ''],
      metaDescription: ['', ''],
      showTopNavbar: true,
    };
  }

  importSEOParametres() {
    // import SEO Meta parametres
    getSEOParametre('SEO_HOME_TITLE').then((jsonRequest) => {
      if (jsonRequest) {
        this.setState({ metaTitle: [jsonRequest.valeurFr, jsonRequest.valeurAr] });
      }
    });
    getSEOParametre('SEO_HOME_DESCRIPTION').then((jsonRequest) => {
      if (jsonRequest) {
        this.setState({ metaDescription: [jsonRequest.valeurFr, jsonRequest.valeurAr] });
      }
    });
  }

  langChange() {
    if (!isPlatform('capacitor')) {
      const url = window.location.href;
      let pathname = new URL(url).pathname;
      let lang;
      if (pathname === '/fr') {
        lang = 'fr';
        this.setState({ lang: lang });
        setLanguage(lang)
          .then((data) => {
            Events.publish(constants.lang_change, { lang: lang });
          })
          .catch((error) => {
            console.error(`Error has been thrown (${error.message})`, error);
          });
      } else if (pathname === '/ar') {
        lang = 'ar';
        this.setState({ lang: lang });
        setLanguage(lang)
          .then((data) => {
            Events.publish(constants.lang_change, { lang: lang });
          })
          .catch((error) => {
            console.error(`Error has been thrown (${error.message})`, error);
          });
      } else if (pathname === '/') {
        Events.subscribe(constants.lang_change, ({ lang }) => {
          this.setState({
            lang: lang,
          });
        });
      }
    }
  }

  componentDidMount() {
    this.langChange();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.importSEOParametres();
  }

  getSearch() {
    const { history } = this.props;
    const { lang } = this.state;

    return (
      <Search
        withAnnounce
        onChange={(searchCriteria: SearchCriteria) => {
          ReactPixel.track('visite', null);
          const {
            region,
            subCategory,
            query,
            price,
            titleOnly,
            urgentOnly,
            showAdvanceSearch,
            marque,
            modele,
            typeCarburant,
            boiteVitesse,
            puissanceFiscale,
            anneeModele,
            subCategoryObject,
            marqueObject,
            modeleObject,
            boiteVitesseObject,
            typeCarburantObject,
          } = searchCriteria;
          let { category, categoryObject } = searchCriteria;
          const constantAllRegion = lang === 'fr' ? 'maroc' : 'المغرب';
          const constantAllCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
          category = subCategory ? subCategory : category;
          categoryObject = subCategoryObject ? subCategoryObject : categoryObject;
          trackEvent(
            searchButtonClicked({
              region,
              category,
              query,
              price,
              titleOnly,
              urgentOnly,
              marque,
              modele,
              typeCarburant,
              boiteVitesse,
              puissanceFiscale,
              anneeModele,
              context: 'home',
            })
          );
          let cityID, regionID;
          const citiesRedux = this.props.citiesRedux;
          let cityRedux;
          cityRedux =
            lang === 'ar'
              ? citiesRedux?.find((item) => item.urlArab === region)
              : citiesRedux?.find((item) => item.urlFr === region);

          cityID = cityRedux?.id;
          regionID = cityRedux?.region?.id;

          const options = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          EventTrackingService.createEvent({
            data: {
              event: {
                id: constants.SEARCH_BUTTON_CLICKED,
              },
              context: {
                id: constants.HOME_PAGE,
              },
              region: regionID
                ? {
                    id: regionID,
                  }
                : null,
              ville: cityID
                ? {
                    id: cityID,
                  }
                : null,
              categorie: categoryObject
                ? {
                    id: categoryObject?.id,
                  }
                : null,
              query: query,
              maxPrice: price?.upper,
              minPrice: price?.lower,
              marque: marqueObject
                ? {
                    id: marqueObject?.id,
                  }
                : null,
              modele: modeleObject
                ? {
                    id: modeleObject?.id,
                  }
                : null,
              typeDeCarburant: typeCarburantObject
                ? {
                    id: typeCarburantObject?.id,
                  }
                : null,
              boiteAVitesse: boiteVitesseObject
                ? {
                    id: boiteVitesseObject?.id,
                  }
                : null,
              puissanceFiscale: puissanceFiscale,
              anneeModele: anneeModele,
            },
            options: options,
          }).catch((reason) => console.error(reason.message));
          // window.location.href=`/${region ? region : constants.allRegion}/${category ? category : constants.allCategories}/${query}`;
          const params = {
            mar: marque,
            mod: modele,
            tc: typeCarburant,
            bv: boiteVitesse,
            pf: puissanceFiscale,
            am: anneeModele,
            minP: price?.lower,
            maxP: price?.upper,
          };
          const searchString = qs.stringify(params, { skipNull: true, skipEmptyString: true });
          history.push({
            pathname: `/${lang}/${region ? region : constantAllRegion}/${category ? category : constantAllCategories}/${
              query ? query.replace(/ /g, '_') : ''
            }`,
            search: searchString,
            state: {
              region: region,
              category: category,
              query: query,
              price: price,
              titleOnly: titleOnly,
              urgentOnly: urgentOnly,
              showAdvanceSearch: showAdvanceSearch,
              marque,
              modele,
              typeCarburant,
              boiteVitesse,
              puissanceFiscale,
              anneeModele,
              categoryObject,
              marqueObject,
              modeleObject,
              boiteVitesseObject,
              typeCarburantObject,
            },
          });
        }}
      />
    );
  }

  render() {
    const { lang } = this.state;
    const styleDesktop = {
      backgroundImage: 'url(/assets/img/flaha.svg)',
      backgroundRepeat: 'no-repeat',
      /*minHeight: window.innerWidth >= 767 ? '40rem' : '30rem',*/
    };
    const styleMobile = {
      backgroundColor: 'white',
    };
    const style = !isPlatform('desktop') ? styleMobile : styleDesktop;
    const marginTop = !isPlatform('desktop') ? '0rem' : '0rem';
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <DFPSlotsProvider dfpNetworkId="22666931002">
          <IonContent>
            <IonGrid style={style}>
              <IonRow className={'justify-content-center'}>
                {isProduction() && isPlatform('desktop') && (
                  <IonCol sizeMd={'2'}>
                    <div
                      className={'p-sticky-top d-none d-md-flex justify-content-end'}
                      style={{
                        marginTop: window.innerWidth <= 992 ? marginTop : !isPlatform('desktop') ? '0rem' : '0rem',
                      }}
                    >
                      <AdSlot sizes={[[160, 600]]} adUnit="banner_home_left" />
                    </div>
                  </IonCol>
                )}
                <IonCol sizeMd={'8'}>
                  <div className={'d-flex justify-content-center mb-4'}>
                    {/* <a href={'/contact-publicite/Publicité'}>
                                    <picture className={'img-fluid'}>
                                        <source type='image/webp'
                                                srcSet={'/assets/img/banners/banner-970x250.webp'}/>
                                        <img loading="lazy" src={'/assets/img/banners/banner-970x250.jpg'}
                                             alt={'AD - 970x250'}/>
                                    </picture>
                                </a>*/}
                    {isProduction() ? (
                      !isPlatform('desktop') ? (
                        <AdSlot sizes={[[300, 50]]} adUnit="banner_home_mobile_adview_page" />
                      ) : (
                        <AdSlot sizes={[[970, 250]]} adUnit="banner_home" />
                      )
                    ) : null}
                  </div>
                  {this.getSearch()}
                  <Categories
                    onCategoryClick={({ id }) => {
                      trackEvent(categoryButtonClicked({ categoryId: id }));
                      const options = {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      };

                      EventTrackingService.createEvent({
                        data: {
                          event: {
                            id: constants.CATEGORY_BUTTON_CLICKED,
                          },
                          categorie: {
                            id: id,
                          },
                        },
                        options: options,
                      }).catch((reason) => console.error(reason.message));
                    }}
                  />
                  {isProduction() && !isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center my-4'}>
                      <AdSlot sizes={[[300, 250]]} adUnit="banner_home_listing_page" />
                    </div>
                  )}
                  <PopularTags lang={lang} />
                  <PopularCities lang={lang} />

                  {isPlatform('mobileweb') ? null : (
                    <IonGrid className="grid-rec-slider">
                      <h2 className="RecommandedTitle">
                        <FormattedMessage id={'post.recommanded'} />
                      </h2>
                      <RecommendedPostsHome lang={this.state.lang} />
                    </IonGrid>
                  )}
                  {/*<Zones onRegionClick={({ id }) => trackEvent(regionButtonClicked({ regionId: id }))} />*/}

                  {isProduction() && !isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center my-4'}>
                      <AdSlot sizes={[[300, 50]]} adUnit="banner_home_mobile_adview_page" />
                    </div>
                  )}
                </IonCol>
                {isProduction() && isPlatform('desktop') && (
                  <IonCol sizeMd={'2'}>
                    <div
                      className={'p-sticky-top d-none d-md-flex justify-content-start'}
                      style={{
                        marginTop: window.innerWidth <= 992 ? marginTop : !isPlatform('desktop') ? '0rem' : '0rem',
                      }}
                    >
                      {/* <a href={'/contact-publicite/Publicité'}>
                                            <picture className={'img-fluid'}>
                                                <source type='image/webp'
                                                        srcSet={'/assets/img/banners/right-banner-160x600.webp'}/>
                                                <img loading="lazy" src={'/assets/img/banners/right-banner-160x600.jpg'}
                                                     alt={'AD - 160x600'}/>
                                            </picture>
                                        </a>*/}
                      <AdSlot sizes={[[160, 600]]} adUnit="banner_home_right" />
                    </div>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
            <Footer />
            {/* <IonFab vertical="bottom" horizontal={'end'} slot="fixed">
                    <IonFabButton color={'orange'} routerLink={'/sendPost'}>
                        <IonIcon icon={addOutline}/>
                    </IonFabButton>
                </IonFab> */}
          </IonContent>
        </DFPSlotsProvider>
      </IonPage>
    );
  }

  metaTags() {
    const { metaTitle, metaDescription, lang } = this.state;
    const categoriesRedux = this.props.categoriesRedux;
    let categoriesAr = [];
    let categoriesFr = [];
    categoriesRedux &&
      categoriesRedux.map((category) => {
        categoriesAr.push(category.titreArabe);
      });
    categoriesRedux &&
      categoriesRedux.map((category) => {
        categoriesFr.push(category.titreFrancais);
      });

    const title = lang === 'ar' ? metaTitle[1] : metaTitle[0];
    const description =
      lang === 'ar'
        ? metaDescription[1].replace('{{0}}', categoriesAr.toString())
        : metaDescription[0].replace('{{0}}', categoriesFr.toString());

    const url = lang === 'fr' ? `https://flaha.ma/fr` : `https://flaha.ma/ar`;
    const alternateUrl = lang === 'ar' ? `https://flaha.ma/fr` : `https://flaha.ma/ar`;
    const hrefLangCanonical = lang === 'fr' ? 'fr-MA' : 'ar-MA';
    const hrefLangAltenate = lang === 'fr' ? 'ar-MA' : 'fr-MA';

    return (
      <Helmet>
        <title> {title} </title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="robots" content={'index, follow'} />
        <meta name="url" content="https://flaha.ma" />
        <meta name="lang" content={lang} />
        <link rel="canonical" hrefLang={hrefLangCanonical} href={url} />
        <link rel="altenate" hrefLang={hrefLangAltenate} href={alternateUrl} />
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(constants.jsonLdDataHome)}
        </script>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {
  categoriesRedux: any;
  citiesRedux: any;
}

interface State extends FlahaState {
  metaTitle?: [string, string];
  metaDescription?: [string, string];
  showTopNavbar: boolean;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
    citiesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.cities,
  };
};

export default connect(mapStateToProps)(Home);
