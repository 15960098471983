class TokenService {
  getLocalUserToken() {
    return localStorage.getItem('@flaha.userToken');
  }

  updateLocalUserToken(token) {
    localStorage.setItem('@flaha.userToken', JSON.stringify(token));
  }
}

export default new TokenService();
