import React, {Component, Requireable} from 'react';
import {SearchForm, SearchFormModal} from "components";
// @ts-ignore
import fadeInUp from 'react-animations/lib/fade-in-up';
import Radium from 'radium';
import PropTypes from 'prop-types';
import './Search.scss';
// @ts-ignore
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {SearchCriteria} from "utils/Types/types";
import {constants, Events} from "utils";
import {FormattedMessage} from 'react-intl';
import {isPlatform} from '@ionic/react';

const languageKey = "@flaha.language";
const cachedLanguage = localStorage.getItem(languageKey);

export default class Search extends Component<Props, State> {

    static propTypes: {
        styleForm: Requireable<object>;
        onChange: Requireable<(...args: any[]) => any>;
        withAnnounce: Requireable<boolean>;
        style: Requireable<object>;
        value: Requireable<object>
    };

    static defaultProps: {
        styleForm: { padding: string };
        onChange: (searchCriteria: SearchCriteria) => void;
        withAnnounce: boolean;
        style: {
            minHeight: string;
            backgroundImage: string
        };
        value: SearchCriteria,
    };

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            searchCriteria: {},
            lang: "ar",
        }
    }

    componentDidMount() {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    getAnnounce() {
        const fade = {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
        };

        // @ts-ignore
        return (<Radium.StyleRoot><div className={'announcement'} style={fade}>
                <h1>
                    <FormattedMessage id={'home.message'} values={{
                        title: <a href={'/'} style={{color: 'var(--ion-color-dark-tint)'}}> Flaha</a>
                    }}/>
                </h1>
                <br/>
                <h2>
                    <FormattedMessage id={'home.message.2'}/>
                </h2>
            </div>
        </Radium.StyleRoot>)
    }

    getSearchForm() {
        const {styleForm, onChange, value} = this.props;
        // @ts-ignore
        return (<SearchForm value={value}
                            style={{...styleForm}}
                            onChange={(searchCriteria: SearchCriteria) => this.setState({searchCriteria: searchCriteria}, () => {
                                onChange(searchCriteria)
                            })}/>)
    }

    getSearchFormModal() {
        const {styleForm, onChange, value} = this.props;
        // @ts-ignore
        return (<SearchFormModal value={value}
                                 style={{...styleForm}}
                                 onChange={(searchCriteria: SearchCriteria) => this.setState({searchCriteria: searchCriteria}, () => {
                                     onChange(searchCriteria)
                                 })}/>)
    }

    render() {
        const path = window.location.pathname;
        // @ts-ignore
        return (<div className={'search'}>
            <div className={'container'}>
                {(window.screen.width >= 1024 && (path === "/" || path === "/ar" || path === "/fr")) ? this.getAnnounce() : null}
                {isPlatform('android') || isPlatform('ios') ? this.getSearchFormModal() : this.getSearchForm()}
            </div>
        </div>);
    }
}


Search.defaultProps = {
    withAnnounce: false,
    style: {
        backgroundImage: 'url(/assets/img/flaha.svg)',
        minHeight: window.innerWidth >= 767 ? '40rem' : '30rem',
    },
    styleForm: {
        padding: window.innerWidth >= 767 ? '1rem 3rem' : '2rem 0rem',
    },
    onChange: (searchCriteria: SearchCriteria) => {
    },
    value: {
        query: '',
        category: cachedLanguage === 'fr' ? "a_vendre" : "للبيع",
        region: cachedLanguage === 'fr' ? "maroc" : "المغرب",
        titleOnly: false,
        urgentOnly: false,
        marque: null,
        modele: null,
        typeCarburant: null,
        boiteVitesse: null,
        puissanceFiscale: '',
        anneeModele: ''
    },
};

Search.propTypes = {
    withAnnounce: PropTypes.bool,
    style: PropTypes.object,
    styleForm: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.object,
};

interface Props extends FlahaProps {
    /**
     * To determine if going to show the form header or not
     * @default false
     * */
    withAnnounce: boolean;
    /**
     * Form container style
     * @default {
     *         backgroundImage: 'url(/assets/img/flaha.svg)',
     *         minHeight: '40rem',
     *      }
     * */
    style: object;
    /**
     * Form style
     * @default {
     *         padding: '5rem 3rem',
     *      }
     * */
    styleForm: object;
    /**
     * On Form is submitted
     * @param searchCriteria SearchCriteria
     * */
    onChange: (searchCriteria: SearchCriteria) => void;
    /**
     * The value to passe to the form
     * @var SearchCriteria
     * */
    value: SearchCriteria;
}

interface State extends FlahaState {
    searchCriteria: SearchCriteria;
}
