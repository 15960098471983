import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import planService from '../../services/PlanService';
import { BoostPlan, BoostPlanType, Shop } from 'utils/Types/types';
import './BoostAnnonceModalContent.scss';
import fundRequestService from '../../services/FundRequestService';
import { constants } from 'utils';
import BoostTimeFormModalContent from '../BoostTimeFormModalContent/BoostTimeFormModalContent';
import axios from 'axios';
import { checkRoleFct, getUserProfile } from 'utils/Functions/Functions';
import ShopService from '../../services/ShopService';
import shopService from '../../services/ShopService';

interface BoostAnnonceModalContentProps {
  intl: any;
  lang: string;
  selectedAnnonceID: number;
  onBoost: any;
  onClose: any;
}

const BoostAnnonceModalContent: React.FC<BoostAnnonceModalContentProps> = ({
  intl,
  lang,
  selectedAnnonceID,
  onBoost,
  onClose,
}) => {
  const [boostID, setBoostID] = useState(undefined);
  const [boostPlans, setBoostPlans] = useState<Array<BoostPlan>>([]);
  const [boostPlanTypes, setBoostPlanTypes] = useState<Array<BoostPlanType>>([]);
  const [creditCost, setCreditCost] = useState(4);

  const [userShops, setUserShops] = useState<Array<Shop>>([]);

  const [showAlertOnBoostError, setShowAlertOnBoostError] = useState(false);
  const [showBoostTimeFormModal, setShowBoostTimeFormModal] = useState(false);

  const [boostActive, setBoostActive] = useState(false);

  const enableSubmit = () => {
    if (boostID) {
      setBoostActive(true);
    } else setBoostActive(false);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getBoostPlans = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: source.token,
      };

      planService
        .getAllBoostPlans(options)
        .then((value) => {
          setBoostPlans(value.data.boostPlans);
          setBoostPlanTypes(value.data.boostPlanTypes);
        })
        .catch((reason) => {
          if (!axios.isCancel(reason)) console.error(reason.message);
        });
    };

    const getRequestParams = (filter, pageSize) => {
      let params = {};
      if (filter) {
        params['filter'] = filter;
      }
      if (pageSize) {
        params['size'] = pageSize;
      }
      return params;
    };

    const getCreditCost = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: source.token,
      };

      fundRequestService
        .getCreditCost(options)
        .then((value) => {
          const { cost } = value.data;
          setCreditCost(cost);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };

    const getUserShops = () => {
      const filter = {
        shopOwnerEmail: String(getUserProfile().email),
        disabled: false,
        moderated: true,
      };

      const params = getRequestParams(JSON.stringify(filter), 50);
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: params,
      };

      shopService
        .getAll(options)
        .then((value) => {
          const { shops } = value.data;
          setUserShops(shops);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };

    getCreditCost();
    getBoostPlans();
    getUserShops();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    enableSubmit();
  }, [boostID]);

  return (
    <IonContent>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id={'annonce.boost.title'} />
          <div>
            <small className={'text-muted text-wrap'}>
              <FormattedMessage id={'annonce.boost.message'} />
            </small>
          </div>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={onClose}>
            <FormattedMessage id={'close'} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonGrid>
        <IonRadioGroup value={boostID} onIonChange={(e) => setBoostID(e.detail.value)}>
          <div className={'d-flex flex-column justify-content-center px-3'}>
            {boostPlans?.map((plan, index) => {
              return (
                <IonCard key={`boost-plan-${index}`} className={'w-100'}>
                  <IonRow className="justify-content-center">
                    {plan.id !== 3 && (
                      <>
                        <IonCol
                          size={'12'}
                          sizeMd={'12'}
                          sizeLg={'8'}
                          className={lang === 'fr' ? 'border-right' : 'border-left'}
                        >
                          <div className={'d-flex flex-wrap flex-md-nowrap'}>
                            <img
                              className={'img-fluid'}
                              src={plan.imagePath}
                              alt={lang === 'fr' ? plan.labelFr : plan.labelAr}
                            />
                            <IonCardHeader className="d-flex flex-column">
                              <IonCardTitle>
                                <h4>{lang === 'fr' ? plan.labelFr : plan.labelAr}</h4>
                              </IonCardTitle>
                              <IonCardSubtitle>
                                {lang === 'fr' ? plan.descriptionFr : plan.descriptionAr}
                              </IonCardSubtitle>
                            </IonCardHeader>
                          </div>
                        </IonCol>
                        <IonCol
                          size={'12'}
                          sizeMd={'12'}
                          sizeLg={'4'}
                          className={'d-flex align-items-center flex-column justify-content-center'}
                        >
                          <IonList lines={'none'} className={'w-100'}>
                            {boostPlanTypes?.map((type, index1) => {
                              return (
                                plan.id === type.boostPlan.id && (
                                  <IonItem key={`boost-plan-type-${index1}`}>
                                    <div className={'d-flex justify-content-between w-100'}>
                                      <div className={'boost-choice d-flex justify-content-between'}>
                                        <span className={'period'}>
                                          <FormattedMessage
                                            id={
                                              type.period > 1
                                                ? 'annonce.boost.choice.period'
                                                : 'annonce.boost.choice.period.singular'
                                            }
                                            values={{
                                              period: type.period,
                                            }}
                                          />
                                        </span>
                                        {userShops?.length <= 0 && (
                                            <IonLabel>
                                              <FormattedMessage
                                                  id={'annonce.boost.choice.amount'}
                                                  values={{ amount: type.nbrCredit * creditCost }}
                                              />
                                            </IonLabel>
                                        )}
                                      </div>

                                      <IonRadio className={'mx-2'} value={type.id} />
                                    </div>
                                  </IonItem>
                                )
                              );
                            })}
                          </IonList>
                        </IonCol>
                      </>
                    )}
                    {plan.id === 3 && userShops?.length > 0 && (
                      <>
                        <IonCol
                          size={'12'}
                          sizeMd={'12'}
                          sizeLg={'8'}
                          className={lang === 'fr' ? 'border-right' : 'border-left'}
                        >
                          <div className={'d-flex flex-wrap flex-md-nowrap'}>
                            <img
                              className={'img-fluid'}
                              src={plan.imagePath}
                              alt={lang === 'fr' ? plan.labelFr : plan.labelAr}
                            />
                            <IonCardHeader className="d-flex flex-column">
                              <IonCardTitle>
                                <h4>{lang === 'fr' ? plan.labelFr : plan.labelAr}</h4>
                              </IonCardTitle>
                              <IonCardSubtitle>
                                {lang === 'fr' ? plan.descriptionFr : plan.descriptionFr}
                              </IonCardSubtitle>
                            </IonCardHeader>
                          </div>
                        </IonCol>
                        <IonCol
                          size={'12'}
                          sizeMd={'12'}
                          sizeLg={'4'}
                          className={'d-flex align-items-center flex-column justify-content-center'}
                        >
                          <IonList lines={'none'} className={'w-100'}>
                            {boostPlanTypes?.map((type, index1) => {
                              return (
                                plan.id === type.boostPlan.id && (
                                  <IonItem key={`boost-plan-type-${index1}`}>
                                    <div className={'d-flex justify-content-between w-100'}>
                                      <div className={'boost-choice d-flex justify-content-between'}>
                                        <span className={'period'}>
                                          <FormattedMessage
                                            id={
                                              type.period > 1
                                                ? 'annonce.boost.choice.period'
                                                : 'annonce.boost.choice.period.singular'
                                            }
                                            values={{
                                              period: type.period,
                                            }}
                                          />
                                        </span>
                                        {userShops?.length <= 0 && (
                                          <IonLabel>
                                            <FormattedMessage
                                              id={'annonce.boost.choice.amount'}
                                              values={{ amount: type.nbrCredit * creditCost }}
                                            />
                                          </IonLabel>
                                        )}
                                      </div>

                                      <IonRadio className={'mx-2'} value={type.id} />
                                    </div>
                                  </IonItem>
                                )
                              );
                            })}
                          </IonList>
                        </IonCol>
                      </>
                    )}
                  </IonRow>
                </IonCard>
              );
            })}

            {/*<IonCard className={'w-100'}>
              <IonRow className="justify-content-center">
                <IonCol
                  size={'12'}
                  sizeMd={'12'}
                  sizeLg={'8'}
                  className={lang === 'fr' ? 'border-right' : 'border-left'}
                >
                  <div className={'d-flex'}>
                    <img className={'img-fluid'} src="./assets/img/annonce-star-placement.png" alt="annonce premium" />
                    <IonCardHeader className="d-flex-flex-column">
                      <IonCardTitle>
                        <h4>
                          <FormattedMessage id={'annonce.boost.star.title'} />
                        </h4>
                      </IonCardTitle>
                    </IonCardHeader>
                  </div>
                </IonCol>
                <IonCol
                  size={'12'}
                  sizeMd={'12'}
                  sizeLg={'4'}
                  className={'d-flex align-items-center flex-column justify-content-center'}
                >
                  <IonList lines={'none'}>
                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.star.choice.one'} />
                      </IonLabel>
                      <IonRadio slot="end" value={6} />
                    </IonItem>

                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.star.choice.two'} />
                      </IonLabel>
                      <IonRadio slot="end" value={7} />
                    </IonItem>

                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.star.choice.three'} />
                      </IonLabel>
                      <IonRadio slot="end" value={8} />
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonCard>

            {userShops?.length > 0 && (<IonCard className={'w-100'}>
              <IonRow className="justify-content-center">
                <IonCol
                  size={'12'}
                  sizeMd={'12'}
                  sizeLg={'8'}
                  className={lang === 'fr' ? 'border-right' : 'border-left'}
                >
                  <div className={'d-flex'}>
                    <img
                      className={'img-fluid'}
                      src="./assets/img/annonce-premium-placement.png"
                      alt="annonce premium"
                    />
                    <IonCardHeader className="d-flex-flex-column">
                      <IonCardTitle>
                        <h4>
                          <FormattedMessage id={'annonce.boost.premium.title'} />
                        </h4>
                      </IonCardTitle>
                    </IonCardHeader>
                  </div>
                </IonCol>
                <IonCol
                  size={'12'}
                  sizeMd={'12'}
                  sizeLg={'4'}
                  className={'d-flex align-items-center flex-column justify-content-center'}
                >
                  <IonList lines={'none'}>
                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.choice.one'} />
                      </IonLabel>
                      <IonRadio slot="end" value={9} />
                    </IonItem>

                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.choice.two'} />
                      </IonLabel>
                      <IonRadio slot="end" value={10} />
                    </IonItem>

                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id={'annonce.boost.choice.three'} />
                      </IonLabel>
                      <IonRadio slot="end" value={11} />
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonCard>)}*/}
          </div>
        </IonRadioGroup>
      </IonGrid>
      <div className={'d-flex justify-content-around py-3'}>
        <IonButton
          disabled={!boostActive}
          onClick={() => {
            const boostType = boostPlanTypes?.find((value) => value.id === boostID);
            if (
              boostType?.boostPlan.id === constants.boostPlanTopList &&
              (userShops?.length > 0 || !checkRoleFct('user'))
            )
              setShowBoostTimeFormModal(true);
            else onBoost(selectedAnnonceID, boostID, null);
          }}
        >
          <FormattedMessage id={'annonce.boost.label'} />
        </IonButton>
      </div>
      <IonModal
        isOpen={showBoostTimeFormModal}
        onDidDismiss={() => {
          setShowBoostTimeFormModal(false);
        }}
        cssClass="my-custom-class"
      >
        <BoostTimeFormModalContent
          onClose={() => {
            setShowBoostTimeFormModal(false);
          }}
          intl={intl}
          lang={lang}
          onBoost={onBoost}
          selectedAnnonceID={selectedAnnonceID}
          boostID={boostID}
        />
      </IonModal>
    </IonContent>
  );
};

export default BoostAnnonceModalContent;
