import axios from 'axios';
import TokenService from './services/TokenService';
import { constants } from 'utils';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalUserToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig?.url !== constants.login && err.response) {
      // User Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log('401 - token expired')
        try {
          const rs = await instance.post(constants.refresh, {
            authToken: TokenService.getLocalUserToken(),
          });

          const { token } = rs.data;
          TokenService.updateLocalUserToken(token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
