import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';


export default class MetaComponent extends Component<Props, State>  {
  render() {
   return (
    <div className="wrapper">
      <MetaTags>
        {
          this.props.jsonLd && 
            <script className='structured-data-list'  type="application/ld+json">{this.props.jsonLd}</script>
        }
      </MetaTags>
    </div>
  )
 }
}

interface Props extends FlahaProps {
   jsonLd: any;
}

interface State extends FlahaState {
}