import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonToggle,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, GallerySelector, Header } from 'components';
import { constants, Events } from 'utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import { Category, City, Marque, Modele, Shop } from 'utils/Types/types';
import { alertCircle, caretDownOutline, closeOutline, createOutline } from 'ionicons/icons';
import './UpdatePost.scss';
import {
  getFileType,
  getUserProfile,
  getUserToken,
  makeblob,
  setUserToken,
  validateEmail,
} from 'utils/Functions/Functions';
import { MenuItem, StylesProvider, TextField, ThemeProvider } from '@material-ui/core';
import Radium from 'radium';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { createMuiTheme, jssPreset, withStyles } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import isEqual from 'lodash/isEqual';
import { getCategoriesReduxUtils } from 'utils/Functions/ReferentialGetters';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fr from 'react-phone-number-input/locale/fr.json';
import ar from 'lang/phone-input/ar.json';
import shopService from '../../services/ShopService';
import ReactQuill from 'react-quill';

const cachedStateKey = '@flaha.cachedState';
const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      '& fieldset': {
        borderColor: 'var(--ion-color-medium)',
        '& legend': {
          padding: '0px 8px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--ion-color-primary)',
      },
    },
  },
})(TextField);

const styleMenuItem = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { paddingRight: '20px', paddingLeft: '20px' };
  } else {
    return { paddingRight: '20px', paddingLeft: '20px', backgroundColor: 'rgb(245 246 249 / 68%)', fontWeight: 400 };
  }
};

const styleAvatar = (sousCatgorie) => {
  if (sousCatgorie !== 0) {
    return { maxWidth: '25px', height: '25px', backgroundColor: '#ffffff' };
  } else {
    return { backgroundColor: 'var(--ion-color-medium-contrast)' };
  }
};

const ltrTheme = createMuiTheme({ direction: 'ltr' });
const rtlTheme = createMuiTheme({ direction: 'rtl' });

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const requiredStyle = {
  color: 'red',
  fontWeight: 'bold',
  fontSize: '10px',
  marginBottom: '9px',
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ],
};

const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent'];

class UpdatePost extends Component<Props, State> {
  link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      images: [],
      categories: [],
      category: null,
      subCategory: null,
      categoryC: null,
      subCategoryC: null,
      cities: [],
      city: null,
      type: 'ventes',
      urgent: false,
      titleFr: '',
      titleAr: '',
      description: '',
      price: 0.0,
      slug: this.link,
      enableSubmit: false,
      token: null,
      id: null,
      telephoneAnnonce: undefined,
      nomUtilisateurAnnonce: '',
      emailAnnonce: '',
      showAlert: false,
      isSubmitting: false,
      marques: [],
      modeles: [],
      typesCarburant: [],
      boiteAVitesses: [],
      surface: '',
      poids: '',
      marque: null,
      modele: null,
      anneeModele: '',
      kilometrage: '',
      typeCarburant: null,
      puissanceFiscale: '',
      boiteVitesse: null,
      receivedCategory: 0,
      marqueId: null,
      userOwnerEmail: '',
      userShops: [],
      commercialShops: [],
      shop: undefined,
      secondaryPhone: undefined,
    };

    this.getCategories();
    this.getCities();
    this.getData();
    this.getUserShops();
    this.getCommercialShops();

    this.onSubmitPost = this.onSubmitPost.bind(this);
    this.checkRole = this.checkRole.bind(this);
    this.getCategoriesOptions = this.getCategoriesOptions.bind(this);
    this.getSubCategoriesOptions = this.getSubCategoriesOptions.bind(this);
    this.showAlertMessage = this.showAlertMessage.bind(this);
    this.renderItemCategorie = this.renderItemCategorie.bind(this);
    this.verifyCategoryC = this.verifyCategoryC.bind(this);
    this.getPrincipalCategory = this.getPrincipalCategory.bind(this);
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  getPrincipalCategory(category: Category) {
    const { lang, receivedCategory } = this.state;
    const categories = getCategoriesReduxUtils();
    if (categories.length > 0) {
      if (category) {
        if (category.sousCategorie !== 0) {
          const principalCategory = categories.find((item) => item.code === category.sousCategorie.toString());
          this.setState({
            category: principalCategory,
            categoryC: lang === 'fr' ? principalCategory.urlFrancais : principalCategory.urlArabe,
            subCategory: category,
            subCategoryC: lang === 'fr' ? category.urlFrancais : category.urlArabe,
            receivedCategory: receivedCategory + 1,
          });
        } else {
          this.setState({
            category: category,
            categoryC: lang === 'fr' ? category.urlFrancais : category.urlArabe,
            receivedCategory: receivedCategory + 1,
          });
        }
      }
    }
  }

  async getData() {
    const { slug } = this.state;
    const url = `${process.env.REACT_APP_API_URL + constants.posts + '/' + slug}`;

    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((request) => {
        if (request.status === 404) {
          return (window.location.href = '/user/mes-annonces');
        }
        if (request.status === 200) {
          return request.json();
        }
      })
      .then((json) => {
        if (json) {
          this.setState(
            {
              images: json.photos,
              city: json.ville.id,
              type: json.typeAnnonce,
              urgent: json.urgent,
              titleFr: json.titreFrancais,
              titleAr: json.titreArabe,
              description: json.description,
              price: json.prix,
              id: json.id,
              telephoneAnnonce: json.telephoneAnnonce || undefined,
              nomUtilisateurAnnonce: json.nomUtilisateurAnnonce,
              emailAnnonce: json.emailAnnonce,
              surface: json.surface,
              poids: json.poids,
              marqueId: json.marque,
              modele: json.modele,
              anneeModele: json.anneeModele,
              kilometrage: json.kilometrage,
              typeCarburant: json.typeDeCarburant,
              puissanceFiscale: json.puissanceFiscale,
              boiteVitesse: json.boiteAVitesse,
              userOwnerEmail: json.utilisateur.email,
              shop: json.shop,
              secondaryPhone: json.secondaryPhone,
            },
            () => {
              this.getModeleByMarque(json.marque ? json.marque : null);
              //this.checkUserAuth();
            }
          );
          this.getPrincipalCategory(json.categorie);
        }
      });
  }

  getRequestParams(filter, pageSize) {
    let params = {};
    if (filter) {
      params['filter'] = filter;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }
    return params;
  }

  getUserShops() {
    const { userOwnerEmail } = this.state;
    const filter = {
      shopOwnerEmail: String(userOwnerEmail),
      disabled: false,
      moderated: true,
    };

    const params = this.getRequestParams(JSON.stringify(filter), 50);
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    };

    shopService
      .getAll(options)
      .then((value) => {
        const { shops } = value.data;
        this.setState({ userShops: [...shops] });
      })
      .catch((reason) => {
        console.error(reason.message);
      });
  }

  getCommercialShops() {
    const filter = {
      shopOwnerEmail: String(getUserProfile().email),
      disabled: false,
      moderated: true,
    };

    const params = this.getRequestParams(JSON.stringify(filter), 50);
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    };

    shopService
      .getAll(options)
      .then((value) => {
        const { shops } = value.data;
        this.setState({ commercialShops: [...shops] });
      })
      .catch((reason) => {
        console.error(reason.message);
      });
  }

  getUserToken() {
    getUserToken().then((token) => {
      this.setState({ token: token });
      ////////////////console.log(token);
    });
  }

  verifyCategoryC() {
    let { category, subCategory, lang, type } = this.state;
    let categ;
    if (category) {
      categ = lang === 'ar' ? category.urlArabe : category.urlFrancais;
      this.setState({ categoryC: categ, type: type });
    }
    if (subCategory) {
      categ = lang === 'ar' ? category.urlArabe : category.urlFrancais;
      this.setState({
        subCategoryC: lang === 'ar' ? subCategory.urlArabe : subCategory.urlFrancais,
        type: type,
      });
    }
  }

  checkUserAuth() {
    const { userOwnerEmail } = this.state;
    if (!this.checkRole('admin') && !this.checkRole('moderateur') && !this.checkRole('editeur')) {
      let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
      let token = localStorage.getItem('@flaha.userToken');
      let bearer = 'Bearer ' + token;
      const headers = new Headers({
        Authorization: bearer,
      });

      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: headers,
      })
        .then((request) => {
          if (request.status === 200) {
            return request.json();
          }
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            if (jsonRequest.email !== userOwnerEmail) {
              window.location.href = '/';
            }
          }
        });
    }
  }

  componentDidMount(): void {
    this.getUserToken();

    //this.getMarques();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });

    window.addEventListener('ionAlertDidPresent', (e) => {
      setTimeout(() => {
        this.setState({ showAlert: false });
      }, 5000);
    });

    this.verifyCategoryC();
    setTimeout(() => {
      this.getMarques();
      this.getTypesCarburant();
      this.getBoiteAVitesse();
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { lang, receivedCategory } = this.state;
    const langChanged = !isEqual(prevState.lang, lang);
    if (langChanged) {
      this.verifyCategoryC();
    }
    const stateChanged = !isEqual(prevState, this.state);
    if (stateChanged) {
      this.activateSubmit();
      if (receivedCategory === 1) {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteAVitesse();
        this.activateSubmit();
        this.setState({ receivedCategory: receivedCategory + 1 });
      }
    }
  }

  showAlertMessage() {
    const { showAlert, shop, lang } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    const annonceModereeMsg = intl.formatMessage(langFunction.annonceModereeMsg);
    const annonceModereeMsgAdmin = intl.formatMessage(langFunction.annonceModificationMsg);
    const closeMsg = intl.formatMessage(langFunction.close);

    return (
      <IonAlert
        isOpen={showAlert}
        message={
          this.checkRole('admin') ||
          this.checkRole('moderateur') ||
          this.checkUserRole('editeur') ||
          this.checkUserRole('commercial')
            ? '<img src = "./assets/check.gif" > ' + annonceModereeMsgAdmin
            : '<img src = "./assets/check.gif" > ' + annonceModereeMsg
        }
        cssClass="success-alert"
        buttons={[
          {
            text: closeMsg,
            role: 'cancel',
            handler: () => {
              this.setState({ showAlert: false });
            },
          },
        ]}
        onDidDismiss={() => {
          shop ? (window.location.href = `/${lang}/${shop.slug}.htm`) : (window.location.href = '/user/mes-annonces');
        }}
      />
    );
  }

  onSubmitPost(event: FormEvent) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const url = `${process.env.REACT_APP_API_URL}${constants.userAnnonces}`;

    let {
      images,
      type,
      urgent,
      category,
      subCategory,
      city,
      titleFr,
      titleAr,
      description,
      price,
      lang,
      token,
      id,
      telephoneAnnonce,
      nomUtilisateurAnnonce,
      emailAnnonce,
      surface,
      poids,
      marque,
      modele,
      anneeModele,
      kilometrage,
      typeCarburant,
      puissanceFiscale,
      boiteVitesse,
      shop,
      secondaryPhone,
    } = this.state;
    // const {history} = this.props;

    let annonce = {
      id: id,
      description: description,
      prix: price,
      titreArabe: titleAr,
      titreFrancais: titleFr,
      typeAnnonce: type,
      urgent: urgent,
      categorie: { id: subCategory !== null ? subCategory.id : category.id },
      ville: { id: city },
      telephoneAnnonce: telephoneAnnonce,
      nomUtilisateurAnnonce: nomUtilisateurAnnonce,
      emailAnnonce: emailAnnonce,
      surface: surface,
      poids: poids,
      marque: marque ? marque.id : null,
      modele: modele ? { id: modele.id } : null,
      anneeModele: anneeModele,
      kilometrage: kilometrage,
      typeDeCarburant: typeCarburant ? { id: typeCarburant.id } : null,
      puissanceFiscale: puissanceFiscale,
      boiteAVitesse: boiteVitesse ? { id: boiteVitesse.id } : null,
      shop: shop ? { id: shop.id } : null,
      secondaryPhone: secondaryPhone,
    };
    //////console.log(annonce)
    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
    });
    const formData: FormData = new FormData();
    formData.append('annonce', JSON.stringify(annonce));
    for (let i = 0; i < images.length; i++) {
      const ph = images[i];
      let bl;
      // @ts-ignore
      if (ph.imageEncodedBase64string) {
        // @ts-ignore
        if (ph.imageEncodedBase64string.charAt(0) === '/') {
          // @ts-ignore
          bl = `data:image/png;base64,${ph.imageEncodedBase64string}`;
          // @ts-ignore
        } else if (ph.imageEncodedBase64string.charAt(0) === 'i') {
          // @ts-ignore
          bl = `data:image/jpeg;base64,${ph.imageEncodedBase64string}`;
        }
        // @ts-ignore
        formData.append('photos', makeblob(bl), ph.nom);
      } else {
        formData.append('photos', makeblob(ph), getFileType(ph, i));
      }
    }
    formData.append('localeId', JSON.stringify(lang));
    // //////////////console.log(formData.get('photos'));
    fetch(url, {
      method: 'PUT',
      body: formData,
      headers: headers,
    })
      .then((request) => {
        if (request.status === 200) {
          ////////////////console.log(request.status);
          window.localStorage.setItem(cachedStateKey, null);
          this.setState({ showAlert: true });
        }
        // if (request.status === 401) {
        //     removeUserToken();
        //     return window.location.href = '/login';
        // }
        if (request.status === 401) {
          ////////////////////// Partie Ajoutée ///////////////
          let urlRefrech = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
          fetch(urlRefrech, {
            method: 'POST',
            body: JSON.stringify({ authToken: token }),
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              return request.json();
            })
            .then((jsonRequest) => {
              ////////////////console.log(jsonRequest);
              setUserToken(jsonRequest.token);
              token = localStorage.getItem('@flaha.userToken');
              let bearer = 'Bearer ' + token;
              const headers = new Headers({
                Authorization: bearer,
              });
              return fetch(url, {
                method: 'GET',
                credentials: 'same-origin',
                headers: headers,
              }).then((result) => {
                window.localStorage.setItem(cachedStateKey, null);
                this.setState({ showAlert: true });
                ////////////////////// Partie Ajoutée ///////////////
              });
            });
        }
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        console.error(`Can not Update post => (${error.message})`, error);
      });
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  // Categories
  async getCategories() {
    const url = process.env.REACT_APP_API_URL + constants.categoriesSendPost;

    await fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        this.setState({
          categories: jsonResult,
        });
      })
      .catch((error) => {
        console.error(error.message, error);
      });
  }

  // Villes
  getCities() {
    const url = process.env.REACT_APP_API_URL + constants.cities;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        this.setState({
          cities: jsonResult,
        });
      })
      .catch((error) => {
        console.error(error.message, error);
      });
  }

  activateSubmit() {
    const {
      telephoneAnnonce,
      categoryC,
      titleFr,
      titleAr,
      price,
      token,
      description,
      city,
      category,
      lang,
      nomUtilisateurAnnonce,
      marque,
      boiteVitesse,
      subCategory,
      secondaryPhone,
    } = this.state;
    ////////console.log(this.state);
    if (
      ((lang === 'fr' && titleFr && titleFr !== '') || (lang === 'ar' && titleAr && titleAr !== '')) &&
      description &&
      description !== '' &&
      price >= 0 &&
      city &&
      ((lang === 'fr' && categoryC && categoryC !== 'a_vendre') ||
        (lang === 'ar' && categoryC && categoryC !== 'للبيع')) &&
      category &&
      ((token !== null &&
        !this.checkRole('user') &&
        !this.checkRole('commercial') &&
        isPossiblePhoneNumber(String(telephoneAnnonce)) &&
        telephoneAnnonce &&
        telephoneAnnonce !== '' &&
        ((secondaryPhone && isPossiblePhoneNumber(String(secondaryPhone))) ||
          !secondaryPhone ||
          secondaryPhone === '') &&
        nomUtilisateurAnnonce &&
        nomUtilisateurAnnonce !== '') ||
        token === null ||
        this.checkRole('user') ||
        this.checkRole('commercial')) &&
      ((category && (category.marques === null || (marque && boiteVitesse))) ||
        (subCategory && (subCategory.marques === null || (marque && boiteVitesse))))

      //&& ( category && category !== null && ((category.haveSurface && surface !== '' ) || !category.haveSurface))
      //&& ( category && category !== null && ((category.haveWeight && poids !== '' ) || !category.haveWeight))
    ) {
      this.setState({ enableSubmit: true });
      return;
    }
    this.setState({ enableSubmit: false });
  }

  handleNone() {
    this.setState(
      {
        category: null,
        subCategory: null,
        modeles: [],
        marques: [],
        marque: null,
        modele: null,
        typeCarburant: null,
        puissanceFiscale: '',
        kilometrage: '',
        anneeModele: '',
        boiteVitesse: null,
      },
      () => {
        this.activateSubmit();
      }
    );
  }

  handleClickSubCategory(value) {
    const { categories } = this.state;
    const subCategory = categories.find((item) => item.id === value);
    this.setState(
      {
        subCategory: subCategory,
        modeles: [],
        marques: [],
        marque: null,
        modele: null,
        typeCarburant: null,
        puissanceFiscale: '',
        kilometrage: '',
        anneeModele: '',
        boiteVitesse: null,
      },
      () => {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteAVitesse();
        this.activateSubmit();
      }
    );
  }

  handleClickCategory(value) {
    const { categories } = this.state;
    const category = categories.find((item) => item.id === value);
    this.setState(
      {
        category: category,
        subCategory: null,
        modeles: [],
        marques: [],
        marque: null,
        modele: null,
        typeCarburant: null,
        puissanceFiscale: '',
        kilometrage: '',
        anneeModele: '',
        boiteVitesse: null,
      },
      () => {
        this.getMarques();
        this.getTypesCarburant();
        this.getBoiteAVitesse();
        this.activateSubmit();
      }
    );
  }

  getSubCategoriesOptions() {
    const { categories, lang, category } = this.state;
    const filtredCategories = categories
      ? categories.filter((item: Category) => {
          return item.searchDesactivate === false;
        })
      : null;
    const allSubCategories = filtredCategories
      ? filtredCategories.filter((item: Category) => {
          return item.sousCategorie !== 0;
        })
      : null;
    const subCategories =
      allSubCategories && category
        ? allSubCategories.filter((item: Category) => {
            return item.sousCategorie.toString() === category.code;
          })
        : null;

    const finalSortedSubCategories = subCategories
      ? subCategories.map((item) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
          };
        })
      : null;
    ////////////console.log(category);
    return finalSortedSubCategories;
  }

  getCategoriesOptions() {
    const { categories, lang } = this.state;
    const principalCategories = categories
      ? categories.filter((item: Category) => {
          return item.sousCategorie === 0;
        })
      : null;

    const principalCategoriesFormatted = principalCategories
      ? principalCategories.map((item: Category) => {
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
          };
        })
      : null;
    return principalCategoriesFormatted;
  }

  getMarques() {
    const { category, subCategory, marqueId } = this.state;
    if (
      (category && category.marques !== null && category.marques !== '') ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '')
    ) {
      const idsMarques = subCategory
        ? subCategory.marques
          ? subCategory.marques
          : category.marques
        : category.marques
        ? category.marques
        : null;
      // //////////////console.log('getMarque called !');
      let url = process.env.REACT_APP_API_URL + constants.getMarques;
      const headers = new Headers({
        'Content-Type': 'application/json',
        accept: '*/*',
      });
      fetch(`${url}` + `?marques=${idsMarques}`, {
        method: 'POST',
        headers: headers,
      }).then((response) => {
        let marquesData = response.json();
        marquesData.then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ marques: jsonRequest }, () => {
              const marqueObject = jsonRequest.find((item: Marque) => item.id === marqueId);
              this.setState({ marque: marqueObject });
            });
          }
        });
      });
    }
  }

  async getTypesCarburant() {
    const { category, subCategory } = this.state;
    //console.log(category, subCategory);
    if (
      (category && category.marques !== null && category.marques !== '' && !category.forAnimal) ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '' && !category.forAnimal)
    ) {
      let url = process.env.REACT_APP_API_URL + constants.getTypesCarburant;
      const request = await fetch(url, {
        method: 'GET',
      });
      const jsonRequest = await request.json();
      if (jsonRequest) {
        this.setState({ typesCarburant: jsonRequest });
      }
    }
  }

  getBoiteAVitesse() {
    const { category, subCategory } = this.state;
    if (
      (category && category.marques !== null && category.marques !== '') ||
      (subCategory && subCategory.marques !== null && subCategory.marques !== '')
    ) {
      let url = process.env.REACT_APP_API_URL + constants.getBoiteAVitesses;

      return fetch(url, {
        method: 'GET',
      })
        .then((request) => {
          return request.json();
        })
        .then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ boiteAVitesses: jsonRequest });
            //////////console.log(jsonRequest);
          }
        });
    }
  }

  getModeleByMarque(idMarque: any) {
    const { marque } = this.state;
    const idMarqueState = marque ? marque.id : null;
    let url;

    if (idMarque) {
      url = process.env.REACT_APP_API_URL + constants.getModelesByMarque + '?idMarque=' + idMarque;
    } else {
      url = process.env.REACT_APP_API_URL + constants.getModelesByMarque + '?idMarque=' + idMarqueState;
    }
    //////////console.log(marqueId)
    return fetch(url, {
      method: 'GET',
    })
      .then((request) => {
        return request.json();
      })
      .then((jsonRequest) => {
        if (jsonRequest) {
          this.setState({ modeles: jsonRequest });
        }
      });
  }

  renderTypeAnnonceService(value) {
    if (value) {
      return (
        <IonGrid>
          <IonRow>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.offer.service'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="offres"
                />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.demand.service'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="demandes"
                />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.location.service'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="locations"
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
    return (
      <IonGrid>
        <IonRow>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.offer.service'} />
              </IonLabel>
              <IonRadio slot="start" value="offres" />
            </IonItem>
          </IonCol>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.demand.service'} />
              </IonLabel>
              <IonRadio slot="start" value="demandes" />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  renderTypeAnnonce(value) {
    if (value) {
      return (
        <IonGrid>
          <IonRow>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.offer'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="ventes"
                />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.demand'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="achats"
                />
              </IonItem>
            </IonCol>
            <IonCol size={'12'} sizeLg={'4'}>
              <IonItem className={'ion-radio-container'}>
                <IonLabel>
                  <FormattedMessage id={'search.location'} />
                </IonLabel>
                <IonRadio
                  disabled={
                    !this.checkRole('admin') &&
                    !this.checkRole('moderateur') &&
                    !this.checkRole('editeur') &&
                    !this.checkRole('commercial')
                  }
                  slot="start"
                  value="locations"
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
    return (
      <IonGrid>
        <IonRow>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.offer'} />
              </IonLabel>
              <IonRadio slot="start" value="ventes" />
            </IonItem>
          </IonCol>
          <IonCol size={'6'}>
            <IonItem className={'ion-radio-container'} lines={'full'}>
              <IonLabel>
                <FormattedMessage id={'search.demand'} />
              </IonLabel>
              <IonRadio slot="start" value="achats" />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  renderItemCategorie(category) {
    const {
      lang,
      surface,
      poids,
      marque,
      modele,
      anneeModele,
      kilometrage,
      typeCarburant,
      puissanceFiscale,
      boiteVitesse,
    } = this.state;
    //debugger;
    if (category.haveSurface) {
      return (
        <IonCol size={'12'}>
          <IonItem lines={'full'}>
            <div className={'label-container'}>
              <IonLabel position="floating">
                <FormattedMessage id={'post.title.surface'} />
              </IonLabel>
            </div>
            <IonInput
              value={surface}
              type={'text'}
              inputMode={'text'}
              name={'surface'}
              maxlength={60}
              placeholder={''}
              required={true}
              clearInput={true}
              onIonChange={(event) => {
                this.setState({ surface: event.detail.value! }, () => {});
              }}
            />
          </IonItem>
        </IonCol>
      );
    }

    if (category.haveWeight) {
      return (
        <IonCol size={'12'}>
          <IonItem lines={'full'}>
            <div className={'label-container'}>
              <IonLabel position="floating">
                <FormattedMessage id={'post.title.poids'} />
              </IonLabel>
            </div>
            <IonInput
              value={poids}
              type={'text'}
              inputMode={'text'}
              name={'poids'}
              maxlength={60}
              placeholder={''}
              required={true}
              clearInput={true}
              onIonChange={(event) => {
                this.setState({ poids: event.detail.value! }, () => {});
              }}
            />
          </IonItem>
        </IonCol>
      );
    }

    if (category !== null && category.marques !== null && category.marques !== '') {
      // @ts-ignore
      const { intl } = this.props;
      const { marques, modeles, typesCarburant, boiteAVitesses } = this.state;

      return (
        <IonRow className={`card mt-3`}>
          {marque == null && (
            <IonText style={requiredStyle}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </IonText>
          )}
          <IonCol size={'12'}>
            <IonItem lines={'full'}>
              <Autocomplete
                className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                options={
                  marques !== null &&
                  marques.length > 0 &&
                  marques.filter((item: any, index: number) => {
                    return item.id >= 1;
                  })
                }
                onChange={(event, values: any) => {
                  this.setState(
                    {
                      marque: values ? values : null,
                      modele: null,
                    },
                    () => {
                      this.getModeleByMarque(values ? values.id : null);
                      this.activateSubmit();
                    }
                  );
                }}
                noOptionsText={<FormattedMessage id={'no.option'} />}
                size={'small'}
                popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                openOnFocus={true}
                forcePopupIcon={true}
                closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                getOptionLabel={(option) => (option !== null ? (lang === 'fr' ? option.titreFr : option.titreAr) : '')}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id={'post.title.marque'} />}
                    margin="normal"
                    variant="standard"
                  />
                )}
                value={marque}
              />
            </IonItem>
          </IonCol>
          {/*  {!category.forAnimal && modeles.length > 0 && modele === null &&
                <IonText style={requiredStyle}>
                    <IonIcon style={{marginBottom: "-4px"}}
                             icon={alertCircle} size={'small'}/>
                    <FormattedMessage id={'form.required'}/>
                </IonText>}*/}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  disabled={marque === null || modeles === null || modeles.length === 0}
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={modeles !== null && modeles.length > 0 && modeles}
                  onChange={(event, values: any) => {
                    this.setState(
                      {
                        modele: values ? values : null,
                      },
                      () => {
                        this.activateSubmit();
                      }
                    );
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) =>
                    option !== null ? (lang === 'fr' ? option.titreFr : option.titreAr) : ''
                  }
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.modele'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={modele}
                />
              </IonItem>
            </IonCol>
          )}
          {/* {anneeModele === '' &&
                <IonText style={requiredStyle}>
                    <IonIcon style={{marginBottom: "-4px"}}
                             icon={alertCircle} size={'small'}/>
                    <FormattedMessage id={'form.required'}/>
                </IonText>}*/}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={anneeModele}
                  type={'text'}
                  inputMode={'text'}
                  name={'anneeModele'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.anneeModele)}
                  onIonChange={(event) => {
                    this.setState({ anneeModele: event.detail.value! }, () => {
                      this.activateSubmit();
                    });
                  }}
                />
              </IonItem>
            </IonCol>
          )}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={kilometrage}
                  type={'text'}
                  inputMode={'text'}
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  name={'kilometrage'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.kilometrage)}
                  onIonChange={(event) => {
                    this.setState({ kilometrage: event.detail.value! }, () => {
                      this.activateSubmit();
                    });
                  }}
                />
              </IonItem>
            </IonCol>
          )}
          {/*{typeCarburant === null &&
                <IonText style={requiredStyle}>
                    <IonIcon style={{marginBottom: "-4px"}}
                             icon={alertCircle} size={'small'}/>
                    <FormattedMessage id={'form.required'}/>
                </IonText>}*/}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={
                    typesCarburant !== null &&
                    typesCarburant.length > 0 &&
                    typesCarburant.filter((item: string, index: number) => {
                      return item !== '';
                    })
                  }
                  onChange={(event, values: any) => {
                    this.setState(
                      {
                        typeCarburant: values !== null ? values : null,
                      },
                      () => {
                        this.activateSubmit();
                      }
                    );
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) =>
                    option ? (
                      lang === 'fr' ? (
                        option.titreFr
                      ) : (
                        option.titreAr
                      )
                    ) : (
                      <FormattedMessage id={'post.title.typeCarburant'} />
                    )
                  }
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.typeCarburant'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={typeCarburant}
                />
              </IonItem>
            </IonCol>
          )}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <IonInput
                  value={puissanceFiscale}
                  type={'text'}
                  inputMode={'text'}
                  name={'puissanceFiscale'}
                  maxlength={60}
                  required={true}
                  clearInput={true}
                  placeholder={intl.formatMessage(langFunction.puissanceFiscale)}
                  onIonChange={(event) => {
                    this.setState({ puissanceFiscale: event.detail.value! }, () => {});
                  }}
                />
              </IonItem>
            </IonCol>
          )}
          {boiteVitesse === '' && (
            <IonText style={requiredStyle}>
              <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
              <FormattedMessage id={'form.required'} />
            </IonText>
          )}
          {!category.forAnimal && (
            <IonCol size={'12'}>
              <IonItem lines={'full'}>
                <Autocomplete
                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                  options={boiteAVitesses !== null && boiteAVitesses.length > 0 && boiteAVitesses}
                  onChange={(event, values: any) => {
                    this.setState(
                      {
                        boiteVitesse: values ? values : '',
                      },
                      () => {
                        this.activateSubmit();
                      }
                    );
                  }}
                  noOptionsText={<FormattedMessage id={'no.option'} />}
                  size={'small'}
                  popupIcon={<IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />}
                  openOnFocus={true}
                  forcePopupIcon={true}
                  closeIcon={<IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />}
                  getOptionLabel={(option) =>
                    option ? (
                      lang === 'fr' ? (
                        option.titreFr
                      ) : (
                        option.titreAr
                      )
                    ) : (
                      <FormattedMessage id={'post.title.typeCarburant'} />
                    )
                  }
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id={'post.title.boiteVitesse'} />}
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  value={boiteVitesse}
                />
              </IonItem>
            </IonCol>
          )}
        </IonRow>
      );
    }
  }

  render() {
    const {
      images,
      type,
      urgent,
      category,
      categoryC,
      subCategoryC,
      isSubmitting,
      city,
      cities,
      titleFr,
      titleAr,
      description,
      price,
      enableSubmit,
      lang,
      telephoneAnnonce,
      nomUtilisateurAnnonce,
      emailAnnonce,
      subCategory,
      userShops,
      shop,
      secondaryPhone,
      commercialShops,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    const categoryPlaceholder = intl.formatMessage(langFunction.categoryPlaceholder);
    const subCategoryPlaceholder = intl.formatMessage(langFunction.subCategoryPlaceholder);

    const categoryOptions = this.getCategoriesOptions();
    const subCategoriesOptions = this.getSubCategoriesOptions();

    const constantAllCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';

    const cityObject = cities.find((item: City) => {
      return item.id === Number(city);
    });
    const cityName = cityObject ? (lang === 'fr' ? cityObject.nomFr : cityObject.nomArab) : '';
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent className={'SendPostPage'}>
          {this.showAlertMessage()}
          <div className={'container mbm-100'}>
            <Radium.StyleRoot>
              <StylesProvider jss={jss}>
                <IonGrid>
                  <IonRow>
                    <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                      <div className={'SendPostForm'}>
                        <IonGrid>
                          <IonRow className={`card mt-5`}>
                            <IonCol size={'12'} className={'card-header'}>
                              <h4 className={'card-title'}>
                                <FormattedMessage id={'post.info'} />
                              </h4>
                            </IonCol>
                            <IonCol size={'12'}>
                              <IonItem className="category-item">
                                <div className="category-label-text">
                                  <IonLabel className={'category-label'} position="stacked">
                                    <FormattedMessage id={'form.category'} />
                                  </IonLabel>
                                </div>
                                <div className="category-select-container w-100 mb-1">
                                  <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                                    <CssTextField
                                      value={categoryC ? categoryC : constantAllCategories}
                                      id="outlined-textarea"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            categoryC: e.target.value,
                                            subCategoryC: lang === 'ar' ? 'للبيع' : 'a_vendre',
                                          },
                                          () => {
                                            this.activateSubmit();
                                            //this.getMarques()
                                          }
                                        );
                                      }}
                                      select
                                      disabled={
                                        !this.checkRole('admin') &&
                                        !this.checkRole('moderateur') &&
                                        !this.checkRole('editeur') &&
                                        !this.checkRole('commercial')
                                      }
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    >
                                      {lang === 'fr' ? (
                                        <MenuItem key={0} value="a_vendre" onClick={() => this.handleNone()}>
                                          <ListItemText primary={categoryPlaceholder} />
                                        </MenuItem>
                                      ) : (
                                        <MenuItem key={1} value="للبيع" onClick={() => this.handleNone()}>
                                          <ListItemText primary={categoryPlaceholder} />
                                        </MenuItem>
                                      )}
                                      {categoryOptions
                                        ? categoryOptions.map((item) => {
                                            return (
                                              <MenuItem
                                                style={styleMenuItem(item.sousCategorie)}
                                                dense
                                                value={item.value}
                                                key={item.id}
                                                onClick={() => this.handleClickCategory(item.id)}
                                              >
                                                {item.sousCategorie === 0 && (
                                                  <ListItemAvatar>
                                                    <Avatar style={styleAvatar(item.sousCategorie)}>
                                                      <picture>
                                                        <source
                                                          type="image/webp"
                                                          srcSet={`${process.env.REACT_APP_API_URL}${
                                                            constants.pictures
                                                          }/categories/${item.nomImage.replace(
                                                            '.jpeg',
                                                            '.webp'
                                                          )}?small=true`}
                                                        />
                                                        <img
                                                          loading="lazy"
                                                          src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`}
                                                          alt={item.value}
                                                        />
                                                      </picture>
                                                    </Avatar>
                                                  </ListItemAvatar>
                                                )}
                                                <ListItemText primary={item.label} />
                                              </MenuItem>
                                            );
                                          })
                                        : null}
                                    </CssTextField>
                                  </ThemeProvider>
                                </div>
                              </IonItem>
                              {category && subCategoriesOptions.length > 0 && (
                                <IonItem className="category-item">
                                  <div className="category-label-text">
                                    <IonLabel className={'category-label'} position="stacked">
                                      <FormattedMessage id={'form.subCategory'} />
                                    </IonLabel>
                                  </div>
                                  <div className="category-select-container w-100 mb-1">
                                    <ThemeProvider theme={lang === 'ar' ? rtlTheme : ltrTheme}>
                                      <CssTextField
                                        value={subCategoryC ? subCategoryC : constantAllCategories}
                                        id="outlined-textarea"
                                        onChange={(e) => {
                                          this.setState({ subCategoryC: e.target.value }, () => {
                                            this.activateSubmit();
                                          });
                                        }}
                                        select
                                        disabled={
                                          !this.checkRole('admin') &&
                                          !this.checkRole('moderateur') &&
                                          !this.checkRole('editeur') &&
                                          !this.checkRole('commercial')
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      >
                                        {lang === 'fr' ? (
                                          <MenuItem
                                            key={0}
                                            value="a_vendre"
                                            onClick={() => this.setState({ subCategory: null })}
                                          >
                                            <ListItemText primary={subCategoryPlaceholder} />
                                          </MenuItem>
                                        ) : (
                                          <MenuItem
                                            key={1}
                                            value="للبيع"
                                            onClick={() => this.setState({ subCategory: null })}
                                          >
                                            <ListItemText primary={subCategoryPlaceholder} />
                                          </MenuItem>
                                        )}
                                        {subCategoriesOptions
                                          ? subCategoriesOptions.map((item) => {
                                              return (
                                                <MenuItem
                                                  style={styleMenuItem(item.sousCategorie)}
                                                  dense
                                                  value={item.value}
                                                  key={item.id}
                                                  onClick={() => this.handleClickSubCategory(item.id)}
                                                >
                                                  <ListItemAvatar>
                                                    <Avatar
                                                      style={{ backgroundColor: 'var(--ion-color-medium-contrast)' }}
                                                    >
                                                      <picture>
                                                        <source
                                                          type="image/webp"
                                                          srcSet={`${process.env.REACT_APP_API_URL}${
                                                            constants.pictures
                                                          }/categories/${item.nomImage.replace(
                                                            '.jpeg',
                                                            '.webp'
                                                          )}?small=true`}
                                                        />
                                                        <img
                                                          loading="lazy"
                                                          src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${item.nomImage}?small=true`}
                                                          alt={item.value}
                                                        />
                                                      </picture>
                                                    </Avatar>
                                                  </ListItemAvatar>
                                                  <ListItemText primary={item.label} />
                                                </MenuItem>
                                              );
                                            })
                                          : null}
                                      </CssTextField>
                                    </ThemeProvider>
                                  </div>
                                </IonItem>
                              )}
                              {(userShops?.length > 0 || (!this.checkRole('user') && commercialShops?.length > 0)) && (
                                <IonCol size={'12'}>
                                  <IonItem>
                                    <Autocomplete
                                      className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                                      options={
                                        !this.checkRole('user') && commercialShops.length > 0
                                          ? userShops?.length > 0
                                            ? userShops.concat(commercialShops)
                                            : commercialShops
                                          : userShops
                                      }
                                      onChange={(event, value: any) => {
                                        this.setState({ shop: value ?? null }, () => {
                                          this.activateSubmit();
                                        });
                                      }}
                                      noOptionsText={<FormattedMessage id={'no.option'} />}
                                      size={'small'}
                                      popupIcon={
                                        <IonIcon size={'small'} icon={caretDownOutline} style={{ float: 'right' }} />
                                      }
                                      openOnFocus={true}
                                      forcePopupIcon={true}
                                      closeIcon={
                                        <IonIcon size={'small'} icon={closeOutline} style={{ float: 'right' }} />
                                      }
                                      getOptionLabel={(option) =>
                                        option ? option.name : <FormattedMessage id={'shop.form.choose.label'} />
                                      }
                                      style={{ width: '100%' }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={<FormattedMessage id={'shop.form.choose.label'} />}
                                          margin="normal"
                                          variant="standard"
                                        />
                                      )}
                                      value={shop}
                                    />
                                  </IonItem>
                                </IonCol>
                              )}
                              {category !== null && (
                                <IonCol size={'12'}>
                                  <IonRadioGroup
                                    value={type}
                                    onIonChange={(e) => {
                                      this.setState({ type: e.detail.value }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  >
                                    <IonListHeader>
                                      <IonLabel>
                                        <FormattedMessage id={'post.type'} />
                                      </IonLabel>
                                    </IonListHeader>
                                    {category.id === 117 ||
                                    category.id === 116 ||
                                    category.id === 130 ||
                                    category.id === 131
                                      ? this.renderTypeAnnonceService(
                                          subCategory ? subCategory.forRental : category.forRental
                                        )
                                      : this.renderTypeAnnonce(
                                          subCategory ? subCategory.forRental : category.forRental
                                        )}
                                  </IonRadioGroup>
                                </IonCol>
                              )}
                            </IonCol>
                            {!this.checkRole('user') && !this.checkRole('commercial') ? (
                              <IonCol size={'12'}>
                                <IonItem>
                                  <IonToggle
                                    checked={urgent}
                                    onIonChange={(e) => {
                                      this.setState({ urgent: e.detail.checked }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                  <IonLabel>
                                    <FormattedMessage id={'post.urgent'} />
                                  </IonLabel>
                                </IonItem>
                              </IonCol>
                            ) : null}
                            {((lang === 'fr' && this.checkUserRole('user')) || !this.checkUserRole('user')) && (
                              <IonCol size={'12'}>
                                <IonItem>
                                  <IonLabel position="floating">
                                    {category !== null && category.id === 117 ? (
                                      <FormattedMessage id={'post.title.veterinaire.1'} />
                                    ) : (
                                      <FormattedMessage id={'post.title.1'} />
                                    )}
                                  </IonLabel>
                                  <IonInput
                                    value={titleFr}
                                    type={'text'}
                                    inputMode={'text'}
                                    name={'name'}
                                    maxlength={60}
                                    placeholder={intl.formatMessage(
                                      category !== null && category.id === 117
                                        ? langFunction.postTitle1VeterinairePlaceholder
                                        : langFunction.postTitle1Placeholder
                                    )}
                                    onIonChange={(event) => {
                                      this.setState({ titleFr: event.detail.value }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                  {titleFr === '' && (
                                    <IonText style={requiredStyle} slot={'end'}>
                                      <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                      <FormattedMessage id={'form.required'} />
                                    </IonText>
                                  )}
                                </IonItem>
                              </IonCol>
                            )}
                            {((lang === 'ar' && this.checkUserRole('user')) || !this.checkUserRole('user')) && (
                              <IonCol size={'12'}>
                                <IonItem>
                                  {titleAr === '' && (
                                    <IonText style={requiredStyle} slot={'end'}>
                                      <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                      <FormattedMessage id={'form.required'} />
                                    </IonText>
                                  )}
                                  <IonLabel position="floating">
                                    {category !== null && category.id === 117 ? (
                                      <FormattedMessage id={'post.title.veterinaire.2'} />
                                    ) : (
                                      <FormattedMessage id={'post.title.2'} />
                                    )}
                                  </IonLabel>
                                  <IonInput
                                    value={titleAr}
                                    type={'text'}
                                    inputMode={'text'}
                                    name={'name'}
                                    maxlength={60}
                                    placeholder={intl.formatMessage(
                                      category !== null && category.id === 117
                                        ? langFunction.postTitle2VeterinairePlaceholder
                                        : langFunction.postTitle2Placeholder
                                    )}
                                    onIonChange={(event) => {
                                      this.setState({ titleAr: event.detail.value }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                </IonItem>
                              </IonCol>
                            )}
                            <IonCol size={'12'}>
                              <IonItem>
                                {description === '' && (
                                  <IonText
                                    style={{
                                      color: 'red',
                                      fontWeight: 'bold',
                                      fontSize: '10px',
                                    }}
                                    slot={'end'}
                                  >
                                    <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                    <FormattedMessage id={'form.required'} />
                                  </IonText>
                                )}
                                <IonLabel position="stacked">
                                  {category !== null && category.id === 117 ? (
                                    <FormattedMessage id={'post.description.veterinaire'} />
                                  ) : (
                                    <FormattedMessage id={'post.description'} />
                                  )}
                                </IonLabel>
                                <ReactQuill
                                  className={'w-100'}
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  value={description}
                                  placeholder={intl.formatMessage(
                                    category !== null && category.id === 117
                                      ? langFunction.postDescriptionVeterinairPlaceholder
                                      : langFunction.postDescriptionPlaceholder
                                  )}
                                  onChange={(value) => {
                                    this.setState({ description: value }, () => {
                                      this.activateSubmit();
                                    });
                                  }}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size={'12'}>
                              <IonItem>
                                <IonLabel position="floating">
                                  <FormattedMessage id={'post.price'} /> <br />
                                  <small className={'text-info'}>
                                    <FormattedMessage id={'post.price.info'} />
                                  </small>
                                </IonLabel>
                                <IonInput
                                  value={price}
                                  type={'number'}
                                  min={'0'}
                                  step={'1'}
                                  inputMode={'text'}
                                  name={'name'}
                                  placeholder={intl.formatMessage(langFunction.postPricePlaceholder)}
                                  onIonChange={(event) => {
                                    this.setState({ price: parseFloat(event.detail.value) }, () => {
                                      this.activateSubmit();
                                    });
                                  }}
                                />
                              </IonItem>
                            </IonCol>
                            {category !== null &&
                              this.renderItemCategorie(subCategory !== null ? subCategory : category)}
                            <IonCol size={'12'}>
                              <IonItem className={'item-has-value'}>
                                <Autocomplete
                                  className={`cityAutoComplete ${lang === 'ar' ? 'cityAutoCompleteRTL' : ''}`}
                                  options={cities.filter((item: City, index: number) => {
                                    return item.id >= 1;
                                  })}
                                  onChange={(event, values: any) => {
                                    this.setState(
                                      {
                                        city: values ? values.id : null,
                                      },
                                      () => {
                                        this.activateSubmit();
                                      }
                                    );
                                  }}
                                  getOptionLabel={(option) =>
                                    lang === 'fr'
                                      ? option
                                        ? option.nomFr.replace(' -', '')
                                        : ''
                                      : option
                                      ? option.nomArab.replace(' –', '')
                                      : ''
                                  }
                                  style={{ width: '100%' }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={city === null ? <FormattedMessage id={'post.city'} /> : cityName}
                                      margin="normal"
                                      variant="standard"
                                    />
                                  )}
                                />
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                        {!this.checkRole('user') && !this.checkRole('commercial') ? (
                          <IonGrid>
                            <IonRow className={`card mt-5`}>
                              <IonCol size={'12'} className={'card-header'}>
                                <h4 className={'card-title'}>
                                  <FormattedMessage id={'post.info.user'} />
                                </h4>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem>
                                  <IonLabel position="floating">
                                    <FormattedMessage id={'post.info.user.nom'} />
                                  </IonLabel>
                                  <IonInput
                                    value={nomUtilisateurAnnonce}
                                    type={'text'}
                                    inputMode={'text'}
                                    name={'name'}
                                    placeholder={intl.formatMessage(langFunction.postNomAnnoncePlaceholder)}
                                    onIonChange={(event) => {
                                      this.setState({ nomUtilisateurAnnonce: event.detail.value! }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                  {!nomUtilisateurAnnonce && nomUtilisateurAnnonce === '' && (
                                    <IonText style={requiredStyle} slot={'end'}>
                                      <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                      <FormattedMessage id={'form.required'} />
                                    </IonText>
                                  )}
                                </IonItem>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem>
                                  <div className={'label-container'}>
                                    <IonLabel position={'stacked'}>
                                      <FormattedMessage id={'post.info.user.telephone'} />
                                    </IonLabel>
                                    {!telephoneAnnonce && (
                                      <IonText style={requiredStyle}>
                                        <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                        <FormattedMessage id={'form.required'} />
                                      </IonText>
                                    )}
                                    {!isPossiblePhoneNumber(String(telephoneAnnonce)) && (
                                      <IonText style={requiredStyle}>
                                        <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                        <FormattedMessage id={'form.phone.invalid'} />
                                      </IonText>
                                    )}
                                  </div>
                                  <PhoneInput
                                    labels={lang === 'fr' ? fr : ar}
                                    international={false}
                                    countryOptionsOrder={['MA', 'FR', '|']}
                                    defaultCountry="MA"
                                    placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                    value={telephoneAnnonce}
                                    onChange={(value) => {
                                      this.setState({ telephoneAnnonce: String(value) }, () => this.activateSubmit());
                                    }}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem>
                                  <div className={'label-container'}>
                                    <IonLabel position={'stacked'}>
                                      <FormattedMessage id={'post.info.user.secondary.phone'} />
                                    </IonLabel>
                                    {secondaryPhone && !isPossiblePhoneNumber(String(secondaryPhone)) && (
                                      <IonText style={requiredStyle}>
                                        <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                        <FormattedMessage id={'form.phone.invalid'} />
                                      </IonText>
                                    )}
                                  </div>
                                  <PhoneInput
                                    labels={lang === 'fr' ? fr : ar}
                                    international={false}
                                    countryOptionsOrder={['MA', 'FR', '|']}
                                    defaultCountry="MA"
                                    placeholder={intl.formatMessage(langFunction.postTelAnnoncePlaceholder)}
                                    value={secondaryPhone}
                                    onChange={(value) => {
                                      this.setState({ secondaryPhone: String(value) }, () => this.activateSubmit());
                                    }}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol size={'12'}>
                                <IonItem>
                                  <IonLabel position="floating">
                                    <FormattedMessage id={'post.info.user.email'} />
                                  </IonLabel>
                                  <IonInput
                                    value={emailAnnonce}
                                    type={'text'}
                                    inputMode={'text'}
                                    name={'name'}
                                    placeholder={intl.formatMessage(langFunction.postEmailPlaceholder)}
                                    onIonChange={(event) => {
                                      this.setState({ emailAnnonce: event.detail.value! }, () => {
                                        this.activateSubmit();
                                      });
                                    }}
                                  />
                                  {/* {!emailAnnonce && emailAnnonce === '' &&
                                                                    <IonText style={requiredStyle} slot={'end'}>
                                                                        <IonIcon style={{marginBottom: "-4px"}}
                                                                                 icon={alertCircle} size={'small'}/>
                                                                        <FormattedMessage id={'form.required'}/>
                                                                    </IonText>}*/}
                                  {!validateEmail(emailAnnonce) && emailAnnonce !== '' && (
                                    <IonText style={requiredStyle} slot={'end'}>
                                      <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                                      <FormattedMessage id={'form.email.invalid'} />
                                    </IonText>
                                  )}
                                </IonItem>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        ) : null}
                        <IonGrid>
                          <IonRow>
                            <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'} className={'send-button'}>
                              <button
                                type={'submit'}
                                className={'btn btn-block'}
                                style={{
                                  backgroundColor: 'var(--ion-color-primary)',
                                  color: 'var(--ion-color-light)',
                                }}
                                onClick={this.onSubmitPost}
                                disabled={!enableSubmit || isSubmitting}
                              >
                                {!isSubmitting ? <FormattedMessage id={'form.send'} /> : <IonSpinner name="crescent" />}
                                <IonIcon size={'small'} icon={createOutline} style={{ float: 'right' }} />
                              </button>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </IonCol>
                    <IonCol sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                      <GallerySelector
                        shop={shop}
                        value={images}
                        onChange={(images: Array<string>) => {
                          this.setState({ images: images }, () => {
                            this.activateSubmit();
                          });
                        }}
                      />
                    </IonCol>
                    <IonCol sizeLg={'12'} sizeMd={'12'} sizeSm={'12'} className={'end-send-button'}>
                      <button
                        type={'submit'}
                        className={'btn btn-block'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        onClick={this.onSubmitPost}
                        disabled={!enableSubmit || isSubmitting}
                      >
                        {!isSubmitting ? <FormattedMessage id={'form.send'} /> : <IonSpinner name="crescent" />}
                        <IonIcon size={'small'} icon={createOutline} style={{ float: 'right' }} />
                      </button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </StylesProvider>
            </Radium.StyleRoot>
          </div>
          <Footer copyrightOnly />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Modifier une annonce</title>
      </Helmet>
    );
  }

  checkUserRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }
}

// @ts-ignore
export default injectIntl(UpdatePost);

interface Props extends FlahaProps {}

interface State extends FlahaState {
  images: Array<string>;
  categories: Array<Category>;
  category: Category | null;
  subCategory: Category | null;
  categoryC?: string | null | undefined;
  subCategoryC?: string | null | undefined;
  cities: Array<City>;
  city: City | null;
  type: 'ventes' | 'achats' | 'locations' | 'offres' | 'achats';
  urgent: boolean;
  titleFr: string;
  titleAr: string;
  description: string;
  price: number;
  enableSubmit: boolean;
  token: string | null;
  slug: string | null;
  id: number | null;
  telephoneAnnonce: any;
  nomUtilisateurAnnonce: string;
  emailAnnonce: string;
  showAlert: boolean;
  isSubmitting: boolean;
  marques: Array<Marque>;
  modeles: Array<Modele>;
  typesCarburant: Array<any>;
  boiteAVitesses: Array<any>;
  surface: string;
  poids: string;
  marque: Marque;
  modele: Modele;
  anneeModele: string;
  kilometrage: string;
  typeCarburant: any;
  puissanceFiscale: string;
  boiteVitesse: any;
  receivedCategory: number;
  marqueId: number;
  userOwnerEmail: string;
  userShops: Array<Shop>;
  commercialShops: Array<Shop>;
  shop: Shop;
  secondaryPhone?: string;
}
