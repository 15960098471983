import React, {Component} from "react";
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import './BlogContainer.scss';
import {IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonRow, IonItem, IonIcon, IonLabel, IonButton, IonCardContent, IonCardTitle, IonRouterLink} from "@ionic/react";
import {BlogData, constants, Events} from "utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {homeOutline, caretForwardOutline } from "ionicons/icons";

class BlogContainer extends Component<Props, State> {

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
            posts: BlogData,
        }
    }

    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    renderItem(item: object) {

        // @ts-ignore
        const {id, image, title, titleAr, description, descriptionAr} = item;
        const {lang} = this.state;

        return (
            <IonCol key={id} size={'3'} sizeLg={'3'} sizeMd={'3'} sizeSm={'6'} sizeXs={'12'}>
                <IonCard routerLink={`/blog/${title}`}>
                    <img src={image ? image : '/assets/Logo_flaha.svg'} alt={''}/>
                    <IonCardHeader>
                        <h2>{lang === 'fr' ? title : titleAr}</h2>
                        <IonCardSubtitle>{lang === 'fr' ? description.truncate(30) : descriptionAr.truncate(30)}</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>
        )
    }

    // blog(){
    //     let {posts} = this.state;
    //     posts = [
    //         <IonCard>
    //         <IonItem>
    //           <IonIcon icon={pin} slot="start" />
    //           <IonLabel>ion-item in a card, icon left, button right</IonLabel>
    //           <IonButton fill="outline" slot="end">View</IonButton>
    //         </IonItem>
    //         <IonCardContent>
    //           This is content, without any paragraph or header tags,
    //           within an ion-cardContent element.
    //         </IonCardContent>
    //       </IonCard>

    //     ]
    // }

    render() {

        return (
            <div className={'container blogContainer mbm-100'}>
                <IonItem lines={"none"} color={'none'}>
                            <IonRouterLink href={'/'}>
                                <IonIcon size={'small'} icon={homeOutline} style={{marginRight:'4px'}}/>
                                <FormattedMessage id={'home'}/>
                            </IonRouterLink>
                            <IonIcon size={'small'} icon={caretForwardOutline}/>
                            <IonLabel>
                                    <FormattedMessage id={'menu.blog.info'}/>
                            </IonLabel>
                    </IonItem>
                {/* <h1 className={'text-center'}><FormattedMessage id={'menu.blog.info'}/></h1> */}
                <IonGrid>
                    <IonRow>
                        {/* {posts.length > 0 ? posts.map((item: object, index: number) => this.renderItem(item, index)) : <NoContent title={intl.formatMessage(langFunction.post)}/>} */}
                        <IonCol>
                        <IonCard>
                            <IonCardHeader>
                            <img src={'assets/img/blog1.jpg'} alt="flaha.ma"/>
                            <IonCardSubtitle>8/6/2020</IonCardSubtitle>
                            <IonCardTitle>Madison, WI</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                            Founded in 1829 on an isthmus between Lake Monona and Lake Mendota, Madison was named the capital of the Wisconsin Territory in 1836.
                            </IonCardContent>
                            <IonItem>
                            <IonButton routerLink={'/blog/slug'} fill="solid" slot="start" expand={'block'}>Voir plus</IonButton>
                            </IonItem>
                        </IonCard>
                        </IonCol>
                        <IonCol>
                        <IonCard>
                            <IonCardHeader>
                            <img src={'assets/img/blog2.jpg'} alt="flaha.ma"/>

                            <IonCardSubtitle>8/6/2020</IonCardSubtitle>
                            <IonCardTitle>Madison, WI</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                            Founded in 1829 on an isthmus between Lake Monona and Lake Mendota, Madison was named the capital of the Wisconsin Territory in 1836.
                            </IonCardContent>
                            <IonItem>
                            <IonButton routerLink={'/blog/slug'} fill="solid" slot="start" expand={'block'}>Voir plus</IonButton>
                            </IonItem>
                        </IonCard>
                        </IonCol>

                        <IonCol>
                        <IonCard>
                            <IonCardHeader>
                            <img src={'assets/img/blog3.jpg'}  alt="flaha.ma"/>

                            <IonCardSubtitle>6/6/2020</IonCardSubtitle>
                            <IonCardTitle>Madison, WI</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                            Founded in 1829 on an isthmus between Lake Monona and Lake Mendota, Madison was named the capital of the Wisconsin Territory in 1836.
                            </IonCardContent>
                            <IonItem>
                            <IonButton routerLink={'/blog/slug'} fill="solid" slot="start" expand={'block'}>Voir plus</IonButton>
                            </IonItem>
                        </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        );
    }
}

interface Props extends FlahaProps {

}

interface State extends FlahaState {
    posts: Array<object>;
}
// @ts-ignore
export default injectIntl(BlogContainer);
