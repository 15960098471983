import { Footer, Header } from 'components';
import Conversation from 'components/Conversation/Conversation';
import Message from 'components/message/Message';
import React, { useEffect, useRef, useState } from 'react';
import './chat.scss';
import { getLanguage, getUserProfile, getUserToken, removeUserToken, setUserToken } from 'utils/Functions/Functions';
import { constants } from 'utils';
import { format } from 'timeago.js';
import axios from 'axios';

export default function Chat() {
  const [conversation, setConversation] = useState([]);
  const [search, setSearch] = useState(null);
  const [token, setToken] = useState(null);
  const [currentChat, setCurrentChat] = useState(null);
  const [currentMsg, setCurrentMsg] = useState([]);
  const [messages, setMessages] = useState([]);
  const [lang, setLang] = useState('ar');
  const [newMessage, setNewMessage] = useState('');
  const [filteredChats, setFilterChats] = useState([]);
  const scrollRef = useRef();

  getLanguage().then((lang) => setLang(lang));  
  let title = 'selectionner une conversation pour commencer le chat ';
  let send = 'envoyer';
  if (lang !== 'fr') {
    title = 'اختر محادتة للبدء';
    send = 'ارسال';
    
  }

  const getToken = async () => {
    await getUserToken().then((data) => {
      if (data === undefined) {
        setToken(null);
      } else {
        setToken(data);
      }
    });
  };

  const getConversation = async () => {
    let token = localStorage.getItem('@flaha.userToken');
    let bearer = 'Bearer ' + token;
    //console.log(bearer);
    const headers = new Headers({
      Authorization: bearer,
    });
    let url = process.env.REACT_APP_API_URL + constants.userChatChannel;
    const response = window
      .fetch(`${url}`, {
        credentials: 'same-origin',
        //mode: 'no-cors',
        headers: headers,
      })
      .then((response) => response.json())
      .then((ChatList) => {
        ChatList.sort((a, b) => b.modifiedAt.localeCompare(a.modifiedAt));
        setConversation(ChatList);
      });
  };

  useEffect(() => {
    getToken();
    getConversation();
  }, []);

  useEffect(() => {
    const getMessages = async () => {
      let token = localStorage.getItem('@flaha.userToken');
      let bearer = 'Bearer ' + token;
      if (currentChat == null) {
        setMessages(null);
      } else {
        let slug = currentChat.url;
        //console.log(bearer);
        const headers = new Headers({
          Authorization: bearer,
        });
        let url = process.env.REACT_APP_API_URL + constants.messageChatChannel;
        const response = window
          .fetch(`${url}${slug}`, {
            credentials: 'same-origin',
            //mode: 'no-cors',
            headers: headers,
          })
          .then((response) => response.json())
          .then((Messages) => {
            setMessages(Messages);
          });
      }
    };
    getMessages();
  }, [currentChat]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = {
      message: newMessage,
    };
    const msg = {
      message: newMessage,
      email:getUserProfile().email,
    };
    let token = localStorage.getItem('@flaha.userToken');
    let bearer = 'Bearer ' + token;
    let slug = currentChat.url;
    //console.log(bearer);
    const headers = new Headers({
      Authorization: bearer,
      "Content-type": "application/json"
    });
    
    let url = process.env.REACT_APP_API_URL+constants.addMessageChannel;   
    const response =  await fetch(`${url}` + `?url=${slug}`,{
      method: 'POST',
      credentials: 'same-origin',
      headers: headers,
      body: JSON.stringify(message),
    })
    .then((response) => response.json())
          .then((msg) => {
            setMessages([...messages,msg]);
            currentMsg.push(msg);
            setNewMessage("");          });
            console.log(msg);
           
        
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView();

  },[messages])
  useEffect(() => {
    filterChat(conversation, search)
  },[conversation,search])
  function filterChat(conversation, search) {
   
    const result = conversation.filter(({ seller }) => {
        return !search ||seller.nom.toLowerCase().includes(search.toLowerCase())
    })
    const result2 = conversation.filter(({ buyer }) => {
      return !search ||buyer.nom.toLowerCase().includes(search.toLowerCase())
  })
    result.length != 0 ? setFilterChats(result) : setFilterChats(result2)
}
  

  
  return (

    <>
   <Header />
      <div className="messenger">
        <div className="chatMenu">
        <input className="chatMenuInput"
                type="text"
                placeholder="Search chat"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />  
          <div className="chatMenuWrapper">
                   
             {filteredChats.map((c) => (
              <div onClick={() => setCurrentChat(c)}>
                <Conversation key={c.id} conversation={c} />
              </div>
            ))}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentChat ? (
              <>
                <div className="chat-contact">
                  <a
                    href={
                      lang === 'ar'
                        ? `/ar/${currentChat.annonce.ville.province.region.urlArab}/${
                            currentChat.annonce.ville.nomArab
                          }/${currentChat.annonce.categorie.urlArabe}/${
                            currentChat.annonce.slug
                              ? currentChat.annonce.slug.replace(
                                  currentChat.annonce.id + '',
                                  '_' + currentChat.annonce.id
                                )
                              : ''
                          }.htm`
                        : `/fr/${currentChat.annonce.ville.province.region.urlFr}/${currentChat.annonce.ville.nomFr}/${
                            currentChat.annonce.categorie.urlFrancais
                          }/${
                            currentChat.annonce.slug
                              ? currentChat.annonce.slug.replace(
                                  currentChat.annonce.id + '',
                                  '_' + currentChat.annonce.id
                                )
                              : ''
                          }.htm`
                    }
                  >
                    <img
                      className="conversationImg"
                      loading="lazy"
                      decoding={'async'}
                      src={`${
                        currentChat.annonce.premierePhoto && currentChat.annonce.premierePhoto.chemin
                          ? process.env.REACT_APP_API_URL +
                            constants.pictures +
                            currentChat.annonce.premierePhoto.chemin +
                            '?small=false'
                          : '/assets/img/no_images.webp'
                      }`}
                    />
                  </a>
                  <div className="chat-contact-details">
                    <p>
                      {getUserProfile().email === currentChat.annonce.utilisateur.email
                        ? currentChat.buyer.nom
                        : currentChat.seller.nom}
                    </p>
                    <p color="medium">last seen {format(currentChat.createdAt)}</p>
                  </div>
                </div>
                <div className="chatBoxTop">
                  {messages ? (
                    <>
                      {' '}
                      {messages.map((m) => (
                        <div ref={scrollRef}>
                        <Message
                          key={m.id}
                          message={m}
                          lang={lang==='ar'}
                          own={currentMsg.includes(m)||getUserProfile().email === m.utilisateur.email}
                          conversation={currentChat}
                        /></div>
                      ))}{' '}
                    </>
                  ) : null}
                </div>
                <div className="chatBoxBottom">
                  <textarea
                    className="chatMessageInput"
                    placeholder="write something ..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  ></textarea>
                  <button className="chatSubmitButton"  onClick={newMessage!= ""? handleSubmit : null}>
                    {send}
                  </button>
                </div>
              </>
            ) : (
              <span className="noConversationText">{title} </span>
            )}
          </div>
        </div>
      </div>
 
    </>
  );
}
