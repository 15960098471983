import React, { Component, MouseEvent } from 'react';
import './MobileMenu.scss';
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonModal,
  IonRouterLink,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import {
  addOutline,
  arrowBackOutline,
  bookmarkOutline,
  caretDownOutline,
  chevronBackOutline,
  chevronForwardOutline,
  home,
  logoFacebook,
  logoInstagram,
  logoYoutube,
  mailOutline,
  peopleOutline,
  personCircleOutline,
  searchOutline,
  shieldCheckmarkOutline,
  storefrontOutline,
} from 'ionicons/icons';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { FormattedMessage } from 'react-intl';
import { constants, Events, getUserToken } from 'utils';
import { getUserProfile, setLanguage } from 'utils/Functions/Functions';
import { connect } from 'react-redux';
import { getCategoriesReduxUtils, getCitiesReduxUtils, getRegionsReduxUtils } from 'utils/Functions/ReferentialGetters';
import { menuController } from '@ionic/core';
import shopService from '../../services/ShopService';
import { Shop } from 'utils/Types/types';
import NotificationShop from '../NotificationShop/NotificationShop';
import Header from '../Header/Header';
import { InputAdornment } from '@material-ui/core';
import NotificationFundRequest from '../NotificationFundRequest/NotificationFundRequest';
import NotificationLeads from '../NotificationLeads/NotificationLeads';

const regionCategorySearchKey = '@flaha.regionCategorySearch';
const regionCategoryKey = '@flaha.regioncategory';
const androidAppVersionKey = '@flaha.androidAppVersion';

class MobileMenu extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      userToken: null,
      headerShowLang: false,
      headerShowLangSub: false,
      userShops: [],
    };
    this.handleLangToggle = this.handleLangToggle.bind(this);
    this.checkRole = this.checkRole.bind(this);
  }

  // @ts-ignore
  handleLangToggle(event: MouseEvent) {
    event.preventDefault();
    const { type } = event;

    setTimeout(() => {
      this.setState({ headerShowLang: type === 'blur' ? false : !this.state.headerShowLang });
    }, 200);
  }

  getRegionsCategories() {
    let regions;
    let categories;
    let cities;

    categories = getCategoriesReduxUtils();
    cities = getCitiesReduxUtils();
    regions = getRegionsReduxUtils();

    this.props.saveRegionsCategories(regions, categories, cities);
  }

  getRequestParams(filter) {
    let params = {};
    if (filter) {
      params['filter'] = filter;
    }
    return params;
  }

  getUserShops() {
    const filter = {
      shopOwnerEmail: String(getUserProfile().email),
      disabled: false,
      moderated: null,
    };

    const params = this.getRequestParams(JSON.stringify(filter));
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    };

    if (getUserProfile().email) {
      shopService
        .getAll(options)
        .then((value) => {
          const { shops } = value.data;
          this.setState({ userShops: [...shops] });
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    }
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.getUserShops();
    this.getToken();
    this.getRegionsCategories();
  }

  async getToken() {
    await getUserToken().then((data) => {
      if (data === undefined) {
        this.setState({ userToken: null });
      } else {
        this.setState({ userToken: data });
      }
    });
  }

  showProfile(userShops) {
    return (
      <div style={{ width: '100%' }}>
        <IonItem
          href={'/user/profile'}
          onClick={() => {
            this.closeMenu();
          }}
        >
          <IonAvatar slot="start">
            <IonImg
              src="assets\img\avatar.jpg"
              style={{
                borderRadius: '50%',
                width: '80%',
                height: '85%',
              }}
            ></IonImg>{' '}
          </IonAvatar>
          <IonLabel>
            <h2>
              <FormattedMessage id={'menu.user.profile'} />
            </h2>
          </IonLabel>
        </IonItem>
        <IonItem>
          <a
            href={'/user/mes-annonces'}
            onClick={() => {
              this.closeMenu();
            }}
          >
            <IonText>
              <span>
                <FormattedMessage id={'user.annonce'} />
              </span>
            </IonText>
          </a>
        </IonItem>
        {userShops?.length > 0 && (
          <IonItem>
            <a
              href={'/user/mes-boutiques'}
              onClick={() => {
                this.closeMenu();
              }}
            >
              <IonText>
                <span>
                  <FormattedMessage id={'user.shops'} />
                </span>
              </IonText>
            </a>
          </IonItem>
        )}
        {/*     <IonItem>
          <a
            href={'/user/chat'}
            onClick={() => {
              this.closeMenu();
            }}
          >
            <IonText>
              <span>
                <FormattedMessage id={'user.chat'} />
              </span>
            </IonText>
          </a>
        </IonItem>*/}
        {this.checkRole('moderateur') || this.checkRole('admin') || this.checkRole('editeur') ? (
          <IonItem>
            <IonText>
              <a
                href={'/user/verify-annonces'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'moderator.annonce'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('moderateur') || this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/user/Desactiver-annonces'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'Desactiver.annonce'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('moderateur') || this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/moderator/moderate-annonces'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'moderator.moderer.annonce'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('moderateur') || this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/moderator/modifier-annonces-en-masse'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'moderator.edit.annonce.en.masse'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('moderateur') || this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/moderator/liste-contacts'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'moderator.liste.contacts'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/moderator/liste-kitmedia'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'moderator.liste.kitmedia'} />
                </span>
              </a>
            </IonText>
          </IonItem>
        ) : null}
        {this.checkRole('commercial') || this.checkRole('admin') ? (
          <IonItem>
            <IonText>
              <a
                href={'/moderator/shops'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <span>
                  <FormattedMessage id={'shop.moderate.title'} />
                </span>
              </a>
            </IonText>
            <div slot={'end'}>
              <NotificationShop />
            </div>
          </IonItem>
        ) : null}
        <IonItem>
          <a
            href={'/logout'}
            onClick={() => {
              this.closeMenu();
            }}
          >
            <IonText>
              <span>
                <FormattedMessage id={'menu.user.sign.out'} />
              </span>
            </IonText>
          </a>
        </IonItem>
      </div>
    );
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  async langChange(lang) {
    const language = lang !== 'fr' ? 'fr' : 'ar';
    this.setState({ lang: language });
    setLanguage(language)
      .then((data) => {
        Events.publish(constants.lang_change, { lang: language });
      })
      .catch((error) => {
        console.error(`Error has been thrown (${error.message})`, error);
      });
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    const pathname = decodeURIComponent(window.location.pathname);
    const splittedPath = pathname.split('/');

    if (splittedPath.length >= 4) {
      if (
        (pathname.includes(constants.allRegion) ||
          pathname.includes('المغرب') ||
          pathname.includes(constants.allCategories) ||
          (pathname.includes('للبيع') &&
            (pathname.includes('user') === false ||
              pathname.includes('boutique') === false ||
              pathname.includes('htm') === false))) &&
        (isPlatform('desktop') || isPlatform('mobileweb') || (isPlatform('capacitor') && !this.props.showModalRedux))
      ) {
        const regionCategorieJson = JSON.parse(localStorage.getItem(regionCategorySearchKey));
        const region = splittedPath[2];
        const category = splittedPath[3];
        const regionArab = regionCategorieJson.regionCategorySearch.regionArab;
        const regionFr = regionCategorieJson.regionCategorySearch.regionFr;
        const categorieArab = regionCategorieJson.regionCategorySearch.categorieArab;
        const categorieFr = regionCategorieJson.regionCategorySearch.categorieFr;

        const regionTitre = lang === 'ar' ? regionFr : regionArab;
        const categoryTitre = lang === 'ar' ? categorieFr : categorieArab;

        const allRegionAr = 'المغرب';
        const allRegionFr = 'maroc';
        const allCategoryAr = 'للبيع';
        const allCategoryFr = 'a_vendre';

        if (lang === 'fr') {
          if (region === 'maroc' && category === 'a_vendre') {
            window.location.href = '/' + language + '/' + allRegionAr + '/' + allCategoryAr + '/' + splittedPath[4];
          } else if (region !== 'maroc' && category === 'a_vendre') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + allCategoryAr + '/' + splittedPath[4];
          } else if (region === 'maroc' && category !== 'a_vendre') {
            window.location.href = '/' + language + '/' + allRegionAr + '/' + categoryTitre + '/' + splittedPath[4];
          } else if (region !== 'maroc' && category !== 'a_vendre') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + categoryTitre + '/' + splittedPath[4];
          }
        } else {
          if (region === 'المغرب' && category === 'للبيع') {
            window.location.href = '/' + language + '/' + allRegionFr + '/' + allCategoryFr + '/' + splittedPath[4];
          } else if (region !== 'المغرب' && category === 'للبيع') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + allCategoryFr + '/' + splittedPath[4];
          } else if (region === 'المغرب' && category !== 'للبيع') {
            window.location.href = '/' + language + '/' + allRegionFr + '/' + categoryTitre + '/' + splittedPath[4];
          } else if (region !== 'المغرب' && category !== 'للبيع') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + categoryTitre + '/' + splittedPath[4];
          }
        }
      } else if (pathname.includes('contact-commercial') && (isPlatform('desktop') || isPlatform('mobileweb'))) {
        window.location.href = `/${language}/${splittedPath[2]}/${splittedPath[3]}`;
      } else if (pathname.includes('boutiques') && (isPlatform('desktop') || isPlatform('mobileweb'))) {
        window.location.href = `/${language}/${splittedPath[2]}/`;
      } else if (
        pathname.includes('htm') &&
        pathname.includes('boutique') === false &&
        (isPlatform('desktop') || isPlatform('mobileweb'))
      ) {
        const regionVilleCategorieJson = JSON.parse(localStorage.getItem(regionCategoryKey));
        const regionArab = regionVilleCategorieJson.regionVilleCategoryResults.regionArab;
        const regionFr = regionVilleCategorieJson.regionVilleCategoryResults.regionFr;
        const villeArab = regionVilleCategorieJson.regionVilleCategoryResults.villeArab;
        const villeFr = regionVilleCategorieJson.regionVilleCategoryResults.villeFr;
        const categorieArab = regionVilleCategorieJson.regionVilleCategoryResults.categorieArab;
        const categorieFr = regionVilleCategorieJson.regionVilleCategoryResults.categorieFr;

        const newPathAr =
          '/' + language + '/' + regionArab + '/' + villeArab + '/' + categorieArab + '/' + splittedPath[5];
        const newPathFr = '/' + language + '/' + regionFr + '/' + villeFr + '/' + categorieFr + '/' + splittedPath[5];

        lang === 'fr' ? (window.location.href = newPathAr) : (window.location.href = newPathFr);
      }
    } else if (
      pathname.includes('htm') &&
      pathname.includes(lang) &&
      (isPlatform('desktop') || isPlatform('mobileweb'))
    ) {
      window.location.href = `/${language}/${splittedPath[2]}`;
    } else if (pathname.includes('boutique-dashboard')) {
      window.location.href = pathname + window.location.search;
    } else {
      window.location.href = pathname;
    }
  }

  closeMenu() {
    menuController.close('mobileMenu');
  }

  render() {
    const { userToken, headerShowLang, headerShowLangSub, lang, userShops } = this.state;
    // const verifyToken = this.verifyTokenUser();
    return (
      <IonMenu side={'end'} contentId="app" menuId={'mobileMenu'}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <picture>
                <source type="image/webp" srcSet={'assets/Logo_flaha.webp'} />
                <img loading="lazy" src={'assets/Logo_flaha.svg'} alt={'Flaha.ma'} />
              </picture>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem lines={'none'}>
              <IonButton
                href={'/sendPost'}
                expand="block"
                color={'primary'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <IonIcon size={'large'} icon={addOutline} /> <FormattedMessage id={'menu.post.add'} />
              </IonButton>
            </IonItem>
            {!this.props.showModalRedux ? (
              <IonItem>
                <IonLabel
                  style={{ cursor: 'pointer', marginLeft: '65px', marginRight: '65px' }}
                  onClick={() => this.langChange(lang)}
                >
                  {lang !== 'fr' ? 'Français' : 'العربية'}
                </IonLabel>
              </IonItem>
            ) : null}
            <IonItem style={{ borderBottom: 'none !important' }}>
              <a
                href={'/'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <IonText>
                  <IonIcon size={'large'} icon={home} />
                  <span>
                    <FormattedMessage id={'home'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
            <IonItem>
              <a
                href={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`}
                onClick={() => {
                  this.closeMenu();
                  this.props.setSearch(true);
                }}
              >
                <IonText>
                  <IonIcon size={'large'} icon={searchOutline} />
                  <span>
                    <FormattedMessage id={'menu.search'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
            {userToken === null ? (
              <IonItem>
                <a
                  href={'/login'}
                  onClick={() => {
                    this.closeMenu();
                  }}
                >
                  <IonText>
                    <IonIcon size={'large'} icon={personCircleOutline} />
                    <span>
                      <FormattedMessage id={'menu.user.sign.in'} />
                    </span>
                  </IonText>
                </a>
              </IonItem>
            ) : (
              <div>
                <IonItem
                  onClick={() => {
                    this.closeMenu();
                    this.setState({ headerShowLang: true });
                  }}
                >
                  <IonText style={{ width: '100%' }}>
                    <IonIcon
                      size={'large'}
                      icon={personCircleOutline}
                      style={lang === 'ar' ? { marginLeft: '18px' } : { marginRight: '18px' }}
                    />
                    <span>{getUserProfile().nom}</span>
                  </IonText>
                  {(this.checkRole('commercial') || this.checkRole('admin')) && <NotificationShop />}
                  <IonIcon slot={'end'} color={'primary'} icon={caretDownOutline} size={'small'} />
                </IonItem>
              </div>
            )}
            <IonItem>
              <IonRouterLink
                onClick={() => {
                  this.closeMenu();
                }}
                routerLink={lang === 'fr' ? '/fr/maroc/Terrain_Ferme/' : '/ar/المغرب/ارض_مزرعة/'}
              >
                <IonText>
                  <IonIcon size={'large'} src={'/assets/icons/farm-icon.svg'} />
                  <span>
                    <FormattedMessage id={'menu.immo.agricole'} />
                  </span>
                </IonText>
              </IonRouterLink>
            </IonItem>
            <IonItem>
              <IonRouterLink
                onClick={() => {
                  this.closeMenu();
                }}
                routerLink={`/${lang}/boutiques/`}
              >
                <IonText>
                  <IonIcon size={'large'} icon={storefrontOutline} />
                  <span>
                    <FormattedMessage id={'menu.shops'} />
                  </span>
                </IonText>
              </IonRouterLink>
            </IonItem>
            <IonItem>
              <a href={`/${lang}/neuf/`}>
                <IonText>
                  <IonIcon size={'large'} src={'/assets/img/flaha-neuf/tracteur-icon.svg'} />
                  <span>
                    <FormattedMessage id={'flaha.neuf.menu.title'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
            <IonItem>
              <a
                href={'/contact'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <IonText>
                  <IonIcon size={'large'} icon={mailOutline} />
                  <span>
                    <FormattedMessage id={'menu.contact'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
            <IonItem>
              <a
                href={'/privacy'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <IonText>
                  <IonIcon size={'large'} icon={bookmarkOutline} />
                  <span>
                    <FormattedMessage id={'footer.term.condition'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
            <IonItem>
              <a
                href={'/about'}
                onClick={() => {
                  this.closeMenu();
                }}
              >
                <IonText>
                  <IonIcon size={'large'} icon={peopleOutline} />
                  <span>
                    <FormattedMessage id={'footer.about'} />
                  </span>
                </IonText>
              </a>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <div className="social-container">
            <IonItem>
              <a href={'https://www.facebook.com/flahamaroc'}>
                <IonIcon size={'large'} icon={logoFacebook} />
              </a>
              <a href={'https://www.instagram.com/flaha.ma/'}>
                <IonIcon size={'large'} icon={logoInstagram} />
              </a>
              <a href={'https://www.youtube.com/channel/UCaa2jEfdflC1n2D79h4tEKA'}>
                <IonIcon size={'large'} icon={logoYoutube} />
              </a>
            </IonItem>
            {isPlatform('capacitor') ? (
              <IonText className="version-text">
                <IonIcon icon={shieldCheckmarkOutline} />
                <span> {'Version ' + localStorage.getItem(androidAppVersionKey) + '.Flaha.ma'}</span>
              </IonText>
            ) : null}
          </div>
        </IonFooter>
        <IonModal isOpen={headerShowLang}>
          <IonHeader>
            <IonToolbar onClick={() => this.setState({ headerShowLang: false })} className="headerM">
              <Header></Header>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonCard>
              <IonCardContent>
                <IonItem
                  href={'/user/profile'}
                  onClick={() => {
                    this.closeMenu();
                  }}
                >
                  <IonAvatar slot="start">
                    <IonImg src="assets\img\avatar.jpg"></IonImg>
                  </IonAvatar>
                  <IonLabel>
                    <h1>{getUserProfile().nom}</h1>
                    <h3>
                      <FormattedMessage id={'menu.user.profile'} />
                    </h3>
                  </IonLabel>
                  {isPlatform('android') ? (
                    <InputAdornment position="end">
                      {lang === 'fr' ? (
                        <IonIcon icon={chevronForwardOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      ) : (
                        <IonIcon icon={chevronBackOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      )}
                    </InputAdornment>
                  ) : null}
                </IonItem>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <IonItem
                  href={'/user/mes-annonces'}
                  onClick={() => {
                    this.closeMenu();
                  }}
                >
                  <IonAvatar slot="start">
                    <IonImg src="assets\img\no_images.png"></IonImg>
                  </IonAvatar>
                  <IonLabel>
                    <h1>
                      <FormattedMessage id={'user.annonce'} />
                    </h1>
                    <h3>
                      <FormattedMessage id={'user.annonce.gestion'} />
                    </h3>
                  </IonLabel>
                  {isPlatform('android') ? (
                    <InputAdornment position="end">
                      {lang === 'fr' ? (
                        <IonIcon icon={chevronForwardOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      ) : (
                        <IonIcon icon={chevronBackOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      )}
                    </InputAdornment>
                  ) : null}
                </IonItem>
              </IonCardContent>
            </IonCard>
            {userShops?.length > 0 && (
              <IonCard>
                <IonCardContent>
                  <IonItem
                    href={'/user/mes-boutiques'}
                    onClick={() => {
                      this.closeMenu();
                    }}
                  >
                    <IonAvatar slot="start">
                      <IonImg src="assets\img\shop.png"></IonImg>
                    </IonAvatar>
                    <IonLabel>
                      <h1>
                        <FormattedMessage id={'user.shops'} />
                      </h1>
                      <h3>
                        <FormattedMessage id={'shop.detail.goto'} />
                      </h3>
                    </IonLabel>
                    {isPlatform('android') ? (
                      <InputAdornment position="end">
                        {lang === 'fr' ? (
                          <IonIcon icon={chevronForwardOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                        ) : (
                          <IonIcon icon={chevronBackOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                        )}
                      </InputAdornment>
                    ) : null}
                  </IonItem>
                </IonCardContent>
              </IonCard>
            )}
            {this.checkRole('commercial') ||
            this.checkRole('moderateur') ||
            this.checkRole('admin') ||
            this.checkRole('editeur') ? (
              <IonCard>
                <IonCardContent>
                  <IonItem
                    onClick={() => {
                      this.setState({ headerShowLangSub: true });
                    }}
                  >
                    <IonAvatar slot="start">
                      <IonImg src="assets\img\icon-admin.png"></IonImg>
                    </IonAvatar>
                    <IonLabel>
                      <h1>
                        <FormattedMessage id={'menu.administration'} />
                      </h1>
                      <h3>
                        <FormattedMessage id={'admin.operation'} />
                      </h3>
                    </IonLabel>
                    <InputAdornment position="end">
                      {lang === 'fr' ? (
                        <IonIcon icon={chevronForwardOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      ) : (
                        <IonIcon icon={chevronBackOutline} style={{ color: 'rgb(168, 180, 192)' }}></IonIcon>
                      )}
                    </InputAdornment>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ) : null}
            <a
              href={'/logout'}
              onClick={() => {
                this.closeMenu();
              }}
            >
              <button className="sign-out">
                <FormattedMessage id={'menu.user.sign.out'} />
              </button>
            </a>
          </IonContent>
        </IonModal>
        <IonModal isOpen={headerShowLangSub}>
          <IonHeader>
            <IonToolbar className="headerM">
              <IonButtons slot="start">
                <IonButton onClick={() => this.setState({ headerShowLangSub: false })}>
                  <IonIcon slot="start" icon={arrowBackOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: 'black' }}>
                <FormattedMessage id={'menu.administration'} />
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonList>
              {this.checkRole('moderateur') || this.checkRole('admin') || this.checkRole('editeur') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/user/verify-annonces'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'moderator.annonce'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('moderateur') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/user/Desactiver-annonces'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'Desactiver.annonce'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('moderateur') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/moderate-annonces'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'moderator.moderer.annonce'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('moderateur') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/modifier-annonces-en-masse'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'moderator.edit.annonce.en.masse'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('moderateur') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/liste-contacts'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'moderator.liste.contacts'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/liste-kitmedia'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'moderator.liste.kitmedia'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('commercial') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/user/analytics-dashboard'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'menu.stats.title'} />
                      </span>
                    </a>
                  </IonText>
                </IonItem>
              ) : null}
              {this.checkRole('commercial') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/user/leads-moderation'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'menu.moderate.leads.title'} />
                      </span>
                    </a>
                  </IonText>
                  <div slot={'end'}>
                    <NotificationLeads />
                  </div>
                </IonItem>
              ) : null}
              {this.checkRole('commercial') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/shops'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'shop.moderate.title'} />
                      </span>
                    </a>
                  </IonText>
                  <div slot={'end'}>
                    <NotificationShop />
                  </div>
                </IonItem>
              ) : null}
              {this.checkRole('commercial') || this.checkRole('admin') ? (
                <IonItem>
                  <IonText>
                    <a
                      href={'/moderator/fund-requests'}
                      onClick={() => {
                        this.closeMenu();
                      }}
                    >
                      <span>
                        <FormattedMessage id={'fund.requests'} />
                      </span>
                    </a>
                  </IonText>
                  <div slot={'end'}>
                    <NotificationFundRequest />
                  </div>
                </IonItem>
              ) : null}
            </IonList>
          </IonContent>
        </IonModal>
      </IonMenu>
    );
  }
}

interface Props extends FlahaProps {
  searchClickedRedux: boolean;
  setSearch: (searchClicked) => void;
  saveRegionsCategories: (regionsRedux, categoriesRedux, citiesRedux) => void;
  regionsRedux: any;
  categoriesRedux: any;
  showModalRedux: boolean;
}

interface State extends FlahaState {
  userToken?: string | null | undefined;
  headerShowLang: boolean;
  headerShowLangSub: boolean;
  userShops: Array<Shop>;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    searchClickedRedux: state.setSearchReducer.search.searchClickedRedux,
    regionsRedux: state.saveRegionsCategoriesReducer.Regions_Categories.regions,
    categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
    showModalRedux: state.setShowModalReducer.showModalRedux.showModal,
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setSearch: (searchClickedRedux) =>
            dispatch({
                type: 'SET_SEARCH_CLICKED',
                payload: {searchClickedRedux: searchClickedRedux},
            }),
        saveRegionsCategories: (regionsRedux, categoriesRedux, citiesRedux) =>
            dispatch({
                type: 'SAVE_REGIONS_CATEGORIES',
                payload: {regions: regionsRedux, categories: categoriesRedux, cities: citiesRedux},
            }),
    };
};

// export default injectIntl(Header);
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
