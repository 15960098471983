import { createReducer } from "@reduxjs/toolkit";
import { saveProvinces} from "../actions/saveProvinces";

const provinces = [];


export const saveProvincesReducer = createReducer(
  { provinces },
  {
    [saveProvinces.type]: (state, action) => ({
      ...state,
      provinces: { ...action.payload }
    }),
  }
);