import http from '../http-common';
import { constants } from 'utils';

class StatsService {
  getShopViewCountByPeriod(shopID: string, period: number, options) {
    return http.get(
      constants.shopViewCountByPeriodGET.replace('{shopID}', shopID).replace('{period}', String(period)),
      options
    );
  }

  getShopAllViewCountByPeriod({ shopID, period, options }: { shopID: string; period: number; options: any }) {
    return http.get(
      constants.shopAllViewCountByPeriodGET.replace('{shopID}', shopID).replace('{period}', String(period)),
      options
    );
  }

  getAnnonceAllViewCountByPeriod({ annonceID, period, options }: { annonceID: string; period: number; options: any }) {
    return http.get(
      constants.annonceAllViewCountByPeriodGET.replace('{annonceID}', annonceID).replace('{period}', String(period)),
      options
    );
  }

  getViewCountByPeriodAndAnnonce(annonceID: string, period: number, options) {
    return http.get(
      constants.viewCountByPeriodAndAnnonceGET.replace('{annonceID}', annonceID).replace('{period}', String(period)),
      options
    );
  }

  /*getViewCountByAnnonce(annonceID: string, options) {
    return http.get(constants.viewCountByPeriodAndAnnonceGET.replace('{annonceID}', annonceID), options);
  }*/

  getCategoriesViewCount(options) {
    return http.get(constants.categoriesViewCountGET, options);
  }

  incrementViewCount(annonceID: string, options) {
    return http.post(constants.viewCountPOST.replace('{annonceID}', annonceID), null, options);
  }

  getAllViewCountByPeriodAndShop({ shopID, period, options }: { shopID: string; period: number; options: any }) {
    return http.get(
        constants.allViewCountByPeriodAndShopGET.replace('{shopID}', shopID).replace('{period}', String(period)),
        options
    );
  }

  getViewCountByPeriodAndShop(shopID: string, period: number, options) {
    return http.get(
        constants.viewCountByPeriodAndShopGET.replace('{shopID}', shopID).replace('{period}', String(period)),
        options
    );
  }

  incrementShopViewCount(shopID: string, options) {
    return http.post(constants.shopViewCountPOST.replace('{shopID}', shopID), null, options);
  }
}

export default new StatsService();
