import React, { useState } from 'react';
import { constants } from 'utils';
import { getLanguage, getUserProfile } from 'utils/Functions/Functions';
import { format } from 'timeago.js';
import './Conversation.scss';

export default function Conversation({conversation}) {
  const [lang, setLang] = useState('ar');
  getLanguage().then((lang) => setLang(lang));

  return (
    <div className="chat-row" id="chat-row">
      <img
        className="conversationImg"
        loading="lazy"
        decoding={'async'}
        src={`${
          conversation.annonce.premierePhoto && conversation.annonce.premierePhoto.chemin
            ? process.env.REACT_APP_API_URL +
              constants.pictures +
              conversation.annonce.premierePhoto.chemin +
              '?small=false'
            : '/assets/img/no_images.webp'
        }`}
      />
      <div className="chat-content-container">
        <div className="chat-content">
          <div className="chat-name-date">
            <h2>
              <strong>
                {' '}
                {getUserProfile().email === conversation.annonce.utilisateur.email
                  ? conversation.buyer.nom
                  : conversation.seller.nom}
              </strong>
            </h2>
          </div>
          <div className="chat-name-date">
            <p>{lang === 'fr' ? conversation.annonce.titreFrancais : conversation.annonce.titreArabe}</p>
          </div>
          <p className="ion-text-wrap">
            {' '}
            <strong>Last message display here !!</strong>
          </p>
          <p className={`chat-date`}>
            <strong>{format(conversation.modifiedAt)}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
