import { createReducer } from '@reduxjs/toolkit';
import { saveModeles } from '../actions/saveModeles';

const modeles = [];

export const saveModelesReducer = createReducer(
  { modeles },
  {
    [saveModeles.type]: (state, action) => ({
      ...state,
      modeles: [...action.payload],
    }),
  }
);
