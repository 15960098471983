import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import PersistedStore from './store/persistedStore';


//import {Helmet} from "react-helmet";

// @ts-ignore
Array.prototype.shuffle = function () {
    var i, j, temp;
    for (i = this.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = this[i];
        this[i] = this[j];
        this[j] = temp;
    }
    return this;
};

// @ts-ignore
String.prototype.truncate = function (start = 0, length = 30, end = '...') {
    return this.substring(start, end) + (this.length > length ? end : '');
};

//const app = ReactDOMServer.renderToStaticMarkup(<App />);


//const helmet = Helmet.renderStatic();

ReactDOM.render(
  <Provider store={PersistedStore.getDefaultStore().store}>
          <App/>
  </Provider>, document.getElementById('application'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




