import http from '../http-common';
import { constants } from 'utils';

class UserService {
  changePassword(data: any, options) {
    return http.put(constants.changePassword, data, options);
  }

  isPhoneExcluded(phone: string, options) {
    return http.get(constants.isPhoneExcludedGET.replace('{phone}', phone), options);
  }
}

export default new UserService();
