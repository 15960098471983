import React, { Component } from 'react';
import get from 'lodash/get';
import './SearchDetail.scss';
import { constants, dateFormatter, Events, getUserToken, madFormatter } from 'utils';
import {
  CreateAnimation,
  IonBadge,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  isPlatform,
} from '@ionic/react';
import {
  alertCircle,
  alertCircleOutline,
  callOutline,
  chevronForwardOutline,
  createOutline,
  eye,
  home,
  listCircle,
  location as loc,
  megaphoneOutline,
  storefrontOutline,
  time,
} from 'ionicons/icons';
import { Helmet } from 'react-helmet';
import { Footer, Header, PostGallery } from 'components';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { Marque, photo, Post } from 'utils/Types/types';
import { RouteComponentProps } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langFunction } from 'lang';
import RecommendedPosts from 'components/RecommendedPosts/RecommendedPosts';
import { trackEvent } from '../../tracking';
import {
  callPhoneNumberClicked,
  phoneNumberViewed,
  whatsappCallClicked,
  whatsappShareClicked,
} from '../../tracking/events';
import { getSEOParametre, isProduction, setLanguage } from 'utils/Functions/Functions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { AdSlot } from 'react-dfp';
import OtherShopPosts from 'components/OtherShopPosts/OtherShopPosts';
import parse from 'html-react-parser';
import StatsService from '../../services/StatsService';
import EventTrackingService from '../../services/EventTrackingService';
import qs from 'query-string';

const RegionCategoryKey = '@flaha.regioncategory';

class SearchDetail extends Component<Props, State> {
  slug: string;
  id: string;

  ChatChannel() {
    const { lang, slug } = this.state;
    const formData: FormData = new FormData();
    const url = `${process.env.REACT_APP_API_URL}${constants.checkChatChannel}`;
    let token = localStorage.getItem('@flaha.userToken');
    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
    });

    const urlAdd = url + slug;

    //////////console.log(marqueId)
    return fetch(urlAdd, {
      method: 'GET',
      headers: headers,
    }).then((request) => {
      if (request.status !== 400) {
        window.location.href = `/${lang}/user/messagerie/form/${slug}`;
      } else {
        window.location.href = `/user/chat`;
      }
    });
  }

  async getData() {
    const slug = this.slug;
    let id;
    if (isPlatform('capacitor')) {
      const dashIndex = slug.lastIndexOf('_');
      id = slug.substring(dashIndex + 1, slug.length);
    } else {
      const { match } = this.props;
      // @ts-ignore
      const slugParam = match.params.slug;
      const dashIndex = slugParam.lastIndexOf('_');
      id = slug.substring(dashIndex, slugParam.length);
    }

    // @ts-ignore

    const url = `${process.env.REACT_APP_API_URL + constants.posts + `${constants.getPostsById}?id=${id}`}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    });
    const json = await response.json();
    ////console.log(json);
    this.setState(
      {
        loading: false,
        lang: 'ar',
        slug: slug,
        post: json,
        showPhone: false,
      },
      () => {
        this.getMarques();
      }
    );
    //////console.log(json);
    if (json.status !== 500) {
      window.localStorage.setItem(
        RegionCategoryKey,
        JSON.stringify({
          regionVilleCategoryResults: {
            regionArab: json.ville.province.region.urlArab,
            regionFr: json.ville.province.region.urlFr,
            villeArab: json.ville.urlArab,
            villeFr: json.ville.urlFr,
            categorieArab: json.categorie.urlArabe,
            categorieFr: json.categorie.urlFrancais,
          },
        })
      );
    }
  }

  async updateCompteurAnnonce() {
    const slug = this.slug;
    const url = `${process.env.REACT_APP_API_URL + constants.updateCompteur + '?slug=' + slug}`;
    //
    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    });

    const id = this.id;

    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    /** StatsService.getAnnonceAllViewCountByPeriod({
      annonceID: String(id),
      period: 0,
      options: options,
    }).then((value) => {
      this.setState({
        annonceViews: value.data.result,
      });
    });
    */

    StatsService.incrementViewCount(id, options).catch((reason) => {
      console.error(reason.message);
    });
  }

  constructor(props: Props, context: any) {
    super(props, context);

    const { match } = props;

    if (isPlatform('capacitor')) {
      this.slug = this.props.slug;
    } else {
      // @ts-ignore
      const slug = match.params.slug;
      const dashIndex = slug.lastIndexOf('_');
      this.slug = slug.substring(0, dashIndex) + '' + slug.substring(dashIndex + 1);
      this.id = slug.substring(dashIndex + 1, slug.length);
    }

    // this.getData();
    // this.state = {
    //     lang: "fr",
    //     post: null,
    //     slug: this.slug,
    //     loading: true,
    //     showPhone: false,
    // };
    this.onShowPhone = this.onShowPhone.bind(this);
    this.importSEOParametres = this.importSEOParametres.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.ChatChannel();
  }

  // @ts-ignore
  onShowPhone = (event: MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    const { shop, id } = this.state.post;

    if (!this.state.showPhone) {
      trackEvent(
        phoneNumberViewed({
          postSlug: this.slug,
          postId: id,
        })
      );

      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      EventTrackingService.createEvent({
        data: {
          event: {
            id: constants.PHONE_NUMBER_VIEWED,
          },
          annonce: {
            id: id,
          },
          shop: shop
            ? {
                id: shop?.id,
              }
            : null,
        },
        options: options,
      }).catch((reason) => console.error(reason.message));
    } else {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      EventTrackingService.createEvent({
        data: {
          event: {
            id: constants.CALL_PHONE_NUMBER_CLICKED,
          },
          annonce: {
            id: id,
          },
          shop: shop
            ? {
                id: shop?.id,
              }
            : null,
        },
        options: options,
      }).catch((reason) => console.error(reason.message));
    }
    this.setState({
      showPhone: true,
    });
  };
  // componentWillMount () {
  //
  //   this.getData();
  // }

  /*shareFacebook(){
                      const post = this.state.post;
                       const lang = this.state.lang;
                       const {titreFrancais,slug, premierePhoto} = post;
                  
                       ////console.log('share')
                       SocialSharing.shareViaFacebook(titreFrancais, premierePhoto.chemin, `https://flaha.ma/detail/${slug}`)
                     }*/

  langChange() {
    if (!isPlatform('capacitor')) {
      const { match } = this.props;
      // @ts-ignore
      const lang = match.params.lang;
      this.setState({ lang: lang });
      setLanguage(lang)
        .then((data) => {
          Events.publish(constants.lang_change, { lang: lang });
        })
        .catch((error) => {
          console.error(`Error has been thrown (${error.message})`, error);
        });
    } else {
      Events.subscribe(constants.lang_change, ({ lang }) => {
        this.setState({
          lang: lang,
        });
      });
    }
  }

  hideTabs() {
    let elements = document.querySelectorAll('ion-tab-bar');
    if (elements != null) {
      Object.keys(elements).map((key) => {
        elements[key].style.display = 'none';
      });
    }
  }

  displayTabs() {
    let elements = document.querySelectorAll('ion-tab-bar');
    if (elements != null) {
      Object.keys(elements).map((key) => {
        elements[key].style.display = 'flex';
      });
    }
  }

  componentWillUnmount() {
    this.displayTabs();
  }

  getMarques() {
    const { post } = this.state;
    const { categorie, marque } = post;
    if (categorie && categorie.marques !== null && categorie.marques !== '') {
      const idsMarques = categorie.marques;
      // //////////////console.log('getMarque called !');
      let url = process.env.REACT_APP_API_URL + constants.getMarques;
      const headers = new Headers({
        'Content-Type': 'application/json',
        accept: '*/*',
      });
      fetch(`${url}` + `?marques=${idsMarques}`, {
        method: 'POST',
        headers: headers,
      }).then((response) => {
        let marquesData = response.json();
        marquesData.then((jsonRequest) => {
          if (jsonRequest) {
            this.setState({ marques: jsonRequest }, () => {
              const marqueObject = jsonRequest.find((item: Marque) => item.id === marque);
              this.setState({ marque: marqueObject });
            });
          }
        });
      });
    }
  }

  componentDidMount() {
    this.hideTabs();

    // const url = `${process.env.REACT_APP_API_URL}${constants.posts}`+'/'+ slug;
    // //
    // const headers = new Headers({
    //     'Content-Type': 'application/json',
    //     'SameSite': 'secure'
    // });

    // const response = await fetch(url, {
    //     method: 'GET',
    //     credentials: "same-origin",
    //     //mode: 'no-cors',
    //     headers: headers,
    // });
    // const json = await response.json();
    this.setState({
      lang: 'ar',
      slug: this.slug,
      loading: true,
      showPhone: false,
      metaTitle: ['', ''],
      metaDescription: ['', ''],
    });
    this.getData();
    this.updateCompteurAnnonce();
    this.getToken();
    this.langChange();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });

    this.importSEOParametres();
  }

  async getToken() {
    await getUserToken().then((data) => {
      if (data === undefined) {
        this.setState({ userToken: null });
      } else {
        this.setState({ userToken: data });
      }
    });
  }

  importSEOParametres() {
    // import SEO Meta parametres
    getSEOParametre('SEO_DETAIL_TITLE').then((jsonRequest) => {
      if (jsonRequest) {
        this.setState({ metaTitle: [jsonRequest.valeurFr, jsonRequest.valeurAr] });
      }
    });
    getSEOParametre('SEO_DETAIL_DESCRIPTION').then((jsonRequest) => {
      if (jsonRequest) {
        this.setState({ metaDescription: [jsonRequest.valeurFr, jsonRequest.valeurAr] });
      }
    });
  }

  getBreadCrumbs() {
    const { post, lang } = this.state;
    const { id, titreFrancais, titreArabe, ville, slug, categorie } = post;
    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
    const villeArab = ville ? ville.urlArab : '';
    const villeFrancais = ville ? ville.urlFr : '';
    const provinceArab = ville && ville.province ? ville.province.urlArab : '';
    const provinceFrancais = ville && ville.province ? ville.province.urlFr : '';
    const regionArab = ville ? ville.province.region.urlArab : '';
    const regionFr = ville ? ville.province.region.urlFr : '';
    const categorieFrancais = categorie ? categorie.urlFrancais : '';
    const categorieArab = categorie ? categorie.urlArabe : '';

    let regionUrl = lang === 'fr' ? `/${lang}/${regionFr}/a_vendre/` : `/${lang}/${regionArab}/للبيع/`;
    let provinceUrl = lang === 'fr' ? `/${lang}/${provinceFrancais}/a_vendre/` : `/${lang}/${provinceArab}/للبيع/`;
    let categorieUrl = lang === 'fr' ? `/${lang}/maroc/${categorieFrancais}/` : `/${lang}/المغرب/${categorieArab}/`;
    let slugUrl =
      lang === 'fr'
        ? `/${lang}/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        : `/${lang}/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" href="/">
          <IonIcon icon={home} style={{ padding: '0px 5px 0px 5px' }}></IonIcon>
          <FormattedMessage id={'home'} />
        </Link>
        <Link color="inherit" href={regionUrl}>
          {lang === 'fr' ? (ville ? ville.province.region.nomFr : '') : ville ? ville.province.region.nomArab : ''}
        </Link>
        <Link color="inherit" href={provinceUrl}>
          {lang === 'fr' ? (ville ? ville.province.nomFr : '') : ville ? ville.province.nomArab : ''}
        </Link>
        <Link color="inherit" href={categorieUrl}>
          {lang === 'fr' ? (categorie ? categorie.titreFrancais : '') : categorie ? categorie.titreArabe : ''}
        </Link>
        <Link color="inherit" href={slugUrl}>
          {lang === 'fr' ? titreFrancais : titreArabe}
        </Link>
      </Breadcrumbs>
    );
  }

  // show Message
  showMessageDesactivateedAnn() {
    return (
      <CreateAnimation
        duration={900}
        easing={'ease-in'}
        play={true}
        fromTo={[
          { property: 'transform', fromValue: 'translateX(-100px)', toValue: 'translateX(0px)' },
          { property: 'opacity', fromValue: '0', toValue: '1' },
        ]}
      >
        <div className={'alert alert-danger'} style={{ width: '100%' }}>
          <IonIcon icon={alertCircle} size={'large'} /> {<FormattedMessage id={'desactivate.detail.annonce.msg'} />}
        </div>
      </CreateAnimation>
    );
  }

  pageContent(
    intl,
    post,
    lang,
    showPhone,
    id,
    titreFrancais,
    titreArabe,
    prix,
    datePublication,
    description,
    ville,
    categorie,
    nomUtilisateurAnnonce,
    telephoneAnnonce,
    shareFacebookUrl,
    shareURL,
    shareMsg,
    shareMsgStore,
    callMsg,
    slug,
    surface,
    poids,
    marque,
    modele,
    anneeModele,
    kilometrage,
    typeCarburant,
    puissanceFiscale,
    boiteVitesse,
    userToken,
    desactivate,
    shop,
    secondaryPhone,
    annonceViews
  ) {
    let tel = telephoneAnnonce
      ? telephoneAnnonce.includes('+212') || telephoneAnnonce.includes('+') || telephoneAnnonce.includes('00212')
        ? telephoneAnnonce
        : `+212${telephoneAnnonce}`
      : null;
    // console.log('marque ; ',marque);
    return (
      <>
        <Header />
        <IonContent
          className={isPlatform('capacitor') ? 'my-ion-content-mobile' : 'my-ion-content'}
          style={{
            marginBottom: window.innerWidth >= 767 ? '0rem' : '1rem !important',
          }}
        >
          <div className={'detailScreen'}>
            <IonGrid fixed={true}>
              {isProduction() && isPlatform('desktop') && (
                <div className={'d-flex justify-content-center my-2'}>
                  <AdSlot sizes={[[970, 90]]} adUnit="banner_detail_billboard" />
                </div>
              )}
              {isProduction() && !isPlatform('desktop') && (
                <div className={'d-flex justify-content-center my-2'}>
                  <AdSlot sizes={[[300, 50]]} adUnit="banner_detail_mobile_adview_page" />
                </div>
              )}
              {!isPlatform('capacitor') ? (
                <IonRow className={'justify-content-lg-start justify-content-md-center'}>
                  <IonCol sizeLg={'8'} sizeMd={'10'}>
                    {this.getBreadCrumbs()}
                  </IonCol>
                </IonRow>
              ) : null}
              <IonRow className={'ion-justify-content-center'}>
                <IonCol size={'10'} sizeMd={'10'} sizeLg={'8'}>
                  {desactivate && (
                    <IonRow className={'annonce-desactivated-msg'}>{this.showMessageDesactivateedAnn()}</IonRow>
                  )}
                  <div className="detail-container p-0">
                    <IonCard className={'post-detail'} style={{ margin: '0px' }}>
                      {desactivate && (
                        <div className="corner-ribbon top-right sticky blue shadow">
                          <FormattedMessage id={'badge.sold'} />
                        </div>
                      )}
                      <PostGallery idAnnonce={post.slug} />
                      {userToken && !this.checkUserRole('user') ? (
                        <div className={'p-3 p-md-0'}>
                          <IonButton
                            className={'d-inline-flex d-md-none justify-content-center align-items-center w-100'}
                            color={'primary'}
                            expand={'block'}
                            href={`/${lang}/user/annonce/edit/${slug}`}
                          >
                            <IonIcon icon={createOutline} />
                            <FormattedMessage id={'update'} />
                          </IonButton>
                        </div>
                      ) : null}
                      <IonCardHeader>
                        <meta
                          itemProp="priceCurrency"
                          content="MAD"
                          data-reactid={
                            prix !== 0 ? madFormatter().format(prix) : intl.formatMessage(langFunction.priceNegotiable)
                          }
                        />
                        <IonCardSubtitle className={'price'} color={'primary'}>
                          {prix !== 0 ? madFormatter().format(prix) : <FormattedMessage id={'price.negotiable'} />}
                        </IonCardSubtitle>
                        <IonCardTitle>
                          <h1>{lang === 'fr' ? titreFrancais : titreArabe}</h1>
                        </IonCardTitle>
                        <div className="d-flex align-items-center flex-wrap">
                          <IonCardSubtitle className={'location'}>
                            <IonIcon icon={listCircle} />
                            <span>
                              {lang === 'fr'
                                ? categorie
                                  ? categorie.titreFrancais
                                  : ''
                                : categorie
                                ? categorie.titreArabe
                                : ''}
                            </span>
                          </IonCardSubtitle>
                          <IonCardSubtitle className={'location'}>
                            <IonIcon icon={loc} />
                            <span>{lang === 'fr' ? (ville ? ville.nomFr : '') : ville ? ville.nomArab : ''}</span>
                          </IonCardSubtitle>
                          <IonCardSubtitle className={'date'}>
                            <IonIcon icon={time} />
                            <span>{dateFormatter(datePublication, lang)}</span>
                          </IonCardSubtitle>
                        </div>
                      </IonCardHeader>
                    </IonCard>
                    <div className={'post-more-detail'}>
                      <ol className={'detail-list d-flex flex-row flex-wrap'}>
                        {surface !== null && surface !== '' && surface !== '0' && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.surface'} />
                            </span>
                            <span className={'list-type-content'}>{surface}</span>
                          </li>
                        )}
                        {poids !== null && poids !== '' && poids !== '0' && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.poids'} />
                            </span>
                            <span className={'list-type-content'}>{poids}</span>
                          </li>
                        )}
                        {/* marque, modele, typeCarbuant and boiteVitesse recieved with an id gretter than 2000 and titles
                                                (spring inserted theme to respect the relations ManyToOne...) */}
                        {marque && marque.titreFr !== null && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.marque'} />
                            </span>
                            <span className={'list-type-content'}>
                              {lang === 'fr' ? marque.titreFr : marque.titreAr}
                            </span>
                          </li>
                        )}
                        {modele && modele.titreFr !== null && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.modele'} />
                            </span>
                            <span className={'list-type-content'}>
                              {lang === 'ar' ? modele.titreAr : modele.titreFr}
                            </span>
                          </li>
                        )}
                        {anneeModele !== null && anneeModele !== '' && anneeModele !== '0' && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.anneeModele'} />
                            </span>
                            <span className={'list-type-content'}>{anneeModele}</span>
                          </li>
                        )}
                        {kilometrage !== null && kilometrage !== '' && kilometrage !== '0' && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.kilometrage'} />
                            </span>
                            <span className={'list-type-content'}>{kilometrage}</span>
                          </li>
                        )}
                        {typeCarburant && typeCarburant.titreFr !== null && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.typeCarburant'} />
                            </span>
                            <span className={'list-type-content'}>
                              {lang === 'fr' ? typeCarburant.titreFr : typeCarburant.titreAr}
                            </span>
                          </li>
                        )}
                        {puissanceFiscale !== null && puissanceFiscale !== '' && puissanceFiscale !== '0' && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.puissanceFiscale'} />
                            </span>
                            <span className={'list-type-content'}>{puissanceFiscale}</span>
                          </li>
                        )}
                        {boiteVitesse && boiteVitesse.titreFr !== null && (
                          <li className={'detail-list-item d-flex justify-content-between align-items-center'}>
                            <span className={'list-type-title'}>
                              <FormattedMessage id={'post.title.boiteVitesse'} />
                            </span>
                            <span className={'list-type-content'}>
                              {lang === 'fr' ? boiteVitesse.titreFr : boiteVitesse.titreAr}
                            </span>
                          </li>
                        )}
                      </ol>
                    </div>
                    <div className="post-desc">
                      <div className="mb-3">
                        <h2>
                          <FormattedMessage
                            id={
                              post.categorie !== null && post.categorie.id === 117
                                ? 'post.description.veterinaire'
                                : 'description'
                            }
                          />
                        </h2>
                        {/*{sanitizeHtml(description)
                          .split('\n')
                          .map(function (item: string, index: number) {
                            return (
                              <span className={'desc'} key={index}>
                                {item}
                              </span>
                            );
                          })}*/}
                        <div className={'desc'}>{parse(description)}</div>
                      </div>
                      <div>
                        <a
                          className={'report-link d-flex align-items-center'}
                          href={`/contact-signaler/${id}`}
                          onClick={() => {
                            const options = {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            };

                            EventTrackingService.createEvent({
                              data: {
                                event: {
                                  id: constants.REPORT_POST_CLICKED,
                                },
                                annonce: {
                                  id: id,
                                },
                                shop: shop
                                  ? {
                                      id: shop?.id,
                                    }
                                  : null,
                              },
                              options: options,
                            }).catch((reason) => console.error(reason.message));
                          }}
                        >
                          <IonIcon icon={alertCircleOutline} />
                          <span>
                            <FormattedMessage id={'post.signal'} />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="text-note m-3">
                    <FormattedMessage id={'text.warning'} />
                  </p>
                  {isProduction() && !isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center my-4'}>
                      <AdSlot sizes={[[300, 50]]} adUnit="banner_detail_mobile_adview_page" />
                    </div>
                  )}
                  <div className={'pt-3 post-recom'}>
                    {!shop ? (
                      <>
                        <span>
                          <FormattedMessage id={'post.recommanded'} />
                        </span>
                        <RecommendedPosts
                          categorie={get(post, 'categorie.id')}
                          typeAnnonce={get(post, 'typeAnnonce')}
                          ville={get(ville, 'id')}
                          lang={this.state.lang}
                        />
                      </>
                    ) : (
                      <>
                        <div className={'d-flex justify-content-between'}>
                          <span>
                            <FormattedMessage id={'shop.other.posts'} />
                          </span>
                          <IonRouterLink
                            routerLink={`/${lang}/${shop.slug}.htm`}
                            className={'d-flex justify-content-around align-items-end'}
                          >
                            <FormattedMessage id={'show.more'} />
                            <IonIcon icon={chevronForwardOutline} />
                          </IonRouterLink>
                        </div>
                        <OtherShopPosts shopId={shop.id} postId={id} lang={this.state.lang} />
                      </>
                    )}
                  </div>
                  {isProduction() && isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center mb-4'}>
                      <AdSlot sizes={[[300, 250]]} adUnit="banner_detail_pave" />
                    </div>
                  )}
                </IonCol>
                <IonCol size={'10'} sizeMd={'10'} sizeLg={'4'}>
                  {isProduction() && !isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center mb-3'}>
                      <AdSlot sizes={[[300, 250]]} adUnit="banner_detail_listing_page" />
                    </div>
                  )}
                  {categorie.neuf && marque && modele && (
                    <div className={'user-info d-flex flex-column p-3 mb-4'}>
                      <IonRouterLink
                        color={'primary'}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          const params = {
                            cat: lang === 'fr' ? categorie?.urlFrancais : categorie?.urlArabe,
                            mar: lang === 'fr' ? marque?.titreFr : marque?.titreAr,
                            mod: lang === 'fr' ? modele?.titreFr : modele?.titreAr,
                          };

                          const searchString = qs.stringify(params, {
                            skipNull: true,
                            skipEmptyString: true,
                            sort: false,
                          });
                          this.props.history.push({
                            pathname: `/${lang}/neuf/`,
                            search: searchString,
                          });
                        }}
                      >
                        <span>
                          <IonIcon icon={megaphoneOutline} className={'mx-1'} />
                          <FormattedMessage
                            id={'flaha.neuf.modele.detail.title'}
                            values={{
                              marque: lang === 'fr' ? marque?.titreFr : marque?.titreAr,
                              modele: lang === 'fr' ? modele?.titreFr : modele?.titreAr,
                            }}
                          />
                        </span>
                      </IonRouterLink>
                    </div>
                  )}
                  <div className={'user-info d-flex flex-column'}>
                    <div className="d-flex justify-content-between align-content-center mb-4">
                      {!shop ? (
                        <picture className={'user-info-avatar'}>
                          <source type="image/webp" srcSet={'/assets/img/avatar.webp'} />
                          <img loading="lazy" src={'/assets/img/avatar.jpg'} alt={'Flaha.ma'} />
                        </picture>
                      ) : (
                        <picture className={'user-info-avatar'}>
                          <source
                            type="image/webp"
                            srcSet={
                              shop?.shopLogo?.webpPath
                                ? process.env.REACT_APP_API_URL + constants.serveShopLogo + shop.shopLogo.webpPath
                                : '/assets/img/no_images.webp'
                            }
                          />
                          <img
                            loading="lazy"
                            decoding={'async'}
                            src={
                              shop?.shopLogo?.path
                                ? process.env.REACT_APP_API_URL + constants.serveShopLogo + shop.shopLogo.path
                                : '/assets/img/no_images.png'
                            }
                            alt={`${shop?.name} - ${shop?.id}`}
                          />
                        </picture>
                      )}
                      <div className="user-detail d-flex flex-grow-1 align-items-center">
                        <div className={'d-flex flex-column'}>
                          {shop && (
                            <IonBadge className={'d-flex justify-content-center'}>
                              <IonIcon className={'mx-1'} icon={storefrontOutline} />
                              <FormattedMessage id={'shop.title'} />
                            </IonBadge>
                          )}
                          {!shop ? (
                            <p> {nomUtilisateurAnnonce} </p>
                          ) : (
                            <p>
                              {' '}
                              {shop.name}{' '}
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: '1rem',
                                }}
                              >
                                ({nomUtilisateurAnnonce}){' '}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column mb-2">
                      {userToken && (this.checkUserRole('admin') || this.checkUserRole('moderateur')) ? (
                        <IonButton
                          className={'d-none d-md-inline-flex justify-content-center align-items-center w-100'}
                          color={'primary'}
                          expand={'block'}
                          href={`/${lang}/user/annonce/edit/${slug}`}
                        >
                          <IonIcon icon={createOutline} />
                          <FormattedMessage id={'update'} />
                        </IonButton>
                      ) : null}
                      {
                        //getUserProfile().email != post.utilisateur.email ? (}
                        /*
                          <IonButton
                            className={'d-none d-md-inline-flex justify-content-center align-items-center w-100'}
                            color={'primary'}
                            expand={'block'}
                            onClick={this.handleClick}
                          >
                            <IonIcon icon={chatbubblesOutline} />
                            <FormattedMessage id={'chat'} />
                          </IonButton>
                        ) : null*/
                      }
                      <IonButton
                        href={
                          showPhone ? `tel:${secondaryPhone || tel ? secondaryPhone || tel : shop.phone}` : undefined
                        }
                        target={'_blank'}
                        className={'d-inline-flex justify-content-center align-items-center w-100'}
                        color={'primary'}
                        expand={'block'}
                        onClick={this.onShowPhone}
                        disabled={desactivate}
                      >
                        <IonIcon icon={callOutline} />
                        {showPhone && !desactivate ? (
                          secondaryPhone || tel ? (
                            secondaryPhone ? (
                              secondaryPhone?.includes('+212') ? (
                                <span style={{ direction: 'ltr' }}>{formatPhoneNumber(secondaryPhone)}</span>
                              ) : (
                                <span style={{ direction: 'ltr' }}>
                                  {formatPhoneNumberIntl(secondaryPhone).replace('+', '00')}
                                </span>
                              )
                            ) : tel?.includes('+212') ? (
                              <span style={{ direction: 'ltr' }}>{formatPhoneNumber(tel)}</span>
                            ) : (
                              <span style={{ direction: 'ltr' }}>{formatPhoneNumberIntl(tel).replace('+', '00')}</span>
                            )
                          ) : (
                            <span style={{ direction: 'ltr' }}>{formatPhoneNumber(shop.phone)}</span>
                          )
                        ) : (
                          <FormattedMessage id={'phone.show'} />
                        )}
                      </IonButton>
                      {(tel || secondaryPhone) && (
                        <IonButton
                          onClick={() => {
                            trackEvent(
                              whatsappCallClicked({
                                postSlug: this.slug,
                                postId: this.state.post.id,
                              })
                            );
                            const options = {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            };

                            EventTrackingService.createEvent({
                              data: {
                                event: {
                                  id: constants.WHATSAPP_CALL_CLICKED,
                                },
                                annonce: {
                                  id: this.state.post.id,
                                },
                                shop: shop
                                  ? {
                                      id: shop?.id,
                                    }
                                  : null,
                              },
                              options: options,
                            }).catch((reason) => console.error(reason.message));
                          }}
                          href={
                            'https://wa.me/' +
                            (secondaryPhone || tel ? secondaryPhone || tel : shop.phone) +
                            '?text=' +
                            callMsg +
                            shareURL
                          }
                          className={'wtsp-btn d-inline-flex justify-content-center align-items-center w-100'}
                          color={'success'}
                          /*fill={'outline'}*/
                          expand={'block'}
                          disabled={desactivate}
                        >
                          <picture className={'mx-1'}>
                            <source type="image/webp" srcSet={'/assets/img/icons8-whatsapp-48.webp'} />
                            <img loading="lazy" src={'/assets/img/icons8-whatsapp-48.jpg'} alt={'Whatsapp'} />
                          </picture>
                          <FormattedMessage id={'chat.whatsapp'} />
                        </IonButton>
                      )}
                      <IonButton
                        onClick={() => {
                          trackEvent(
                            whatsappShareClicked({
                              postSlug: this.slug,
                              postId: this.state.post.id,
                            })
                          );
                          const options = {
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          };

                          EventTrackingService.createEvent({
                            data: {
                              event: {
                                id: constants.WHATSAPP_SHARE_CLICKED,
                              },
                              annonce: {
                                id: this.state.post.id,
                              },
                              shop: shop
                                ? {
                                    id: shop?.id,
                                  }
                                : null,
                            },
                            options: options,
                          }).catch((reason) => console.error(reason.message));
                        }}
                        href={'https://wa.me/?text=' + shareMsg + shareURL}
                        className={'wtsp-btn d-inline-flex justify-content-center align-items-center w-100'}
                        color={'success'}
                        /*fill={'outline'}*/
                        expand={'block'}
                        disabled={desactivate}
                      >
                        <picture className={'mx-1'}>
                          <source type="image/webp" srcSet={'/assets/img/icons8-whatsapp-48.webp'} />
                          <img loading="lazy" src={'/assets/img/icons8-whatsapp-48.jpg'} alt={'Whatsapp'} />
                        </picture>
                        <FormattedMessage id={'share.whatsapp'} />
                      </IonButton>
                      {shop && (
                        <IonRouterLink
                          className={'text-center mt-2'}
                          routerLink={`/${lang}/${shop?.slug}.htm`}
                          onClick={() => {
                            const options = {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            };

                            EventTrackingService.createEvent({
                              data: {
                                event: {
                                  id: constants.SHOW_SHOP_CLICKED,
                                },
                                annonce: {
                                  id: this.state.post.id,
                                },
                                shop: shop
                                  ? {
                                      id: shop?.id,
                                    }
                                  : null,
                              },
                              options: options,
                            }).catch((reason) => console.error(reason.message));
                          }}
                        >
                          <strong>
                            <FormattedMessage id={'shop.detail.goto'} />
                          </strong>
                        </IonRouterLink>
                      )}
                    </div>
                  </div>
                  {isProduction() && (
                    <div className={'d-flex justify-content-center my-4'}>
                      <AdSlot sizes={[[300, 600]]} adUnit="banner_detail_grand_angle" />
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {isPlatform('desktop') && <Footer detail webOnly />}
        </IonContent>

        <IonFooter className={'user-info-mini d-flex flex-row justify-content-center align-items-center d-md-none'}>
          {(tel || secondaryPhone) && (
            <IonButton
              onClick={() => {
                trackEvent(
                  whatsappCallClicked({
                    postSlug: this.slug,
                    postId: this.state.post.id,
                  })
                );
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                };

                EventTrackingService.createEvent({
                  data: {
                    event: {
                      id: constants.WHATSAPP_CALL_CLICKED,
                    },
                    annonce: {
                      id: this.state.post.id,
                    },
                    shop: shop
                      ? {
                          id: shop?.id,
                        }
                      : null,
                  },
                  options: options,
                }).catch((reason) => console.error(reason.message));
              }}
              href={
                'https://wa.me/' +
                (secondaryPhone || tel ? secondaryPhone || tel : shop.phone) +
                '?text=' +
                callMsg +
                shareURL
              }
              className={'wtsp-btn d-inline-flex justify-content-center align-items-center w-100'}
              color={'success'}
              /*fill={'outline'}*/
              expand={'block'}
              disabled={desactivate}
            >
              <picture className={'mx-1'}>
                <source type="image/webp" srcSet={'/assets/img/icons8-whatsapp-48.webp'} />
                <img loading="lazy" src={'/assets/img/icons8-whatsapp-48.jpg'} alt={'Whatsapp'} />
              </picture>
              <FormattedMessage id={'chat.whatsapp'} />
            </IonButton>
          )}

          {/*   <IonButton
              className={'d-inline-flex justify-content-center align-items-center w-100'}
              color={'primary'}
              expand={'block'}
              onClick={this.handleClick}
            >
              <IonIcon className={'mx-1'} icon={chatbubblesOutline} />
              <FormattedMessage id={'chat'} />
            </IonButton>
          ) : null*/}
          <IonButton
            href={'tel:' + (!shop ? tel : shop.phone)}
            target={'_blank'}
            className={'d-inline-flex justify-content-center align-items-center w-100'}
            color={'primary'}
            expand={'block'}
            disabled={desactivate}
            onClick={() => {
              trackEvent(
                callPhoneNumberClicked({
                  postSlug: this.slug,
                  postId: this.state.post.id,
                })
              );
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                },
              };

              EventTrackingService.createEvent({
                data: {
                  event: {
                    id: constants.CALL_PHONE_NUMBER_CLICKED,
                  },
                  annonce: {
                    id: this.state.post.id,
                  },
                  shop: shop
                    ? {
                        id: shop?.id,
                      }
                    : null,
                },
                options: options,
              }).catch((reason) => console.error(reason.message));
            }}
          >
            <IonIcon className={'mx-1'} icon={callOutline} />
            <FormattedMessage id={'call'} />
          </IonButton>
        </IonFooter>
      </>
    );
  }

  checkUserRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  render() {
    // @ts-ignore
    const { intl } = this.props;
    if (!this.state || this.state.loading) return null;

    const { post, lang, metaTitle, metaDescription, showPhone, userToken, marque, annonceViews } = this.state;
    //console.log(post)
    const {
      id,
      titreFrancais,
      titreArabe,
      prix,
      datePublication,
      description,
      ville,
      slug,
      nomUtilisateurAnnonce,
      telephoneAnnonce,
      premierePhoto,
      categorie,
      surface,
      poids,
      modele,
      anneeModele,
      kilometrage,
      typeDeCarburant,
      puissanceFiscale,
      boiteAVitesse,
      desactivate,
      shop,
      secondaryPhone,
    } = post;
    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
    const villeArab = ville ? ville.urlArab : '';
    const villeFrancais = ville ? ville.urlFr : '';
    const regionArab = ville ? ville.province.region.urlArab : '';
    const regionFr = ville ? ville.province.region.urlFr : '';
    const categorieFrancais = categorie ? categorie.urlFrancais : '';
    const categorieArab = categorie ? categorie.urlArabe : '';
    const categorieId = categorie ? categorie.id : 0;

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?src=sdkpreparse&u=`;
    const shareFacebookUrl =
      lang === 'fr'
        ? `${facebookUrl}https://flaha.ma/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        : `${facebookUrl}https://flaha.ma/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;

    let shareURL =
      lang === 'fr'
        ? `https://flaha.ma/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        : `https://flaha.ma/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;

    let shareMsg = intl.formatMessage(langFunction.shareWhatsappMsg);
    let callMsg = intl.formatMessage(langFunction.callWhatsappMsg, {
      title: lang === 'fr' ? titreFrancais : titreArabe,
    });
    let shareMsgStore = intl.formatMessage(langFunction.shareWhatsappMsgStore);

    return (
      <IonPage>
        {this.metaTags(
          id,
          titreArabe,
          titreFrancais,
          description,
          lang,
          slug,
          regionArab,
          regionFr,
          villeArab,
          villeFrancais,
          categorieFrancais,
          categorieArab,
          categorieId,
          metaTitle,
          metaDescription,
          premierePhoto,
          prix,
          marque,
          anneeModele
        )}
        {this.pageContent(
          intl,
          post,
          lang,
          showPhone,
          id,
          titreFrancais,
          titreArabe,
          prix,
          datePublication,
          description,
          ville,
          categorie,
          nomUtilisateurAnnonce,
          telephoneAnnonce,
          shareFacebookUrl,
          shareURL,
          shareMsg,
          shareMsgStore,
          callMsg,
          slug,
          surface,
          poids,
          marque,
          modele,
          anneeModele,
          kilometrage,
          typeDeCarburant,
          puissanceFiscale,
          boiteAVitesse,
          userToken,
          desactivate,
          shop,
          secondaryPhone,
          annonceViews
        )}
      </IonPage>
    );
  }

  getMetaTypeAnnonce(typeAnnonce: any, lang: any) {
    switch (typeAnnonce) {
      case 'locations': {
        return lang === 'fr' ? 'à louer' : 'للكراء';
      }
      case 'ventes': {
        return lang === 'fr' ? 'à vendre' : 'للبيع';
      }
      case 'achats': {
        return lang === 'fr' ? 'à acheter' : 'للشراء';
      }
      case 'offres': {
        return lang === 'fr' ? '' : '';
      }
      case 'demandes': {
        return lang === 'fr' ? '' : '';
      }
      default: {
        return lang === 'fr' ? '' : '';
      }
    }
  }

  metaTags(
    id: number,
    titreArabe: string,
    titreFrancais: string,
    description: string,
    lang: string,
    slug: string,
    regionArab: string,
    regionFr: string,
    villeArab: string,
    villeFrancais: string,
    categorieFrancais: string,
    categorieArab: string,
    categorieId: number,
    metaTitle: [string, string],
    metaDescription: [string, string],
    premierePhoto: photo,
    prix: number,
    marque: any,
    anneeModele: string
  ) {
    const { post } = this.state;
    const typeAnnonce = get(post, 'typeAnnonce');
    const typeAnnonceMeta = this.getMetaTypeAnnonce(typeAnnonce, lang);
    const { categorie, ville } = post;
    const nomVille = lang === 'fr' ? villeFrancais : villeArab;
    const nomCategorie = lang === 'fr' ? categorieFrancais : categorieArab;
    const titre = lang === 'fr' ? titreFrancais : titreArabe;
    let productionDate;
    let brand;
    let ldType;
    let isCar = false;

    const metaTitre = lang === 'fr' ? metaTitle[0] : metaTitle[1];
    const metaDesc = lang === 'fr' ? metaDescription[0] : metaDescription[1];

    const categorieTitre = lang === 'fr' ? categorie?.titreFrancais : categorie?.titreArabe;
    const villeTitre = lang === 'fr' ? ville?.nomFr : ville?.nomArab;

    const meta_Title =
      metaTitre && titre && nomCategorie && nomVille
        ? metaTitre
            .replace('({0})', titre.replace(/_/g, ' '))
            .replace('({1})', categorieTitre)
            .replace('({2})', villeTitre)
            .replace('({3})', typeAnnonceMeta)
        : '';
    const meta_Description =
      metaDesc && nomCategorie ? metaDesc.replace('({0})', description).replace('({1})', nomCategorie) : '';

    const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';

    const searchRegionUrl =
      lang === 'fr' ? `https://flaha.ma/fr/${regionFr}/a_vendre/` : `https://flaha.ma/ar/${regionArab}/للبيع/`;

    const searchCategoryUrl =
      lang === 'fr'
        ? `https://flaha.ma/fr/maroc/${categorieFrancais}/`
        : `https://flaha.ma/ar/المغرب/${categorieArab}/`;

    const url =
      lang === 'fr'
        ? `https://flaha.ma/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`
        : `https://flaha.ma/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;

    const mainUrl = `https://flaha.ma/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`;
    const alternateUrl = `https://flaha.ma/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`;

    const hrefLangCanonical = lang === 'fr' ? 'fr-MA' : 'ar-MA';

    const imageUrl = `${
      premierePhoto && premierePhoto.cheminWebp
        ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
        : 'https://flaha.ma/assets/img/no_images.webp'
    }`;

    if (categorieId === 142 || categorieId === 143 || categorieId === 144 || categorieId === 107) {
      ldType = 'Car';
      if (marque) {
        productionDate = anneeModele ? anneeModele : '';
        brand = marque ? (lang === 'fr' ? marque.titreFr : marque.titreAr) : '';
      }
      isCar = true;
    } else if (categorieId === 2) {
      ldType = 'Residence';
    } else if (categorieId === 117) {
      ldType = 'VeterinaryCare';
    } else {
      ldType = 'Product';
    }

    const jsonLdDataBreadcrumb = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          type: 'ListItem',
          position: 1,
          name: 'Accueil',
          item: { '@type': 'thing', '@id': 'https://flaha.ma', name: 'Accueil' },
        },
        {
          type: 'ListItem',
          position: 2,
          name: lang === 'fr' ? regionFr : regionArab,
          item: { '@type': 'thing', '@id': searchRegionUrl, name: lang === 'fr' ? regionFr : regionArab },
        },
        {
          type: 'ListItem',
          position: 3,
          name: nomCategorie,
          item: { '@type': 'thing', '@id': searchCategoryUrl, name: nomCategorie },
        },
        {
          type: 'ListItem',
          position: 4,
          name: titre,
          item: { '@type': 'thing', '@id': url, name: titre },
        },
      ],
    };

    const jsonLdData = {
      '@context': 'http://schema.org',
      '@type': ldType,
      name: titre,
      description: description,
      image: imageUrl,
      url: url,
      offers: {
        '@type': 'Offer',
        price: prix,
        priceCurrency: 'MAD',
        url: url,
        seller: { '@type': 'Person' },
      },
    };
    const jsonLdDataCar = {
      '@context': 'http://schema.org',
      '@type': ldType,
      name: titre,
      description: description,
      image: imageUrl,
      url: url,
      offers: {
        '@type': 'Offer',
        price: prix,
        priceCurrency: 'MAD',
        url: url,
        seller: { '@type': 'Person' },
      },
      brand,
      productionDate,
    };

    document.querySelector('meta[property="og:title"]').setAttribute('content', meta_Title);
    document.querySelector('meta[property="og:description"]').setAttribute('content', meta_Description);
    document.querySelector('meta[property="og:url"]').setAttribute('content', url);

    return (
      <Helmet>
        <title>{meta_Title}</title>
        <meta name="title" content={meta_Title} />
        <meta name="description" content={meta_Description} />
        <meta name="robots" content={'index, follow'} />
        <meta name="url" content={url} />
        <meta name="lang" content={lang} />
        <link rel="canonical" href={url} />
        <link rel="alternate" hrefLang={'x-default'} href={mainUrl} />
        <link rel="alternate" hrefLang={'fr-MA'} href={alternateUrl} />
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(isCar ? jsonLdDataCar : jsonLdData)}
        </script>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(jsonLdDataBreadcrumb)}
        </script>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps, RouteComponentProps {
  slug: string;
}

interface State extends FlahaState {
  userToken?: string | null | undefined;
  post: Post;
  slug: string;
  loading: boolean;
  showPhone?: boolean;
  metaTitle?: [string, string];
  metaDescription?: [string, string];
  marques: Array<Marque>;
  marque: Marque;
  annonceViews: number;
}

// @ts-ignore
export default injectIntl(SearchDetail);
