import React, { Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { IonContent, IonPage } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, Header, BlogContainer } from 'components';
import './Blog.scss';

export default class Blog extends Component<Props, State> {
  render() {
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent className={'blogPage'}>
          <BlogContainer />
          <Footer copyrightOnly />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Information Agricole</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps {}

interface State extends FlahaState {}
