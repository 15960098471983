import React, { Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonText,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, Header } from 'components';
import './Privacy.scss';
import { FormattedMessage } from 'react-intl';
import { caretForwardOutline, homeOutline } from 'ionicons/icons';

export default class Privacy extends Component<Props, State> {
  render() {
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent>
          <div className={'container mbm-100'}>
            <IonItem lines={'none'}>
              <IonRouterLink href={'/'}>
                <IonIcon size={'small'} icon={homeOutline} style={{ marginRight: '4px' }} />
                <FormattedMessage id={'home'} />
              </IonRouterLink>
              <IonIcon size={'small'} icon={caretForwardOutline} />
              <IonLabel>
                <FormattedMessage id={'footer.term.condition'} />
              </IonLabel>
            </IonItem>
            {/* Réglementant de la loi de publication */}
            <IonCard style={{ marginBottom: '28px' }}>
              <IonCardHeader color={'primary'}>
                <IonCardTitle color={'primary'}>
                  <FormattedMessage id={'privacy.title'} />
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ padding: '18px' }}>
                <p>
                  <FormattedMessage id={'privacy.regle.text.0'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.regle.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.regle.text.2'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.regle.title.0'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.regle.title.0.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.regle.title.1'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.regle.title.1.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.regle.title.2'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.regle.title.2.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.regle.title.3'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.regle.title.3.text'} />
                </p>
              </IonCardContent>
            </IonCard>
            {/* Réglementant de la loi de publication */}
            <IonCard style={{ marginBottom: '28px' }}>
              <IonCardHeader color={'primary'}>
                <IonCardTitle color={'primary'}>
                  <FormattedMessage id={'privacy.title.2'} />
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ padding: '18px' }}>
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.0'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.0.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.1'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.1.text'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.1.text.1'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.1.text.2'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.2'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.2.text'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.2.text.1'} />
                </p>
                <IonList>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.0'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.1'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.2'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.3'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.4'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.5'} />
                    </p>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <p>
                      <FormattedMessage id={'privacy.confidentiality.title.2.text.list.6'} />
                    </p>
                  </IonItem>
                </IonList>
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.3'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.3.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.4'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.4.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.5'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.5.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.6'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.6.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.7'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.7.text'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.7.text.1'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.7.text.2'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.7.text.3'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.7.text.4'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.7.text.5'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.8'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.0'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.2'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.3'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.4'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.8.text.5'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.9'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.9.text.0'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.9.text.1'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.9.text.2'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.9.text.3'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.10'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.10.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.11'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.11.text.0'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.11.text.1'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.12'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.12.text'} />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.0'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.1'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.2'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.3'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.4'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.12.text.list.5'} />
                  </li>
                </ul>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.14'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.14.text'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.14.text.0'} />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.14.text.list.0'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.14.text.list.1'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.14.text.list.2'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.14.text.list.3'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.14.text.list.4'} />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.14.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.14.text.2'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.15'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.15.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.15.text.2'} />
                  <br />
                  <FormattedMessage id={'privacy.confidentiality.title.15.text.3'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.16'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.16.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.16.text.2'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.17'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.17.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.17.text.2'} />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.17.text.2.list.1'} />
                  </li>
                  <li>
                    <FormattedMessage id={'privacy.confidentiality.title.17.text.2.list.2'} />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.17.text.3'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.18'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.18.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.19'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.19.text.1'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.19.text.2'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.20'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.20.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.21'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.22.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.22'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.22.text'} />
                </p>
                <br />
                <IonText color="primary">
                  <h2>
                    <FormattedMessage id={'privacy.confidentiality.title.23'} />
                  </h2>
                </IonText>
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.23.text'} />
                </p>
                <br />
                <p>
                  <FormattedMessage id={'privacy.confidentiality.title.24.text'} /> <br />
                  <a href="mailto:contact@flaha.ma" target="_blank">
                    contact@flaha.ma
                  </a>
                  <br />
                  <a href="https://Flaha.ma/contact">https://Flaha.ma/contact</a>
                </p>
              </IonCardContent>
            </IonCard>
          </div>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Réglemant</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps {
}

interface State extends FlahaState {
}
