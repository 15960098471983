import { createReducer } from "@reduxjs/toolkit";
import { savePosition } from "../actions/savePosAction";

const scrollPosition = {
  position: 0
};


export const savePosReducer = createReducer(
  { scrollPosition },
  {
    [savePosition.type]: (state, action) => ({
      ...state,
      scrollPosition: { ...action.payload }
    }),
  }
);