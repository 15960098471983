import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonCard, IonCardContent } from "@ionic/react";
import { Category } from 'components';
import './Categories.scss';
// @ts-ignore
import fadeInUp from 'react-animations/lib/fade-in-up';
import Radium from 'radium';
import { Category as CategoryType } from 'utils/Types/types';
// @ts-ignore
import { FlahaProps, FlahaState } from "utils/Interfaces/Interfaces";
import { constants, Events } from 'utils';
import { FormattedMessage } from "react-intl";
import { isPlatform } from '@ionic/core';
import { getCategoriesReduxUtils } from 'utils/Functions/ReferentialGetters';
import { json } from 'express';

export default class Categories extends Component<Props, State> {

    _categories = null;

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            items: 6,
            lang: "ar",
            categories: [],
        };

        

        this._handleWindowResize = this._handleWindowResize.bind(this);
    }

    componentDidMount() {
        this.getCategories();
        window.addEventListener('resize', this._handleWindowResize);
        this._handleWindowResize();
        Events.subscribe(constants.lang_change, ({ lang }) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);
        Events.destroy(constants.lang_change);
    }

    _handleWindowResize = () => {
        if (window.innerWidth <= 485) {
            this.setState({
                items: 2,
            })
        } else if (window.innerWidth <= 767) {
            this.setState({
                items: 3,
            })
        } else if (window.innerWidth <= 991) {
            this.setState({
                items: 4,
            })
        } else {
            this.setState({
                items: 6,
            })
        }
    };

    getCategories() {
        const categories = getCategoriesReduxUtils();
        const filtredCategories =categories && categories.filter((category) => category.sousCategorie === 0);
        const sortedCategories = filtredCategories && filtredCategories.sort(function (a, b) {
            return a.orderAffichage - b.orderAffichage;
          });
        this.setState({
            categories: sortedCategories
        });
    }

    render() {
        const { onCategoryClick } = this.props;
        const { categories } = this.state;
        const marginTop = !isPlatform('desktop') ? '0rem' : '0rem'
        const fade = {
            animation: 'x 1.5s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
            animationDelay: '1s',
            marginTop: window.innerWidth <= 992 ? marginTop : !isPlatform('desktop') ? '0rem' : '0rem',
            backgroundColor: '#f5f6f9',
            borderRadius: '1.25rem',
        };


        // @ts-ignore
        return (categories ? <Radium.StyleRoot className={"category-radium-container"}><div style={fade}>
            <IonGrid>
                <IonRow>
                    <IonCol size={'12'}>
                        <h2><FormattedMessage id={'home.categories'} /></h2>
                    </IonCol>
                    <IonCol className="categories-box">
                        {categories.map(function (category: CategoryType) {
                            return (
                                <IonCard key={category.id} button > 
                                    <IonCardContent>
                                        <Category key={category.id} category={category} onCategoryClick={onCategoryClick} />
                                    </IonCardContent>
                                </IonCard>
                            )})}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
        </Radium.StyleRoot> : null
        );
    }
}

interface Props extends FlahaProps {
    onCategoryClick?: Function
}

interface State extends FlahaState {
    categories?: Array<CategoryType>;
    items: number;
}
