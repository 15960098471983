import React, { Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer, Header } from 'components';
import './About.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { caretForwardOutline, homeOutline } from 'ionicons/icons';

class About extends Component<Props, State> {
  render() {
    // @ts-ignore
    const { intl } = this.props;

    const aboutSectionFiveParagraphTranslated = intl.formatMessage(
      { id: 'about.section.five.paragraph.one' },
      {
        values: {
          lienAddAnnonce: '/sendPost',
          lienAddShop: '/ouvrir-boutique',
        },
      }
    );
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <IonContent>
          <div className={'container mbm-100'}>
            <IonItem lines={'none'}>
              <IonRouterLink href={'/'}>
                <IonIcon size={'small'} icon={homeOutline} style={{ marginRight: '4px' }} />
                <FormattedMessage id={'home'} />
              </IonRouterLink>
              <IonIcon size={'small'} icon={caretForwardOutline} />
              <IonLabel>
                <FormattedMessage id={'footer.about'} />
              </IonLabel>
            </IonItem>
            <IonCard style={{ marginBottom: '28px' }}>
              <IonCardHeader color={'primary'}>
                <IonCardTitle color={'primary'}>
                  <FormattedMessage id={'footer.about'} />
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ padding: '18px' }}>
                <IonRow>
                  <IonCol>
                    <div className={'mb-3'}>
                      <h2 className={'font-weight-bold'}>
                        <FormattedMessage id={'about.section.one.subheader'} />
                      </h2>
                      <p>
                        <FormattedMessage id={'about.section.one.paragraph.one'} />
                      </p>
                      <p>
                        <FormattedMessage id={'about.section.one.paragraph.two'} />
                      </p>
                    </div>
                    <div className="mb-3">
                      <h2 className={'font-weight-bold'}>
                        <FormattedMessage id={'about.section.two.subheader'} />
                      </h2>
                      <ul>
                        <li>
                          <p>
                            <strong>
                              <FormattedMessage id={'about.section.two.bullet.one.title'} />
                            </strong>
                            {' : '}
                            <FormattedMessage id={'about.section.two.bullet.one.paragraph'} />
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <FormattedMessage id={'about.section.two.bullet.two.title'} />
                            </strong>
                            {' : '}
                            <FormattedMessage id={'about.section.two.bullet.two.paragraph'} />
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <FormattedMessage id={'about.section.two.bullet.three.title'} />
                            </strong>
                            {' : '}
                            <FormattedMessage id={'about.section.two.bullet.three.paragraph'} />
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="mb-3">
                      <h2 className={'font-weight-bold'}>
                        <FormattedMessage id={'about.section.three.subheader'} />
                      </h2>
                      <p>
                        <FormattedMessage id={'about.section.three.paragraph'} />
                      </p>
                    </div>
                    <div className="mb-3">
                      <h2 className={'font-weight-bold'}>
                        <FormattedMessage id={'about.section.four.subheader'} />
                      </h2>
                      <p>
                        <FormattedMessage id={'about.section.four.paragraph'} />
                      </p>
                    </div>
                    <div className="mb-3">
                      <h2 className={'font-weight-bold'}>
                        <FormattedMessage id={'about.section.five.subheader'} />
                      </h2>
                      <p dangerouslySetInnerHTML={{ __html: aboutSectionFiveParagraphTranslated }}></p>
                      <p>
                        <FormattedMessage id={'about.section.five.paragraph.two'} />
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }

  metaTags() {
    return (
      <Helmet>
        <title>Qui Somme Nous?</title>
      </Helmet>
    );
  }
}

interface Props extends FlahaProps {}

interface State extends FlahaState {}

// @ts-ignore
export default injectIntl(About);
