import React, {Component, Validator} from 'react';
import PropTypes from 'prop-types';
import './Category.scss';
// @ts-ignore
import {FlahaProps, FlahaState} from "utils/Interfaces/Interfaces";
import {Category as CategoryType} from 'utils/Types/types';
import {constants, Events} from "utils";
import {getLanguage} from "utils/Functions/Functions";
import { Link } from 'react-router-dom';

export default class Category extends Component<Props, State> {

    static propTypes: { category: Validator<NonNullable<object>> };


    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            lang: "ar",
        }
    }

    UNSAFE_componentWillMount(): void {
        getLanguage().then((lang) => {
            if (lang && (lang === 'fr' || lang === 'ar')) {
                this.setState({
                    lang: lang,
                }, () => {
                    Events.publish(constants.lang_change, {lang: lang});
                })
            }
        }).catch((error) => {
            console.error(`Error has been thrown (${error.message})`, error);
        });
    }

    componentDidMount(): void {
        Events.subscribe(constants.lang_change, ({lang}) => {
            this.setState({
                lang: lang,
            })
        });
    }

    componentWillUnmount(): void {
        Events.destroy(constants.lang_change);
    }

    render() {
        const {category, onCategoryClick} = this.props;
        const {lang} = this.state;
        
        return (
            <div className={'category'}>
              <Link to={lang === 'ar' ?  `/ar/المغرب/${category.urlArabe}/` 
                   : `/fr/${constants.allRegion}/${category.urlFrancais}/`}  onClick={() => onCategoryClick && onCategoryClick(category)}>
                         <picture>
                        <source type='image/webp' srcSet={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${category.nomImage.replace(".jpeg",".webp")}?small=true`} className={'rounded'} />
                        <img loading="lazy" src={`${process.env.REACT_APP_API_URL}${constants.pictures}/categories/${category.nomImage}?small=true`} alt={lang === 'fr' ? category.titreFrancais : category.titreArabe}/>
                    </picture>
                    <p className="legend">{lang === 'fr' ? category.titreFrancais : category.titreArabe}</p>
                   </Link>
            </div>
        );
    }
}

Category.propTypes = {
    category: PropTypes.object.isRequired,
};

interface Props extends FlahaProps {
    category: CategoryType;
    onCategoryClick?: Function;
}

interface State extends FlahaState {
}
