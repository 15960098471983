import React, { Component, FormEvent } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { getUserProfile, getUserToken, setUserProfile, setUserToken, validateEmail } from 'utils/Functions/Functions';
import { langFunction } from 'lang';
import { constants, Events } from 'utils';
import { Footer, Header } from 'components';
import {
  CreateAnimation,
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './Profile.scss';
import { alertCircle, cloudUploadOutline, createOutline, lockClosedOutline, trashOutline } from 'ionicons/icons';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import fr from 'react-phone-number-input/locale/fr.json';
import ar from 'lang/phone-input/ar.json';

class Profile extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      email: '',
      nom: '',
      telephone: undefined,
      type: '',
      imageUri: '',
      showMessage: false,
      updated: false,
      enableSubmit: false,
      token: '',
      password: '',
      showPhoneMsg: false,
      showAlert: false,
      showUploadModal: false,
      keywordFile: false,
      showUploadError: false,
      roles: '',
      isSubmitting: false,
    };
    this.onSubmitUpdate = this.onSubmitUpdate.bind(this);
    this.desactivateAccount = this.desactivateAccount.bind(this);
  }

  getProfile() {
    this.setState({
      email: getUserProfile().email,
      nom: getUserProfile().nom,
      telephone: getUserProfile().telephone,
      type: getUserProfile().type,
      imageUri: getUserProfile().imageUrl,
      roles: getUserProfile().roles,
    });
  }

  phoneIsSubmitted() {
    const { telephone } = this.state;
    if (!telephone) {
      this.setState({ showPhoneMsg: true });
    }
  }

  //display warning when phone number is not submitted
  showMessagePhone() {
    const { showPhoneMsg } = this.state;
    if (showPhoneMsg) {
      return (
        <CreateAnimation
          duration={1000}
          easing={'ease-in'}
          play={true}
          fromTo={[
            {
              property: 'opacity',
              fromValue: '0',
              toValue: '1',
            },
          ]}
        >
          <IonGrid>
            <div className={'alert alert-danger'}>
              <IonIcon icon={alertCircle} size={'large'} />
              <FormattedMessage id={'profile.phone.required'} />
            </div>
          </IonGrid>
        </CreateAnimation>
      );
    } else {
      return null;
    }
  }

  UNSAFE_componentWillMount() {
    this.getProfile();
  }

  componentDidMount() {
    this.getTokenUser();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.phoneIsSubmitted();
  }

  getTokenUser() {
    getUserToken().then((data) => {
      if (data === undefined) {
        this.setState({ token: null });
      } else {
        this.setState({ token: data });
      }
    });
  }

  getUserProfile(token: string, password: string | null) {
    let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
    });

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        if (request.status === 200) {
          return request.json();
        }
        if (request.status === 401) {
          ////////////////////// Partie Ajoutée ///////////////
          let urlRefrech = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
          fetch(urlRefrech, {
            method: 'POST',
            body: JSON.stringify({ authToken: token }),
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              //console.log(request)
              return request.json();
            })
            .then((jsonRequest) => {
              //console.log(jsonRequest);
              setUserToken(jsonRequest.token);
              token = localStorage.getItem('@flaha.userToken');
              let bearer = 'Bearer ' + token;
              const headers = new Headers({
                Authorization: bearer,
              });
              return fetch(url, {
                method: 'GET',
                credentials: 'same-origin',
                headers: headers,
              }).then((result) => {
                return result.json();
                ////////////////////// Partie Ajoutée ///////////////
              });
            });
        }
      })
      .then((jsonRequest) => {
        if (jsonRequest) {
          setUserProfile(
            jsonRequest.email,
            jsonRequest.nom,
            jsonRequest.telephone,
            jsonRequest.type,
            jsonRequest.imageUrl,
            jsonRequest.isAdmin,
            jsonRequest.moderator ? 'true' : 'false',
            jsonRequest.roles ? jsonRequest.roles : []
          );
        }
      });
  }

  onSubmitUpdate(event: FormEvent) {
    this.setState({ isSubmitting: true });
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}${constants.userProfilePut}`;

    let { nom, telephone, email, type, imageUri, token, password } = this.state;
    let userData = {
      provider: 'email',
      email: email,
      nom: nom,
      telephone: telephone,
      type: type,
      imageUrl: imageUri,
    };
    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
      'Content-Type': 'application/json',
      accept: '*/*',
    });
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(userData),
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        if (request.status === 200) {
          this.setState({ updated: true, showMessage: true, isSubmitting: false });
          return request.json();
        }
        if (request.status === 401) {
          ////////////////////// Partie Ajoutée ///////////////
          let urlRefrech = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
          fetch(urlRefrech, {
            method: 'POST',
            body: JSON.stringify({ authToken: token }),
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              //console.log(request)
              //debugger;
              return request.json();
            })
            .then((jsonRequest) => {
              //console.log(jsonRequest);
              //debugger;
              setUserToken(jsonRequest.token);
              token = localStorage.getItem('@flaha.userToken');
              let bearer = 'Bearer ' + token;
              const headers = new Headers({
                Authorization: bearer,
              });
              fetch(url, {
                method: 'PUT',
                body: JSON.stringify(userData),
                credentials: 'same-origin',
                headers: headers,
              }).then((result) => {
                this.setState({ updated: true, showMessage: true, isSubmitting: false });
                return result.json();
                ////////////////////// Partie Ajoutée ///////////////
              });
            });
        }
        this.setState({ updated: true, isSubmitting: false });
      })
      .then((jsonRequest) => {
        if (jsonRequest) {
          this.getUserProfile(jsonRequest.token, password);
          this.setState({ token: jsonRequest.token });
        }
      })
      .catch((error) => {
        console.error(`Can not update (${error.message})`, error);
        this.setState({ isSubmitting: false });
      });
  }

  activateSubmit() {
    const { nom, email, telephone } = this.state;

    if (telephone) {
      this.setState({ showPhoneMsg: false });
    } else {
      this.setState({ showPhoneMsg: true });
    }

    if (nom && nom !== '' && email && email !== '' && validateEmail(email)) {
      if (telephone && isPossiblePhoneNumber(String(telephone))) {
        this.setState({ enableSubmit: true });
        return;
      }
    }
    this.setState({ enableSubmit: false });
  }

  // show Message
  showMessage() {
    const { showMessage } = this.state;
    let classN = '';
    let msg = <FormattedMessage id={'sign.up.account.infos.error'} />;
    if (showMessage === true) {
      classN = 'alert-success';
      msg = <FormattedMessage id={'user.profile.success'} />;
    } else {
      classN = 'alert-danger';
      msg = <FormattedMessage id={'sign.up.account.infos.error'} />;
    }
    return (
      <CreateAnimation
        duration={1000}
        easing={'ease-in'}
        play={true}
        fromTo={[
          {
            property: 'opacity',
            fromValue: '0',
            toValue: '1',
          },
        ]}
      >
        <div className={'alert ' + classN}>
          <IonIcon icon={alertCircle} size={'large'} /> {msg}
        </div>
      </CreateAnimation>
    );
  }

  desactivateAccount(event: FormEvent) {
    this.setState({ showAlert: true });
  }

  showAlertMessage() {
    const { lang, showAlert } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    let title, message, confirm, close;
    title = intl.formatMessage(langFunction.userAccountDeleteTitle);
    message = intl.formatMessage(langFunction.userAccountDeleteMessage);
    confirm = intl.formatMessage(langFunction.confirmYes);
    close = intl.formatMessage(langFunction.close);

    return (
      <IonAlert
        isOpen={showAlert}
        subHeader={title}
        message={message}
        buttons={[
          {
            text: close,
            role: 'cancel',
            handler: () => {
              this.setState({ showAlert: false });
            },
          },
          {
            text: confirm,
            role: 'ok',
            handler: () => {
              this.desactivateUser();
            },
          },
        ]}
      />
    );
  }

  desactivateUser() {
    let url = process.env.REACT_APP_API_URL + constants.userProfileDel;
    let token = localStorage.getItem('@flaha.userToken');
    let bearer = 'Bearer ' + token;
    const headers = new Headers({
      Authorization: bearer,
    });

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    }).then((request) => {
      if (request.status === 200) {
        return (window.location.href = '/logout');
      }
    });
  }

  onFileChange(fileChangeEvent: any) {
    this.setState({ keywordFile: fileChangeEvent.target.files[0] });
  }

  async submitFile() {
    const { keywordFile } = this.state;
    if (keywordFile.name === 'keywords.xlsx' || keywordFile.name === 'keywords2.xlsx') {
      if (!keywordFile) {
        return false;
      }
      let formData = new FormData();
      formData.append('file', keywordFile, keywordFile.name);
      let url = process.env.REACT_APP_API_URL + '/api/files/uploadKeyword';
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        this.setState({ showUploadModal: false });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ showUploadError: true });
      return false;
    }
  }

  render() {
    const {
      lang,
      email,
      nom,
      telephone,
      type,
      enableSubmit,
      updated,
      showUploadModal,
      showUploadError,
      roles,
      isSubmitting,
    } = this.state;
    // @ts-ignore
    const { intl } = this.props;
    const tel =
      telephone?.includes('+212') || telephone?.includes('+') || telephone?.includes('00212')
        ? telephone
        : `+212${telephone}`;
    return (
      <IonPage>
        <Header />
        <IonContent>
          <div className={'container mbm-100'}>
            {roles.includes('admin') ? (
              <IonModal
                isOpen={showUploadModal}
                cssClass="upload-keyword-class"
                swipeToClose={true}
                onDidDismiss={() => this.setState({ showUploadModal: false })}
                showBackdrop={true}
                backdropDismiss={true}
              >
                <IonHeader>
                  <p>
                    <FormattedMessage id={'upload.label'} />
                  </p>
                </IonHeader>
                {showUploadError ? (
                  <div
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                      fontSize: '10px',
                      paddingLeft: '17px',
                      paddingRight: '17px',
                    }}
                  >
                    <IonIcon style={{ marginBottom: '-4px' }} icon={alertCircle} size={'small'} />
                    <FormattedMessage id={'upload.error'} />
                  </div>
                ) : null}
                <IonItem>
                  <input type="file" accept=".xls,.xlsx" onChange={(ev) => this.onFileChange(ev)}></input>
                </IonItem>
                <IonButton expand="block" color={'primary'} onClick={() => this.submitFile()}>
                  <IonIcon size={'small'} icon={cloudUploadOutline} />
                  <span>
                    <FormattedMessage id={'user.profile.confirm'} />
                  </span>
                </IonButton>
              </IonModal>
            ) : null}
            <IonRow>
              <div className={'links'}>
                <IonRow>
                  <IonCol sizeLg={'2'}></IonCol>
                  <IonCol sizeLg={'8'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/mes-annonces'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={createOutline} />
                        <span>
                          <FormattedMessage id={'user.annonce'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg={'6'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/profile'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={createOutline} />
                        <span>
                          <FormattedMessage id={'user.profile.text'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                  <IonCol sizeLg={'6'} sizeXs={'12'}>
                    <IonItem lines={'none'}>
                      <IonButton routerLink={'/user/changepassword'} expand="block" color={'primary'}>
                        <IonIcon size={'small'} icon={lockClosedOutline} />
                        <span>
                          <FormattedMessage id={'user.profile.password.text'} />
                        </span>
                      </IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
                {roles.includes('admin') ? (
                  <IonRow>
                    <IonCol sizeLg={'12'} sizeXs={'12'}>
                      <IonItem lines={'none'}>
                        <IonButton
                          expand="block"
                          color={'primary'}
                          onClick={() => this.setState({ showUploadModal: true })}
                        >
                          <IonIcon icon={cloudUploadOutline} />
                          <span>
                            <FormattedMessage id={'upload.label'} />
                          </span>
                        </IonButton>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                ) : null}
              </div>
              <div className={'Profile'}>{this.showMessagePhone()}</div>
              {updated ? (
                <div className={'Profile'}>
                  <IonGrid>{this.showMessage()}</IonGrid>
                </div>
              ) : null}
              <form className={'Profile'} method={'POST'} onSubmit={this.onSubmitUpdate}>
                <IonGrid>
                  <IonRow>
                    <IonCol size={'12'}>
                      <IonRadioGroup
                        value={type}
                        onIonChange={(e) => {
                          this.setState({ type: e.detail.value }, () => {
                            this.activateSubmit();
                          });
                        }}
                      >
                        <IonListHeader>
                          <IonLabel>
                            <FormattedMessage id={'user.profile.text'} />
                          </IonLabel>
                        </IonListHeader>
                        <IonGrid>
                          <IonRow>
                            <IonCol sizeLg={'6'} sizeXs={'12'}>
                              <IonItem>
                                <IonLabel>
                                  <FormattedMessage id={'form.account.create.me'} />
                                </IonLabel>
                                <IonRadio slot="start" value="Particulier" />
                              </IonItem>
                            </IonCol>
                            <IonCol sizeLg={'6'} sizeXs={'12'}>
                              <IonItem>
                                <IonLabel>
                                  <FormattedMessage id={'form.account.create.company'} />
                                </IonLabel>
                                <IonRadio slot="start" value="Professionnel" />
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.name'} />
                        </IonLabel>
                        <IonInput
                          value={nom}
                          type={'text'}
                          inputMode={'text'}
                          name={'name'}
                          placeholder={intl.formatMessage(langFunction.formNamePlaceholder)}
                          onIonChange={(event) => {
                            this.setState({ nom: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                        />
                        <IonText slot={'end'} color={'medium'}>
                          <FormattedMessage id={'form.required'} />
                        </IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="stacked">
                          <FormattedMessage id={'form.phone'} />
                        </IonLabel>
                        <PhoneInput
                          labels={lang === 'fr' ? fr : ar}
                          international={false}
                          countryOptionsOrder={['MA', 'FR', '|']}
                          defaultCountry="MA"
                          placeholder={intl.formatMessage(langFunction.formPhonePlaceholder)}
                          value={tel}
                          onChange={(value) => {
                            this.setState({ telephone: value }, () => this.activateSubmit());
                          }}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <IonItem>
                        <IonLabel position="floating">
                          <FormattedMessage id={'form.email'} />
                        </IonLabel>
                        <IonInput
                          value={email}
                          type={'email'}
                          inputMode={'email'}
                          name={'login'}
                          placeholder={intl.formatMessage(langFunction.formEmailPlaceholder)}
                          onIonChange={(event) => {
                            this.setState({ email: event.detail.value! }, () => {
                              this.activateSubmit();
                            });
                          }}
                          disabled={true}
                        />
                        <IonText slot={'end'} color={'medium'}>
                          <FormattedMessage id={'form.required'} />
                        </IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size={'12'}>
                      <button
                        className={'btn btn-block mt-2'}
                        type={'submit'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        disabled={!enableSubmit}
                      >
                        {isSubmitting ? (
                          <IonSpinner name="crescent" />
                        ) : (
                          <FormattedMessage id={'user.profile.confirm'} />
                        )}
                      </button>
                    </IonCol>
                    <IonCol size={'12'} className={'text-center mt-2'}></IonCol>
                    <IonCol size={'12'}>
                      {this.showAlertMessage()}
                      <button
                        className={'btn btn-block mt-2'}
                        type={'button'}
                        style={{
                          backgroundColor: 'var(--ion-color-primary)',
                          color: 'var(--ion-color-light)',
                        }}
                        onClick={this.desactivateAccount}
                      >
                        <IonIcon size={'small'} icon={trashOutline} />
                        <FormattedMessage id={'user.profile.desactivate'} />
                      </button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </IonRow>
          </div>
          <Footer copyrightOnly />
        </IonContent>
      </IonPage>
    );
  }
}

interface Props extends FlahaProps {}

interface State extends FlahaState {
  nom: string | null;
  email: string | null;
  telephone: any;
  type: string | null;
  imageUri: string | null;
  token: string | null;
  enableSubmit: boolean;
  showMessage: boolean;
  password: string | null;
  updated: boolean;
  showPhoneMsg: boolean;
  showAlert: boolean;
  showUploadModal: boolean;
  keywordFile: any;
  showUploadError: boolean;
  roles: string;
  isSubmitting: boolean;
}

// @ts-ignore
export default injectIntl(Profile);
