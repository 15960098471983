import React from 'react';
import { format } from 'timeago.js';
import { constants } from 'utils';
import { getUserProfile } from 'utils/Functions/Functions';
import './Message.scss';

export default function Message({ message, own, conversation,lang }) {
  return (
    <div className={own ? 'message' : 'message own'}  >
      
      <div className="messageTop">
        <img
          className="messageImg"
          loading="lazy"
          decoding={'async'}
          src={`${
            conversation.annonce.premierePhoto && conversation.annonce.premierePhoto.chemin
              ? process.env.REACT_APP_API_URL +
                constants.pictures +
                conversation.annonce.premierePhoto.chemin +
                '?small=false'
              : '/assets/img/no_images.webp'
          }`}
        />
        <div className="messageText">{message.message}</div>
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
}
