import React, { Component } from 'react';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { Post, Subscription } from 'utils/Types/types';
import { constants, Events, urlDataFormatter } from 'utils';
import { Footer, Header } from 'components';
import {
  CreateAnimation,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './AnnoncesUser.scss';
import { removeUserToken, setUserToken } from 'utils/Functions/Functions';
import { alertCircle, diamondOutline, informationCircle } from 'ionicons/icons';
import AnnonceCard from '../../components/AnnonceCard/AnnonceCard';
import subscriptionService from '../../services/SubscriptionService';
import { langFunction } from 'lang';

class AnnoncesUser extends Component<Props, State> {
  private _infiniteScroll: any = null;

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      lang: 'ar',
      posts: [],
      filtredPost: [],
      alerts: [],
      page: 0,
      itemCount: 20,
      lastPage: false,
      totalElements: 0,
      token: null,
      showMessage: false,
      showMessageAnnonce: false,
      deleted: false,
      showAlert: false,
      scrollPage: true,
      showModal: false,
      showLoading: false,
      slug: '',
      annonceId: '',
      moderate: '',
      slicedSlug: '',
    };
    this.onIonInfinite = this.onIonInfinite.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAnnonceClicked = this.handleAnnonceClicked.bind(this);
    this.updateSubscription = this.updateSubscription.bind(this);
    //this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  onIonInfinite(event: Event) {
    const item = this.state.itemCount;
    this.setState({ itemCount: item });
    const { posts } = this.state;
    if (posts.length >= 20) {
      this.getAnnonces();
    }
    if (this._infiniteScroll) this._infiniteScroll.complete();
  }

  getTokenUser() {
    this.setState({
      token: localStorage.getItem('@flaha.userToken'),
    });
  }

  componentDidMount() {
    this.getAnnonces();
    this.getTokenUser();
    this.getSubscription();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  updateSubscription() {
    this.getSubscription();
  }

  // Supprimer annonce
  deleteAnnonce(slug: string, motif: string) {
    const url = `${process.env.REACT_APP_API_URL}${constants.userAnnonceDelete}`;

    let { token } = this.state;

    let bearer = 'Bearer ' + token;
    //console.log(bearer);

    const headers = new Headers({
      Authorization: bearer,
      'Content-Type': 'application/json',
      accept: '*/*',
    });
    let urlDelete = url + slug + '/' + motif;
    fetch(urlDelete, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: headers,
    })
      .then((request) => {
        //console.log(request.status);
        if (request.status === 200) {
          const { posts } = this.state;
          const updatedPosts = posts.filter((item) => item.slug !== slug);

          this.setState({
            posts: updatedPosts,
            showMessage: true,
            deleted: true,
          });
          this.getAnnonces();
          return;
        }
        if (request.status === 401) {
          ////////////////////// Partie Ajoutée ///////////////
          //debugger;
          let urlRefresh = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
          fetch(urlRefresh, {
            method: 'POST',
            body: JSON.stringify({ authToken: token }),
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              //console.log(request)
              //debugger;
              return request.json();
            })
            .then((jsonRequest) => {
              //console.log(jsonRequest);
              //debugger;
              setUserToken(jsonRequest.token);
              token = localStorage.getItem('@flaha.userToken');
              let bearer = 'Bearer ' + token;
              const headers = new Headers({
                Authorization: bearer,
              });
              fetch(`${url}${slug}`, {
                method: 'DELETE',
                credentials: 'same-origin',
                headers: headers,
              }).then((result) => {
                if (result.status !== 200) {
                  const { posts } = this.state;
                  const updatedPosts = posts.filter((item) => item.slug !== slug);

                  this.setState({
                    posts: updatedPosts,
                    showMessage: true,
                    deleted: true,
                  });
                  this.getAnnonces();
                  return;
                }
              });
            });
          ////////////////////// Partie Ajoutée ///////////////
          //removeUserToken();
          //return window.location.href = '/login';
          return;
        }
        this.setState({ deleted: true });
      })
      .catch((error) => {
        console.error(`Can not delete (${error.message})`, error);
      });
  }

  // show Message
  showMessage() {
    const { showMessage } = this.state;
    let classN = '';
    let msg = <FormattedMessage id={'user.annonce.delete.error'} />;
    if (showMessage === true) {
      classN = 'alert-success';
      msg = <FormattedMessage id={'user.annonce.delete.success'} />;
    } else {
      classN = 'alert-danger';
      msg = <FormattedMessage id={'user.annonce.delete.error'} />;
    }
    return (
      <CreateAnimation
        duration={1000}
        easing={'ease-in'}
        play={true}
        fromTo={[
          {
            property: 'opacity',
            fromValue: '0',
            toValue: '1',
          },
        ]}
      >
        <div className={'alert text-center-ua ' + classN} style={{ marginTop: '13px' }}>
          <IonIcon icon={alertCircle} size={'large'} /> {msg}
        </div>
      </CreateAnimation>
    );
  }

  getAnnonces() {
    let { page, itemCount, posts, token } = this.state;
    let urlAnnonce = process.env.REACT_APP_API_URL + constants.userAnnonces;
    let data = {
      page: page,
      size: itemCount,
    };
    let formData = urlDataFormatter(data);
    token = localStorage.getItem('@flaha.userToken');
    let bearer = 'Bearer ' + token;
    //console.log(bearer);
    const headers = new Headers({
      Authorization: bearer,
    });
    fetch(`${urlAnnonce}` + (formData.length > 0 ? `?${formData.join('&')}` : ''), {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        //console.log(result);
        //debugger;
        if (result.status === 500) {
          this.setState({ showMessageAnnonce: true });
          return;
        }
        if (result.status === 401) {
          ////////////////////// Partie Ajoutée ///////////////
          let url = process.env.REACT_APP_API_URL + '/api/token/refreshtoken';
          fetch(url, {
            method: 'POST',
            body: JSON.stringify({ authToken: token }),
            credentials: 'same-origin',
            headers: headers,
          })
            .then((request) => {
              //console.log(request)
              //debugger;
              return request.json();
            })
            .then((jsonRequest) => {
              //console.log(jsonRequest);
              //debugger;
              setUserToken(jsonRequest.token);
              token = localStorage.getItem('@flaha.userToken');
              let bearer = 'Bearer ' + token;
              const headers = new Headers({
                Authorization: bearer,
              });
              fetch(`${urlAnnonce}` + (formData.length > 0 ? `?${formData.join('&')}` : ''), {
                method: 'GET',
                credentials: 'same-origin',
                //mode: 'no-cors',
                headers: headers,
              })
                .then((result) => {
                  //console.log(result);
                  //debugger;
                  if (result.status === 500) {
                    this.setState({ showMessageAnnonce: true });
                    return;
                  }
                  if (result.status === 401) {
                    removeUserToken();
                    return (window.location.href = '/login');
                    return;
                  }
                  if (result.status !== 200) {
                    return {
                      content: [],
                      number: page,
                      last: true,
                    };
                  }
                  return result.json();
                })
                .then((jsonResult) => {
                  if (jsonResult.content) {
                    //console.log("jsonResult : ",jsonResult);
                    //console.log(jsonResult.content);
                    this.setState(
                      {
                        // @ts-ignore
                        posts: [...posts, ...jsonResult.content],
                        page: jsonResult.number + 1,
                        lastPage: jsonResult.totalPages,
                        totalElements: jsonResult.totalElements,
                      },
                      () => {
                        if (this._infiniteScroll) this._infiniteScroll.complete();
                      }
                    );
                    if (jsonResult.totalElements === 0) {
                      this.setState({ showMessageAnnonce: true });
                      //console.log(this.state.showMessageAnnonce);
                    }
                    return;
                  } else {
                    this.setState({ showMessageAnnonce: true });
                  }
                  if (this._infiniteScroll) this._infiniteScroll.complete();
                })
                .catch((error) => {
                  console.error(error.message, error);
                  if (this._infiniteScroll) this._infiniteScroll.complete();
                });
            });
          ////////////////////// Partie Ajoutée ///////////////
          //removeUserToken();
          //return window.location.href = '/login';
          return;
        }
        if (result.status !== 200) {
          return {
            content: [],
            number: page,
            last: true,
          };
        }
        return result.json();
      })
      .then((jsonResult) => {
        if (jsonResult.content) {
          //console.log("jsonResult : ",jsonResult);
          //console.log(jsonResult.content);
          this.setState(
            {
              // @ts-ignore
              posts: [...posts, ...jsonResult.content],
              page: jsonResult.number + 1,
              lastPage: jsonResult.totalPages,
              totalElements: jsonResult.totalElements,
            },
            () => {
              if (this._infiniteScroll) this._infiniteScroll.complete();
            }
          );
          if (jsonResult.totalElements === 0) {
            this.setState({ showMessageAnnonce: true });
            //console.log(this.state.showMessageAnnonce);
          }
          return;
        } else {
          this.setState({ showMessageAnnonce: true });
        }
        if (this._infiniteScroll) this._infiniteScroll.complete();
      })
      .catch((error) => {
        console.error(error.message, error);
        if (this._infiniteScroll) this._infiniteScroll.complete();
      });
  }

  handleDelete(slug: string, motif: string) {
    this.deleteAnnonce(slug, motif);
  }

  handleAnnonceClicked(slug: string, slicedSlug: string) {
    this.setState({ slug: slug, slicedSlug: slicedSlug, showLoading: true, showModal: true });
  }

  handleSearchSubmit() {
    const { filtredPost, posts, annonceId, moderate } = this.state;
    if (moderate !== '' && typeof moderate !== undefined) {
      // Chercher d'abord dans la liste des annonces affichées (première page), si pas de résultat,
      // appeler le WS
      var Moderate = JSON.parse(moderate);
      let filtredPosts = posts.filter((item: Post) => {
        return item.moderate === Moderate;
      });
      //console.log('filtredPosts :',filtredPosts);
      debugger;
      if (filtredPosts.length === 0) {
        const url = `${process.env.REACT_APP_API_URL + constants.posts + '?moderate=' + Moderate}`;
        const headers = new Headers({
          'Content-Type': 'application/json',
          SameSite: 'secure',
        });

        fetch(url, {
          method: 'GET',
          credentials: 'same-origin',
          //mode: 'no-cors',
          headers: headers,
        })
          .then((request) => {
            if (request.status === 200) {
              return request.json();
            }
          })
          .then((jsonResult) => {
            //console.log('jsonResult : ',jsonResult);
            if (jsonResult !== undefined) {
              filtredPosts = [...posts, ...jsonResult.content];
            }
          });
      }
      this.setState({ filtredPost: filtredPosts });
    } else {
      window.location.reload();
    }
  }

  getSubscription() {
    const token = localStorage.getItem('@flaha.userToken');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    subscriptionService
      .getActiveSubscription(options)
      .then((value) => {
        this.setState({ subscription: value.data });
      })
      .catch((reason) => console.error(`Error has been thrown (${reason.message})`, reason));
  }

  checkUserRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  render() {
    const {
      posts,
      slicedSlug,
      deleted,
      showModal,
      showLoading,
      alerts,
      showMessageAnnonce,
      annonceId,
      moderate,
      lang,
      filtredPost,
      subscription,
    } = this.state;

    posts.map((post) => (alerts[post.id] = false));
    const slugProps = {
      // make sure all required component's inputs/Props keys&types match
      slug: slicedSlug,
    };

    // @ts-ignore
    const { intl } = this.props;
    const token = localStorage.getItem('@flaha.userToken');

    return (
      <IonPage>
        <Header />
        <IonContent>
          <IonGrid fixed={true} className={'min-vh-100'}>
            <IonRow className={'justify-content-center align-items-end'}>
              <IonCol sizeXl={'3'} sizeLg={'3'} sizeMd={'3'} sizeSm={'6'} sizeXs={'6'}>
                <IonItem className="ion-item-search-verification">
                  <IonLabel position={'stacked'}>
                    <FormattedMessage id={'shop.form.status'} />
                  </IonLabel>
                  <IonSelect
                    interface={'alert'}
                    name={'category'}
                    placeholder={intl.formatMessage(langFunction.shopFormStatus)}
                    onIonChange={(e) => this.setState({ moderate: e.detail.value! })}
                  >
                    <IonSelectOption value="">
                      {' '}
                      <FormattedMessage id={'annonces.all'} />
                    </IonSelectOption>
                    <IonSelectOption value="true">
                      {' '}
                      <FormattedMessage id={'moderate.true'} />
                    </IonSelectOption>
                    <IonSelectOption value="false">
                      {' '}
                      <FormattedMessage id={'moderate.false'} />
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol sizeLg={'4'} sizeMd={'12'} sizeSm={'12'}>
                <button
                  type={'submit'}
                  className={'btn btn-block btn-light'}
                  onClick={() => this.handleSearchSubmit()}
                  style={{ backgroundColor: 'black', color: 'var(--ion-color-light)' }}
                >
                  <FormattedMessage id={'menu.search'} />
                </button>
              </IonCol>
            </IonRow>
            <IonRow>{deleted ? this.showMessage() : null}</IonRow>
            <IonRow>
              {' '}
              <div className={'alert text-center-ua alert-primary'} style={{ marginTop: '13px' }}>
                <IonIcon icon={alertCircle} size={'large'} /> <FormattedMessage id={'annonces.user.shop'} />
              </div>
            </IonRow>
            {subscription && (
              <div className={'d-flex justify-content-center align-items-baseline p-2'}>
                <IonText className={'h4'} color={'primary'}>
                  <FormattedMessage
                    id={'user.total.credits'}
                    values={{ credits: subscription.totalCredits <= 0 ? 0 : subscription.totalCredits }}
                  />{' '}
                </IonText>
                <IonIcon size={'small'} className={'mx-1'} color={'primary'} icon={diamondOutline} />
              </div>
            )}
            <IonRow className={'justify-content-center'}>
              {showMessageAnnonce === false ? (
                filtredPost.length === 0 ? (
                  posts.map((value, index) => (
                    <AnnonceCard
                      key={index}
                      handleDelete={this.handleDelete}
                      handleAnnonceClicked={this.handleAnnonceClicked}
                      lang={lang}
                      post={value}
                      intl={intl}
                      token={token}
                      updateSubscription={this.updateSubscription}
                    />
                  ))
                ) : (
                  filtredPost.map((value, index) => (
                    <AnnonceCard
                      key={index}
                      handleDelete={this.handleDelete}
                      handleAnnonceClicked={this.handleAnnonceClicked}
                      lang={lang}
                      post={value}
                      intl={intl}
                      token={token}
                      updateSubscription={this.updateSubscription}
                    />
                  ))
                )
              ) : (
                <div
                  className={'container text-no-post'}
                  style={{
                    marginTop: '130px',
                    marginBottom: '13.1rem',
                  }}
                >
                  <IonIcon icon={informationCircle} size={'large'} color={'medium'} />
                  <p>
                    <FormattedMessage id={'no.my.posts'} />
                  </p>
                  <a href={'/sendPost'}>
                    <FormattedMessage id={'menu.post.add'} />
                  </a>
                </div>
              )}
            </IonRow>
            <IonInfiniteScroll
              ref={(ref) => (this._infiniteScroll = ref)}
              threshold={'200rem'}
              onIonInfinite={this.onIonInfinite}
              disabled={false}
            >
              <IonInfiniteScrollContent loadingSpinner={'crescent'} />
            </IonInfiniteScroll>
          </IonGrid>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }
}

interface Props extends FlahaProps {}

interface State extends FlahaState {
    posts: Array<Post>;
    filtredPost: Array<Post>;
    page: number;
    itemCount: number;
    lastPage: boolean;
    totalElements: number;
    token: string | null;
    showMessage: boolean;
    deleted: boolean;
    showAlert: boolean;
    scrollPage: boolean;
    alerts: Array<boolean>;
    showMessageAnnonce: boolean;
    showModal: boolean;
    showLoading: boolean;
    slug: string;
    annonceId: string;
    moderate: string;
    slicedSlug: string;
    subscription?: Subscription;
}

// @ts-ignore
export default injectIntl(AnnoncesUser);
